import React, { Component } from 'react';
import Forms from '../src/components/liveTrainingDesign/knowMoreTrainerForm';
import KnowMoreHeader from '../src/components/liveTrainingDesign/knowmoreheader';
import KnowMoreRating from '../src/components/liveTrainingDesign/knowmoreRating';
import FooterWebinar from "../src/components/liveTrainingDesign/footer-webinar.jsx";
import KnowMoreShi from '../src/components/liveTrainingDesign/knowmoreShi.jsx';
import KnowMoreHowToJoinWebinar from '../src/components/liveTrainingDesign/knowmoreHowtojoing';

class knowMoreTrainer extends Component{
    render(){
        return(
            <div>
                <KnowMoreHeader />
                <KnowMoreShi />
                <Forms />
                <KnowMoreHowToJoinWebinar />
                <KnowMoreRating />
                <FooterWebinar />
            </div>
        )
    }
}

export default knowMoreTrainer;