import React, { Component } from 'react';

class GearsContainer extends Component{
  render(){
    return(
    <div>
        <style jsx>
            {`
                .containerBg{
                    background: #33A478;
                    padding: 5px;
                }
                .imgContainer{
                    padding-right: 0px;
                    padding-left: 0px;
                    position: relative;
                }
                .gearContainer{
                    position: fixed;
                    left: 0;
                    top: 23%;
                    z-index: 9;
                }
                .gearContainer a{
                    width: auto;
                    padding: 10px 25px 10px 10px;
                    background: #000000;
                    color: #00cc96;
                    font-weight: bold;
                    font-size: 16px;
                    border-top-right-radius: 20px;
                    border-bottom-right-radius: 20px;
                    display: inline-block;
                }
                .gearContainer a:hover{
                    text-decoration: none;
                }
                .gearContainer ul {
                    padding-left: 0;
                    margin-bottom: 0;
                    border-top-right-radius: 30px;
                    border-bottom-right-radius: 30px;
                    background-color: #000;
                    height: 0px;
                    overflow: hidden;
                    -webkit-transition: all 0.3s linear;
                    -moz-transition: all 0.3s linear;
                    -o-transition: all 0.3s linear;
                    transition: all 0.3s linear;
                }
                .gearContainer:hover ul {
                    height: 100%;
                    padding-top: 10px;
                    padding-bottom: 10px;
                }
                .gearContainer ul li a:hover{
                    color: white;
                }
                .gearsContent{
                    padding-left: 5px;
                }
                .gearContainer ul li a span {
                    width: 28px;
                    height: 28px;
                    display: inherit;
                    background-image: url(https://skillrary.com/assets/skillrary/images/gear_caret.png);
                    position: relative;
                    top: 8px;
                }
                .gearContainer ul li:first-child a span {
                    content: '';
                    background-position: -138px 33px;
                }
                .gearContainer ul li:first-child:hover a span {
                    content: '';
                    background-position: -138px 0px;
                }
                .gearContainer ul li:nth-child(2) a span {
                    content: '';
                    background-position: -36px 30px;
                }
                .gearContainer ul li:nth-child(2):hover a span {
                    content: '';
                    background-position: -36px 89px;
                }
                .gearContainer ul li:nth-child(3) a span {
                    content: '';
                    background-position: -4px 30px;
                } 
                .gearContainer ul li:nth-child(3):hover a span {
                    content: '';
                    background-position: -4px 89px;
                } 
                .gearContainer ul li:nth-child(4) a span {
                    content: '';
                    background-position: -69px 31px;
                } 
                .gearContainer ul li:nth-child(4):hover a span {
                    content: '';
                    background-position: -69px 90px;
                } 
                .gearContainer ul li:nth-child(5) a span {
                    content: '';
                    background-position: 136px 31px;
                } 
                .gearContainer ul li:nth-child(5):hover a span {
                    content: '';
                    background-position: 136px 90px;
                } 
                .gearContainer ul li:nth-child(6) a span {
                    content: '';
                    background-position: 68px 31px;
                } 
                .gearContainer ul li:nth-child(6):hover a span {
                    content: '';
                    background-position: 68px 90px;
                } 
                .gearContainer ul li:nth-child(7) a span {
                    content: '';
                    background-position: 31px 31px;
                } 
                .gearContainer ul li:nth-child(7):hover a span {
                    content: '';
                    background-position: 31px 91px;
                }
                @media only screen and (max-width: 600px){
                    .gearsContent{
                        display: none;
                    }
                }
            `}
        </style>
    <div class="gearContainer">
    <a href="#"><i class="fa fa-certificate" aria-hidden="true"></i><span className="gearsContent">GEARS</span></a>
    <ul> 
        <li>
            <a href="https://assess.skillrary.com/skillrary/whiteboard" class="ignorelink" target="_blank"><span></span> SkillRary Whiteboard</a>
        </li> 
        <li>
            <a href="https://assess.skillrary.com/essay" class="ignorelink" target="_blank"><span></span> SkillRary Essay</a>
        </li> 
        <li>
            <a href="https://compiler.skillrary.com/" class="ignorelink" target="_blank"><span></span> SkillRary Compilers</a>
        </li> 
        <li>
            <a href="https://assess.skillrary.com/html-editor" class="ignorelink" target="_blank"><span></span> HTML Editor</a>
        </li>
        <li>
            <a href="https://assess.skillrary.com/typing-test" class="ignorelink" target="_blank"><span></span> Typing Speed Test</a>
        </li> 
        <li>
            <a href="https://assess.skillrary.com/sql-editor" class="ignorelink" target="_blank"><span></span> SQL Editor</a>
        </li> 
        <li>
            <a href="https://demoapp.skillrary.com/" class="ignorelink" target="_blank"><span></span> SkillRary Demo APP</a>
        </li> 
    </ul>
    </div>
    </div>
    )
  }
}

export default GearsContainer;
