import React, { Component } from 'react';
import WebinarTrainer from './components/liveTrainingDesign/webinartrainers';
import HowToJoinWebinar from './components/liveTrainingDesign/howtojoinWebinar';
import WhySkillrary from './components/liveTrainingDesign/whyskillrarywebinar';
import WebinarVideo from '../src/components/liveTrainingDesign/webinarVideo.jsx';
import FooterTraining from "../src/components/liveTrainingDesign/footerTraining.jsx";

class Webinar extends Component{
    render(){
        return(
            <div>
                <WebinarVideo />
                <WhySkillrary />
                <WebinarTrainer />
                <HowToJoinWebinar />
                <FooterTraining />
            </div>
        )
    }
}

export default Webinar;