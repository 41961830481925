import React, { Component } from 'react';
import Header from '../src/components/liveTrainingDesign/liveTrainingHeader';
import ScrollSpy from '../src/components/liveTrainingDesign/liveTrainingScrollSpy';
import FooterTraining from "../src/components/liveTrainingDesign/footerTraining.jsx";
import ContactLiveTraining from "../src/components/liveTrainingDesign/liveTrainingContact.jsx";
import LiveTrainingBanner from "../src/components/liveTrainingDesign/liveTrainingBanner.jsx";
import LiveTrainingCard from '../src/components/liveTrainingDesign/liveTrainingCard.jsx';
import GearsContainer from '../src/components/liveTrainingDesign/gearsContainer';
import WhatsappComponent from '../src/components/liveTrainingDesign/whatsappcomponent';

class CourseInfo extends Component{

    render(){
        return (
            <div>
                <Header />
                <LiveTrainingBanner /><br/><br/>
                <GearsContainer />
                <LiveTrainingCard /><br/>
                <ScrollSpy /><br/><br/><br/>
                <ContactLiveTraining />
                <WhatsappComponent />
                <FooterTraining />
            </div>
        )
    }
}

export default CourseInfo;