import React, { Component } from 'react';
import server from '../liveTrainingDesign/assets/server.png';
import live from '../liveTrainingDesign/assets/live.png';
import assess from '../liveTrainingDesign/assets/assessment.png';
import diploma from '../liveTrainingDesign/assets/diploma1.png';
import hands from '../liveTrainingDesign/assets/hands.png';
import twentyfour from '../liveTrainingDesign/assets/24hrs.png';

class Forms extends Component{
    render(){
        return(
            <div>
                <style jsx>
                    {`
                    .keyFeatures{
                        font-size: 24px;
                        color: #0548CE;
                        font-weight: 600;
                        margin-bottom: 15px;
                    }
                    .topic{
                        width: 20%;
                    }
                    .description{
                        width: 35%;
                    }
                    .date{
                        width: 12%;
                    }
                    .time{
                        width: 8%;
                    }
                    .attend{
                        width: 20%;
                    }
                    .descriptionData{
                        text-align: justify !important;
                    }
                    .preview{
                        color: #0548CE;
                        font-size: 26px;
                    }
                    .knowvideoContainer{
                        width: 100%;
                        height: 300px;
                    }
                    .knowIcon{
                        float: left;
                        font-size: 30px;
                        width: 45px;
                        height: 47px;
                    }
                    .icon{
                        width: 60px;
                        height: 60px;
                        vertical-align: text-top;
                    }
                    .headingKnowmore{
                        font-size: 18px;
                        font-weight: 600;
                        margin-top: 25px;
                    }
                    .knowTextIcon{
                        padding-left: 70px;
                    }
                    .knowMoreContainer{
                        background: #EDEDED;
                        padding:70px 55px;
                    }
                    .inputFieldKnow{
                        width: 100%;
                        height: 40px;
                    }
                    .labelName{
                        color: #0548CE;
                        font-size: 21px;
                        margin: 4px;
                    }
                    .inputFieldKnow1{
                        width: 100%;
                        height: 40px;
                        margin: 4px;
                        margin-top: 28px;
                    }
                    .buttonApply{
                        background: #F9CA33;
                        border: 1px solid #F9CA33;
                        padding: 11px;
                        width: 100%;
                        max-width: 170px;
                        min-width: 170px;
                        color: black;
                        font-size: 20px;
                        box-shadow: 4px 4px #e6e6e6;
                    }
                    .formContainer{
                        display: inline-flex;
                    }
                    .tableWidth{
                        width: 100%;
                    }
                    .thborder{
                        text-align: center !important;
                        color: #0548CE;
                        font-size: 20px;
                    }
                    table, .tdborder, .thborder {
                        border: 1px solid black;
                        border-collapse: collapse;
                        width: 20%;
                    }
                    .thdescription{
                        width: 40% !important;
                        text-align: justify !important;
                    }
                    .tdborder, .thborder {
                        padding: 5px;
                        text-align: left;    
                    }
                   
                    .butnCol{
                        text-align: center;
                    }
                    table{
                        background: #F0F8FD;
                        transform: scale(1,1);
                        -webkit-transform: scale(1,1);
                        -moz-transform: scale(1,1);
                        box-shadow: 0 2px 8px rgba(0,0,0,0.2);
                        -webkit-box-shadow: 0 2px 8px rgba(0,0,0,0.2);
                        -moz-box-shadow: 0 2px 8px rgba(0,0,0,0.2);
                    }
                    @media only screen and (max-width: 600px){
                        .formContainer{
                            display: inline;
                        }
                        .preview{
                            margin-top: 20px;
                        }
                    }
                    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px)  { 
                        .formContainer{
                            display: inline;
                        }
                    }
                    `}
                </style>
                <div className="container-fluid knowMoreContainer">
                    <div className="row">
                        <div className="col-md-7">
                            <div className="row" style={{overflowX: "auto", overflowY: 'hidden'}}>
                                {/* <div className="col-lg-2 col-md-3">
                                    <span className="labelName">Course name</span><br />
                                    <input type="text" name="coursename" className="inputFieldKnow"/>
                                </div>
                                <div className="col-lg-2 col-md-3">
                                    <span className="labelName">Date</span><br /><br />
                                    <input type="text" name="coursename" className="inputFieldKnow"/>
                                </div>
                                <div className="col-lg-2 col-md-3">
                                    <span className="labelName">Time</span><br /><br />
                                    <input type="text" name="coursename" className="inputFieldKnow"/>
                                </div>
                                <div className="col-lg-2 col-md-3">
                                    <button className="buttonApply">Apply</button>
                                </div> */}

                                {/* coorect one */}
                                 {/* <div className="formContainer">
                                    <span className="labelName">Course name<br/>
                                    <input type="text" name="coursename" className="inputFieldKnow"/></span>
                                
                                    <span className="labelName">Date<br/>
                                    <input type="text" name="coursename" className="inputFieldKnow"/></span>
                              
                                    <span className="labelName">Time<br/>
                                    <input type="text" name="coursename" className="inputFieldKnow"/></span>
                              
                                    <button className="buttonApply">Attend</button>
                                </div> */}
                            
                                <table className="tableWidth">
                                    <thead>
                                        <tr>
                                            <th className="thborder">Topic Name</th>
                                            <th className="thborder">Description</th>
                                            <th className="thborder">Date</th>
                                            {/* <th className="tableHead time">Time</th> */}
                                            <th className="thborder">Attend</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="tdborder">Sorting Using Comparable</td>
                                            <td className="tdborder thdescription">In this session we learn how to implement Comparable
                                            with String & wrapper classes.
                                            Sorting a List using Comparable.
                                            We also look upon how to use TreeSet & TreeMap
                                            with Comparable.</td>
                                            <td className="tdborder butnCol">Starting soon</td>
                                            {/* <td className="tableData timeData">06:00 PM</td> */}
                                            <td className="tdborder butnCol"><a href="https://skillrary.com/user/register"><button className="buttonApply" disabled>Attend</button></a></td>
                                        </tr>
                                        <tr>
                                            <td className="tdborder"> Sorting using Comparator</td>
                                            <td className="tdborder">Here we learn to use Comparator.
                                            Implementation of Comparator with List, Set & Map
                                            Also we learn how to implement custom sorting.</td>
                                            <td className="tdborder butnCol">Starting soon</td>
                                            {/* <td className="tableData">06:00 PM</td> */}
                                            <td className="tdborder butnCol"><a href="https://skillrary.com/user/register"><button className="buttonApply" disabled>Attend</button></a></td>
                                        </tr>
                                        <tr style={{ height: '90px' }}>
                                            <td className="tdborder">Difference between Comparable & Comparator</td>
                                            <td className="tdborder" style={{ textAlign: 'center'}}>-</td>
                                            <td className="tdborder butnCol">Starting soon</td>
                                            {/* <td className="tableData">06:00 PM</td> */}
                                            <td className="tdborder butnCol"><a href="https://skillrary.com/user/register"><button className="buttonApply" disabled>Attend</button></a></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            {/* <div className="row">
                                 <div className="formContainer">
                                    <input type="text" name="coursename" className="inputFieldKnow1"/>
                                    <input type="text" name="date" className="inputFieldKnow1"/>
                                    <input type="text" name="time" className="inputFieldKnow1"/>
                              
                                    <button className="buttonApply">Apply</button>
                                </div>
                            </div> */}
                            <br/><br/>
                            <h5 className="keyFeatures">Key Features</h5>
                            <div className="row">
                                <div className="col-lg-4 col-md-5 col-sm-6" style={{ marginBottom: '15px' }}>
                                    <div className="knowIcon">
                                        <img src={server} className="icon"/>
                                    </div>
                                    <div className="knowTextIcon">
                                        <h4 className="headingKnowmore">Cloud Lab Services</h4>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-5 col-sm-6" style={{ marginBottom: '15px' }}>
                                    <div className="knowIcon">
                                        <img src={live} className="icon"/>
                                    </div>
                                    <div className="knowTextIcon">
                                        <h4 className="headingKnowmore">Compilers</h4>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-5 col-sm-6" style={{ marginBottom: '15px' }}>
                                    <div className="knowIcon">
                                        <img src={assess} className="icon"/>
                                    </div>
                                    <div className="knowTextIcon">
                                        <h4 className="headingKnowmore">Assessments</h4>
                                    </div>
                                </div>
                            </div><br/>
                            <div className="row">
                                <div className="col-lg-4 col-md-5 col-sm-6" style={{ marginBottom: '15px' }}>
                                    <div className="knowIcon">
                                        <img src={diploma} className="icon"/>
                                    </div>
                                    <div className="knowTextIcon">
                                        <h4 className="headingKnowmore">Certification</h4>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-5 col-sm-6" style={{ marginBottom: '15px' }}>
                                    <div className="knowIcon">
                                        <img src={hands} className="icon"/>
                                    </div>
                                    <div className="knowTextIcon">
                                        <h4 className="headingKnowmore">Projects</h4>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-5 col-sm-6" style={{ marginBottom: '15px' }}>
                                    <div className="knowIcon">
                                        <img src={twentyfour} className="icon"/>
                                    </div>
                                    <div className="knowTextIcon">
                                        <h4 className="headingKnowmore">24 hrs Support</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 offset-lg-1  align-self-center">
                            <h3 className="preview">Preview</h3>
                            {/* <video playsinline="playsinline" className="knowvideoContainer"  controls muted="muted" loop="loop">
                                <source src="https://www.youtube.com/embed/xmR7O4_DGeU" type="video/mp4" />
                            </video> */}
                            {/* <iframe className="knowvideoContainer"
                            src="">
                            </iframe> */}
                            <iframe className="knowvideoContainer" src="https://www.youtube.com/embed/xmR7O4_DGeU" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Forms;