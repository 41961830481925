import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import LiveTraining from './App';
import CourseInfo from './CourseInfo';
import howItWorks from './HowItWorks';
import FileNotFounder from './fileNotFound';
import UpcomingClasses from './UpcomingClasses';
import OngoingClasses from './OngoingClasses';
import SearchList from './searchList';
import ReadInstructions from '../src/components/liveTrainingDesign/readCompleteInstructions';
// import listViewCarousel from '../src/components/liveTrainingDesign/listCarouselView';
import knowMoreTrainer from './knowmoreTrainerPage';
import CloudLab from './CloudLabTest';
import Webinar from './skillrarywebinar';
import OngoingSchedule from './ongoingScheduleCalendar';
import Facebook from './facebook';
import CategoryPage from './categoryPage';
import CategoryPageDropdown from './viewCategoryCourses';
// import ListView from './listCarouselView';
import webinarNewDesign from './components/liveTrainingDesign/webinarnewdesign';
import LoadTimer from './components/liveTrainingDesign/LoadTimer';

class SKillraryRoute extends Component {
    render() {
        return (
            <div>
                <Router>
                    <Switch>
                        <Route path="/" exact strict component={LiveTraining} />
                        {/* <Route path="/CourseInfo" exact component={LiveTraining} /> */}
                        {/* <Route path="/externalLink" exact component={ExternalLink} /> */}
                        {/* <Route path="/ongoingclasses"><Redirect push to="/#ongoingClasses" /> </Route> */}
                        <Route path="/howItWorks" exact component={howItWorks} />
                        <Route path="/ongoingclasses" exact component={OngoingClasses} />
                        <Route path="/upcomingclasses" exact component={UpcomingClasses} />
                        {/* <Route path="/listViewClasses" exact component={listViewCarousel}/> */}
                        <Route path="/cloudlabtest" exact component={CloudLab} />
                        <Route path="/searchCourses/:course_searchcourse" exact component={SearchList} />
                        <Route path="/ad/:course_title?" exact component={Facebook} />
                        {/* <Route path="/skillrarynewdesign" exact component={webinarNewDesign} /> */}
                        <Route path="/skillrary_webinar" exact component={Webinar} />
                        <Route path="/webinar_instructions" exact component={ReadInstructions} />
                        <Route path="/webinar-shishirabhat" exact component={knowMoreTrainer} /> 
                        <Route path="/schedule_courses" exact component={OngoingSchedule} />
                        <Route path="/category_course_details/:cat_id_course" exact component={CategoryPage} />
                        <Route path="/category" exact component={CategoryPageDropdown} />
                        {/* <Route path="/loadTimer" exact component={LoadTimer} /> */}
                        {/* <Route path="/course_list_view" exact component={ListView} /> */}
                        <Route path="/filenotfound" component={FileNotFounder} />
                        <Route path='/:type_course/:title_course?' component={CourseInfo} />
                    </Switch>
                </Router>
            </div>
        );
    }
}
export default SKillraryRoute;