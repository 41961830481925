import React, { Component } from 'react';
import 'react-responsive-modal/styles.css';
import Modal  from 'react-responsive-modal';
import Swal from 'sweetalert2';

class LiveTrainingCard extends Component{

	constructor(props){
		super(props);
		console.log(props,'live card')
			this.state = {
				openSession: false,
				first_name : '',
				last_name : '',
				user_email : '',
				country_code: '+91',
				contact_number : '',
				messages : '',
				errors: {},
				openQuote: false,
				first_name_quote: '',
				last_name_quote: '',
				corporate_name_quote: '',
				user_email_quote: '',
				contact_number_quote: '',
				message_quote: '',
				openClassroom: false,
				name_classroom: '',
				email_classroom: '',
				category: window.location.pathname.slice(1).charAt(0).toUpperCase() + 
           	window.location.pathname.slice(2),
				date_classroom: '',
				mobile_classroom: '',
				training_classroom: 'Classroom',
				type: 'text',
			};
			this.onSubmitSessionForm = this.onSubmitSessionForm.bind(this)
			this.onSubmitQuote = this.onSubmitQuote.bind(this)
			this.onSubmitClassroom = this.onSubmitClassroom.bind(this)

			//this.onBlurClassroom = this.onBlurClassroom.bind(this)

			this.onFocusClassroom = this.onFocusClassroom.bind(this)
	}

	onOpenModalSession = () => {
		this.setState({ openSession: true });
	};
	 
	onCloseModalSession = () => {
		this.setState({ openSession: false });
		this.setState({first_name : '',last_name: '',user_email:'',contact_number:'',messages:'',openSession: false })
	};

	onOpenModalQuote = () => {
		this.setState({ openQuote: true });
	};
	 
	onCloseModalQuote = () => {
		this.setState({ openQuote: false });
		this.setState({first_name_quote : '',last_name_quote: '',corporate_name_quote:'',user_email_quote:'',contact_number_quote:'',message_quote:'',openQuote: false })
	};

	onOpenModalClassroom = () => {
		this.setState({ openClassroom: true });
	}

	onCloseModalClassroom = () => {
		this.setState({ openClassroom: false });
		this.setState({name_classroom : '',mobile_classroom: '',email_classroom: '',category: '',date_classroom: ''})
	}

	onSubmitSessionForm(e){
		e.preventDefault();
		
        let errors = {};
		let formIsValid = false;
		
		if(!this.state.first_name){
			formIsValid = true
			errors['first_name'] = "This field is required"
		}
		else if(!this.state.last_name){
			formIsValid = true
			errors['last_name'] = "This field is required"
		}
		else if(!this.state.user_email) {
			formIsValid = true
			errors['user_email'] = "This field is required"
		}
		else if(!this.state.contact_number){
			formIsValid = true
			errors['contact_number'] = "This field is required"
		}
		else if(this.state.contact_number.length < 10){
			formIsValid = true
			errors['contact_number'] = "Minimum should be 10 characters"
		}
		else if(this.state.contact_number.length > 10){
			formIsValid = true
			errors['contact_number'] = "Maximum should be 10 characters"
		}
		else if(!this.state.messages){
			formIsValid = true
			errors['messages'] = "This field is required"
		}

		if(Object.keys(errors).length > 0){
			this.setState({errors:errors})
			return formIsValid;
		}

		fetch("https://live.skillrary.com/live-courses/index.php/api/v1/one-to-one", {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json'
			},
			
			body: JSON.stringify({
				course_id: window.sessionStorage.getItem('courseId'),
				first_name: this.state.first_name,
				last_name: this.state.last_name,
				user_email: this.state.user_email,
				contact_number: this.state.country_code + ' '+ this.state.contact_number,
				messages: this.state.messages
			})
		})
      .then(res => res.json())
      .then((result) => {
		  console.log('rest', result.status);
		  if(result.status == 200){
            Swal.fire({
            type: 'success',
            title: 'Thank you, Your request is submitted successfully',
            showConfirmButton: false,
            timer: 2500
            })
			this.setState({first_name : '',last_name: '',user_email:'',contact_number:'',messages:'',openSession: false })
          }
          else if(result.status == 400){
            Swal.fire({
              type: 'error',
              title: 'Oops...',
              text: 'Something went wrong!',
              footer: '<a href>Why do I have this issue?</a>'
            })
          }
		})
		
	}
	
	onSubmitQuote(e){
		e.preventDefault();

		var course_quote_id = this.props.courseId

		let errors = {};
		let formIsValid = false;

		if(!this.state.first_name_quote){
			formIsValid = true;
			errors['first_name_quote'] = "This field is required"
		}
		else if(!this.state.last_name_quote){
			formIsValid = true;
			errors['last_name_quote'] = "This field is required"
		}
		else if(!this.state.corporate_name_quote){
			formIsValid = true;
			errors['corporate_name_quote'] = "This field is required"
		}
		else if(!this.state.user_email_quote){
			formIsValid = true;
			errors['user_email_quote'] = "This field is required"
		}
		else if(!this.state.contact_number_quote){
			formIsValid = true
			errors['contact_number_quote'] = "This field is required"
		}
		else if(this.state.contact_number_quote.length < 10){
			formIsValid = true
			errors['contact_number_quote'] = "Minimum should be 10 characters"
		}
		else if(this.state.contact_number_quote.length > 10){
			formIsValid = true
			errors['contact_number_quote'] = "Maximum should be 10 characters"
		}
		else if(!this.state.message_quote){
			formIsValid = true;
			errors['message_quote'] = "This is field is required"
		}
		if(Object.keys(errors).length > 0 ){
			this.setState({errors:errors})
			return formIsValid;
		}

		fetch("https://live.skillrary.com/live-courses/index.php/api/v1/corporate-request", {
			method: 'POST',

			headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json'
			},
			body: JSON.stringify({
				course_id: window.sessionStorage.getItem('courseId'),
				first_name: this.state.first_name_quote,
				last_name: this.state.last_name_quote,
				corporate_name: this.state.corporate_name_quote,
				user_email: this.state.user_email_quote,
				contact_number: this.state.country_code + ' ' + this.state.contact_number_quote,
				message: this.state.message_quote
			})
		})
      .then(res => res.json())
      .then((result) => {
		  console.log('rest', result.status);
		  if(result.status == 200){
            Swal.fire({
            type: 'success',
            title: 'Thank you, Your request is submitted successfully',
            showConfirmButton: false,
            timer: 2500
            })
			this.setState({first_name_quote : '',last_name_quote: '',corporate_name_quote:'',user_email_quote:'',contact_number_quote:'',message_quote:'',openQuote: false })
          }
          else if(result.status == 400){
            Swal.fire({
              type: 'error',
              title: 'Oops...',
              text: 'Something went wrong!',
              footer: '<a href>Why do I have this issue?</a>'
            })
          }
		})
		
	}
	
	onSubmitClassroom(e){
		e.preventDefault();
			
		let errors = {};
		let formIsValid = false;
		
		if(!this.state.name_classroom){
			formIsValid = true
			errors['name_classroom'] = "This field is required"
		}
		else if(!this.state.email_classroom) {
			formIsValid = true
			errors['email_classroom'] = "This field is required"
		}
		else if(!this.state.category){
			formIsValid = true
			errors['category'] = "This field is required"
		}
		else if(!this.state.date_classroom){
			formIsValid = true
			errors['date_classroom'] = "This field is required"
		}
		else if(!this.state.mobile_classroom){
			formIsValid = true
			errors['mobile_classroom'] = "This field is required"
		}
		else if(this.state.mobile_classroom.length < 10){
			formIsValid = true
			errors['mobile_classroom'] = "Minimum should be 10 characters"
		}
		else if(this.state.mobile_classroom.length > 10){
			formIsValid = true
			errors['mobile_classroom'] = "Maximum should be 10 characters"
		}

		if(Object.keys(errors).length > 0){
			this.setState({errors:errors})
			return formIsValid;
		}
	
		fetch("https://live.skillrary.com/live-courses/index.php/api/v1/course-request", {
	
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json'
			},
			
			body: JSON.stringify({
				course_id: window.sessionStorage.getItem('courseId'),
				first_name: this.state.name_classroom,
				mobile: this.state.mobile_classroom,
				user_email : this.state.email_classroom,
				course_requested: this.state.category,
				start_date: this.state.date_classroom,
				training_method: this.state.training_classroom,
				notifications: 0
			})
			})
			.then(res => res.json())
			.then(
				(result) => {
	
				console.log('rest', result);
	
				if(result.status == 200){
					Swal.fire({
					type: 'success',
					title: 'Thank you, Your request is submitted successfully',
					showConfirmButton: false,
					timer: 2500
					})
				this.setState({name_classroom : '',mobile_classroom: '',email_classroom: '',category: '',date_classroom: '',openClassroom: false})
				}
				else if(result.status == 400){
					Swal.fire({
					type: 'error',
					title: 'Oops...',
					text: 'Something went wrong!',
					footer: '<a href>Why do I have this issue?</a>'
					})
				}
				},
			)
	}
	
	onFocusClassroom() {
        this.setState({
            type: 'date'
        });
    }

	
	render(){

		const { openSession } = this.state;
		const { openQuote } = this.state;
		const { openClassroom } = this.state;

		return(
			<div className="cardBg">
				<div style={{ float: 'right',marginTop: '370px' }}>
					{/* <div className="col-md-3"></div> */}
					{/* <div className="col-md-1">
						<i class="fa fa-long-arrow-right" aria-hidden="true" id="arrowName"></i>
					</div> */}
					{/* <div className="col-md-5"> 
						<a href="https://www.skillrary.com/" target="_blank"><button className="recordingButton">Live Recording Classes</button></a>
					 </div> */}
				</div>
			<style jsx>{`
				#arrowName{
					font-size: 45px;
					/* line-height: 8px; */
					vertical-align: middle;
					position: relative;
				}
				.recordingButton{
					background-color: white;
					color: #33A478;
					font-weight: 700;
					height: auto; 
					padding: 8px;
					font-size: 20px;
					border-radius: 3px;
					border: 2px solid #33A478;
				}
				.recordingButton:focus{
					outline: none;
					background-color: white;
					color: #33A478;
					font-weight: 700;
					height: auto; 
					padding: 8px;
					font-size: 20px;
					border-radius: 3px;
					border: 2px solid #33A478;
				}
				.recordingButton:hover{
					background: black;
				}
				.onlineClassSchedule{
					color: white;
				}
				.onlineClassSchedule:hover{
					text-decoration: none;
					color: white;
				}
				.errTextArea{
					font-size: 12px;
					font-weight: normal;
					color: red;
					width: 300px;
					bottom: 0;
					left: -75px;
					top: 71px;
					position: absolute;
					z-index: 1;
				}
				.errTextNumber{
					font-size: 12px;
					font-weight: normal;
					color: red;
					width: 300px;
					bottom: 0;
					left: -40px;
					top: 49px;
					position: absolute;
					z-index: 1;
				}
				.errText{
					font-size: 12px;
					font-weight: normal;
					color: red;
					width: 300px;
					bottom: 0;
					left: -75px;
					top: 49px;
					position: absolute;
					z-index: 1;
				}
				#sessionBody {
					top: 40% !important;
					position: absolute !important;
					transform: translateY(40%) !important;
					background: #fff;
				}
				.cardBg{
					background: white;
	    			padding: 30px 0 30px;
				}
				.cardFace{
					height: 350px;
				    position: relative;
				    margin-bottom: 18px;
				    width: 275px;
				    overflow: hidden;
				    background: #fff;
				    box-shadow: 0px 1px 0px 0px rgba(36,38,47,0.10), 0px 4px 6px 0px rgba(0,0,0,0.06);
				    border-radius: 3px;
				    padding: 20px;
				    border: 1px solid white;
				}
				.logo{
					width: 57px !important;
					height: 57px !important;
					margin-top: 18px;
					font-size: 57px;
					color: #696969;
				}
				.logoTeam{
					width: 57px !important;
					height: 57px !important;
					margin-top: 18px;
					font-size: 49px;
					color: #696969;
				}
				.cardDetails{
					padding: 0 38px;
	    			height: 188px;
				}
				.heading{
					border-bottom: 1px solid #e7e7e7;
					font-weight: 600;
					padding: 15px 0 22px 0;
					font-size: 15px;
					color: #33a478;
				}
				.cardContent{
					height: 123px !important;
				    overflow: hidden;
				    font-size: 12px !important;
				    color: #999 !important;
				}
				.btnDesign{
					width: 100%;
					background: #33A478 !important;
				    box-shadow: 0px 6px 8px 0px rgba(0,0,0,0.10);
				    border-radius: 3px;
				    height: 44px;
				    font-weight: 700;
				    font-size: 12px;
				    color: #fff !important;
				    letter-spacing: 0px;
				    text-transform: uppercase;
				    border: 0 none;
				    padding: 14px 15px 16px 15px;
				    line-height: 14px;
				}
				.btnDesign:focus{
					outline: none;
					box-shadow:  0px 0px 5px 2px rgba(0,0,0,0.4);
				}
				.questionIcon{
					position: absolute;
				    top: 20px;
				    right: 20px;
				    color: #eee;
				    font-size: 24px;
				}
				.modeOfDeliveryHeading{
					font-weight: 700;
					font-size: 22px;
					color: #333742;
					letter-spacing: 1.5px;
					position: relative;
					padding: 0px 0px 10px 0px;
					margin-bottom: 20px;
                }
				.modeOfDeliveryHeading:before{
					content: ' ';
					background: #33A478;
					box-shadow: 0 4px 8px 0 rgba(76,215,200,0.3);
					width: 15%;
					height: 4px;
					position: absolute;
					bottom: 0;
					left: 50%;
					transform: translate(-50%);
				}
				.sessionHeading{
					font-weight: 700;
					font-size: 16px;
					color: #333742;
					letter-spacing: 1.5px;
					position: relative;
					padding: 0px 0px 10px 0px;
					margin-bottom: 20px;
					text-align: center;
                }
				.sessionHeading:before{
					content: ' ';
					background: #33A478;
					box-shadow: 0 4px 8px 0 rgba(76,215,200,0.3);
					width: 15%;
					height: 4px;
					position: absolute;
					bottom: 0;
					left: 50%;
					transform: translate(-50%);
				}
				.modalWidth{
					width: 600px !important;
					margin-top: 50px;
				}
				.inputField{
					font-size:15px;
					border: 0;
					outline: 0;
					position: relative;
					background: transparent;
					border-bottom: 1px solid #33A478;
					// -webkit-text-fill-color: black;
					padding-left:25px;
					padding-top:23px;
					display: flex;
					width: 100%;
				}
				.inputFieldMsz{
					font-size:15px;
					border: 0;
					outline: 0;
					position: relative;
					background: transparent;
					border-bottom: 1px solid #33A478;
					padding-left:5px;
					padding-top:23px;
					display: flex;
					width: 100%;
				}
				.SelectQuoteField select{
					font-size:15px;
					border: 0;
					outline: 0;
					background: transparent;
					border-bottom: 1px solid #33A478;
					width: 570px;
					-webkit-appearance: none;
					padding-left:4px;
					margin-top: 25px;
				}
				.SelectQuoteField select{
					color: #696969;
				}
				.SelectQuoteField:after{
				    content: "\\25BC";
					top: 20px;
					margin-left: -31px;
					bottom: 0;
					position: absolute;
					font-size: 15px;
					line-height: 30px;
					padding: 0 7px;
					color: #33A478;
					pointer-events: none;
					border-radius: 0 6px 6px 0;
				}
				.sessionSubBtn{
					background: #33A478;
					border: 1px solid #33A478;
					font-size: 16px;
					padding: 5px 15px;
					color: white;
					font-weight: 600;
					border-radius: 4px;
				}
				#userLogo{
					position: absolute;
					left: 20px;
					top: 25px;
					float: left;
					color: #696969;
				}
				#userLogoEmail{
					position: absolute;
					left: 20px;
					top: 25px;
					float: left;
					font-size: 14px;
					color: #696969;
				}
				@media only screen and (max-width: 400px){
					.SelectQuoteField select{
						color: #696969;
					}
					.modalWidth {
						width: 250px !important;
					}
					.SelectQuoteField select{
						font-size:15px;
						border: 0;
						outline: 0;
						background: transparent;
						border-bottom: 1px solid #33A478;
						width: 218px;
						-webkit-appearance: none;
						padding-left:6px;
						margin-top: 25px;
					}
					.SelectQuoteField:after{
						content: "\\25BC";
						top: 20px;
						margin-left: -31px;
						bottom: 0;
						position: absolute;
						font-size: 15px;
						line-height: 30px;
						padding: 0 7px;
						color: #33A478;
						pointer-events: none;
						border-radius: 0 6px 6px 0;
					}
				}
				@media only screen and (min-width: 400px) and (max-width : 600px){
				
					.SelectQuoteField select{
						color: #696969;
					}
					.modeOfDeliveryHeading:before{
						content: ' ';
						background: #33A478;
						box-shadow: 0 4px 8px 0 rgba(76,215,200,0.3);
						width: 40%;
						height: 4px;
						position: absolute;
						bottom: 0;
						left: 50%;
						transform: translate(-50%);
					}
					.modalWidth {
						width: 300px !important;
					}
					.SelectQuoteField select{
						font-size:15px;
						border: 0;
						outline: 0;
						background: transparent;
						border-bottom: 1px solid #33A478;
						width: 270px;
						-webkit-appearance: none;
						padding-left:6px;
						margin-top: 25px;
					}  
					.SelectQuoteField:after{
						content: "\\25BC";
						top: 20px;
						margin-left: -31px;
						bottom: 0;
						position: absolute;
						font-size: 15px;
						line-height: 30px;
						padding: 0 7px;
						color: #33A478;
						pointer-events: none;
						border-radius: 0 6px 6px 0;
					}
				}
				@media only screen and (min-device-width : 768px) and (max-device-width : 1024px)  { 
				
					.btnDesign{
						width: 100%;
						background: #33A478 !important;
						box-shadow: 0px 6px 8px 0px rgba(0,0,0,0.10);
						border-radius: 3px;
						height: 44px;
						font-weight: 700;
						font-size: 12px;
						color: #fff !important;
						letter-spacing: 0px;
						text-transform: uppercase;
						border: 0 none;
						padding: 4px;
						line-height: 14px;
					}
					.cardDetails{
						padding: 0px;
						height: 188px;
					}
					.modeOfDeliveryHeading:before{
						content: ' ';
						background: #33A478;
						box-shadow: 0 4px 8px 0 rgba(76,215,200,0.3);
						width: 23%;
						height: 4px;
						position: absolute;
						bottom: 0;
						left: 50%;
						transform: translate(-50%);
					}
					.cardFace{
						height: 350px;
					    position: relative;
					    margin-bottom: 18px;
					    width: 170px;
					    overflow: hidden;
					    background: #fff;
					    box-shadow: 0px 1px 0px 0px rgba(36,38,47,0.10), 0px 4px 6px 0px rgba(0,0,0,0.06);
					    border-radius: 3px;
					    padding: 20px;
					    border: 1px solid white;
					}
				}
				@media only screen and (min-width: 1024px) and (max-height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) {
					.modeOfDeliveryHeading:before{
						content: ' ';
						background: #33A478;
						box-shadow: 0 4px 8px 0 rgba(76,215,200,0.3);
						width: 15%;
						height: 4px;
						position: absolute;
						bottom: 0;
						left: 50%;
						transform: translate(-50%);
					}
					.cardFace{
						height: 350px;
					    position: relative;
					    margin-bottom: 18px;
					    width: 230px;
					    overflow: hidden;
					    background: #fff;
					    box-shadow: 0px 1px 0px 0px rgba(36,38,47,0.10), 0px 4px 6px 0px rgba(0,0,0,0.06);
					    border-radius: 3px;
					    padding: 20px;
					    border: 1px solid white;
					}
				}
				`}</style>
				<div className="container" align="center">
					<h3 className="modeOfDeliveryHeading">Mode of Delivery</h3>
					<div className="row">
						<div className="col-md-3">
							<div className="cardFace">
								<i className="fa fa-laptop logo" aria-hidden="true"></i>
								<div className="cardDetails">
									<h3 className="heading">Online Classroom</h3>
									<div className="cardContent">
										Instructor Led Live Online Training
									</div>
								</div>
								<div>
									<a href="#1" className="onlineClassSchedule"><button className="btnDesign">VIEW SCHEDULES</button></a>
								</div>
								{/* <div className="questionIcon">
									<i className="fa fa-question-circle"></i>
								</div> */}
							</div>
						</div>
						<div className="col-md-3">
							<div className="cardFace">
							<i className="fa fa-crosshairs logo" aria-hidden="true"></i>
							<div className="cardDetails">
									<h3 className="heading">Classroom</h3>
									<div className="cardContent">
										Instructor Led Live Classroom Training
									</div>
							</div>
								<div>
									<button className="btnDesign"  onClick={this.onOpenModalClassroom}>REQUEST A SESSION</button>
								</div>
								<Modal open={openClassroom} onClose={this.onCloseModalClassroom} closeOnOverlayClick={false} closeIconSize={22} styles={{ modal: { marginTop: '10%' }}}>
										<div className="modalWidth" >
											<h2 className="sessionHeading" style={{ marginTop: '-50px' }}>Request for a Session</h2>
											<p style={{ fontSize: '13px', textAlign: 'center' }}>Please fill in the details and Skillrary Course Advisor will get back within 24 hrs to help you up skill your team</p>
											<div className="container" align="center">
												<form onSubmit={this.onSubmitClassroom}>
													<div className="row">
														<div className="col-md-6"  style={{ marginBottom: '-15px'}}>
															<div className="form-group">
																<input type="text" name="name_classroom" className="inputField" 
																placeholder="Name"  autoComplete="off"
																value={this.state.name_classroom} onChange={(v) => this.setState({name_classroom: v.target.value, errors: { name_classroom:false }})} />
																<i className="fa fa-user" id="userLogo"></i>
																<span className="errText">{this.state.errors.name_classroom}</span>
															</div>
														</div>
														<div className="col-md-6"  style={{ marginBottom: '-15px'}}>
															<div className="form-group">
																<input type="email" name="email_classroom" className="inputField" 
																placeholder="Email"  autoComplete="off"
																value={this.state.email_classroom} onChange={(v) => this.setState({email_classroom: v.target.value, errors: { email_classroom:false }})} />
																<i className="fa fa-envelope" id="userLogoEmail"></i>
																<span className="errText">{this.state.errors.email_classroom}</span>
															</div>
														</div>
													</div>
													<div className="row">
														<div className="col-md-6"  style={{ marginBottom: '-15px'}}>
															<div className="form-group">
																<input type="text" name="mobile_classroom" className="inputField" 
																placeholder="Mobile"  autoComplete="off"
																value={this.state.mobile_classroom} onChange={(v) => this.setState({mobile_classroom: v.target.value, errors: { mobile_classroom:false }})} />
																<i className="fa fa-phone" aria-hidden="true" id="userLogo"></i>
																<span className="errText">{this.state.errors.mobile_classroom}</span>
															</div>
														</div>

														<div className="col-md-6"  style={{ marginBottom: '-15px'}}>
															<div className="form-group">
																{/* <input type="date" name="date_classroom" className="inputField" 
																placeholder="Date"  autoComplete="off"
																value={this.state.date_classroom} onChange={(v) => this.setState({date_classroom: v.target.value, errors: { date_classroom:false }})} />
																<i className="fa fa-calendar" aria-hidden="true" id="userLogo"></i>
																<span className="errText">{this.state.errors.date_classroom}</span> */}
											
																<input type={ this.state.type } onFocus={ this.onFocusClassroom } 

																className="inputField" 

																placeholder="Date" name="date_classroom" id="batchInput"
																onChange={(v) => this.setState({date_classroom: v.target.value, errors: { date_classroom : false}})} value={this.state.date_classroom}  autoComplete="off"/>
																<i className="fa fa-calendar" aria-hidden="true" id="userLogo"></i>
																<span className="errText">{this.state.errors.date_classroom}</span> 
															</div>
														</div>
													</div>
													<div className="row">

														<div className="col-md-6"  style={{ marginBottom: '-15px'}}>
															<div className="form-group">
																<input type="text" name="category" className="inputField" 
																placeholder="Category"  disabled autoComplete="off"
																value={this.state.category} onChange={(v) => this.setState({category: v.target.value, errors: { category:false }})} />
																<i className="fa fa-bars" id="userLogo" aria-hidden="true"></i>
																<span className="errText">{this.state.errors.category}</span>
															</div>
														</div>
														<div className="col-md-6"  style={{ marginBottom: '-15px'}}>
															<div className="form-group">
																<input type="text" name="training_classroom" className="inputField" 
																placeholder="Training Method"  autoComplete="off" disabled
																value={this.state.training_classroom} onChange={(v) => this.setState({training_classroom: v.target.value, errors: { training_classroom:false }})} />
																<i className="fa fa-graduation-cap" aria-hidden="true" id="userLogo"></i>																
															</div>
														</div>
													</div><br/>
													<div align="center" style={{ justifyContent: "center" }}>
														<button className="sessionSubBtn">Submit</button>
													</div>
												</form>
											</div>
										</div>
									</Modal>

								{/* <div className="questionIcon">
									<i className="fa fa-question-circle"></i>
								</div> */}
							</div>
						</div>
						<div className="col-md-3">
							<div className="cardFace">
								<i className="fa fa-clock-o logo" aria-hidden="true"></i>
									<div className="cardDetails">
									<h3 className="heading">One to One Training</h3>
									<div className="cardContent">
										Customized and exclusive training based on your requirements
									</div>
								</div>
								<div>
									<button className="btnDesign" onClick={this.onOpenModalSession}>REQUEST A SESSION</button>
									{/* <button className="btnDesign"  data-toggle="modal" data-target="#myModalSession">REQUEST A SESSION</button> */}
								</div>

									{/* form has started here */}


					{/* <div id="myModalSession" className="modal fade"  open={openSession} tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
					<div className="modal-dialog">
						<div className="modal-content">
							<div className="modal-body" id="sessionBody">
							<h2 className="sessionHeading">Request for a Session</h2>
								<p style={{ fontSize: '13px', textAlign: 'center' }}>Please fill in the details and Zeolearn's Course Advisor will get back within 24 hrs to help you up skill your team</p>
								<div className="container" align="center">
									<form onSubmit={this.onSubmitSessionForm}>

										<div className="row">
											<div className="col-md-6"  style={{ marginBottom: '-15px'}}>
												<div className="form-group">
													<input type="text" name="first_name" className="inputField" 
													placeholder="Firstname"  autoComplete="off"
													value={this.state.first_name} onChange={(v) => this.setState({first_name: v.target.value})} required/>
													<i className="fa fa-user" id="userLogo"></i>
												</div>
											</div>
											<div className="col-md-6"  style={{ marginBottom: '-15px'}}>
												<div className="form-group">
													<input type="text" name="last_name" className="inputField" 
													placeholder="Lastname"  autoComplete="off"
													value={this.state.last_name} onChange={(v) => this.setState({last_name: v.target.value})} required/>
													<i className="fa fa-user" id="userLogo"></i>
												</div>
											</div>
										</div>
										<div className="row">
											<div className="col-md-12" style={{ marginBottom: '-15px'}}>
												<div className="form-group">
													<input type="email" name="user_email" className="inputField" 
													placeholder="E-mail"  autoComplete="off"
													value={this.state.user_email} onChange={(v) => this.setState({user_email: v.target.value})} required/>
													<i className="fa fa-envelope" id="userLogoEmail"></i>
												</div>
											</div>
										</div>
										<div className="row">
											<div className="col-md-3"  style={{ marginBottom: '-15px'}}>
												<div className="form-group">
												<input type="text" name="country_code" className="inputField" 
													autoComplete="off"
													value={this.state.country_code} onChange={(v) => this.setState({country_code: v.target.value})} required/>

												</div>
											</div>
											<div className="col-md-9"  style={{ marginBottom: '-15px'}}>
												<div className="form-group">
													<input type="number" name="contact_number" className="inputField" 
													placeholder="Phone"  autoComplete="off"
													value={this.state.contact_number} onChange={(v) => this.setState({contact_number: v.target.value})} required/>
													<i className="fa fa-phone" aria-hidden="true" id="userLogo"></i>
												</div>
											</div>
										</div>
										<div className="row" style={{ marginBottom: '-15px'}}>
											<div className="col-md-12"  style={{ marginBottom: '-15px'}}>
												<div className="form-group">
													<textarea type="text" name="messages" className="inputFieldMsz" 
													placeholder="Message"  autoComplete="off"
													value={this.state.messages} onChange={(v) => this.setState({messages: v.target.value})} required/>
												</div>
											</div>
										</div><br/>
									
										<div className="row" style={{ justifyContent: "center" }}>
											<button className="sessionSubBtn">Submit</button>
										</div>

									</form>
								</div>
							</div>
						</div>
					</div>
					</div> */}



									<Modal open={openSession} onClose={this.onCloseModalSession} closeOnOverlayClick={false} closeIconSize={22} styles={{ modal: { marginTop: '10%' }}}>
										<div className="modalWidth" >
										<h2 className="sessionHeading" style={{ marginTop: '-50px' }}>Request for a Session</h2>
											<p style={{ fontSize: '13px', textAlign: 'center' }}>Please fill in the details and Skillrary Course Advisor will get back within 24 hrs to help you up skill your team</p>
											<div className="container" align="center">
												<form onSubmit={this.onSubmitSessionForm}>

													<div className="row">
														<div className="col-md-6"  style={{ marginBottom: '-15px'}}>
															<div className="form-group">
																<input type="text" name="first_name" className="inputField" 
																placeholder="Firstname"  autoComplete="off"
																value={this.state.first_name} onChange={(v) => this.setState({first_name: v.target.value, errors: { first_name:false }})} />
																<i className="fa fa-user" id="userLogo"></i>
																<span className="errText">{this.state.errors.first_name}</span>
															</div>
														</div>
														<div className="col-md-6"  style={{ marginBottom: '-15px'}}>
															<div className="form-group">
																<input type="text" name="last_name" className="inputField" 
																placeholder="Lastname"  autoComplete="off"
																value={this.state.last_name} onChange={(v) => this.setState({last_name: v.target.value, errors: { last_name:false }})} />
																<i className="fa fa-user" id="userLogo"></i>
																<span className="errText">{this.state.errors.last_name}</span>
															</div>
														</div>
													</div>
													<div className="row">
														<div className="col-md-12" style={{ marginBottom: '-15px'}}>
															<div className="form-group">
																<input type="email" name="user_email" className="inputField" 
																placeholder="E-mail"  autoComplete="off"
																value={this.state.user_email} onChange={(v) => this.setState({user_email: v.target.value, errors: { user_email:false }})} />
																<i className="fa fa-envelope" id="userLogoEmail"></i>
																<span className="errText">{this.state.errors.user_email}</span>
															</div>
														</div>
													</div>
													<div className="row">
														<div className="col-md-3"  style={{ marginBottom: '-15px'}}>
															<div className="form-group">
															<input type="text" name="country_code" className="inputField" 
															  	autoComplete="off"
																value={this.state.country_code} onChange={(v) => this.setState({country_code: v.target.value, errors: { country_code:false }})} />

															</div>
														</div>
														<div className="col-md-9"  style={{ marginBottom: '-15px'}}>
															<div className="form-group">
																<input type="number" name="contact_number" className="inputField" 
																placeholder="Phone"  autoComplete="off"
																value={this.state.contact_number} onChange={(v) => this.setState({contact_number: v.target.value, errors: { contact_number:false }})} />
																<i className="fa fa-phone" aria-hidden="true" id="userLogo"></i>
																<span className="errTextNumber">{this.state.errors.contact_number}</span>
															</div>
														</div>
													</div>
													<div className="row" style={{ marginBottom: '-15px'}}>
														<div className="col-md-12"  style={{ marginBottom: '-15px'}}>
															<div className="form-group">
																<textarea type="text" name="messages" className="inputFieldMsz" 
																placeholder="Message"  autoComplete="off"
																value={this.state.messages} onChange={(v) => this.setState({messages: v.target.value, errors: { messages:false }})} />
																<span className="errTextArea">{this.state.errors.messages}</span>
															</div>
														</div>
													</div><br/>
												
													<div className="row" style={{ justifyContent: "center" }}>
														<button className="sessionSubBtn">Submit</button>
													</div>

												</form>
											</div>
										</div>
									</Modal> 

									{/* form ended here */}

							
								{/* <div className="questionIcon">
									<i className="fa fa-question-circle"></i>
								</div> */}
							</div>				
						</div>
						<div className="col-md-3">
							<div className="cardFace">
								<i className="fa fa-users logoTeam" aria-hidden="true"></i>
								<div className="cardDetails">
									<h3 className="heading">Team/Corporate Training</h3>
									<div className="cardContent">
										Up-skill your team by training them on SkillRary Certification Courses.
									</div>
								</div>
								<div>
									<button className="btnDesign" onClick={this.onOpenModalQuote}>REQUEST A QUOTE</button>
								</div>

								{/* form started here */}

								<Modal open={openQuote} onClose={this.onCloseModalQuote} closeOnOverlayClick={false} closeIconSize={22} styles={{ modal: { marginTop: '10%' }}}>
										<div className="modalWidth" >
											<h2 className="sessionHeading" style={{ marginTop: '-50px' }}>Request for a Quote</h2>
											<p style={{ fontSize: '13px', textAlign: 'center' }}>Please fill in the details and Skillrary Course Advisor will get back within 24 hrs to help you up skill your team</p>
											<div className="container" align="center">
												<form onSubmit={this.onSubmitQuote}>
													<div className="row">
														<div className="col-md-6"  style={{ marginBottom: '-15px'}}>
															<div className="form-group">
																<input type="text" name="first_name_quote" 
																className="inputField" placeholder="Firstname" autoComplete="off"
																onChange={(v) => this.setState({first_name_quote:v.target.value, errors: {first_name_quote: false }})} value={this.state.first_name_quote} />
																<i className="fa fa-user" id="userLogo"></i>
																<span className="errText">{this.state.errors.first_name_quote}</span>
															</div>
														</div>
														<div className="col-md-6"  style={{ marginBottom: '-15px'}}>
															<div className="form-group">
																<input type="text" name="last_name_quote" 
																className="inputField" placeholder="Lastname" autoComplete="off"
																onChange={(v) => this.setState({last_name_quote:v.target.value, errors: {last_name_quote: false }})} value={this.state.last_name_quote} />
																<i className="fa fa-user" id="userLogo"></i>
																<span className="errText">{this.state.errors.last_name_quote}</span>
															</div>
														</div>
													</div>
													<div className="row">
														<div className="col-md-12" style={{ marginBottom: '-15px'}}>
															<div className="form-group">
																<input type="text" name="corporate_name_quote" 
																className="inputField" placeholder="Company" autoComplete="off"
																onChange={(v) => this.setState({corporate_name_quote:v.target.value, errors: { corporate_name_quote : false}})} value={this.state.corporate_name_quote} />
																<i className="fa fa-building-o" id="userLogoEmail"></i>
																<span className="errText">{this.state.errors.corporate_name_quote}</span>
															</div>
														</div>
													</div>
													<div className="row">
														<div className="col-md-12" style={{ marginBottom: '-15px'}}>
															<div className="form-group">
																<input type="email" name="user_email_quote"
																 className="inputField" placeholder="E-mail" autoComplete="off"
																 onChange={(v) => this.setState({user_email_quote:v.target.value, errors: {user_email_quote : false }})} value={this.state.user_email_quote} />
																<i className="fa fa-envelope" id="userLogoEmail"></i>
																<span className="errText">{this.state.errors.user_email_quote}</span>
															</div>
														</div>
													</div>
													<div className="row">
														<div className="col-md-3" style={{ marginBottom: '-15px'}}>
															<div className="form-group">
															<input type="text" name="country_code" className="inputField" autoComplete="off"
															  	autoComplete="off"
																value={this.state.country_code} onChange={(v) => this.setState({country_code: v.target.value})}/>
															</div>
														</div>
														<div className="col-md-9"  style={{ marginBottom: '-15px'}}>
															<div className="form-group">
																<input type="number" name="contact_number_quote"
																 className="inputField" placeholder="Phone" autoComplete="off"
																 value={this.state.contact_number_quote} 
																 onChange={(v) => this.setState({contact_number_quote: v.target.value, errors: {contact_number_quote : false }})} />
																<i className="fa fa-phone" aria-hidden="true" id="userLogo"></i>
																<span className="errText">{this.state.errors.contact_number_quote}</span>
															</div>
														</div>
													</div>
													<div className="row" style={{ marginBottom: '-15px'}}>
														<div className="col-md-12"  style={{ marginBottom: '-15px'}}>
															<div className="form-group">
																<textarea type="text" name="message_quote" 
																className="inputFieldMsz" placeholder="Message" autoComplete="off"
																value={this.state.message_quote} onChange={(v) => this.setState({message_quote: v.target.value, errors: { message_quote: false }})} />
																<span className="errTextArea">{this.state.errors.message_quote}</span>
															</div>
														</div>
													</div><br/>
												
													<div className="row" style={{ justifyContent: "center" }}>
														<button className="sessionSubBtn">Submit</button>
													</div>
													<p style={{ fontSize: '13px', textAlign: 'center', marginTop: '8px' }}>* At Skillrary, we take data security very seriously. Hence, we will ensure that the information mentioned by you will be used only to solve your query.</p>
												</form>
											</div>
										</div>
									</Modal>

								{/* form started ends  */}

								{/* <div className="questionIcon">
									<i className="fa fa-question-circle"></i>
								</div> */}
							</div>
						</div>
					</div><br/>
					
				</div>

			</div>

		)
	}
}

export default LiveTrainingCard;