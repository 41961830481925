import React, { Component } from 'react';

// images 

import online from '../liveTrainingDesign/assets/oc.png';
import classroom from '../liveTrainingDesign/assets/classroom.png';
import o2o from '../liveTrainingDesign/assets/o2o.png';
import team from '../liveTrainingDesign/assets/team.png';
import enroll from '../liveTrainingDesign/assets/enroll.png';
import startlearning from '../liveTrainingDesign/assets/startlearning.png';
import get from '../liveTrainingDesign/assets/get.png';
import live from '../liveTrainingDesign/assets/live.png';
import projects from '../liveTrainingDesign/assets/projects.png';
import courseInfo from '../liveTrainingDesign/assets/Know-More.jpg';


class WorksBanner extends Component{
    render(){
        return(
            <div>
                <style jsx>
                    {`
                    .onlineImages{
                        width: 115px;
                    }
                    .howitworksImages{
                        width: 100px;

                    }
                   	#workBannerContainer{
                        padding: 0px;
                        position: relative;
                    }
                    .workBannerImage{
                        opacity: 0.94;
                    }
                    .bannerContentContainer{
                        position: absolute;
                        width: 100%;
                        top: 75%;
                        left: 0px;
                        padding: 1rem;
                        transform: translateY(-50%);
                        color: white
                    }
                    .bannerContent h1{
                        width: 35%;
                        font-weight: 600;
                        font-size: 50px;
                        line-height: 60px;
                    }
                    .workHeading{
                        font-weight: 700;
                        font-size: 22px;
                        color: #333742;
                        letter-spacing: 1.5px;
                        position: relative;
                        padding: 0px 0px 10px 0px;
                        margin-bottom: 20px;
                    }
                    .workHeading:before{
                        content: ' ';
                        background: #33A478;
                        box-shadow: 0 4px 8px 0 rgba(76,215,200,0.3);
                        width: 13%;
                        height: 4px;
                        position: absolute;
                        bottom: 0;
                        margin-left: 40px;
                    }
                    #fontIcon{
                        font-size: 65px;
                    }
                    .workSubHeading{
                        font-size: 22px;
                        font-weight: 600;
                        color: #33A478;
                        margin-top: 20px;
                    }
                    .pTagChoose{
                        font-size: 17px;
                        font-weight: 600;
                        margin-top: -10px;
                    }
                    .liPoints{
                        text-align: left;
                        color: #676767;
                    }
                    .hr_design{
                        border: 0;
                        height: 1px;
                        background-image: linear-gradient(to right, rgba(0, 0, 0, 0), #33A478, rgba(0, 0, 0, 0));
                    }
                    @media only screen and (max-width: 600px) {
                        .bannerContent h1{
                            width: initial;
                            font-weight: 600;
                            font-size: 40px;
                            line-height: 40px;
                        }				
                        .workHeading:before{
                            content: ' ';
                            background: #33A478;
                            box-shadow: 0 4px 8px 0 rgba(76,215,200,0.3);
                            width: 46%;
                            height: 4px;
                            position: absolute;
                            bottom: 0;
                            margin-left: 40px;
                        }		
                        .workBannerImage{
                            display: none;
                        }
                        #hrDesign{
                            display: none;
                        }
                    }
                    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px)  { 
                        .workHeading:before{
                            content: ' ';
                            background: #33A478;
                            box-shadow: 0 4px 8px 0 rgba(76,215,200,0.3);
                            width: 23%;
                            height: 4px;
                            position: absolute;
                            bottom: 0;
                            margin-left: 40px;
                        }		
                    }
                    @media only screen and (min-width: 1024px) and (max-height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) {
                        .workHeading:before{
                            content: ' ';
                            background: #33A478;
                            box-shadow: 0 4px 8px 0 rgba(76,215,200,0.3);
                            width: 19%;
                            height: 4px;
                            position: absolute;
                            bottom: 0;
                            margin-left: 40px;
                        }		
                    }

                    `}
                </style>
                <div>
                    <div className="container-fluid" id="workBannerContainer">

                        {/* image banner starts */}

                        <div>
                            <img src={courseInfo} alt="banner" width="100%" height="625px" className="workBannerImage"/>
                        </div><br/>
                           <hr className="hr_design" id="hrDesign"></hr><br/>

                        {/* image banner ends  */}

                        {/* how live training work starts */}

                        <div className="container-fluid">
                            <h2 className="workHeading" align="center">How Live Training Works</h2>
                            <div>
                                <div className="row">
                                  

                                        <div className="col-md-4" align="center">
                                            <div>
                                                <img src={enroll} alt="enroll" className="howitworksImages" />
                                                <h4 className="workSubHeading">Enroll</h4>
                                            </div>
                                            <div style={{ textAlign: "justify",color: "#676767" }}>
                                                Getting enrolled is now easier
                                                than ever. All you have to do is
                                                choose the right course, take
                                                the demo and later enroll for
                                                live sessions. We know we have
                                                to lot of options for you to
                                                offer. So just in case you
                                                require more clarity on which
                                                course you wish to pursue;
                                                contact us and our instructors
                                                are there to help you!
                                            </div>
                                        </div>

                                        <div className="col-md-4" align="center">
                                            <div>
                                                 <img src={startlearning} alt="enroll" className="howitworksImages" />
                                                <h4 className="workSubHeading">Start Learning</h4>
                                            </div>
                                            <div style={{ textAlign: "justify",color: "#676767" }}>
                                                If you are new to SkillRary,
                                                take a demo class to get a
                                                better idea of the functionalities.
                                                Also, you get to know your instructor
                                                in advance to give you maximum learning
                                                benefits. We guarantee your satisfaction.
                                                Hence, we make you pay for the course
                                                only after you take the demo
                                                classes.
                                            </div>
                                        </div>

                                        
                                        <div className="col-md-4" align="center">
                                            <div>
                                                <img src={get} alt="enroll" className="howitworksImages" />
                                                <h4 className="workSubHeading">Get Mentored</h4>
                                            </div>
                                            <div style={{ textAlign: "justify",color: "#676767" }}>
                                                Your actual Live session begins only after
                                                you have completed the demo sessions. You
                                                will be provided with right guidance from
                                                industry experts. They will help you
                                                throughout the course in order to get you a
                                                deeper understanding of the theories and
                                                prepare you for live projects.
                                            </div>
                                        </div>
                                </div>
                            </div>
                        </div><br/>

                        <div className="container">
                            <div className="row">
                                <div className="col-md-6" align="center">
                                    <div>
                                        <img src={live} alt="enroll" className="howitworksImages" />
                                        <h4 className="workSubHeading">Live Coding</h4>
                                    </div>
                                    <div style={{ textAlign: "justify",color: "#676767" }}>
                                    Learn new concepts and
                                    master the art of Coding.
                                    This will prepare you for
                                    practical applications so
                                    that you can achieve the
                                    desired results.
                                    </div>
                                </div>

                                <div className="col-md-6" align="center">
                                    <div>
                                        <img src={projects} alt="enroll" className="howitworksImages" />
                                        <h4 className="workSubHeading">Projects</h4>
                                    </div>
                                    <div style={{ textAlign: "justify",color: "#676767" }}>
                                    You will be introduced to
                                    hands-on experience with live
                                    projects, once you are
                                    confident with the concepts.
                                    This will provide you the
                                    pathway to test your skills
                                    and see yourself as a talented
                                    future employee proving to be
                                    an important asset to the
                                    organization.
                                    </div>
                                </div>
                            </div>
                        </div><br/>
                        <hr className="hr_design"></hr><br/>

                        {/* how live training work ends  */}

                        {/* our training methods starts  */}

                        <div>
                            <h2 className="workHeading" align="center">Our Training Methods</h2>
                            <p align="center" className="pTagChoose">Choose suitable Training method based on your convience</p><br/>
                            <div className="row" style={{ margin: "0px" }}>
                                <div className="col-md-3" align="center">
                                    <div>
                                        <img src={online} alt="online classes" className="onlineImages"/><br/><br/>
                                        <h4 className="workSubHeading">Online Classroom</h4>
                                    </div>
                                    <div>
                                        <ul>
                                            <li className="liPoints">Access to Live Class for 90 days at your/our venue Hands on experiments days</li>
                                            <li className="liPoints">Course Materials</li>
                                            <li className="liPoints">Access to recorded videos for six months</li>
                                            <li className="liPoints">1 Grooming Session within 90days of session assigned</li>
                                            <li className="liPoints">SkillRary Certification</li>
                                            <li className="liPoints">Placement Assistance</li>
                                            <li className="liPoints">Resume building session</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-md-3" align="center">
                                    <div>
                                        <img src={classroom} alt="class room" className="onlineImages"/><br/><br/>
                                        <h4 className="workSubHeading">Classroom</h4>
                                    </div>
                                    <div>
                                        <ul>
                                            <li className="liPoints">Classroom Session can be conducted at your/our venue</li>
                                            <li className="liPoints">session includes presentations, hands on Experiments</li>
                                            <li className="liPoints">Access to recorded videos till the completion of the session</li>
                                            <li className="liPoints">Course Material</li>
                                            <li className="liPoints">Doubts clarification</li>
                                            <li className="liPoints">Customizable Course Content and Schedule</li>
                                            <li className="liPoints">SkillRary Certification</li>
                                            <li className="liPoints">Career Guidance</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-md-3" align="center">
                                    <div>
                                        <img src={o2o} alt="one2one classes" className="onlineImages"/><br/><br/>
                                        <h4 className="workSubHeading">One to One</h4>
                                    </div>
                                    <div>
                                        <ul>
                                            <li className="liPoints">Customized and exclusive training</li>
                                            <li className="liPoints">Hands on experiments</li>
                                            <li className="liPoints">Can be scheduled based on the request</li>
                                            <li className="liPoints">Course Materials</li>
                                            <li className="liPoints">1 Grooming session</li>
                                            <li className="liPoints">No access to recorded videos</li>
                                            <li className="liPoints">180 days access to recorded live class</li>
                                            <li className="liPoints">Placement Assistance</li>
                                            <li className="liPoints">Resume Building</li>
                                            <li className="liPoints">Explaining the Scope & job opportunities of the subject</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-md-3" align="center">
                                    <div>
                                        <img src={team} alt="team classes" className="onlineImages"/><br/><br/>
                                        <h4 className="workSubHeading">Team/Corporate Training</h4>
                                    </div>
                                    <div>
                                        <ul>
                                            <li className="liPoints">Live Presentation on Theory and demonstration of  features on related subjects</li>
                                            <li className="liPoints">Customized content and schedule</li>
                                            <li className="liPoints">Access to Recorded Videos</li>
                                            <li className="liPoints">Employee Analytics</li>
                                            <li className="liPoints">Hands on Practical</li>
                                            <li className="liPoints">Access to Compiler tool</li>
                                            <li className="liPoints">Access to Cloud Lab</li>
                                            <li className="liPoints">SkillRary Certification</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr className="hr_design"></hr><br/>

                  
                        {/* our training methods ends  */}

                        {/* request a batch starts */}



                        {/* request a batch ends  */}
                    </div>
                </div>			
            </div>
        )
    }
}

export default WorksBanner;
