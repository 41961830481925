import React, { Component } from 'react';
import Header from '../src/components/liveTrainingDesign/liveTrainingHeader';
import FooterTraining from "../src/components/liveTrainingDesign/footerTraining.jsx";
import ListCarousel from '../src/components/liveTrainingDesign/listCarouselView';

class ListView extends Component{
    constructor(props) {
        super(props);
            console.log('proo', props)
            this.state = {
                heading: "All Courses",
                courseType: 'all'
            }
        }

    render(){
        return (
            <div>
                {/* <Header /><br/><br/><br/> */}
                <ListCarousel heading={this.state.heading} courseType={this.state.courseType}/><br/>
                {/* <FooterTraining /> */}
            </div>
        )
    }
}

export default ListView;