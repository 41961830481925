import React, { Component } from 'react';
import youtubeImage from "../liveTrainingDesign/assets/home-banner-3.png";
import courseCertification from '../liveTrainingDesign/assets/course-certificate-about.png';

class ImageContainer extends Component{
    render(){
      console.log(this.props)
        return(

            <div>
                <style jsx>{`
                .imageContainer{
                    background: #33A478;
                    height: 470px;
                }
                
                * {
                    box-sizing: border-box;
                  }
                  
                  .row {
                    display: -ms-flexbox; /* IE10 */
                    display: flex;
                    -ms-flex-wrap: wrap; /* IE10 */
                    flex-wrap: wrap;
                   
                  }
                  
                  .column1 {
                    -ms-flex: 25%; /* IE10 */
                    flex: 25%;
                    max-width: 25%;
                    padding: 11px
                  }
                  .columnBtn{
                    -ms-flex: 25%; /* IE10 */
                    flex: 25%;
                    max-width: 25%;
                    padding: 11px
                  }
                  .columnSix{
                    -ms-flex: 75%; /* IE10 */
                    flex: 75%;
                    max-width: 75%;
                    padding: 11px 20px 20px 0px
                  }
                  .column {
                    -ms-flex: 50%; /* IE10 */
                    flex: 50%;
                    max-width:50%;
                 
                  }
                  .column2 {
                    -ms-flex: 75%; /* IE10 */
                    flex: 75%;
                    max-width: 75%;
                    padding: 11px 20px 20px 0px;
         
                  }
                  .column3 {
                    -ms-flex: 50%; /* IE10 */
                    flex: 50%;
                    max-width: 50%;
                    padding: 7px 7px 0px 0px
                  }
                  .column4 {
                    -ms-flex: 100%; /* IE10 */
                    flex: 100%;
                    max-width: 100%;
                    padding: 6px 0px
                  }
                  .column img {
                    width: 100%;
                  }
                  
                  .firstBox{
          
                    width: 100%;
                    height: 350px;
                    overflow: hidden;
                    border: 1px solid #33A478;
                    text-overflow: ellipsis;
                    background: white;
                    padding: 8px;
                    /* text-align: justify; */
                    margin: 0;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                
                  }
                  .secondBox{
                        width: 100%;
                        height: 120px;
                        overflow: hidden;
                        border: 1px solid #33A478;
                        text-overflow: ellipsis;
                        background: white;
                        padding: 8px;
                        text-align: justify;
                        margin: 0;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;
                  }
                  .thirdBox{
                        width: 100%;
                        height: 221px;
                        overflow: hidden;
                        border: 1px solid #33A478;
                        text-overflow: ellipsis;
                        background: white;
                        padding: 8px;
                        text-align: justify;
                        margin: 0;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;
                  }
                  .fourthBox{
                        width: 100%;
                        height: 105px;
                        overflow: hidden;
                        border: 1px solid #33A478;
                        text-overflow: ellipsis;
                        background: white;
                        padding: 8px;
                        // text-align: justify;
                        margin: 0;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;
                  }
                  .sixthBox{
                        width: 100%;
                        height: 85px;
                        overflow: hidden;
                        border: 1px solid #33A478;
                        text-overflow: ellipsis;
                        background: white;
                        padding: 5px;
                        text-align: justify;
                        margin: 0;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;
                  }
                  .seventhBox{
                        width: 100%;
                        height: 85px;
                        overflow: hidden;
                        border: 1px solid #33A478;
                        text-overflow: ellipsis;
                        background: white;
                        padding: 8px;
                        text-align: justify;
                        margin: 0;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;
                  }
                  .pTagContent{
                        width: 100%;
                        display: -webkit-box;
                        /* max-width: 220px; */
                        -webkit-line-clamp: 15;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        margin-top: 0px;
                        height: 330px;     
                        padding-top: 35px; 
                  }
                  .pTagContent1{
                      width: 100%;
                      display: -webkit-box;
                      /* height: 220px; */
                      -webkit-line-clamp: 4;
                      -webkit-box-orient: vertical;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      padding: 4px;
                      height: 97px;
                  }
                  .pTagContent7{
                        width: 100%;
                        display: -webkit-box;
                        /* max-width: 220px; */
                        -webkit-line-clamp: 4;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        margin: 5px auto;
                        height: 66px;
                  }
                  .pTagContent3{
                        width: 100%;
                        display: -webkit-box;
                        /* max-width: 220px; */
                        -webkit-line-clamp: 4;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        margin-top: 0px;
                        height: 210px;
                  }
                  .pTagContent4{
                        width: 100%;
                        display: -webkit-box;
                        /* max-width: 220px; */
                        -webkit-line-clamp: 4;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        margin-top: 0px;
                        height: 93px;
                  }
                  .enrollBtnNow{
                        margin: 16px;
                        /*width: 50%;*/
                        border-radius: 5px;
                        /* max-width: 278px; */
                        /*padding: 9px;*/
                        background: black;
                        border: 1px solid black;
                        color: white;
                        min-width: 121px;
                  }
                  .enrollBtnNow:hover{
                      outline: none;
                  }
                  /* Responsive layout - makes a two column-layout instead of four columns */
                  @media screen and (max-width: 800px) {
                    .imageContainer{
                        background: #33A478;
                        height: auto;
                    }
                    .column {
                        -ms-flex: 100%;
                        flex: 100%;
                        max-width: 100%;
                        padding: 0px;
                    }
                     
                    .column1 {
                        -ms-flex: 100%;
                        flex: 100%;
                        max-width: 100%;
                        padding: 11px;
                    }
                    .column2 {
                        -ms-flex: 100%;
                        flex: 100%;
                        max-width: 100%;
                        padding: 11px;
                      }
                      .column3 {
                        -ms-flex: 100%;
                        flex: 100%;
                        max-width: 100%;
                        padding: 10px 0px;
                      }
                      .column4 {
                        -ms-flex: 100%;
                        flex: 100%;
                        max-width: 100%;
                        padding: 10px 0px;
                      }
                      .columnBtn{
                        -ms-flex: 100%;
                        flex: 100%;
                        max-width: 100%;
                        padding: 11px
                      }
                      .columnSix{
                        -ms-flex: 100%;
                        flex: 100%;
                        max-width: 100%;
                        padding: 11px;
                      }
                      .sixthBox {
                        width: 100%;
                        height: 90px;
                        overflow: hidden;
                        border: 1px solid #33A478;
                        text-overflow: ellipsis;
                        background: white;
                        padding: 20px;
                        text-align: center;
                        margin: 9px 0px;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                    .enrollBtnNow{
                        margin: 2px;
                        width: 50%;
                        border-radius: 5px;
                        /* max-width: 278px; */
                        padding: 9px;
                        background: black;
                        border: 1px solid black;
                        color: white;
                        min-width: 121px;
                    }
                  }
                  
                  /* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
                  @media screen and (max-width: 600px) {
                    .column {
                      -ms-flex: 100%;
                      flex: 100%;
                      max-width: 100%;
                    }
                  }
                
                `}</style>

                <div className="container imageContainer">
                <div className="row"> 
                <div className="column1">
                    <div className="firstBox">
                  
                    <p className="pTagContent">
                          {this.props.jobHiring !== null ? this.props.jobHiring : ''}
                    </p>   
                    
                    </div>
                  
                  </div>
                  
                <div className="column2">
                <div className="secondBox">
                  
                  <p className="pTagContent1">
                          {this.props.impOfCourse !== null ? this.props.impOfCourse : ''}
                  </p>   
                    
                    </div>
                    
                      <div className="row" style={{ margin: "0px" }}>
                        <div className="column3"  style={{ }}>
                            <div className="thirdBox">
                  
                      <p className="pTagContent3">
                            <img src={this.props.whyCourseImg !== null ? this.props.whyCourseImg : ""} alt="image"  data-toggle="modal" data-target="#whyThis" style={{ width: "100%", height: '204px'}}/>
                      </p>   
                        
                        </div>

                    <div id="whyThis" className="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-body" id="imageBody">
                          <button data-dismiss="modal" className="closeImage">×</button>
                          <img src={this.props.whyCourseImg}  className="popupImage"/>
                        </div>
                      </div>
                    </div>
                    </div>

                  
                          </div>
                                
                  <div className="column">
                  
                        <div className="column4">
                        <div className="fourthBox">
                  
                    <p className="pTagContent4">
                      {this.props.avgSalary !== null ? this.props.avgSalary : ""}
                    </p>   
                    
                    </div>
                        </div>
                        
                        <div className="column4">
                        <div className="fourthBox">
                  
                  <p style={{ height:"104px",overflow:"hidden" }}>
                                {this.props.jobOpen}
                    </p>   
                    
                    </div>
                        
                    </div>
                    
                    
                  </div>
                      </div>
                  </div>
                </div> 
                <div className="row" style={{ marginTop: '-26px'}}> 
                  <div className="columnBtn">
                    <div className="sixthBox">

                    <a href={this.props.enrollLink !== null ? this.props.enrollLink : ''} target="_blank"><button className="enrollBtnNow">Enroll Now</button></a>
                    </div>
                  
                  </div>
                  
                <div className="columnSix">
                <div className="seventhBox">
                  
                  <p className="pTagContent7">
                    {this.props.studentNumber !== null ? this.props.studentNumber : ''}
                    </p>   
                    
                    </div>
                    
                  </div>  

                </div> 

                </div>
            </div> 
        )
    }
}

export default ImageContainer;