import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import webinar_video from '../liveTrainingDesign/assets/webinar.jpg';
import $ from 'jquery';

class WebinarVideo extends Component{

//   componentDidMount() {
//   	$(window).scrollTop(0); 
//   }

  render(){
    return(
      <div>
      <style jsx>{`
            #videoContainer{
                margin: 0;
                padding: 0;
            }
            .containerVideo{
                position: relative;
                background-color: #0548ce96;
                height: 75vh;
                min-height: 25rem;
                width: 100%;
                overflow: hidden;
              }
              
              .containerVideo video {
                position: absolute;
                top: 50%;
                left: 50%;
                min-width: 100%;
                min-height: 100%;
                width: auto;
                height: auto;
                z-index: 0;
                -ms-transform: translateX(-50%) translateY(-50%);
                -moz-transform: translateX(-50%) translateY(-50%);
                -webkit-transform: translateX(-50%) translateY(-50%);
                transform: translateX(-50%) translateY(-50%);
              }
              
              .containerVideo .textContainer {
                position: relative;
                z-index: 2;
              }
              
              .containerVideo .overlay {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                // background-color: black;
                // opacity: 0.5;
                z-index: 1;
              }
              .skillraryText{
                color: #0548CE;
                font-weight: 600;
                position: absolute;
                top: 35%;
                left: 50%;
                background: #e9ecef;
                padding: 10px;
                transform: translate(-50%,-35%);
              }
              .videoWidth{
                width: 100%;
                height: 75vh;
              }
              @media only screen and (max-width: 600px){
                .videoWidth{
                  width: 100%;
                  height: 71vh;
                }
                .skillraryText{
                  color: #0548CE;
                  font-weight: 600;
                  position: absolute;
                  top: 30%;
                  font-size: 28px;
                  left: 50%;
                  width: 255px;
                  background: #e9ecef;
                  padding: 10px;
                  transform: translate(-50%,-30%);              
                }
                .containerVideo{
                  position: relative;
                  background-color: #0548ce96;
                  height: 60vh;
                  min-height: 25rem;
                  width: 100%;
                  overflow: hidden;
                }
              }
              // @media (pointer: coarse) and (hover: none) {
              //   .containerVideo {
              //     background: url('https://source.unsplash.com/XT5OInaElMw/1600x900') black no-repeat center center scroll;
              //   }
              //   .containerVideo video {
              //     display: none;
              //   }
              // }
      `}
      </style>
		<div classNameName="container-fluid" id="videoContainer">
            <div className="containerVideo">
                {/* <div className="overlay"></div> */}
                    {/* <video playsinline="playsinline" autoplay="autoplay" muted="muted" loop="loop" controls="controls" >
                        <source src="https://www.youtube.com/watch?v=XUdfKzsYm5g&t=138s" type="video/mp4" />
                    </video> */}
                    {/* <iframe width="100%" height="609" src="https://www.youtube.com/embed/XUdfKzsYm5g" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
                    {/* <img src={webinar_video} className="videoWidth"/> */}
            </div>

            
            <div className="container-fluid textContainer h-100">
                        <div className="d-flex align-items-center">
                        <div className="w-100 text-white">
                            <h1 className="display-3 skillraryText">SkillRary Webinar</h1>
                            {/* <p className="lead mb-0">With HTML5 Video and Bootstrap 4</p> */}
                            
                        </div>
                        </div>
                    </div>
		</div>
      </div>
    )
  }
}

export default WebinarVideo;