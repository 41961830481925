import React, { Component } from 'react';

class webinarNewDesign extends Component{
    render(){
        return(
            <div>
                <style jsx>
                    {`
                    body{
                        background: #f6f9ff;
                    }
                    .icon-Icon-webinar-category-2{
                        content: "\eca8";
                    }
                    .headingTitle{
                        font-size: 25px;
                        font-weight: 600;
                        margin-left: 10px;
                        color: #33A478;
                    }
                    .containerblock{
                        display: flex;
                        width: 100%;
                        align-items: center;
                        justify-items: center;
                        padding: 30px 0;
                        flex-direction: row;
                    }
                    .webinarHeadleft {
                        display: flex;
                        align-items: center;
                        width: 55%;
                    }
                    .webinarHeadright {
                        display: flex;
                        align-items: center;
                        width: 45%;
                        text-align: right;
                    }
                    .sort_label {
                        border-right: 2px solid black;
                        padding: 0px 10px;
                        font-size: 18px;
                    }
                    .radio{
                        margin-left: 25px;
                        font-size: 18px;
                    }
                    .radio label{
                        margin-bottom: 0px;
                        margin-left: 10px;
                    }
                    .icondribble{
                        font-size: 26px;
                        font-weight: 600;
                    }
                    .CourseHeadingBox{
                        background: white;
                        padding-right: 15px;
                        padding-left: 100px;
                    }
                    .circleRight{
                        font-size: 21px;
                    }
                    .radioButton {
                        transform: scale(1.3);
                    }

                    .radioButton {
                        -webkit-appearance: none;
                        width: 12px;
                        height: 12px;
                        border-radius: 50%;
                        outline: none;
                        box-shadow: 0 0 0 2px gray;
                    }
            
                    .radioButton:checked {
                        box-shadow: 0 0 0 2px #33A478;
                    }
            
                    .radioButton:before {
                        content: '';
                        display: block;
                        width: 59%;
                        height: 60%;
                        margin: 18% auto;
                        border-radius: 50%;
                    }
            
                    .radioButton:checked:before {
                        background: #33A478;
                    }

                    .container:before {
                        content: '';
                        display: table;
                    }
                    .completeCard{
                        margin-bottom: 20px;
                    }
                    .courseCard{
                      
                        display: inline-block;
                        width: 100%;
                        padding: 25px 30px;
                        border-radius: 2px;
                        border: medium none;
                        box-shadow: 0 2px 10px 0 rgba(47,83,151,0.15);
                    }
                    .courseHeader{
                        background-color: white !important;
                        border-bottom: 1px solid white !important;
                        display: flex;
                        align-items: center;
                        width: 100%;
                        justify-content: space-between;
                        padding: 0;
                    }
                    .courseleftside{
                        display: inline-block;
                        width: 100%;
                    }
                    .courserightside{
                        display: inline-flex;
                        width: 100%;
                        align-items: center;
                        justify-content: flex-end;
                    }
                    .date_block {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 2px;
                        background: #f6f9ff;
                        padding: 15px;
                        margin: 0 20px 0 0;
                    }
                    .date {
                        display: flex;
                        align-items: center;
                        width: auto;
                        margin: 0 10px 0 0;
                        padding: 0 10px 0 0;
                        border-right: 1px solid #4a4a4a;
                        font-size: 20px;
                    }
                    .time {
                        font-weight: 600;
                        font-size: 20px;
                        color: #4a4a4a;
                    }
                    .registerbtn {
                        display: inline-block;
                        width: auto;
                        height: 50px;
                        border-radius: 2px;
                        border: solid 1.6px #33A478;
                        background-color: #ffffff;
                        padding: 14px 18px;
                        font-size: 16px;
                        font-weight: 600;
                        color: #33A478;
                        text-transform: uppercase;
                        text-align: center;
                    }
                    .courseBody{
                        background: white;
                    }
                    .infoBox{
                        display: flex;
                        width: 100%;
                        justify-content: space-between;
                        padding: 0;
                    }
                    .infoleft{
                        display: inline-block;
                        width: 60%;
                        margin: 0 40px 0 0;
                    }
                    .inforight {
                        display: inline-block;
                        width: 40%;
                        padding: 30px;
                        border-radius: 2px;
                        border: solid 1px #bfbfbf;
                        background-color: #fbfbfb;
                        height: 100%;
                        align-items: center;
                        flex-direction: column;
                    }
                    .form img{
                        margin-right: 11px;
                    }
                    .form span{
                        display: inline-block;
                        font-size: 18px;
                        font-weight: bold;
                        color: #000000;
                        margin-left: 5px;
                    }
                    .inputControl:focus{
                        border: solid 1px #c3c3c3;
                        box-shadow: initial;
                    }
                    .inputControl{
                        height: 50px;
                        border-radius: 4px;
                        border: solid 1px #c3c3c3;
                        background-color: #ffffff;
                        font-size: 19px;
                        font-weight: 400;
                        color: #9b9b9b;
                        padding: 8px 20px;
                        margin: 0 0 5px 0;
                        box-shadow: none;
                        width: 100%;
                    }
                    .inputControlnumber:focus{
                        border: solid 1px #c3c3c3;
                        box-shadow: initial;
                    }
                    .inputControlnumber{
                        height: 50px;
                        border-radius: 4px;
                        border: solid 1px #c3c3c3;
                        background-color: #ffffff;
                        font-size: 19px;
                        font-weight: 400;
                        color: #9b9b9b;
                        padding: 8px 20px 8px 90px;
                        margin: 0 0 5px 0;
                        box-shadow: none;
                        width: 100%;
                        margin-left: 68px;
                    }
                    .inputControlSelect{
                        height: 50px;
                        border-radius: 4px;
                        border: solid 1px #c3c3c3;
                        background-color: #ffffff;
                        font-size: 19px;
                        font-weight: 400;
                        color: #9b9b9b;
                        margin: 0 0 5px 0;
                        box-shadow: none;
                        position: absolute;
                      
                    }
                    .submitButton{
                        display: inline-block;
                        width: 100%;
                        border-radius: 4px;
                        background: linear-gradient(to right,#33A478,black);
                        font-size: 16px;
                        font-weight: bold;
                        color: #ffffff;
                        text-transform: uppercase;
                        text-align: center;
                        padding: 15px 40px;
                        box-shadow: none;
                        border: medium none;
                    }
                    .infoHeading{
                        font-size: 23px;
                        color: #33A478;
                    }
                    .agenda li{
                        font-size: 18px;
                    }
                    .factsul{
                        display: inline-block;
                        width: 100%;
                        padding: 15px 25px;
                        border-radius: 2px;
                        border: dashed 1px #33A478;
                        list-style-type: disc;
                        list-style-position: outside;
                        padding-left: 50px;
                    }
                    .factsul li{
                        font-size: 18px;
                   
                    }
                    `}
                </style>
                <div className="container-fluid CourseHeadingBox">
                    {/* <div className="container"> */}
                        <div className="containerblock">
                            <div className="webinarHeadleft">
                                <i className="fa fa-dribbble icondribble" aria-hidden="true"></i>
                                <span className="headingTitle"> DevOps</span>
                            </div>
                            <div className="webinarHeadright">
                                <span className="sort_label">
                                    <span>SORT BY </span>&nbsp;
                                    <i className="fa fa-arrow-circle-o-right circleRight" aria-hidden="true"></i>
                                </span>
                                <div className="radio">
                                    <input type="radio" value="most_popular" name="filtercourse" className="giTrackElement radioButton" />
                                    <label className="radio-label">By Upcoming Date</label>
                                </div>
                                <div className="radio">
                                    <input type="radio" value="most_popular" name="filtercourse" className="giTrackElement radioButton" />
                                    <label className="radio-label">Most Popular</label>
                                </div>
                            </div>
                        </div>
                    {/* </div> */}
                </div><br/><br/>

				
                    <div className="container courseContainer">
					    <div id="courseAccordion">
                            <div className="completeCard">
					            <div className="card courseCard">
                                    <div className="card-header collapsed courseHeader" data-toggle="collapse" href="#course1">
                                        <div className="courseleftside">
                                            <h3>Course Heading Course Heading</h3>
                                            <div>
                                            <i className="fa fa-user" style={{color: '#33A478'}} aria-hidden="true"></i><span style={{color: '#33A478',fontSize: '16px',fontWeight: '600',}}> 255 Registered</span>
                                            </div>
                                        </div>
                                        <div className="courserightside">
                                            <div className="date_block">
                                                <span className="date">
                                                <i class="fa fa-calendar" aria-hidden="true"></i>&nbsp;
                                                    <span>28th July</span>
                                                </span>
                                                <span class="time">02:30 PM (IST)</span>
                                            </div>
                                            <span><button className="registerbtn">Register Now</button></span>
                                        </div>
                                    </div>
                                    </div>
                                    <div id="course1" className="card-body courseBody collapse" data-parent="#courseAccordion" >
                                       <div className="infoBox">
                                           <div className="infoleft">
                                                <h4 className='infoHeading'>DESCRIPTION</h4>
                                                <p style={{ fontSize: '18px' }}>DevOps is a software development strategy which bridges the gap between Dev and Ops teams. DevOps Engineer ranks #2 on Glassdoor’s 50 Best Jobs in America rankings. “the role of DevOps Engineer has seen a 225% jump in postings on Indeed,” according to an SD Times report.</p>
                                                <h4 className='infoHeading'>AGENDA</h4>
                                                <ul className="agendaul">
                                                    <li>Introduction to DevOps Lifecycle</li>
                                                    <li>What are CI and CD?</li>
                                                    <li>Pipelines: What are they?</li>
                                                    <li>Pipeline Concepts</li>
                                                    <li>Continuous Delivery and Continuous Deployment</li>
                                                    <li>Role of Jenkins in CI/CD Pipeline</li>
                                                    <li>Hands-On</li>
                                                </ul>
                                                <h4 className='infoHeading'>Webinar Facts</h4>
                                                <ul className="factsul">
                                                    <li>81% of the enterprises are using DevOps practices along with 70% of small to medium businesses</li>
                                                    <li>80% of the companies pay more than $90,000 starting salary to a DevOps Engineer. Also, 35% pay at least $115,000, and 17% pay more than $125,000</li>
                                                    <li>Companies can increase frequency of their deployment by 50% by adopting to DevOps Practices also can save up to 46% in cost on other hand</li>
                                                </ul>
                                           </div>
                                           <div className="inforight">
                                                <div className="form">
                                                <img src="https://d1jnx9ba8s6j9r.cloudfront.net/imgver.1595415488/img/elearningpop.svg" alt="Form Header Image" id=""/><span>RESERVE YOUR SPOT NOW</span>
                                                </div><br/>
                                                <div>
                                                    <input type="email" className="form-control inputControl"/>
                                                </div><br/>
                                                <div style={{ display: 'inline-flex' }}>
                                                    <input type="text" className="form-control inputControlnumber"/>
                                                
                                                    <select className="inputControlSelect">
                                                        <option>+91</option>
                                                        <option>+919</option>
                                                        <option>+911</option>
                                                    </select>
                                                </div><br/><br/>
                                                <div style={{     fontSize: '20px' }}>
                                                    <input type="checkbox" /> Check here to receive further updates
                                                </div><br/>
                                                <div>
                                                    <button className="submitButton">Submit</button>
                                                </div>
                                           </div>
                                       </div>
                                    </div>
                                </div>


                                <div className="completeCard">
					            <div className="card courseCard">
                                    <div className="card-header collapsed courseHeader" data-toggle="collapse" href="#course2">
                                        <div className="courseleftside">
                                            <h3>Course Heading Course Heading</h3>
                                            <div>
                                            <i className="fa fa-user" style={{color: '#33A478'}} aria-hidden="true"></i><span style={{color: '#33A478',fontSize: '16px',fontWeight: '600',}}> 255 Registered</span>
                                            </div>
                                        </div>
                                        <div className="courserightside">
                                            <div className="date_block">
                                                <span className="date">
                                                <i class="fa fa-calendar" aria-hidden="true"></i>&nbsp;
                                                    <span>28th July</span>
                                                </span>
                                                <span class="time">02:30 PM (IST)</span>
                                            </div>
                                            <span><button className="registerbtn">Register Now</button></span>
                                        </div>
                                    </div>
                                    </div>
                                    <div id="course2" className="card-body courseBody collapse" data-parent="#courseAccordion" >
                                       <div className="infoBox">
                                           <div className="infoleft">
                                                <h4 className='infoHeading'>DESCRIPTION</h4>
                                                <p style={{ fontSize: '18px' }}>DevOps is a software development strategy which bridges the gap between Dev and Ops teams. DevOps Engineer ranks #2 on Glassdoor’s 50 Best Jobs in America rankings. “the role of DevOps Engineer has seen a 225% jump in postings on Indeed,” according to an SD Times report.</p>
                                                <h4 className='infoHeading'>AGENDA</h4>
                                                <ul className="agendaul">
                                                    <li>Introduction to DevOps Lifecycle</li>
                                                    <li>What are CI and CD?</li>
                                                    <li>Pipelines: What are they?</li>
                                                    <li>Pipeline Concepts</li>
                                                    <li>Continuous Delivery and Continuous Deployment</li>
                                                    <li>Role of Jenkins in CI/CD Pipeline</li>
                                                    <li>Hands-On</li>
                                                </ul>
                                                <h4 className='infoHeading'>Webinar Facts</h4>
                                                <ul className="factsul">
                                                    <li>81% of the enterprises are using DevOps practices along with 70% of small to medium businesses</li>
                                                    <li>80% of the companies pay more than $90,000 starting salary to a DevOps Engineer. Also, 35% pay at least $115,000, and 17% pay more than $125,000</li>
                                                    <li>Companies can increase frequency of their deployment by 50% by adopting to DevOps Practices also can save up to 46% in cost on other hand</li>
                                                </ul>
                                           </div>
                                           <div className="inforight">
                                                <div className="form">
                                                <img src="https://d1jnx9ba8s6j9r.cloudfront.net/imgver.1595415488/img/elearningpop.svg" alt="Form Header Image" id=""/><span>RESERVE YOUR SPOT NOW</span>
                                                </div><br/>
                                                <div>
                                                    <input type="email" className="form-control inputControl"/>
                                                </div><br/>
                                                <div style={{ display: 'inline-flex' }}>
                                                    <input type="text" className="form-control inputControlnumber"/>
                                                
                                                    <select className="inputControlSelect">
                                                        <option>+91</option>
                                                        <option>+919</option>
                                                        <option>+911</option>
                                                    </select>
                                                </div><br/><br/>
                                                <div style={{     fontSize: '20px' }}>
                                                    <input type="checkbox" /> Check here to receive further updates
                                                </div><br/>
                                                <div>
                                                    <button className="submitButton">Submit</button>
                                                </div>
                                           </div>
                                       </div>
                                    </div>
                                </div>
					        </div>
					    </div>
					</div>


        )
    }
}

export default webinarNewDesign;