import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';

class Categories extends Component{

    constructor(props) {
        super(props);
        this.state = {
          course: []
        }
        this.getCourse = this.getCourse.bind(this);
        this.categoryName = this.categoryName.bind(this);
        this.courseName = this.courseName.bind(this);
        this.categoryItems = this.categoryItems.bind(this);
        this.toggleHover = this.toggleHover.bind(this);
    }

    componentDidMount() {
        this.getCourse();        
    }

    toggleHover() {
         $('.productlist li').hover(function() { console.log('ddfd')
            $('.productlist li.activeCategory').removeClass('activeCategory');
            $(this).closest('li').addClass('activeCategory');
        });

        var $boxes = $('.boxlink'),
        $productLinks = $('.productlist .product-link').mouseover(function() {
            $boxes.hide().filter('#box' + this.id).show();
        });
    }

    getCourse() {
        fetch("https://live.skillrary.com/live-courses/index.php/api/v1/all-catogories-courses/virtusa", {
         method: 'GET',
        })
        .then(res => res.json())
        .then(
            (result) => {
                //console.log('res carousel', result);
                this.setState({
                    course: result.result,
                });   
            //console.log('courseList-=======================',this.state.course)
            },
            (error) => {
                this.setState({
                    isLoaded: true,
                    error
                });
            }
        )
    }

    categoryName(course,keyIndex) {
        var classActive = "activeCategory";
        
        if (keyIndex > 0) {
            classActive = "";
        }

        return (
            <li className={"header_roles "+classActive} onMouseEnter={this.toggleHover} onMouseLeave={this.toggleHover}>
                    <a href={"/category_course_details/"+course.id}  id={"link"+keyIndex} className="product-link" >{course.category_name}</a>
                    </li>
        )
    }

    categoryItems(course,keyIndex) {
        return (
            <li><a href={"/"+course.slug} className="coursename"><span>{course.course_title}</span></a></li>
        )
    }

    courseName(course,keyIndex) {
        var cname = [];
        
        if (course.length > 0) {       
            var self = this;
            Object.keys(course).map(function(keyName, keyIndex) {
                cname.push(self.categoryItems(course[keyIndex]))                
            })
        }

        return (
            <div id={"boxlink"+keyIndex} className="header_tabs_content boxlink" >
                <div className="header_tabs_content--container">
                    <div className="header_tabs_column">
                        <p className="first">Courses</p>
                        <ul className="header_topics_menu coursesUl">
                           {cname}
                        </ul>
                    </div>
                </div>
            </div>
        )
    }

  render(){

    console.log('state', this.state.course)
    const course = this.state.course;
    const mainCategory = [];
    const courseName = [];
    
    
    if (course.length > 0) {       
        var self = this;
        Object.keys(course).map(function(keyName, keyIndex) {
            var category = self.categoryName(course[keyIndex],keyIndex);
            var courseField = self.courseName(course[keyIndex].courses, keyIndex);
            mainCategory.push(category);
            courseName.push(courseField);
        })
    }
    
    return(
    <div>
    <style jsx>{`

    .productlist{
        background: #0B4433 !important;
    padding: 0;
    margin: 0;
    overflow-y: auto;
    height: 410px;
    width: 200px;
    position: absolute;
    }
    .header_dropdown {
        position: absolute;
        top: 0px;
        left: 0;
        right: 0;
        bottom: 0;
        width: 900px;
    }
    #header_tabs {
        max-height: 60vh;
    }

     #header_tabs {
        overflow: hidden;
    }
    .header_dropdown .container-lg {
        margin: 0 auto;
        display: flex;
        position: relative;
        box-sizing: border-box;
        z-index: 9999;
    }
    .header_dropdown .container-lg {
        max-width: 1230px;
    }
    #header_tabs ul .header_roles{
        text-align: right;
        margin-bottom: 0;
        margin-right: 0;
        margin-left: 0;
        position: relative;
        padding: 0;
        display: block;
        transition: all .3s ease;
    }

     #header_tabs ul li{
        margin: 0px;
        white-space: unset;
    }
    ul li {
        list-style: none;
    }
    .header_roles a{
        color: white;
        font-size: 14px;
        font-weight: 500;
        text-decoration: none;
        display: block;
        padding: 29px 8px 20px 7px;
        border-bottom: 1px solid #f8f9fa;
    }
    .header_roles.active:after{
        content: "";
        width: 2px;
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
    }
    .header_tabs_content{
        /*display: none;*/
        /*width: 100%;*/
        background: #107253;
        position: relative;
        margin-left: 200px;
        height: 410px;
    }
     .header_tabs_content--container {
        margin: 10px 0px;
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        max-height: 41vh;
        overflow-y: auto;
        white-space: initial;
        -webkit-overflow-scrolling: touch;
    }

    .header_tabs_column ul li.first {
        color: #fff;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 12px;
        margin-bottom: 15px;
        padding: 0 12px;
        position: relative;
    }
    .header_tabs_column ul li a {
        color: #ccc;
        padding: 8px 12px;
        display: flex;
        font-weight: 500;
        height: 100%;
        max-height: 54px;
        min-height: 54px;
    }
     .item .item-each {
        background-color: #222;
        width: 100%;
        position: relative;
        padding: .85em .25em .45em 60px;
    }
     .item .item-image {
        height: 60px;
        width: 60px;
        position: absolute;
        top: 50%;
        left: 0;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
    }
     .item .item-text {
        color: #fff;
        font-weight: 500;
        line-height: 1.2;
    }
    .item .item-text>ul li {
        text-transform: none;
        color: #aaa;
        font-weight: 200;
        font-size: 12px;
        display: inline-block;
        margin-bottom: 0;
        padding-left: 0;
        padding-right: 20px;
    }
    #header_tabs {
       
        max-height: 70vh;
    }
    .boxlink {
        display: none;
    }
    #boxlink0{
        display: block;
    }


    ul.coursesUl {
        width: 540px;
        padding: 20px;
    }

    ul.coursesUl li{
          display: inline-block;
        width: 225px;
    }
    .first{
            font-size: 17px;
        font-weight: 600;
        color: white;
        text-align: center;
    }
    .coursename{
        padding: 15px 20px 15px 20px;
        background: black;
        color: white !important;
        font-size: 14px;
    }
    .activeCategory
    {
       background-color: #107253;
    }
    .coursesUl li{
        margin: 7px !important;
    }
    .header_tabs_column ul li:hover .coursename {
        
        color: #107253 !important;
    }
`}
</style>
    <div className="header_dropdown">
        <div id="header_tabs" className="container-lg">
            <div className="closebutton " tabIndex="3"></div>
                <ul className="productlist">
                {mainCategory}
                </ul>
                {courseName}
            </div>
        </div>
    </div>
    )
  }
}

export default Categories;
