import React, { Component } from 'react';
import dateFormat from 'dateformat';
import $ from 'jquery';
// import cap from '../../assets/cap.png';

class nextCourse extends Component{
    constructor(props){
        super(props);
        this.state = {
            workshopDate : 'october 15, 2020 15:00:00',
    
        }
        this.clickCross = this.clickCross.bind(this);
    }


     
    componentDidMount(){
       
        var deadline = new Date(this.state.workshopDate).getTime(); 
        var x = setInterval(function() { 
        var now = new Date().getTime(); 
        var t = deadline - now; 
        var days = Math.floor(t / (1000 * 60 * 60 * 24)); 
        var hours = Math.floor((t%(1000 * 60 * 60 * 24))/(1000 * 60 * 60)); 
        var minutes = Math.floor((t % (1000 * 60 * 60)) / (1000 * 60)); 
        var seconds = Math.floor((t % (1000 * 60)) / 1000); 

        if(days < 10){
            document.getElementById("day").innerHTML = '0' + days ; 
        }else{
            document.getElementById("day").innerHTML = days ; 
        }

        if(hours < 10){
            document.getElementById("hour").innerHTML = '0' + hours ; 
        }else{
            document.getElementById("hour").innerHTML = hours ; 
        }

        if(minutes < 10){
            document.getElementById("minute").innerHTML = '0' + minutes ; 
        }else{
            document.getElementById("minute").innerHTML = minutes ; 
        }

        if(seconds < 10){
            document.getElementById("second").innerHTML = '0' + seconds ; 
        }else{
            document.getElementById("second").innerHTML = seconds ; 
        }

        if (t < 0) { 
                clearInterval(x); 
                document.getElementById("demo").innerHTML = "TIME UP"; 
                document.getElementById("day").innerHTML = '00'; 
                document.getElementById("hour").innerHTML ='00'; 
                document.getElementById("minute").innerHTML ='00' ;  
                document.getElementById("second").innerHTML = '00'; 
            } 
        }, 1000); 


        $(document).ready(function(){
            // Delay the action by 10000ms
            setTimeout(function(){
               // Display the div containing the class "bottomdiv"
               $(".nextContainer").show();
               $('.content').css('margin-top','55px')
            }, 8000);
            
         });
    }
    clickCross(){
        $('.nextContainer').css('display','none')
        $('.content').css('margin-top','0px')
    }

    render(){



        return(
            <div>
                <style jsx>
                    {`
                    .nextContainer{
                        animation: 1s ease-out 0s 1 ad-animation;
                        -moz-animation-fill-mode: forwards
                        -webkit-animation-fill-mode: forwards;
                        animation-fill-mode: forwards;
                      }
                      @-webkit-keyframes ad-animation {
                        0% {
                            transform: translateY(-100%);
                        }
                        100% {
                            transform: translateY(0);
                        }
                      }
                      @-moz-keyframes ad-animation {
                        0% {
                            transform: translateY(-100%);
                        }
                        100% {
                            transform: translateY(0);
                        }
                      }
                      @-o-keyframes ad-animation{
                        0% {
                            transform: translateY(-100%);
                        }
                        100% {
                            transform: translateY(0);
                        }
                      }
                      @keyframes ad-animation {
                        0% {
                            transform: translateY(-100%);
                        }
                        100% {
                            transform: translateY(0);
                        }
                      }
                    .nextContainer{
                        background: black;
                        padding: 10px 0;
                        transition: all 5s;
                        display:flex;
                        position: fixed;
                        top:0;
                        width: 100%;
                        z-index: 1;
                    }

                    .iconSize{
                        display: block;
                        float: left;
                        width: 100px;
                        height: 100px;
                        border-radius: 70px;
                        background: #fff;
                        text-align: center;
                        padding-top: 36px;
                        margin-right: 15px;
                    }
                    .courseContent{
                        padding-left: 90px;
                    }
                    .courseContent h2{
                        font-size: 28px;
                        color: #fff;
                        text-transform: uppercase;
                        margin-bottom: 10px;
                        padding-top: 10px;
                        font-weight: 600;
                        letter-spacing: 2px;
                    }
                    .capIcon{
                        font-size: 30px;
                        color: #33a478;
                    }
                    .DHMSContainer{
                        padding-left: 10px;
                        display: flex;
                    }
                    .DHMSContainer .DHMSitem h4{
                        font-size: 20px;
                        letter-spacing: 4px;
                        font-weight: 600;
                        color: #33A478;
                    }
                    .DHMSContainer .DHMSitem{
                        min-width: 55px;
                        font-size: 16px;
                        text-transform: uppercase;
                        color: #fff;
                        border-right: 1px solid #33A478;
                        text-align: center;
                        display: flex;
                        margin-left: 15px;
                        margin-top: 4px;
                    }
                    .DHMSContainer .DHMSitem:last-child{
                        border-right: none;
                    }
                    .dateTime{
                        font-size: 20px;
                        color: white;
                        letter-spacing: 3px;
                        font-weight: 600;
                    }
                    .timeUp{
                        // position: absolute;
                        // bottom: -45px;
                        // text-align: center;
                        color: white;
                        margin: 4px 20px;
                        // left: 50%;
                        // width: 100%;
                        // font-size: 22px;
                        // letter-spacing: 3px;
                        // transform: translateX(-50%);
                    }
                    .cap{
                        margin-top: -25px;
                        max-width: 86px;
                        min-width: 76px;
                    }
                    #crossIconTimer{
                        color: white;
                        position: absolute;
                        right: 30px;
                        top: 21px;
                        font-size: 20px;
                    
                    }
                   
                    @media only screen and (max-width: 600px){
                        .DHMSContainer{
                            padding-left: 0px;
                        }
                        .DHMSContainer .DHMSitem{
                            min-width: 90px;
                            font-size: 16px;
                            text-transform: uppercase;
                            color: #fff;
                            border-right: 1px solid #33A478;
                            text-align: center;
                            display: inline-block;
                        }
                    }
                    @media only screen and (max-width: 991px) and (min-width: 768px){
                        .DHMSContainer .DHMSitem{
                            min-width: 70px;
                            font-size: 16px;
                            text-transform: uppercase;
                            color: #fff;
                            border-right: 1px solid #33A478;
                            text-align: center;
                            display: inline-block;
                        }
                    }
                    `}
                </style>
            
                <div className="nextContainer" style={{ display: 'none'}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-5 col-md-6">
                                {/* <div className="iconSize">
                                    <img src={cap} className="cap"/>
                                </div>
                                <div className="courseContent">
                                    <h2>{this.state.workshopName}</h2>
                                    <p className="dateTime">{dateFormat(this.state.workshopDate, "dd/mm/yyyy")} <span>{workshop_time}</span></p>
                                </div> */}
                                <p style={{color: "white",fontSize:'20px',     width: '467px',borderRight:'2px solid white'}}>Deal of the Month - Flat 15% Off + 20% Cashback </p>
                            </div>

                            <div className="col-lg-7 col-md-6">
                                <div className="DHMSContainer">
                                    <span  style={{color: "white",fontSize:'20px'}}> OFFER ENDING IN :</span>&nbsp;
                                    <div className="DHMSitem">
                                        <h4 id="day"></h4>
                                        D
                                    </div>
                                    <div className="DHMSitem">
                                        <h4 id="hour"></h4>
                                        H
                                    </div>
                                    <div className="DHMSitem">
                                        <h4 id="minute"></h4>
                                        M
                                    </div>
                                    <div className="DHMSitem">
                                        <h4 id="second"></h4>
                                        S
                                    </div>
                                    <span id="demo" className="timeUp"></span> 
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    <div onClick={this.clickCross}><i class="fa fa-times" aria-hidden="true" id="crossIconTimer"></i></div>
                </div>
            </div>
        )
    }
}

export default nextCourse;