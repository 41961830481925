import React, { Component } from "react";
import $ from 'jquery';
import { Link } from 'react-router-dom';
import CarouselLoader from "./carouselLoader.jsx";

class CategoryRelatedCourses extends Component {

  constructor(props) {
    super(props);
    this.state = {
      course: [],
      checkCourse: undefined
    }
    this.getCourse = this.getCourse.bind(this);
    this.getCourseCard = this.getCourseCard.bind(this);
    this.setCourseData = this.setCourseData.bind(this);
    this.detectmob = this.detectmob.bind(this);
  }

  componentDidMount() {
    $(window).scrollTop(0); 
    this.getCourse();
  }

  getCourse() {
    var url = document.location.pathname;
    var pathname = url.substring(url.lastIndexOf('/')+1)
    console.log(pathname,'caturl')
    fetch("https://live.skillrary.com/live-courses/index.php/api/v1/category-courses", {
      method: 'POST',
			headers: {'Content-Type':'application/json'},
			body: JSON.stringify({
			  category_id: pathname,
			})
		})
      .then(res => res.json())
      .then(
        (result) => {
          console.log('res carousel', result.result);
          if (result.result !== "No Data Found") {
            this.setState({
              course: result.result,
            });
          } else {
            this.setState({
              checkCourse: "a",
            });
          }

          console.log('courseList-=======================',this.state.course)
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }

  getCourseCard(course, i, l) {  
    // alert('enter')
  console.log('=================================================================',l)
    return (
      <div className={"carousel-item " + ((l <= 0 || i == 4) ? "active" : "")} align="center">
        {course}
      </div>
    )
  }

  setCourseData(course) {
  console.log('aa',course);

    return (
      
        <div className="col-md-3 col-sm-6"> 
          <div className="card" style={{ height: "360px", marginBottom: "10px"}} >
            <a className="completeUpcomingCard">
              
              <span className="backUpcomingCard">
                <span className="cardBackUpcomingTopName">
                  <span>{course.course_title}</span> 
                </span>
                <span style={{ textAlign: "center"}}>
                <img src={(null !== course.course_img) ? course.course_img : course.course_img} className="frontUpcomingLogo"/>
                </span>
                <span className="secondUpcomingPart">

                     <Link to={`/${course.slug}`}><button className="buttonActiveSchedule">
                    View Schedule 
                    </button>
                    </Link>

                </span>
              </span>
            </a>              
          </div>
        </div>
    )

  }

detectmob() {
   if(window.innerWidth <= 800) {
     return false;
   } else {
     return true;
   }
}

  render() {

    console.log('a', this.detectmob())
      const course = this.state;
      var i = 0;
      var courseValue = [];
      var courseCard = [];

      var winWidth = this.detectmob();
      console.log('cou', course.course.length);

      if (course.course.length) {

        var self = this;
        Object.keys(course.course).map(function(keyName, keyIndex) {
          console.log('adf', course.course[keyName].id);
          console.log('i',i)
          if (winWidth) { 
            if (i > 3) {
              courseCard.push(self.getCourseCard(courseValue, keyName, (course.course.length - 1)));
              i = 0;
              courseValue = [];
            }

            console.log('key',keyName,course.course.length)
            
            if (keyName == (course.course.length - 1) ) {
              courseValue.push(self.setCourseData(course.course[keyName]));
              courseCard.push(self.getCourseCard(courseValue, i, (course.course.length - 1)));
            } else {
              courseValue.push(self.setCourseData(course.course[keyName]));
            }

            i++;
          } else {
            courseValue.push(self.setCourseData(course.course[keyName]));
            courseCard.push(self.getCourseCard(courseValue, keyName, (course.course.length - 1)));
            courseValue = [];
          }

        })
      }

    return (
    <div>
    <style jsx>{`
            .buttonActiveSchedule {
                display: inline-block;
                font-size: 14px;
                color: #2f70e1;
                padding: 10px 30px;
                cursor: pointer;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                padding: 10px;
                border: 1px solid #33A478;
                color: #33A478;
                padding: ;
                padding: 5px;
                background: white;
                border-radius: 5px;
                font-weight: 600;
                margin: 30px;
            }
            .buttonActiveSchedule:focus{
              outline: none;
            }
            .relatedHeading{
              font-weight: 700;
              font-size: 22px;
              color: #333742;
              letter-spacing: 1.5px;
              position: relative;
              padding: 0px 0px 10px 0px;
              margin-bottom: 20px;
            }
            .relatedHeading:before{
              content: ' ';
              background: #33A478;
              box-shadow: 0 4px 8px 0 rgba(76,215,200,0.3);
              width: 8%;
              height: 4px;
              position: absolute;
              bottom: 0;
              left: 50%;
              transform: translate(-50%);
            }

            .frontUpcomingLogo{
              display: inline-block;
              width: 100% !important;
              height: 200px !important;
              background-position: 50% 50%;
              background-repeat: no-repeat;
              background-size: cover;
            }
            .completeUpcomingCard{
              height: 362px;
              border-radius: 3px;
              transition: all 0.8s;
              overflow: hidden;
              border: 0 none;
              margin-bottom: 15px;
              padding: 0;
            }
            .completeUpcomingCard span{
              display: block;
            }
      
            .backUpcomingCard .backUpcomingDescription{
              font-size: 14px;
              color: #000000c4;
              letter-spacing: 0;
              margin-bottom: 23px;
              line-height: 18px;
              height: 85px;
              overflow: hidden;
            }
            .cardBackUpcomingTopName{
                border-radius: 3px 3px 0 0;
                height: 66px;
                vertical-align: middle;
                font-weight: 700;
                font-size: 16px;
                color: #fff;
                text-align: center;
                position: relative;
                transition: all 0.8s;
                background-image: linear-gradient(to right, rgba(0,0,0), rgba(51, 164, 120) , rgba(0,0,0));
            }
            .cardBackUpcomingTopName span{
                left: 0;
                height: auto;
                padding: 15px;
                display: block;
                text-align: center;
            }

            #viewUpcomingDetailsBtn{
                font-weight: 600;
                color: white;
                margin-bottom: 0 !important;
                margin: 0 auto !important;
                display: block;
                width: auto !important;
                font-size: 12px;
                background: #33A478;
                border: 1px solid #33A478;
                /* box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0,0,0,0.12); */
                padding: 10px;
                font-size: 15px;
            }
            #viewUpcomingDetailsBtn:focus{
                outline: none;
            }
            .carousel-control-prev{
                opacity: 5 !important;
            }
            .carousel-control-prev-icon {
                background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
            }

            .carousel-control-next-icon {
                opacity: 3;
                background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
            }

            .col-md-3{
                display: inline-block;
            }
           

      `}</style>

<div align="center" style={{ background: "white",
    marginTop: "-15px",
    padding: "16px"}}><h4 className="relatedHeading">Related Courses</h4><br/></div>
<div id="demo" class="carousel slide" data-ride="carousel" data-interval="0" style={{ background: 'white'}}>
  <div class="container carousel-inner no-padding">
 {courseCard.length > 0 ? courseCard : undefined !== this.state.checkCourse ? (<h3 style={{textAlign:'center'}}> No Course Found </h3>) : <CarouselLoader />}
</div>
{course.course.length > 4 ? 
(<span><a class="carousel-control-prev" href="#demo" data-slide="prev">
    <span class="carousel-control-prev-icon"></span>
</a>
<a class="carousel-control-next" href="#demo" data-slide="next">
    <span class="carousel-control-next-icon"></span>
</a></span>) : ""}

</div><br/>

</div>
);

  }
}
 

export default CategoryRelatedCourses;


















