import React, { Component } from 'react';
import $ from 'jquery';


class KnowMoreHowToJoinWebinar extends Component{
    constructor(props){
        super(props);
        this.state = {
            clicked: true
        }
    }
    componentDidMount(){
        $('.knowreadBtn').click(function(){
            var link = $(this);
            $('.knowContent').slideToggle('slow', function() {
                if ($(this).is(":visible")) {
                     link.text('Show Less');                
                } else {
                     link.text('Read Complete Instructions');                
                }  
            });    
        });
    }
    changeText(){
        this.setState({clicked: !this.state.clicked})
    }
    render(){
        return(
            <div>
                <style jsx>
                    {`
                    .howToContainer{
                        padding: 75px 55px;
                        background: #0548CE;
                    }
                    .knowhowAttend{
                        color: white;
                        font-size: 75px;
                        width: 80%;
                        letter-spacing: 2px;
                    }
                    .knowstepsWebinar{
                        color: white;
                        font-size: 35px;
                    }
                    .knowpTagContent{
                        color: white;
                        font-size: 20px;
                        text-align: justify;
                    }
                    .knowreadBtn{
                        background: #F9CA33;
                        border: 1px solid #F9CA33;
                        padding: 5px;
                        width: 100%;
                        max-width: 210px;
                        min-width: 210px;
                        font-size: 22px;
                        color: #0548CE;
                        box-shadow: 4px 4px #e6e6e6;
                        margin-bottom: 20px;
                    }
                    .knowarricon{
                        background: #F9CA33;
                        padding: 0px;
                        font-size: 25px;
                        border-radius: 50px;
                        position: relative;
                        margin-left: 20px;
                        /* top: 19px; */
                        color: white;
                        width: 60px;
                        height: 60px;
                        display: inline-block;
                        text-align: center;
                        vertical-align: middle;
                    }
                    .videoContainer{
                       height: 400px;
                        width: 100%;
                    }
                    @media only screen and (max-width:600px){
                        .videoContainer{
                            width: 100%;
                        }
                        .knowpTagContent {
                            color: white;
                            font-size: 20px;
                            /* text-align: justify; */
                            word-break: break-all;
                        }
                        .knowhowAttend {
                            color: white;
                            font-size: 50px;
                            width: 80%;
                            letter-spacing: 2px;
                        }
                    }
                    `}
                </style>
                <div className="container-fluid howToContainer">
                    <div className="row">
                        <div className="col-lg-7">
                            <div className="row">
                                <div className="col-lg-11">
                                <div className="content">
                                    <h3 className="knowhowAttend">How to attend Webinar</h3><br/><br/>
                                    <h4 className="knowstepsWebinar">Steps to join the Webinar</h4><br/><br/>
                                    <p className="knowpTagContent">Firstly, open
                                    the web browser and search for SkillRary. On the home-page of SkillRary, click
                                    on the sign-in option. Here enter your email id, password and enter the
                                    Recaptcha to ensure the user is human and click on submit.</p>
                                    <p className="knowpTagContent">Once you have clicked, you will find all the courses that you have assigned.</p>
                                    <p className="knowpTagContent">For you to join the sessions, your account has to be approved in the back-end from SkillRary
                                    admin to begin the course. This is done only for the first session for the
                                    course. For later sessions, you need not do this as the courses have already
                                    been approved.</p>
                                    <p className="knowpTagContent">Choose the course you have to join. Bear in mind, you can join the meeting only 10 mins
                                    prior to the assigned time of your course.</p>
                                
                                    <div className="knowContent" style={{ display: "none" }}>
                                        <p className="knowpTagContent">
                                        Once you have selected the course, you can see your corresponding course analytics. Below, you can see the name of the course, start date and end date of the course along with the timings of each session. Click on Join Meeting. 
                                        </p>
                                        <p className="knowpTagContent">
                                        Once you have done that, a new tab is launched which contains launching of an application- Go to Meeting. Here you can see a dialogue box which opens up which has the option ‘open Go To Meeting’. You need to launch the application for the only first session.
                                        </p>
                                        <p className="knowpTagContent">
                                        If you have joined the session before the instructor joins the session, you will get the message saying ‘The meeting will begin when the instructor arrives.’ Wait for the instructor to join the session. 
                                        </p>
                                        <p className="knowpTagContent">
                                        Once you have joined the session, you can see the taskbar on your right-hand side. Here you have the option to unmute your conversation, share your screen or share your webcam. To share your screen or webcam, the function has to be approved by your instructor. 
                                        </p>
                                        <p className="knowpTagContent">
                                        Below, you can see all the attendees at the meeting. If you click on the dropdown option on anyone of the attendee, you can see the following functions. 
                                        </p>
                                        <p className="knowpTagContent">
                                        Next, you have the chat option. You can select whom you wish to chat with. Here I will choose everyone and type my message. 
                                        </p>
                                        <p className="knowpTagContent">
                                        Lastly, to end your session, click on the drop-down button present in the top of the go-to-meeting taskbar. Here in the last option, you can see, Exit- end meeting. Click on this to end the meeting and finish the session. 
                                        </p>
                                        <p className="knowpTagContent">
                                        After ending the session, you can revisit the SkillRary page and log out of SkillRary. 
                                        </p>    
                                    </div>

                                <button className="knowreadBtn" onClick={ () => this.changeText() }>Read Complete Instructions</button>
                                <span className="knowarricon"><i className={this.state.clicked ? "fa fa-long-arrow-up" : 'fa fa-long-arrow-down'} style={{ verticalAlign : '-webkit-baseline-middle'}} aria-hidden="true"></i></span>
                                </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-5  align-self-center">
                            {/* <video playsinline="playsinline" className="videoContainer" controls muted="muted" loop="loop">
                                <source src={webinar_video} type="video/mp4" />
                            </video> */}
                            <iframe className="videoContainer"  src="https://www.youtube.com/embed/XUdfKzsYm5g" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default KnowMoreHowToJoinWebinar;