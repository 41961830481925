import React,{ Component } from 'react';

class ContactLiveTraining extends Component{
	render(){
		return(
			<div>
			<style jsx>{`
				#contactContainer{
					background-color: #f5f5f5 !important;
					height: auto;
					padding: 0px;
				}
				.waysToContact{
				    position:relative;
				    display:inline-block;
				    padding-bottom:20px;
				}
				.waysToContact:after {
				    content: "";
				    position: absolute;
				    bottom: 10px;
				    left: 20%;
				    display: inline-block;
				    width: 160px;
				    border-bottom: 4px solid white;
				}
				.contactCard{
					background: white;
					height: 300px;
					width: 100%;
					margin-bottom: 25px;
				}
				.contactIcon{
					text-align: center;
				    padding-top: 40px;
				    padding-bottom: 20px;
				    font-size: 50px;
				}
				.callUs{
					margin-bottom: 1px;
				    font-size: 18px;
				    font-weight: 700;
				    text-align: center;
				}
				.contactNumber{
					font-size: 14px;
				    font-weight: 500;
					letter-spacing: 2px;
					margin-top: 10px;
				    text-align: center;
				}
				.contactBtn:focus{
					outline: none;
				}
				.contactBtn{
					background: white;
				    border: 2px solid black;
					padding: 6px 20px;
					color: black;
				    font-weight: 500;
				}
				#contactSecondContainer{
					padding: 30px;
				}
				.kFBgBorder{
					border-top: 45px solid #33A478;
					margin-top: 10px;
				}
				.keyFeaturesHeading{
					color: white;
				    margin-top: -38px;
				    text-align: center;
				}
				.contactUsHeading{	
					font-weight: 700;
					font-size: 22px;
					color: #333742;
					letter-spacing: 1.5px;
					position: relative;
					padding: 0px 0px 10px 0px;
					top: 10px;
				}
				.contactUsHeading:before{
					content: ' ';
					background: #33A478;
					box-shadow: 0 4px 8px 0 rgba(76,215,200,0.3);
					width: 11%;
					height: 4px;
					position: absolute;
					bottom: 0;
					left: 50%;
					transform: translate(-50%);
				}
				@media only screen and (max-width: 600px){
					.contactUsHeading{	
						font-weight: 700;
						font-size: 22px;
						color: #333742;
						letter-spacing: 1.5px;
						position: relative;
						padding: 0px 0px 10px 0px;
						top: 10px;
					}
					.contactUsHeading:before{
						content: ' ';
						background: #33A478;
						box-shadow: 0 4px 8px 0 rgba(76,215,200,0.3);
						width: 45%;
						height: 4px;
						position: absolute;
						bottom: 0;
						left: 50%;
						transform: translate(-50%);
					}
				} 
				
			`}
			</style>
				<div className="container-fluid" id="contactContainer">
				{/* <div className="kFBgBorder" id="upcoming"><h4 className="keyFeaturesHeading">Ways to contact us</h4></div> */}

					<div id="upcoming"><h4 className="contactUsHeading" style={{ textAlign:'center'}}>Ways to contact us</h4></div>
					<div className="container" id="contactSecondContainer">
						<div className="row">
							<div className="col-md-4">
								<div className="contactCard">
									<div className="contactIcon">
										<i className="fa fa-volume-control-phone" aria-hidden="true"></i>
									</div>
									<div>
										<p className="callUs">Call Us</p>
										<p className="contactNumber">(+91)9606655655</p>
										<p className="contactNumber">+1(415)429-3957</p>
										<p className="contactNumber">+44(747)190-0695</p>									
									</div>
								</div>	
							</div>
							<div className="col-md-4">
								<div className="contactCard">
									<div className="contactIcon">
										<i className="fa fa-comments-o" aria-hidden="true"></i>
									</div>
									<div align="center">
										<button className="contactBtn" disabled>Live Chat</button>
										<p className="contactNumber">Click on 'Chat now' below to get connected with us live</p>
									</div>
								</div>	
							</div>
							<div className="col-md-4">
								<div className="contactCard">
									<div className="contactCard">
										<div className="contactIcon">
											<i className="fa fa-envelope" aria-hidden="true"></i>
										</div>
										<div align="center">
											<a href="https://www.skillrary.com/contact-us"><button className="contactBtn">Drop a Query</button></a>
											<p className="contactNumber">Click to drop your query</p>
										</div>
									</div>	
								</div>	
							</div>
						</div>
					</div>
				</div>			
			</div>
		)
	}
}

export default ContactLiveTraining;
