import React, { Component } from 'react';
import Header from '../src/components/liveTrainingDesign/liveTrainingHeader';
import FooterTraining from "../src/components/liveTrainingDesign/footerTraining.jsx";
import SearchListComponent from '../src/components/liveTrainingDesign/searchListComponent.jsx';

class SearchList extends Component{

	  constructor(props) {
        super(props);
        console.log('pp', props)

       }
    render(){
        return(
            <div>
                <Header /><br/><br/><br/><br/>
                <SearchListComponent course={this.props.match.params.course}/><br/>
                <FooterTraining />
            </div>
        )
    }
}

export default SearchList;