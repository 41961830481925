import React, { Component } from 'react';
import Footer from './footerTraining';

class ReadInstructions extends Component{
    render(){
        return(
            <div>
                <style jsx>
                    {`
                    .instructionsContainer{
                        background: #0548CE;
                        padding: 50px 25px;
                    }
                    .webinarAttend{
                        color: #0548CE;
                        font-size: 60px;
                        background: lightgrey;
                        width: 70%;
                        padding: 10px 60px;
                        border-top-right-radius: 40px;
                        border-bottom-left-radius: 40px;
                    }
                    .webinarSteps{
                        color: white;
                        font-size: 35px;
                    }
                    .pTagContent{
                        color: white;
                        font-size: 19px;
                        text-align: justify;
                        line-height: 2;
                    }
                    .ulListSteps{
                       
                        padding: 0;
                        margin: 0;                    
                    }
                    
                    @media only screen and (max-width: 600px){
                        .knowmorevideoContainer{
                            width: 100%;
                        }
                        .frameWidth{
                            width: 100%;
                        }
                    }
                    `}
                </style>
                <div className="contaier-fluid instructionsContainer">
                    <div className="row">
                        <div className="container">
                            <h3 className="webinarAttend">How to attend Webinar</h3><br/>
                            <h4 className="webinarSteps">Steps to join the Webinar</h4><br/><br/>
                            <ul className="ulListSteps">
                                <li className="pTagContent">Firstly, open
                                the web browser and search for Skillrary. On the home-page of Skillrary, click
                                on the sign-in option. Here enter your email id, password and enter the
                                Recaptcha to ensure the user is human and click on submit.</li>
                                <li className="pTagContent">Once you have clicked, you will find all the courses that you have assigned.</li>
                                <li className="pTagContent">For you to join the sessions, your account has to be approved in the back-end from SkillRary
                                admin to begin the course. This is done only for the first session for the
                                course. For later sessions, you need not do this as the courses have already
                                been approved.</li>
                                <li className="pTagContent">Choose the course you have to join. Bear in mind, you can join the meeting only 10 mins
                                prior to the assigned time of your course.</li>
                                <li className="pTagContent">
                                Once you have selected the course, you can see your corresponding course analytics. Below, you can see the name of the course, start date and end date of the course along with the timings of each session. Click on Join Meeting. 
                                </li>
                                <li className="pTagContent">
                                Once you have done that, a new tab is launched which contain launching of an application- Go to Meeting. Here you can see a dialogue box which opens up which has the option ‘open Go To Meeting’. You need to launch the application for the only first session.
                                </li>
                                <li className="pTagContent">
                                If you have joined the session before the instructor joins the session, you will get the message saying The meeting will begin when the instructor arrives. Wait for the instructor to join the session. 
                                </li>
                                <li className="pTagContent">
                                Once you have joined the session, you can see the taskbar on your right-hand side. Here you have the option to unmute your conversation, share your screen or share your webcam. To share your screen or webcam, the function has to be approved by your instructor. 
                                </li>
                                <li className="pTagContent">
                                Below, you can see all the attendees at the meeting. If you click on the dropdown option on anyone of the attendee, you can see the following functions. 
                                </li>
                                <li className="pTagContent">
                                Next, you have the chat option. You can select whom you wish to chat with. Here I will choose everyone and type my message. 
                                </li>
                                <li className="pTagContent">
                                Lastly, to end your session, click on the drop-down button present in the top of the go=to=meeting taskbar. Here in the last option, you can see, Exit- end meeting. Click on this to end the meeting and finish the session. 
                                </li>
                                <li className="pTagContent">
                                After ending the session, you can revisit the SkillRary page and log out of SkillRary. 
                                </li>
                                <li className="pTagContent">
                                This ends the demo on how you can join a live session or meeting on SkillRary. 
                                </li>
                                <li className="pTagContent">
                                Login now to access all the courses designed by the experts of SkillRary. 
                                </li>
                                <li className="pTagContent">
                                If you like this video subscribe to Skillrary Youtube channel. Click here to watch similar videos. 
                                </li>
                            </ul>
                        </div>
                    </div><br/>
                    <div className="row">
                        <div className="col-md-8 offset-md-4 align-self-center">
                            {/* <video width="500" height="300" controls className="knowmorevideoContainer">
                                <source src="https://www.youtube.com/watch?v=XUdfKzsYm5g&t=138s" type="video/mp4" />
                            </video> */}
                            <iframe className="frameWidth" width="500" height="300" src="https://www.youtube.com/embed/XUdfKzsYm5g" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}

export default ReadInstructions;