import React, { Component } from 'react';

class FacebookTrainingOptions extends Component{
    render(){
        return(
            <div>
                <style jsx>
                    {`
                    .trainingContainer{
                        background: black;
                        padding: 40px 10px;
                    }
                    .cardFace{
                        height: 350px;
                        position: relative;
                        margin-bottom: 18px;
                        width: 275px;
                        overflow: hidden;
                        background: #fff;
                        box-shadow: 0px 1px 0px 0px rgba(36,38,47,0.10), 0px 4px 6px 0px rgba(0,0,0,0.06);
                        border-radius: 3px;
                        padding: 20px;
                        border: 1px solid white;
                    }
                    .logo{
                        width: 57px !important;
                        height: 57px !important;
                        margin-top: 18px;
                        font-size: 57px;
                        color: #696969;
                    }
                    .cardDetails{
                        padding: 0 38px;
                        height: 188px;
                    }
                    .heading{
                        border-bottom: 1px solid #e7e7e7;
                        font-weight: 600;
                        padding: 15px 0 22px 0;
                        font-size: 15px;
                        color: #33a478;
                    }
                    .cardContent{
                        height: 123px !important;
                        overflow: hidden;
                        font-size: 12px !important;
                        color: #999 !important;
                    }
                    .btnDesign{
                        width: 100%;
                        background: #33A478 !important;
                        box-shadow: 0px 6px 8px 0px rgba(0,0,0,0.10);
                        border-radius: 3px;
                        height: 44px;
                        font-weight: 700;
                        font-size: 12px;
                        color: #fff !important;
                        letter-spacing: 0px;
                        text-transform: uppercase;
                        border: 0 none;
                        padding: 14px 15px 16px 15px;
                        line-height: 14px;
                    }
                    .btnDesign:focus{
                        outline: none;
                        box-shadow:  0px 0px 5px 2px rgba(0,0,0,0.4);
                    }
                    .questionIcon{
                        position: absolute;
                        top: 20px;
                        right: 20px;
                        color: #eee;
                        font-size: 24px;
                    }
                    .onlineClassSchedule{
                        color: white;
                    }
                    .onlineClassSchedule:hover{
                        text-decoration: none;
                        color: white;
                    }
                    .trainingHeading{
                        color: #ffc339;
                        font-weight: 600;
                        font-size: 35px;
                    }
                    .cardfloat{
                        float: right;
                    }
                    .cardfloat1{
                        float: left;
                    }
                    @media only screen and (max-width: 600px){
                        .cardfloat{
                            float: inherit;
                        }
                        .cardfloat1{
                            float: inherit;
                        }
                    }
                    `}
                </style>
                <div className ="container-fluid trainingContainer">
                    <div className="container-fluid" align="center">
                        <div className="row">
                            <div className="col-md-3">
                                <h3 className="trainingHeading">Training Options:</h3>
                            </div>
                        </div><br/><br/><br/>
                        <div className="row">
                            <div className="col-md-3 col-sm-6">
                                <div className="cardFace cardfloat">
                                    <i className="fa fa-laptop logo" aria-hidden="true"></i>
                                    <div className="cardDetails">
                                        <h3 className="heading">Online Classroom</h3>
                                        <div className="cardContent">
                                            Instructor Led Live Online Training
                                        </div>
                                    </div>
                                    <div>
                                        <a href="#1" className="onlineClassSchedule"><button className="btnDesign">VIEW SCHEDULES</button></a>
                                    </div>
                                    <div className="questionIcon">
                                        <i className="fa fa-question-circle"></i>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-6">
                                <div className="cardFace">
                                    <i className="fa fa-crosshairs logo" aria-hidden="true"></i>
                                    <div className="cardDetails">
                                        <h3 className="heading">Classroom</h3>
                                        <div className="cardContent">
                                            Instructor Led Live Online Training
                                        </div>
                                    </div>
                                    <div>
                                        <a href="#1" className="onlineClassSchedule"><button className="btnDesign">REQUEST A SESSION</button></a>
                                    </div>
                                    <div className="questionIcon">
                                        <i className="fa fa-question-circle"></i>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-2 col-sm-6">
                                <div className="cardFace cardfloat1">
                                    <i className="fa fa-clock-o logo" aria-hidden="true"></i>
                                    <div className="cardDetails">
                                        <h3 className="heading">One to One Training</h3>
                                        <div className="cardContent">
                                            Customized and exclusive training based on your requirements
                                        </div>
                                    </div>
                                    <div>
                                        <a href="#1" className="onlineClassSchedule"><button className="btnDesign">REQUEST A SESSION</button></a>
                                    </div>
                                    <div className="questionIcon">
                                        <i className="fa fa-question-circle"></i>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-6">
                                <div className="cardFace">
                                    <i className="fa fa-users logo" aria-hidden="true"></i>
                                    <div className="cardDetails">
                                        <h3 className="heading">Team/Corporate Training</h3>
                                        <div className="cardContent">
                                            Up-skill your team by training them on SkillRary Certification Courses 
                                        </div>
                                    </div>
                                    <div>
                                        <a href="#1" className="onlineClassSchedule"><button className="btnDesign">REQUEST A SESSION</button></a>
                                    </div>
                                    <div className="questionIcon">
                                        <i className="fa fa-question-circle"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default FacebookTrainingOptions;