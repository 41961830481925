import React from 'react';

class LoadAd extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = { show: false,headings:"",courseImage:"",enrollLink:"" }
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
  }

  componentDidMount(){
    this.showModal();
    var apiUrl = "https://live.skillrary.com/live-courses/index.php/api/v1/course-ads";

    fetch(apiUrl, {
     method: 'GET',
    })
      .then(res => res.json())
      .then(
        (result) => {
     
          console.log('result',result.result[0])
          this.setState({
           headings: result.result[0].headings,
           courseImage: result.result[0].course_image,
           enrollLink: result.result[0].enroll_link
          });  
        },
        (error) => {
          this.setState({
           isLoaded: true,
            error
          });
        }
      )
  }

  showModal () {
    console.log('da');
    this.setState({ show: true });
  }
  
  hideModal() {

    this.setState({ show: false });
  }
    
  render() {
    
    console.log('state', this.state)
    return (
      <div>
      <main>
        <Modal show={this.state.show} handleClose={this.hideModal} heading={this.state.headings} courseImage={this.state.courseImage} enrollLink={this.state.enrollLink} />
      </main>
      </div>
    )
  }
}

class Modal extends React.Component {

// const Modal = ({ handleClose, show, children }) => {
 
render() {
  console.log('teste',this.props)
  const showHideClassName = this.props.show ? 'modal display-block opacity-animate3' : 'modal display-none';
  return (
    <div>
      <style jsx>{`
        .modal {
          position: fixed;
          top: 0;
          left: 0;
          width:100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.6);
        }
        .modal-main {
          position:fixed;
          height: auto;
          top:50%;
          left:50%;
          transform: translate(-50%,-50%);
        }
        .display-block {
          display: block;
        }
        .display-none {
          display: none;
        }
        .ModalContent{
          width: 618px !important;
          background: black;
        }
        .modal-dialog {
          position: relative;
          width: auto;
          pointer-events: none;
          left: -43px;
        }
        .titleContent{
          font-size: 20px;
          color: #33A478;
        }
        .close {
          float: right;
          font-size: 1.5rem;
          font-weight: 700;
          line-height: 1;
          color: #ffffff;
          opacity: 1; 
        }        
        .modal-header {
          display: -ms-flexbox;
          display: flex;
          -ms-flex-align: start;
          align-items: flex-start;
          -ms-flex-pack: justify;
          justify-content: space-between;
          padding: 1rem 1rem;
          border-bottom: 1px solid #4e4e4f;
          border-top-left-radius: .3rem;
          border-top-right-radius: .3rem;
        }
        .modal-footer {
          display: -ms-flexbox;
          display: flex;
          -ms-flex-align: center;
          align-items: center;
          -ms-flex-pack: end;
          justify-content: center;
          padding: 1rem;
          border-top: 1px solid #4e4e4f;
          border-bottom-right-radius: .3rem;
          border-bottom-left-radius: .3rem;
        }
        .infobtn{
          background: #33A478;
          border: 1px solid #33A478;
          color: black;
          font-weight: 600;
          width: 130px;
        }
        .closebtn{
          background: #33A478;
          border: 1px solid #33A478;
          color: black;
          font-weight: 600;
          width: 130px;
        }
        .close:hover {
          float: right;
          font-size: 1.5rem;
          font-weight: 700;
          line-height: 1;
          color: #ffffff;
          opacity: 1; 
        }

        .opacity-animate3{
          animation: 1s ease-out 0s 1 ad-animation;
          -moz-animation-fill-mode: forwards
          -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
        }
        @-webkit-keyframes ad-animation {
          0% {
              transform: translateY(-100%);
          }
          100% {
              transform: translateY(0);
          }
        }
        @-moz-keyframes ad-animation {
          0% {
              transform: translateY(-100%);
          }
          100% {
              transform: translateY(0);
          }
        }
        @-o-keyframes ad-animation{
          0% {
              transform: translateY(-100%);
          }
          100% {
              transform: translateY(0);
          }
        }
        @keyframes ad-animation {
          0% {
              transform: translateY(-100%);
          }
          100% {
              transform: translateY(0);
          }
        }
        `}
        </style>
      {/* <div className={showHideClassName}>
      <section className='modal-main'>
        <div class="modal-content ModalContent">
          <div class="modal-header">
            <h4 class="modal-title titleContent">{this.props.heading}</h4>
            <button onClick={this.props.handleClose} type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
             <img src={this.props.courseImage} />
          </div>
          <div class="modal-footer">
            <a href={this.props.enrollLink}><button type="button" class="btn closebtn mx-auto">Enroll now</button></a>
            <a href={this.props.enrollLink}><button type="button" class="btn infobtn mx-auto">Info</button></a>
          </div>
       </div>
      </section>
    </div> */}
    </div>
  );
}
}


export default LoadAd;