import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class FileNotFounder extends Component{
    render(){
        return(
            <div>
                <style jsx>
                    {`
                    
                    .homeIcon{
                        background: #33A478;
                        padding: 0px 30px;
                        color: white;
                        border: 1px solid #33A478;
                        font-size: 25px;
                        border-radius: 2px;
                    }
                    .mac {
                        background: url("http://edurary.com/assets/skillrary/images/lap.png") no-repeat rgba(0, 0, 0, 0);
                        height: 306px;
                        padding: 0;
                        width: 105%;
                        padding: 28px 74px;
                    }
                    .bgColorMac{
                        background: linear-gradient(180deg, #33a478 3%, black);
                        height: 240px;                    
                    }
                    .number404{
                        position: relative;
                        top: 25%;
                        color: black;
                        background: white;
                        height: 100px;
                        width: 26%;
                        padding: 13px;
                        border-radius: 60px;
                        font-size: 3em;
                    }
                    #SorryPage1{
                        display: none;
                    }

                    @media only screen and (max-width: 600px) {
                        #SorryPage{
                            display: none;
                        }
                        #SorryPage1{
                            display: block;
                        }
                        .number404Mobile{
                            position: relative;
                            top: 36%;
                            color: white;
                            background: linear-gradient(180deg, #33a478 3%, black);
                            height: 150px;
                            width: 150px;
                            padding: 35px;
                            border-radius: 111px;
                            font-size: 3em;
                        }
                    }

                    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
                        #SorryPage{
                            display: none;
                        }
                        #SorryPage1{
                            display: block;
                        }
                        .number404Mobile{
                            position: relative;
                            top: 36%;
                            color: white;
                            background: linear-gradient(180deg, #33a478 3%, black);
                            height: 150px;
                            width: 150px;
                            padding: 35px;
                            border-radius: 111px;
                            font-size: 3em;
                        }
                    }
                    @media only screen and (max-width : 1200px) {
                        #SorryPage{
                            display: none;
                        }
                        #SorryPage1{
                            display: block;
                        }
                        .number404Mobile{
                            position: relative;
                            top: 36%;
                            color: white;
                            background: linear-gradient(180deg, #33a478 3%, black);
                            height: 150px;
                            width: 150px;
                            padding: 35px;
                            border-radius: 111px;
                            font-size: 3em;
                        }
                    }

                    `}
                </style>
                <div className="container" id="SorryPage" align="center" style={{ marginTop: '10%' }}>
                    <div class="col-md-6 col-sm-6">
                        <div class="mac">
                            <div className="bgColorMac">
                                <p className="number404">404</p>
                            </div>
                        </div>
                    </div><br/>
                    <div style={{ color: 'black', fontSize: '32px' }}>
                        Sorry, Page Not Found
                    </div><br/>
                    <div>
                        <Link to="/"><button className="homeIcon"><i className="fa fa-home" aria-hidden="true"></i></button></Link>
                    </div>
                </div>


                <div className="container" id="SorryPage1" align="center" style={{ marginTop: '10%' }}>
                    <div class="col-md-6 col-sm-6">
                        <div>
                            <p className="number404Mobile">404</p>
                        </div>
                    </div><br/>
                    <div style={{ color: 'black', fontSize: '32px' }}>
                        Sorry, Page Not Found
                    </div><br/>
                    <div>
                        <Link to="/"><button className="homeIcon"><i className="fa fa-home" aria-hidden="true"></i></button></Link>
                    </div>
                </div>

            </div>
        )
    }
}

export default FileNotFounder;