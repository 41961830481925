import React, { Component } from 'react';
import youtubeImage from '../liveTrainingDesign/assets/home-banner-3.png';

class AboutUs extends Component{
	render(){
		return(
		<div>
		<style jsx>{`
		    *{
          box-sizing: border-box;
        }
        body{
          color:#333;
        }
        html{
          -webkit-tap-highlight-color:rgba(0,0,0,0);
        }
        .mac {
          background: url("https://www.skillrary.com/assets/skillrary/images/lap.png") no-repeat rgba(0, 0, 0, 0);
          height: 306px;
          padding: 0;
          width: 100%;
          padding: 27px 72px;
        }
        .iframeImage{
          width: 100%;
          max-width: 400px;
          /* height: 242px; */
          margin: 30px auto;
        }
        .panel-flat-lg{
          position:relative;
          background-color: #f4f4f4;
        }
        div{
          display: block;
          margin: 0;
          padding: 0;
          border: 0;
          font-size: 100%;
          font: inherit;
        }
        section{
            display:block;
            margin: 0;
            padding: 0;
            border: 0;
            font-size: 100%;
            font: inherit;
        }
        .panel-right-image {
            background-repeat: no-repeat;
            background-position: top right;
            background-size: 50%;
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            left: 0;
            width: 100%;
        }
        .container {
            font-weight: 400;
            padding-right: 15px;
            padding-left: 15px;
            margin-right: auto;
            margin-left: auto;
        }
        .row {
            margin-right: -15px;
            margin-left: -15px;
        }
        .col-md-6.col-sm-6{
            position: relative;
            min-height: 1px;
            padding-right: 15px;
            padding-left: 15px;
        }
        .panel-left{
            position: relative;
            padding: 34px 70px 50px 0;
        }
        .panel-left h2 {
            line-height: 35px;
            font-weight: 300;
            text-transform: capitalize;
            letter-spacing: 1px;
        }
        .section-heading {
            color: #333742;
            position: relative;
            padding: 15px 0;
            font-family: 'Raleway',sans-serif;
        }
        h2{
          margin:0;
          border:0;
          font:'Raleway',sans-serif;;
        }
        .panel-left p {
            font-size: 16px;
            color: #a6a6a6;
            letter-spacing: 0;
            line-height: 24.13px;
            text-align: left;
            position: relative;

        }
        p{
            margin: 0;
            padding: 0;
            border: 0;
            font: inherit;
        }
        .panel-flat-lg .circle-green {
            width: 160px;
            height: 160px;
            line-height: 196px;
            position: absolute;
            top: 50%;
            left: 50%;
            -webkit-transform: translate(-50%,-50%);
        }
        .circle-green {
            background: #33A478CF;
            color: white;
            box-shadow: 0 6px 8px 0 rgba(0,0,0,0.10);
            border-radius: 100%;
        }
        .text-center {
            text-align: center;
        }
        .icon-panel-grid-2 {
            background-position: -124px -85px;
            height: 49px;
            width: 46px;
            display: inline-block;
        }
        .icons-new {
            background-image: url(https://d6vdma9166ldh.cloudfront.net/assets/images/icons/sprite-new.png);
            background-repeat: no-repeat;
        }
        i{
          margin: 0;
            padding: 0;
            border: 0;
            font-size: 100%;
            font: inherit;
        }
        .spanText{
          color: black;
        }
        .span1Text{
          color: black;
          position: relative;
          top: 20px;
        }
        .span2Text{
          color: black;
          position: relative;
          top: 34px;
        }
        .aboutUsHeading{
          font-weight: 700;
          font-size: 22px;
          color: #333742;
          letter-spacing: 1.5px;
          position: relative;
          padding: 0px 0px 10px 0px;
          margin-bottom: 20px;
        }
        .aboutUsHeading:before{
          content: ' ';
          background: #33A478;
          box-shadow: 0 4px 8px 0 rgba(76,215,200,0.3);
          width: 5%;
          height: 4px;
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translate(-50%);
        }
        
        @media (device-width:360px){
          .mac {
            background: transparent;
            height: 306px;
            padding: 0;
            width: 100%;
          }
          .panel-right-image {
            background-size: 100% 300px;
            position: relative;
            height: 300px;
        }
        .panel-left{
          padding-right: 0;
            padding-bottom: 15px;
            margin-top: 20px;
            padding-top: 0;
        }
        .panel-left h2 {
            margin-bottom: 30px;
            font-size: 22px;
        }
        .panel-left p {
            padding-right: 0px;
            height: 310px;
        }
        .hidden-xs {
            display: none!important;
        }
        }
        @media (device-width:411px){
          .mac {
            background: transparent;
            height: 306px;
            padding: 0;
            width: 100%;
          }
          .panel-right-image {
            background-size: 100% 300px;
            position: relative;
            height: 300px;
        }
        .panel-left{
            padding-right: 0;
            padding-bottom: 15px;
            margin-top: 20px;
            padding-top: 0;
        }
        .panel-left h2 {
            margin-bottom: 30px;
            font-size: 22px;
        }
        .panel-left p {
            padding-right: 0px;
            height: 310px;
        }
        .hidden-xs {
            display: none!important;
        }
        }

        @media (device-width:320px){
          .mac {
            background: transparent;
            height: 306px;
            padding: 0;
            width: 100%;
          }
          .panel-right-image {
            background-size: 100% 300px;
            position: relative;
            height: 300px;
        }
        .panel-left{
          padding-right: 0;
            padding-bottom: 15px;
            margin-top: 20px;
            padding-top: 0;
        }
        .panel-left h2 {
            margin-bottom: 30px;
            font-size: 22px;
        }
        .panel-left p {
            padding-right: 0px;
            height: 310px;
        }
        .hidden-xs {
            display: none!important;
        }
        }
        @media (device-width:375px){
          .mac {
            background: transparent;
            height: 306px;
            padding: 0;
            width: 100%;
          }
          .panel-right-image {
            background-size: 100% 300px;
            position: relative;
            height: 300px;
        }
        .panel-left{
          padding-right: 0;
            padding-bottom: 15px;
            margin-top: 20px;
            padding-top: 0;
        }
        .panel-left h2 {
            margin-bottom: 30px;
            font-size: 22px;
        }
        .panel-left p {
            padding-right: 0px;
            height: 310px;
        }
        .hidden-xs {
            display: none!important;
        }
        }
        @media (device-width:414px){
          .mac {
            background: transparent;
            height: 306px;
            padding: 0;
            width: 100%;

          }
          .panel-right-image {
            background-size: 100% 300px;
            position: relative;
            height: 300px;
        }
        .panel-left{
          padding-right: 0;
            padding-bottom: 15px;
            margin-top: 20px;
            padding-top: 0;
        }
        .panel-left h2 {
            margin-bottom: 30px;
            font-size: 22px;
        }
        .panel-left p {
            padding-right: 0px;
            height: 310px;
        }
        .hidden-xs {
            display: none!important;
        }
        }
        @media(max-width: 600px){
          .aboutUsHeading{
            font-weight: 700;
            font-size: 22px;
            color: #333742;
            letter-spacing: 1.5px;
            position: relative;
            padding: 0px 0px 10px 0px;
            margin-bottom: 20px;
          }
          .aboutUsHeading:before{
            content: ' ';
            background: #33A478;
            box-shadow: 0 4px 8px 0 rgba(76,215,200,0.3);
            width: 20%;
            height: 4px;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translate(-50%);
          }
          .iframeImage{
            width: 100%;
            max-width: 400px;
            /* height: 242px; */
            margin: 0px;
          }
        }
        @media(device-width: 768px ){
          .mac {
            background: transparent;
            height: 306px;
            padding: 0;
            width: 100%;
            margin-top: 80px;
          }
        }
        @media (device-width: 1024px){
          .mac {
            background: transparent;
            height: 306px;
            padding: 0;
            width: 100%;
            padding: 27px 72px;
          }
          .panel-left h2 {
            margin-top: 18px;
          font-size: 18px;
        }
        }
        .iconSize{
          font-size: 48px;
        }
		    .kFBgBorder{
          border-top: 45px solid #33A478;
          margin-top: 10px;
          color: white;
	      }
				`}</style>
        			<div id="ongoingClasses"></div>

				<div id="upcoming" style={{ textAlign: 'center' }}><h4 className="aboutUsHeading">About Us</h4></div>

		<section className="panel-flat-lg panel-grid-2">

	<div className="container">
		<div className="row">
			<div className="col-md-6 col-sm-6 ">
				<div className="panel-left">
					
					<p>
            <span className="spanText"><span style= {{ color: '#33A478', fontWeight: '600' }}> Mentored by Experts: </span> You will be mentored by leading industry experts throughout the course so that you can excel in the subject.<br/></span>
            <span className="span1Text"><span style= {{ color: '#33A478', fontWeight: '600' }}>Interactive Sessions:</span> Move over just learning online. You get to create real-time projects to provide you with hands-on classroom experience.<br/></span>
            <span className="span2Text"><span style= {{ color: '#33A478', fontWeight: '600' }}>Upskill and Upgrade:</span> We extend our guidance post your course as well. Our mentors will help you upgrade your portfolio to meet the industry requirements.<br/></span>
					</p>
					
				</div>
				
			</div>
      <div className="col-md-1"></div>
      <div className="col-md-5 col-sm-5">
      {/* <div class="mac"> */}
      {/* <a href=""> */}
				{/* <iframe src="https://www.youtube.com/embed/zlUnD1TEnT4" className="iframeImage" frameborder="0">
        </iframe> */}
        
        <img src={youtubeImage} className="iframeImage"/> 
			{/* </a> */}
				{/* </div> */}
			</div>

		</div>
		
	</div>

	
</section>

</div>
		)
	}
}

export default AboutUs;