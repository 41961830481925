import React, { Component } from 'react';
import * as Autosuggest from "react-autosuggest";
import AutosuggestHighlightMatch from "autosuggest-highlight/umd/match";
import AutosuggestHighlightParse from "autosuggest-highlight/umd/parse";
import { Redirect } from 'react-router-dom';

const languages = [
    {
      name: 'C',
      year: 1972
    },
    {
      name: 'C#',
      year: 2000
    },
    {
      name: 'C++',
      year: 1983
    },
    {
      name: 'Clojure',
      year: 2007
    },
    {
      name: 'Elm',
      year: 2012
    },
    {
      name: 'Go',
      year: 2009
    },
    {
      name: 'Haskell',
      year: 1990
    },
    {
      name: 'Java',
      year: 1995
    },
    {
      name: 'Javascript',
      year: 1995
    },
    {
      name: 'Perl',
      year: 1987
    },
    {
      name: 'PHP',
      year: 1995
    },
    {
      name: 'Python',
      year: 1991
    },
    {
      name: 'Ruby',
      year: 1995
    },
    {
      name: 'Site Reliability Engineering Foundation',
      year: 2003
    },
    {
      name: 'Site Reliability Engineering For Developer',
    },
    {
      name: 'Site Reliability Engineering for Automation Engineers',
    },
    {
      name: 'SRE Cloud Engineer with AWS',
    },
    {
      name: 'SRE Cloud Engineer with Google Could'
    }
  ];
  
  function escapeRegexCharacters(str) {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  }
  
  function getSuggestions(value) {
    const escapedValue = escapeRegexCharacters(value.trim());
    
    if (escapedValue === '') {
      return [];
    }
  
    const regex = new RegExp('^' + escapedValue, 'i');
  
    return languages.filter(language => regex.test(language.name));
  }
  
  function getSuggestionValue(suggestion) {
    return suggestion.name;
  }
  
  function renderSuggestion(suggestion, { query }) {
    const matches = AutosuggestHighlightMatch(suggestion.name, query);
    const parts = AutosuggestHighlightParse(suggestion.name, matches);
  
    return (
      <span>
        {parts.map((part, index) => {
          const className = part.highlight ? 'react-autosuggest__suggestion-match' : null;
  
          return (
            <span className={className} key={index}>
              {part.text}
            </span>
          );
        })}
      </span>
    );
  }
  
  class SearchBarComponent extends Component {
    constructor() {
      super();
  
      this.state = {
        value: '',
        suggestions: []
      };
      this.onEnter = this.onEnter.bind(this);   
    }
    
    onEnter = (event) => {
        if (event.key == "Enter") {
          window.location.reload();
        }
     };

    onChange = (event, { newValue, method }) => {

    console.log('eee', newValue)
    //var course = event.target.value;
    //console.log('id', course, newValue)
    //this.setState ({searchText: course})
    
   
   if (window.location.pathname.includes("searchCourses/")) {
      window.history.pushState(null, "Search Results", "" + newValue)
   } else {
      window.history.pushState(null, "Search Results", "/searchCourses/" + newValue)
   }
   
   window.sessionStorage.setItem("courseSearch", newValue);

      this.setState({
        value: newValue
      });
    };
    
    onSuggestionSelected(event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) {
      console.log('sss', suggestion)
      
      window.location.reload()
    }

    onSuggestionsFetchRequested = ({ value }) => {
      this.setState({
        suggestions: getSuggestions(value)
      });
    };
  
    onSuggestionsClearRequested = () => {
      this.setState({
        suggestions: []
      });
    };
  
    render() {
      const { value, suggestions } = this.state;
      const inputProps = {
        placeholder: 'Search Courses',
        value: window.sessionStorage.getItem("courseSearch"),
        onChange: this.onChange,
        onKeyPress: this.onEnter,
      };
  
      return (
          <div>
              <style jsx>
                    {`
          
                        .react-autosuggest__container {
                            position: relative;
                        }
                        
                        .react-autosuggest__input {
                            width: 240px;
                            height: 30px;
                            padding: 10px 25px;
                            // font-family: Helvetica, sans-serif;
                            font-weight: 300;
                            font-size: 14px;
                            border: 1px solid #aaa;
                            border-radius: 4px;
                            font-family: FontAwesome, "Open Sans", Verdana, sans-serif;
                        }
                        
                        .react-autosuggest__input--focused {
                            outline: none;
                        }
                        
                        .react-autosuggest__input--open {
                            border-bottom-left-radius: 0;
                            border-bottom-right-radius: 0;
                        }
                        
                        .react-autosuggest__suggestions-container {
                            display: none;
                        }
                        
                        .react-autosuggest__suggestions-container--open {
                            display: block;
                            position: absolute;
                            top: 30px;
                            width: 100%;    
                            border: 1px solid #aaa;
                            background-color: #fff;
                            font-family: Helvetica, sans-serif;
                            font-weight: 300;
                            font-size: 16px;
                            border-bottom-left-radius: 4px;
                            border-bottom-right-radius: 4px;
                            z-index: 2;
                        }
                        
                        .react-autosuggest__suggestions-list {
                            margin: 0;
                            padding: 0;
                            list-style-type: none;
                        }
                        
                        .react-autosuggest__suggestion {
                            cursor: pointer;
                            padding: 3px 18px;
                            text-align: left;
                        }
                        
                        .react-autosuggest__suggestion--highlighted {
                            background-color: #ddd;
                        }
                        
                        .react-autosuggest__suggestion-match {
                            color: #33A478;
                            font-weight: bold;
                        }

                    `}
              </style>
            <Autosuggest
            onClick={this.onEnter} 
            suggestions={suggestions}
            onSuggestionSelected={this.onSuggestionSelected}
            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={renderSuggestion}
            inputProps={inputProps}/>
          </div>
      );
    }
  }
  
  export default SearchBarComponent;