import React, { Component } from 'react';
import $ from 'jquery';
import waitImage from './components/liveTrainingDesign/assets/wait.gif';

class CloudLab extends Component{

    constructor(props) {
        super(props);
        this.state = {
            token:"",
            cookie:"",
            username: "swetha1@skillrary.com",
            password: "Skillrary#223",
            labId: "53322",
            viewlab: "",
            labStatus: 0,
            validMail : 0,
            userMail: ""
        }
        this.startLab = this.startLab.bind(this);
        this.stopLab = this.stopLab.bind(this);
        this.loginLab = this.loginLab.bind(this);
        this.showLabLink = this.showLabLink.bind(this);
        this.getLabDetails = this.getLabDetails.bind(this);
        this.checkLabStatus = this.checkLabStatus.bind(this);
        //this.checkMail = this.checkMail.bind(this);
    }

    componentDidMount() {
        console.log('mount')
        this.loginLab();
        //this.getLabDetails();
        // this.startLab();

    }

    // checkMail(e) {
    //     console.log('aa');
    //     this.setState({
    //         userMail: 
    //     })
    // }

    showLabLink() {
        this.setState({
            viewlab:"https://www.skillrary.com/openlab"
        })
    }

    stopLab() {
         var apiUrl = "https://live.skillrary.com/live-courses/index.php/api/cloud-lab/stop-lab";
        const data = new URLSearchParams();
        data.append('token', this.state.token)
        data.append('cookies', this.state.cookie)
        data.append('labid', this.state.labId)
        fetch(apiUrl, {
            method: 'POST',
            
            body: data
        })
          .then(res => res.json())
          .then(
            (result) => {
              console.log('res carousel', result);
                setTimeout(this.checkLabStatus, 120000);
                this.checkLabStatus();
            },
            (error) => {
              this.setState({
                isLoaded: true,
                error
              });
            }
          )
    }

    startLab() {
        this.setState({
                    labStatus: 2
                })
        var apiUrl = "https://live.skillrary.com/live-courses/index.php/api/cloud-lab/start-lab";
        const data = new URLSearchParams();
        data.append('token', this.state.token)
        data.append('cookies', this.state.cookie)
        data.append('labid', this.state.labId)
        fetch(apiUrl, {
            method: 'POST',
            
            body: data
        })
          .then(res => res.json())
          .then(
            (result) => {
                console.log('res carousel', result);
                setTimeout(this.showLabLink, 120000);
                setTimeout(this.checkLabStatus, 120000);
                this.checkLabStatus();
            },
            (error) => {
              this.setState({
                isLoaded: true,
                error
              });
            }
          )
    }

    getLabDetails() {
        var apiUrl = "https://live.skillrary.com/live-courses/index.php/api/cloud-lab/get-details";
        //const data = new URLSearchParams();
        //data.append('cookie', this.state.cookie)
        const data = new URLSearchParams();
        data.append('token', this.state.token)
        data.append('cookie', this.state.cookie)
        data.append('labId', this.state.labId)
        fetch(apiUrl, {
            method: 'POST',
            
            body: data
        })
          .then(res => res.json())
          .then(
            (result) => {
              console.log('resa', result.result.totalTime);
                this.setState({
                    totalTime: result.result.totalTime,
                    usedTime:  result.result.usedTime,
                    leftTime:  result.result.leftTime
                })
                
                //var token = result['token'];
                //var cookie = result['session_name']."=".result['sessid'];
            },
            (error) => {
              this.setState({
                isLoaded: true,
                error
              });
            }
          )
    }

    checkLabStatus() {
        var apiUrl = "https://live.skillrary.com/live-courses/index.php/api/cloud-lab/checkStatus";
        //const data = new URLSearchParams();
        //data.append('cookie', this.state.cookie)
        const data = new URLSearchParams();
        data.append('token', this.state.token)
        data.append('cookie', this.state.cookie)
        data.append('labid', this.state.labId)
        fetch(apiUrl, {
            method: 'POST',
            
            body: data
        })
          .then(res => res.json())
          .then(
            (result) => {
              console.log('resd', result);

                this.setState({
                    labStatus: result
                })
                //var token = result['token'];
                //var cookie = result['session_name']."=".result['sessid'];
            },
            (error) => {
              this.setState({
                isLoaded: true,
                error
              });
            }
          )
    }

    loginLab() {
        var apiUrl = "https://live.skillrary.com/live-courses/index.php/api/cloud-lab/login";
        const data = new URLSearchParams();
        data.append('email', this.state.username)
        data.append('pwd', this.state.password)
        fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            
            body: data
        })
          .then(res => res.json())
          .then(
            (result) => {
              console.log('res carousel', result.result);
                this.setState({
                    token:result.result.token,
                    cookie: result.result.session_name + "=" + result.result.sessid
                })
                this.getLabDetails();
                this.checkLabStatus();
                //var token = result['token'];
                //var cookie = result['session_name']."=".result['sessid'];
            },
            (error) => {
              this.setState({
                isLoaded: true,
                error
              });
            }
          )
    }

    render(){
        console.log('ss', this.state)
        return(
            <div>
                <style jsx>
                    {`

                    @keyframes zoominoutsinglefeatured {
                        0% {
                            transform: scale(1,1);
                        }
                        50% {
                            transform: scale(1.2,1.2);
                        }
                        100% {
                            transform: scale(1,1);
                        }
                    }
                    .bubbleCircle{
                        position: fixed;
                        opacity: 0.5;
                        width: 100%;
                        border-radius: 100%;
                        -webkit-animation: zoominoutsinglefeatured 3s infinite;
                        animation: zoominoutsinglefeatured 3s infinite;
                        background: linear-gradient(125deg, #33A478, #33A478 30%, black);
                    }
                    .bubbleCircle1{
                        top: 40px;
                        left: 75px;
                        max-width: 165px;
                        height: 160px;
                    }
                    .bubbleCircle2{
                        bottom: 5%;
                        left: 10%;
                        max-width: 165px;
                        height: 160px;
                    }
                    .bubbleCircle3{
                        top: 5%;
                        left: 34%;
                        max-width: 70PX;
                        height: 70PX;
                    }
                    .bubbleCircle4{
                        top: 10%;
                        left: 80%;
                        max-width: 150px;
                        height: 160px;    
                    }
                    .bubbleCircle5{
                        left: 40%;
                        bottom: 54%;
                        max-width: 150px;
                        height: 160px;   
                    }
                    .bubbleCircle6{
                        left: 28%;
                        bottom: 33%;
                        max-width: 60px;
                        height: 60px;   
                    }
                    .bubbleCircle7{
                        right: 28%;
                        bottom: 50%;
                        max-width: 60px;
                        height: 60px; 
                    }
                    .bubbleCircle8{
                        right: 29%;
                        bottom: 10%;
                        max-width: 180px;
                        height: 180px;
                    }
                    .bubbleCircle9{
                        right: 10%;
                        bottom: 20%;
                        max-width: 60px;
                        height: 60px;
                    }
                    .bubbleCircle10{
                        right: 35%;
                        top: 9%;
                        max-width: 80px;
                        height: 80px;
                    }
                    .cloudButton{
                        position: absolute;
                        left: 22%;
                        top: 25%;
                        transform: translate(25%,25%);
                        background: #33A478;
                        border: 1px solid #33A478;
                        padding: 12px 25px;
                        font-size: 28px;
                    }   
                    .cloudButton:focus{
                        outline: none;
                    }   
                    .cloudTitle{
                        font-weight: 600;
                    }
                    #myModalCloud{
                        position: fixed;
                        top: 50%;
                        left: 0;
                        z-index: 1050;
                        display: none;
                        width: 100%;
                        height: 100%;
                        overflow: hidden;
                        outline: 0;
                        transform: translateY(-25%);
                    }
                    .cloudModalHeader{
                        padding: 0.5em 1em;
                        background: black;
                        color: #33A478;
                    }
                    #cardCloudOne{
                        width: 100%;
                        height : 140px;
                        flex-direction: row;
                        border: 1px solid black;
                    }
                    .cardColor{
                        background: #33A478;
                        // background: linear-gradient(to right, black , #33A478, black);
                        width: 30%;
                        height : 140px;
                        border-radius: 5px 0px 0px 5px;
                    }
                    .cardText{
                        width: 70%;
                        text-align: center;
                        height : 140px;
                    }
                    .startTest{
                        background: black;
                        color: #33A478;
                        border: 1px solid black;
                        font-size: 14px;
                        padding: 6px;
                        border-radius: 5px;
                        font-weight: 600;
                    }
                    .cloudModalHeader .close {
                        padding: 1rem 1rem;
                        margin: -1rem -1rem -1rem auto;
                        color: #33A478;
                        text-shadow: none;
                        opacity: 1;
                    }
                    .cloudModalHeader .close:focus{
                        outline: none;
                    }
                    @media only screen and (max-width: 600px){
                        #myModalCloud{
                            position: fixed;
                            top: 30%;
                            left: 0;
                            z-index: 1050;
                            display: none;
                            width: 100%;
                            height: 100%;
                            overflow-y: scroll;
                            outline: 0;
                        }
                    }
                                        
                    `}
                </style>

                <button type="button" data-toggle="modal" onClick={this.checkLabStatus} data-target="#myModalCloud" className="cloudButton">

 Cloud Lab
                </button>
                <div align="center">
                    <div className="bubbleCircle1 bubbleCircle"></div>
                    <div className="bubbleCircle2 bubbleCircle"></div>
                    <div className="bubbleCircle3 bubbleCircle"></div>
                    <div className="bubbleCircle4 bubbleCircle"></div>
                    <div className="bubbleCircle5 bubbleCircle"></div>
                    <div className="bubbleCircle6 bubbleCircle"></div>
                    <div className="bubbleCircle7 bubbleCircle"></div>
                    <div className="bubbleCircle8 bubbleCircle"></div>
                    <div className="bubbleCircle9 bubbleCircle"></div>
                    <div className="bubbleCircle10 bubbleCircle"></div>
		        </div>

                   
                <div className="modal" id="myModalCloud">
                    <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header cloudModalHeader">
                            <h6 className="modal-title cloudTitle">Cloud Lab Test</h6>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body">
                            <div className="container">
                                {/*<span> Enter Email : <input type="email" id="cloudMail" /> 
                                <button className="btn btn-success" onClick={this.checkMail}> Login </button>
                                </span>*/}
                                
                                <div className="row">
                                    <div className="col-md-4" style={{ marginBottom: '10px'}}>

                                            <div className="card" style={{ width: '100%',height : '140px',flexDirection:'row' }}>
                                                <div className="cardColor" style={{ background: 'green',width: '30%',height : '140px' }}>

                                                </div>
                                                <div className="cardText" style={{ width: '100%',height : '140px' }}>
                                                   Total Time : {undefined !== this.state.totalTime && this.state.totalTime.toFixed(2) + " hours"}
                                                </div>
                                            </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="card" style={{ width: '100%',height : '140px',flexDirection:'row' }}>
                                                <div className="cardColor" style={{ background: 'red',width: '30%',height : '140px' }}>

                                                </div>
                                                <div className="cardText" style={{ width: '100%',height : '140px' }}>
                                                    Used Time : {undefined !== this.state.usedTime && this.state.usedTime.toFixed(2) + " hours"}
                                                </div>
                                            </div>
                                    </div>
                                    <div className="col-md-4">
                                         <div className="card" style={{ width: '100%',height : '140px',flexDirection:'row' }}>
                                                <div className="cardColor" style={{ background: 'blue',width: '30%',height : '140px' }}>

                                                </div>
                                                <div className="cardText" style={{ width: '100%',height : '140px' }}>
                                                    Left Time : {undefined !== this.state.leftTime && this.state.leftTime.toFixed(2) + " hours"}
                                                </div>
                                            </div>
                                    </div>


                                
                                {this.state.labStatus == 2 && (<button className="btn btn-info" onClick={this.startLab} style={{marginTop:"20px"}}>Start Lab </button>)}
                                {this.state.labStatus == 1 && (<a href="https://live.skillrary.com/live-courses/index.php/lab-view" > open lab </a>)}
                                {this.state.labStatus == 1 && (<button className="btn btn-danger" onClick={this.stopLab} style={{marginTop:"20px"}}>Stop Lab </button>)}
                                {this.state.labStatus == 3 && (<img src={waitImage} />)}

                            </div>

                        </div>
                    </div>
                    </div>
                </div>
            </div>
            </div>
        )
    }
}

export default CloudLab;