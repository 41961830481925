import React, { Component } from 'react';
import Contact from '../liveTrainingDesign/assets/contact.png';
import Swal from 'sweetalert2';

var value;

class WorksForm extends Component{
   constructor() {
    super();
    this.state ={
        type: 'number',
        first_name: '',
        mobile: '',
        user_email: '',
        course_requested: '',
        start_date: '',
        training_method: 'Online',
        checkbox: 'false',
        errors: {}
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onFocus = this.onFocus.bind(this);

    // this.onBlur = this.onBlur.bind(this);

    this.onhandleCheckbox = this.onhandleCheckbox.bind(this);
  }

onhandleCheckbox(){
    this.setState({checkbox: !this.state.checkbox});
}

handleSubmit(event) {
    event.preventDefault();

    	
    let errors = {};
    let formIsValid = false;
    
    if(!this.state.first_name){
        formIsValid = true
        errors['first_name'] = "This field is required"
    }
    else if(!this.state.user_email) {
        formIsValid = true
        errors['user_email'] = "This field is required"
    }
    else if(!this.state.course_requested){
        formIsValid = true
        errors['course_requested'] = "This field is required"
    }
    else if(!this.state.start_date){
        formIsValid = true
        errors['start_date'] = "This field is required"
    }
    else if(!this.state.mobile){
        formIsValid = true
        errors['mobile'] = "This field is required"
    }
    else if(this.state.mobile.length < 10){
        formIsValid = true
        errors['mobile'] = "Minimum should be 10 characters"
    }
    else if(this.state.mobile.length > 10){
        formIsValid = true
        errors['mobile'] = "Maximum should be 10 characters"
    }

    if(Object.keys(errors).length > 0){
        this.setState({errors:errors})
        return formIsValid;
    }

    fetch("https://live.skillrary.com/live-courses/index.php/api/v1/course-request", {

        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        
        body: JSON.stringify({
            first_name: this.state.first_name,
            mobile: this.state.mobile,
            user_email : this.state.user_email,
            course_requested: this.state.course_requested,
            start_date: this.state.start_date,
            training_method: this.state.training_method,
            notifications: value
        })
        })
        .then(res => res.json())
        .then(
            (result) => {

            console.log('rest', result);

            if(result.status == 200){
                Swal.fire({
                type: 'success',
                title: 'Thank you, Your request is submitted successfully',
                showConfirmButton: false,
                timer: 2500
                })
            this.setState({first_name : '',mobile: '',user_email: '',course_requested: '',start_date: ''})
            }
            else if(result.status == 400){
                Swal.fire({
                type: 'error',
                title: 'Oops...',
                text: 'Something went wrong!',
                footer: '<a href>Why do I have this issue?</a>'
                })
            }
            },
        )
    }

    onFocus() {
        this.setState({
            type: 'date'
        });
    }

    render(){
  
        if (this.state.checkbox) {
            value = 'false'
          } 
        else {
            value = 'true'
          }

        return(
              <div>
                  <style jsx>
                  {`
                    .errText{
                        font-size: 12px;
                        font-weight: normal;
                        color: red;
                        width: 300px;
                        bottom: 0;
                        position: absolute;
                        z-index: 1;
				    }
                    .requestBatchHr{
                        border: 0;
                        height: 3px;
                        position: relative;
                        background-image: linear-gradient( #33A478 , #33A478, #33A478 );
                    }
                    .pTagFeelFree{
                        background: white;
                        width: 50%;
                        padding-left: 23px;
                        position: absolute;
                        top: 24.5%;
                        left: 24%;
                    }
                    .requestHeading{
                        font-size: 25px;
                        text-align: center; 
                    }
                    .requestBtn{
                        background: #33a478;
                        border: 1px solid #33a478;
                        padding: 4px 10px;
                        color: white;
                    }
                    #batchInput{
                        border: 1px solid #33A478;
                    }
                    #batchInput:focus{
                        outline: none;
                        box-shadow: none;
                    }
                    .workBannerRequest{
                        width: 70%;
                    }
                    @media only screen and (max-width: 600px) {
                        .pTagFeelFree {
                            background: white;
                            width: 69%;
                            padding-left: 23px;
                            position: absolute;
                            top: 21.5%;
                            left: 15%;                        
                        }
                    }
                    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px)  { 
                        .pTagFeelFree {
                            background: white;
                            width: 69%;
                            padding-left: 23px;
                            position: absolute;
                            top: 29%;
                            left: 15%;                        
                        }
                    }
                  `}
                  </style>
                  <div className="container">
                      <div className="row">
                          <div className="col-md-6">
                            <div align="center"><img src={Contact} alt="banner" className="workBannerRequest"/></div>
                          </div>
                          <div className="col-md-6">
                              <h3 className="requestHeading">Request a Batch</h3><br/>
                              <p align="center" style={{ marginBottom: '30px'}}>Our Team will help you in scheduling your selected courses based on your convience.</p>
                              <hr className="requestBatchHr"></hr>
                              <p className="pTagFeelFree">Feel free to contact us any time</p><br/>
                              <form onSubmit={this.handleSubmit.bind(this)}>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <input className="form-control" name="first_name" 
                                            type="text" placeholder="Name" id="batchInput"
                                            onChange={(v) => this.setState({first_name: v.target.value, errors: {first_name : false }})} value={this.state.first_name} autoComplete="off"/>
                                            <span className="errText">{this.state.errors.first_name}</span>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <input className="form-control" name="user_email" 
                                            type="email" placeholder="Email" id="batchInput"
                                            onChange={(v) => this.setState({user_email: v.target.value, errors: { user_email : false }})} value={this.state.user_email}  autoComplete="off"/>
                                            <span className="errText">{this.state.errors.user_email}</span>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <input type="text" className="form-control" placeholder="Course" 
                                            name="course_requested" id="batchInput"
                                            onChange={(v) => this.setState({course_requested: v.target.value, errors: { course_requested: false }})} value={this.state.course_requested}  autoComplete="off"/>
                                            <span className="errText">{this.state.errors.course_requested}</span>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <input type={ this.state.type } onFocus={ this.onFocus } 

                                            className="form-control" 
                                            placeholder="StartDate" name="start_date" id="batchInput"
                                            onChange={(v) => this.setState({start_date: v.target.value, errors: { start_date : false}})} value={this.state.start_date}  autoComplete="off"/>
                                            <span className="errText">{this.state.errors.start_date}</span>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <input type="text" className="form-control" 
                                            placeholder="Mobile" name="mobile" id="batchInput"
                                            onChange={(v) => this.setState({mobile: v.target.value, errors: { mobile: false }})} value={this.state.mobile}  autoComplete="off"/>
                                            <span className="errText">{this.state.errors.mobile}</span>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <input type="text" className="form-control" placeholder="Training Method"
                                             name="training_method" id="batchInput" disabled
                                             onChange={(v) => this.setState({training_method: v.target.value, errors : { training_method : false }})} value={this.state.training_method}  autoComplete="off"/>

                                             {/* <span className="errText">{this.state.errors.training_method}</span> */}

                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-12" style={{ marginLeft: '20px' }}>
                                        <div className="form-group">
                                            <input class="form-check-input" type="checkbox" onChange={this.onhandleCheckbox} value={this.state.checkbox} />
                                            <label class="form-check-label" for="defaultCheck1">
                                            Allow SkillRary to send notifications on new updates, Sales and courses
                                            </label>
                                        </div>
                                
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-12">
                                        <input className="requestBtn" type="submit" value="Request" />
                                    </div>
                                </div>
                                </form>
                          </div>
                      </div>
                  </div>
              </div>
        )
    }
}

export default WorksForm;
