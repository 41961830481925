import React, { Component } from 'react';
import { WhatsappShareButton  } from 'react-share';

class whatsappComponent extends Component{
    render(){
        const shareUrl = window.location.href;
        return(
            <div>
                <style jsx>
                    {`
                    .whatsappContent{
                       
                        background-image: linear-gradient(95deg,#33A478,black  50%,#33A478 100%);
                    }
                    .whatsappButton{
                        background: black;
                        color: #33A478;
                        font-weight: 600;
                        font-size: 18px;
                        padding: 9px 6px;
                        border: 2px solid white;
                        border-radius: 5px;
                        width: 100%;
                        max-width: 250px;
                        min-width: 250px;
                    }
                    .whatsappIcon{
                        background: black !important;
                        /* width: 62px; */
                        padding: 10px 1px !important;
                        color: #33A478 !important;
                        display: inline-flex;
                        transition: all 0.2s ease-in;
                    }
                    .whatsappText {
                        opacity: 1;
                        transition: all 0.1s ease-in;
                    }
                    `}
                </style>
                <div className="container-fluid whatsappContent">
                    <div className="container">
                    <div className="row">
                       
                        <div className="col-md-9">
                            <p style={{ padding: '38px 40px',fontSize: '20px', color: 'white', marginBottom: '0px' }}>Enroll, Learn, Grow, Repeat! Get ready to achieve your learning goals with SkillRary </p>
                        </div>
                        <div className="col-md-3" style={{ padding: '30px 20px' }}>
                            {/* <button className="whatsappButton">
                            <WhatsappShareButton
						url={shareUrl}
					
						separator=":: "
						className="whatsappIcon"
					>
					<span className="whatsappText">Connect Through Whatsapp</span>
				</WhatsappShareButton>
                </button> */}
                <a href="https://api.whatsapp.com/send?phone=+919606655655" target="_blank"><button className="whatsappButton">Connect Through Whatsapp</button></a>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default whatsappComponent;