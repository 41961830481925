import React, { Component } from 'react';
import skillrary_logo from '../liveTrainingDesign/assets/webinarSRLOGO.png';

class KnowMoreHeader extends Component{
    render(){
        return(
        <div>
            <style jsx>
                {`
                .headContainer{
                    margin: 0px;    
                    padding: 0px;
                }
                .bg-white{
                    background: #f9ca33 !important;
                    position: relative;
                    z-index: 1;
                    box-shadow: 0 8px 6px -6px #aaa;
                }
                `}
            </style>
            <div className="container-fluid headContainer">
                <nav className="navbar navbar-white bg-white">
                    <div className="container-fluid">
                        <img src={skillrary_logo} alt="SkillRary Logo" width="160px"/>
                    </div>
                </nav>  
            </div>
        </div>
        )
    }
}

export default KnowMoreHeader;