import React, { Component, Fragment }from 'react';
import './App.css';
import Header from '../src/components/liveTrainingDesign/liveTrainingHeader';
import LiveVideo from '../src/components/liveTrainingDesign/liveVideo';
// import KeyFeatures from '../src/components/liveTrainingDesign/liveTrainingKeyFeatures';
import About from '../src/components/liveTrainingDesign/liveTrainingAboutus';
import Carousel from '../src/components/liveTrainingDesign/carousel';
import FooterTraining from "../src/components/liveTrainingDesign/footerTraining.jsx";
import ContactLiveTraining from "../src/components/liveTrainingDesign/liveTrainingContact.jsx";
// import UpcomingCarousel from "../src/components/liveTrainingDesign/upcomingCarousel.jsx";
import ScrollSpy from '../src/components/liveTrainingDesign/liveTrainingScrollSpy';
import LiveTrainingBanner from "../src/components/liveTrainingDesign/liveTrainingBanner.jsx";
// import $ from 'jquery';
import IdleTimer from 'react-idle-timer'
import Swal from 'sweetalert2';
import AllCourses from '../src/components/liveTrainingDesign/allCourses';
// import MetaTags from 'react-meta-tags';
import GearsContainer from '../src/components/liveTrainingDesign/gearsContainer';
import LoadAd from '../src/components/liveTrainingDesign/loadAd';
import ListView from './listCarouselView';
import WhatsappComponent from '../src/components/liveTrainingDesign/whatsappcomponent';
import LoadTimer from '../src/components/liveTrainingDesign/LoadTimer';

class App extends Component {
    constructor(props) {
        super(props);
            console.log('proo', props)
            console.log(window.location.origin)
            this.state = {
                heading: "Enroll Our Ongoing Classes",
                page_title: '',
                image_alt: '',
                description: '',
                keywords: '',
                username: '',
                test: 0
            }
            this.idleTimer = null
            this.onAction = this._onAction.bind(this)
            this.onActive = this._onActive.bind(this)
            this.onIdle = this._onIdle.bind(this)
            this.changeState = this.changeState.bind(this)
	          this.detectmob = this.detectmob.bind(this)
            window.sessionStorage.setItem("test", 0);
        }

        changeState() {
            this.setState({test:2});
            window.sessionStorage.setItem("test", 2);
        }

	detectmob() {
 	 if(window.innerWidth <= 800) {
    		return false;
  	} else {
    		return true;
  	}
}

    // componentDidMount(){
    //   var url = window.location.href;
    //   var filename = url.substring(url.lastIndexOf('/')+1);
      
    //   fetch("https://live.skillrary.com/live-courses/index.php/api/v1/meta-tags", {
    //     method: 'POST',
    //     headers: {
    //       'Content-Type': 'application/json',
    //       'Accept': 'application/json'
    //     },
        
    //     body: JSON.stringify({
    //       page_title: filename
    //     })
    //     })
    //     .then(res => res.json())
    //     .then(
    //       (result) => {
    //       console.log('metatags', result)
    //       this.setState({
    //         page_title : result.result[0].page_title,
    //         description: result.result[0].meta_desc,
    //         keywords: result.result[0].meta_key,
    //         image_alt: result.result[0].image_alt,
    //         username: result.result[0].username
    //       })
    //       },
    //     )
    // }
    render() {

		// var Heading = this.state.heading
		console.log('drop')

        return (
          <div>
            {/* <LoadTimer /> */}
        
          <div className="content">
          {/* <MetaTags>
            <title>{this.state.page_title}</title>
            <meta property="og:title" content={this.state.page_title} />
            <meta property="og:description" content={this.state.description} />
            <meta property="og:image:alt" content={this.state.image_alt} />
          </MetaTags> */}


            <div className="App">
            <IdleTimer
          ref={ref => { this.idleTimer = ref }}
          element={document}
          onActive={this.onActive}
          onIdle={this.onIdle}
          onAction={this.onAction}
          debounce={250}
          timeout={3000} />
                <Header /><br/><br/>
		{this.detectmob() && (<LoadAd />) }
                {this.props.location.courseId !== undefined ?
                    (<Fragment>
                        <LiveTrainingBanner courseId={this.props.location.courseId}/><br/>
                        <ScrollSpy  courseId={this.props.location.courseId}/>
                    </Fragment>) :
                    (<Fragment>
                        <LiveVideo />
                        {/*<KeyFeatures />*/}

                    {/* <Carousel heading={this.state.heading} id = "0"/><br/> */}
                    <ListView />
                    <AllCourses /><br/>
                    <About />
                    </Fragment>)
                }
                <GearsContainer />
                {/* <UpcomingCarousel /> */}
                <ContactLiveTraining />
                <WhatsappComponent />
                <FooterTraining />
                {/* {this.state.test > 1 ? "a":"b"} */}
            </div>
            </div>

            </div>
        );
    }

    _onAction(e) {
    //console.log('user did something', e)
  }
 
  _onActive(e) {
    //console.log('user is active', e)
    //console.log('time remaining', this.idleTimer.getRemainingTime())
  }
 
  _onIdle(e) {
    console.log('user is idle', e)
    this.setState({test:2});
    if (this.state.test < 2  && window.sessionStorage.getItem("test") < 2) {
      console.log('aa', window.sessionStorage.getItem("test"), this.state.test)
        Swal.fire({
                  title: '<strong style="color:#33A478;border-bottom:2px solid black">SkillRary <span >Login</span></strong>',
                  icon: 'info',
                  html:
                    '<span style="color:black">To enroll our latest <b>courses login to</b>, ' +
                    '<a href="https://www.skillrary.com/user/login" style="color:#33A478;outline:none;border-bottom:2px solid black">skillrary.com</a></span> ',
                  showCloseButton: false,
                  showCancelButton: true,
                  focusConfirm: false,
                  confirmButtonColor: '#33A478',
                  cancelButtonColor: '#33A478',
                  cancelButtonText: '<a href="javascript:void(0)" style="color:black" onClick={this.changeState}>May be later</a>',
                  confirmButtonText:
                    '<a href="https://www.skillrary.com/user/login" target="_blank" style="color:black">Login</a>',
                  confirmButtonAriaLabel: 'Thumbs up, great!',
                 
                })
        console.log('last active', this.idleTimer.getLastActiveTime())
    }
  }
}
export default App;

