// import React, { Component } from 'react';
// import $ from 'jquery';
// import { Redirect } from 'react-router-dom';
// import LogoSr from '../liveTrainingDesign/assets/srlogo.png';
// import courseCertification from '../liveTrainingDesign/assets/course-certificate-about.png';
// import Swal from 'sweetalert2';
// import ReactHtmlParser from 'react-html-parser';
// import ImageContainer from './imageContainer';
// import Particles from '../liveTrainingDesign/particle';

// class ScrollSpy extends Component{

// 	constructor(props) {
// 		super(props);

// 		//console.log('propsafaf',props);

// 		this.state = {
// 			courseId: props.courseId,
// 			courseTitle: "",
// 			courseTagLine: "",
// 			description:"",
// 			FAQ:[],
// 			curriculum: [],
// 			keyWord: "",
// 			schedule: [],
// 			whyCourseImg: "",
// 			impOfCourse: "",
// 			avgSalary: "",
// 			jobOpen: "",
// 			jobHiring: "",
// 			studentNumber: "",
// 			enrollLink: "",
// 			certificate : "",
// 			overview: "",
// 			whatlearn: "",
// 			familiar: "",
// 			whyCourseTag: "",
// 			errors: {},
// 			first_name_indi : '',
// 			last_name_indi : '',
// 			user_email_indi : '',
// 			country_code_indi: '+91',
// 			contact_number_indi : '',
// 			messages_indi : '',
// 			first_name_busi: '',
// 			last_name_busi: '',
// 			corporate_name_busi: '',
// 			user_email_busi: '',
// 			contact_number_busi: '',
// 			message_busi: '',
// 			whyCourseImg: '',
// 		}

// 		this.getCourse = this.getCourse.bind(this);
// 		this.getFAQ = this.getFAQ.bind(this);
// 		this.getCurriculum = this.getCurriculum.bind(this);
// 		this.getSchedule = this.getSchedule.bind(this);
// 		this.getMonthName = this.getMonthName.bind(this);
// 		this.prefixZero = this.prefixZero.bind(this);
// 		this.get12HourFormat = this.get12HourFormat.bind(this);
// 		this.scheduleCalendar = this.scheduleCalendar.bind(this);
// 		this.getScheduleCard = this.getScheduleCard.bind(this);
// 		this.setScheduleData = this.setScheduleData.bind(this);
// 		this.getDayName = this.getDayName.bind(this);
// 		this.getSessionStorage = this.getSessionStorage.bind(this);
// 		this.onClickIndividuals = this.onClickIndividuals.bind(this);
// 		this.onClickBusiness = this.onClickBusiness.bind(this);
// 		this.onClickBusiFormSubmit = this.onClickBusiFormSubmit.bind(this);
// 		this.onClickIndiFormSubmit = this.onClickIndiFormSubmit.bind(this);
// 		this.onChangeTimeZone = this.onChangeTimeZone.bind(this);
// 	}

// 	getSessionStorage() {
// 		this.setState({courseId: window.sessionStorage.getItem("courseId")});
// 	}

// 	componentWillReceiveProps(props) {
// 		////console.log('test props', props.courseId)
// 		this.setState({courseId: props.courseId});
// 		//window.sessionStorage.setItem("courseId", '1');
// 		this.getCourse();

// 	}

// 	onClickIndividuals(){
// 		$('.individualForm').css('display','block')
// 		$('.businessForm').css('display','none')
// 		$(".individualTab").css({'background':'black','color':'#33A478','outline':'none'});
// 		$(".businessTab").css({'background':'#33A478','color':'black','outline':'none'});
// 		this.setState({first_name_busi : '',last_name_busi: '',corporate_name_busi:'',user_email_busi:'',contact_number_busi:'',message_busi:''})
// 	}

// 	onClickBusiness(){
// 		$('.individualForm').css('display','none')
// 		$('.businessForm').css('display','block')
// 		$(".businessTab").css({'background':'black','color':'#33A478','outline':'none'});
// 		$(".individualTab").css({'background':'#33A478','color':'black','outline':'none'});
// 		this.setState({first_name_indi : '',last_name_indi: '',user_email_indi:'',contact_number_indi:'',messages_indi:'' })
// 	}

// 	onClickIndiFormSubmit(e){
// 		e.preventDefault();

// 		let errors = {};
// 		let formIsValid = false;
		
// 		if(!this.state.first_name_indi){
// 			formIsValid = true
// 			errors['first_name_indi'] = "This field is required"
// 		}
// 		else if(!this.state.last_name_indi){
// 			formIsValid = true
// 			errors['last_name_indi'] = "This field is required"
// 		}
// 		else if(!this.state.user_email_indi) {
// 			formIsValid = true
// 			errors['user_email_indi'] = "This field is required"
// 		}
// 		else if(!this.state.contact_number_indi){
// 			formIsValid = true
// 			errors['contact_number_indi'] = "This field is required"
// 		}
// 		else if(this.state.contact_number_indi.length < 10){
// 			formIsValid = true
// 			errors['contact_number_indi'] = "Minimum 10 characters"
// 		}
// 		else if(this.state.contact_number_indi.length > 10){
// 			formIsValid = true
// 			errors['contact_number_indi'] = "Maximum 10 characters"
// 		}
// 		else if(!this.state.messages_indi){
// 			formIsValid = true
// 			errors['messages_indi'] = "This field is required"
// 		}

// 		if(Object.keys(errors).length > 0){
// 			this.setState({errors:errors})
// 			return formIsValid;
// 		}

// 		fetch("https://live.skillrary.com/live-courses/index.php/api/v1/one-to-one", {
// 			method: 'POST',
// 			headers: {
// 				'Content-Type': 'application/json',
// 				'Accept': 'application/json'
// 			},
			
// 			body: JSON.stringify({
// 				course_id:this.state.courseId,
// 				first_name: this.state.first_name_indi,
// 				last_name: this.state.last_name_indi,
// 				user_email: this.state.user_email_indi,
// 				contact_number: this.state.country_code_indi + ' '+ this.state.contact_number_indi,
// 				messages: this.state.messages_indi
// 			})
// 		})
//       .then(res => res.json())
//       .then((result) => {
// 		  ////console.log('rest', result.status);
// 		  if(result.status == 200){
//             Swal.fire({
//             type: 'success',
//             title: 'Thank you, Your request is submitted successfully',
//             showConfirmButton: false,
//             timer: 2500
//             })
// 			this.setState({first_name_indi : '',last_name_indi: '',user_email_indi:'',contact_number_indi:'',messages_indi:'' })
//           }
//           else if(result.status == 400){
//             Swal.fire({
//               type: 'error',
//               title: 'Oops...',
//               text: 'Something went wrong!',
//               footer: '<a href>Why do I have this issue?</a>'
//             })
//           }
// 		})
		
// 	}

// 	onClickBusiFormSubmit(e){
// 		e.preventDefault();

// 		let errors = {};
// 		let formIsValid = false;

// 		if(!this.state.first_name_busi){
// 			formIsValid = true;
// 			errors['first_name_busi'] = "This field is required"
// 		}
// 		else if(!this.state.last_name_busi){
// 			formIsValid = true;
// 			errors['last_name_busi'] = "This field is required"
// 		}
// 		else if(!this.state.corporate_name_busi){
// 			formIsValid = true;
// 			errors['corporate_name_busi'] = "This field is required"
// 		}
// 		else if(!this.state.user_email_busi){
// 			formIsValid = true;
// 			errors['user_email_busi'] = "This field is required"
// 		}
// 		else if(!this.state.contact_number_busi){
// 			formIsValid = true
// 			errors['contact_number_busi'] = "This field is required"
// 		}
// 		else if(this.state.contact_number_busi.length < 10){
// 			formIsValid = true
// 			errors['contact_number_busi'] = "Minimum 10 characters"
// 		}
// 		else if(this.state.contact_number_busi.length > 10){
// 			formIsValid = true
// 			errors['contact_number_busi'] = "Maximum 10 characters"
// 		}
// 		else if(!this.state.message_busi){
// 			formIsValid = true;
// 			errors['message_busi'] = "This is field is required"
// 		}
// 		if(Object.keys(errors).length > 0 ){
// 			this.setState({errors:errors})
// 			return formIsValid;
// 		}

// 		fetch("https://live.skillrary.com/live-courses/index.php/api/v1/corporate-request", {
// 			method: 'POST',

// 			headers: {
// 				'Content-Type': 'application/json',
// 				'Accept': 'application/json'
// 			},
// 			body: JSON.stringify({
// 				course_id: this.state.courseId,
// 				first_name: this.state.first_name_busi,
// 				last_name: this.state.last_name_busi,
// 				corporate_name: this.state.corporate_name_busi,
// 				user_email: this.state.user_email_busi,
// 				contact_number: this.state.country_code_indi + ' ' + this.state.contact_number_busi,
// 				message: this.state.message_busi
// 			})
// 		})
//       .then(res => res.json())
//       .then((result) => {
// 		 // //console.log('rest', result.status);
// 		  if(result.status == 200){
//             Swal.fire({
//             type: 'success',
//             title: 'Thank you, Your request is submitted successfully',
//             showConfirmButton: false,
//             timer: 2500
//             })
// 			this.setState({first_name_busi : '',last_name_busi: '',corporate_name_busi:'',user_email_busi:'',contact_number_busi:'',message_busi:''})
//           }
//           else if(result.status == 400){
//             Swal.fire({
//               type: 'error',
//               title: 'Oops...',
//               text: 'Something went wrong!',
//               footer: '<a href>Why do I have this issue?</a>'
//             })
//           }
// 		})
// 	}

// 	componentDidMount() {
// 		if (undefined == this.props.courseId) {
// 			this.getSessionStorage();
// 		}
// 		$(window).scrollTop(0); 
// 		////console.log('ss', window.sessionStorage.getItem("test"));
// 		this.getCourse();

// 		$('.navigation ul li a').click(function(){
//         $(this).toggleClass('.activeMenu');
// 		});

// 		$(window).bind('scroll', function() {
//             var currentTop = $(window).scrollTop();
//             var elems = $('.page-section');
//             elems.each(function(index){
//               var elemTop 	= $(this).offset().top;
//               var elemBottom 	= elemTop + $(this).height();
//               if(currentTop >= elemTop && currentTop <= elemBottom){
//                 var id 		= $(this).attr('id');
//                 var navElem = $('a[href="#' + id+ '"]');
//             	navElem.parent().addClass('listActive').siblings().removeClass( 'listActive' );
//               }
//             })
// 		});
		
//  	}


// 	 getCourse() {
// 		var url = document.location.pathname;
// 		var pathname = url.substring(url.lastIndexOf('/')+1)
// 		var completename = document.location.pathname.split('/');
	
// 		//console.log(pathname,'pathname')
// 		//console.log(completename,'completename')
	
// 		fetch("https://live.skillrary.com/live-courses/index.php/api/v1/course-info", {
// 			method: 'POST',
// 			headers: {'Content-Type':'application/json'},
// 			body: JSON.stringify({
// 			slug: pathname !== '' ? pathname : '',
// 			course_id: 0,
// 			course_type: completename[2] !== '' ? completename[2] : 'upcoming',
// 			session_type: 1
// 			})
// 		})
// 		.then(res => res.json())
// 		.then(
// 		(result) => {
// 			//console.log('rest', result);
// 			this.setState({
// 			courseTitle: result.result[0].course_title,
// 			courseTagLine:   result.result[0].course_tagline,
// 			description:  ReactHtmlParser(result.result[0].description),
// 			FAQ: result.result[0].faq,
// 			curriculum:  result.result[0].curricullum,
// 			keyWord: result.result[0].key_features,
// 			schedule: result.result[0].sessions,
// 			whyCourseImg: result.result[0].why_course,
// 			whyCourseTag: result.result[0].whythis_tagline,
// 			certificate: result.result[0].certificate,
// 			overview:  ReactHtmlParser(result.result[0].overview),
// 			whatlearn: ReactHtmlParser(result.result[0].whatlearn),
// 			familiar:  ReactHtmlParser(result.result[0].familiar),
// 			impOfCourse:  ReactHtmlParser(result.result[0].impofcourse),
// 			avgSalary:  ReactHtmlParser(result.result[0].avgsalary),
// 			jobOpen:  ReactHtmlParser(result.result[0].jobopen),
// 			jobHiring:  ReactHtmlParser(result.result[0].jobhirings),
// 			studentNumber:  ReactHtmlParser(result.result[0].studnumber),
// 			enrollLink: result.result[0].enrolllink,
// 			});
// 		},	
// 		(error) => {
// 			this.setState({
// 			isLoaded: true,
// 			error
// 			});
// 		}
// 		)
// 	}
	
// 	prefixZero(num) {
// 		return ("0"+num);
// 	}

// 	get12HourFormat(hour) {
// 		var formatHour = 0;
// 		switch(parseInt(hour)) {
// 			case 13 : formatHour = 1;
// 					 break;
// 			case 14 : formatHour = 2;
// 					 break;
// 			case 15 : formatHour = 3;
// 					 break;
// 			case 16 : formatHour = 4;
// 					 break;
// 			case 17 : formatHour = 5;
// 					 break;
// 			case 18 : formatHour = 6;
// 					 break;
// 			case 19 : formatHour = 7;
// 					 break;
// 			case 20 : formatHour = 8;
// 					 break;
// 			case 21 : formatHour = 9;
// 					 break;
// 			case 22 : formatHour = 10;
// 					 break;
// 			case 23 : formatHour = 11;
// 					 break;
// 		}

// 		return formatHour;
// 	}

// 	getDayName(day) {
// 		var dayName = "";
// 		switch(parseInt(day) + 1) {
// 			case 1 : dayName = "Sun";
// 					 break;
// 			case 2 : dayName = "Mon";
// 					 break;
// 			case 3 : dayName = "Tue";
// 					 break;
// 			case 4 : dayName = "Wed";
// 					 break;
// 			case 5 : dayName = "Thu";
// 					 break;
// 			case 6 : dayName = "Fri";
// 					 break;
// 			case 7 : dayName = "Sat";
// 					 break;
// 		}

// 		return dayName;
// 	}

// 	getMonthName(month) {
// 		////console.log('a',month)
// 		var monthName = "";
// 		switch(parseInt(month)) {
// 			case 1 : monthName = "Jan";
// 					 break;
// 			case 2 : monthName = "Feb";
// 					 break;
// 			case 3 : monthName = "Mar";
// 					 break;
// 			case 4 : monthName = "Apr";
// 					 break;
// 			case 5 : monthName = "May";
// 					 break;
// 			case 6 : monthName = "Jun";
// 					 break;
// 			case 7 : monthName = "Jul";
// 					 break;
// 			case 8 : monthName = "Aug";
// 					 break;
// 			case 9 : monthName = "Sep";
// 					 break;
// 			case 10 : monthName = "Oct";
// 					 break;
// 			case 11 : monthName = "Nov";
// 					 break;
// 			case 12 : monthName = "Dec";
// 					 break;
// 		}

// 		return monthName;
// 	}

// 	getScheduleCard(schedule) {
		
// 		return (
// 			<div className="training-dates-list row" style={{ float: "none", listStyle: "none", position: "relative", left:"25px" }}>
// 				{schedule}
// 			</div>
// 		)

// 	}

// 	setScheduleData(scheduleData) {
// 	////console.log('sddd', this.getDayName(scheduleData.getDay()) )

// 		return (
// 			<div className="col-2">
// 				<div className="schedule-dates">
// 					<div className="training-week">{this.getDayName(scheduleData.getDay())}</div>
// 					<div className="training-date" style={{ fontVariantNumeric: "oldstyle-nums" }}>{scheduleData.getDate()}</div>
// 					<div className="training-month">{this.getMonthName(scheduleData.getMonth() + 1 )}</div>
// 				</div>
// 			</div>
// 		)

// 	}

// 	scheduleCalendar(start,end) {
		
// 	    var arr = new Array();
// 	    var dt = new Date(start);
// 	    dt.setDate(dt.getDate() - 1);
// 	    while (dt <= end) {
// 	    	dt.setDate(dt.getDate() + 1);
// 	        arr.push(new Date(dt));
	        
// 	    }

// 		var i = 0;
// 		var scheduleValue = [];
// 		var scheduleCard = [];
// 		////console.log('aa', arr);
// 		if (arr.length) {

// 	        var self = this;
	        
// 	        for (var j = 0 ; j< arr.length; j++) {
// 	        ////console.log('a', arr[j].getMonth())
	         
// 	           if (i > 4) {
// 	            scheduleCard.push(self.getScheduleCard(scheduleValue));
// 	            i = 0;
// 	            scheduleValue = [];
// 	           }

// 	           if (j == (arr.length - 1) ) {
// 	            scheduleValue.push(self.setScheduleData(arr[j]));
// 	            scheduleCard.push(self.getScheduleCard(scheduleValue));
// 	           } else {
// 	            scheduleValue.push(self.setScheduleData(arr[j]));
// 	           }

// 	           i++;
// 	        }
// 	    }
// 	    return scheduleCard;
// 	}

// 	// onChangeTimeZone(e,time){
// 	// 	//console.log('timezone========',time.value)
// 	// }

// 	onChangeTimeZone(){
// 		return e => {
// 			//console.log(e.target.value)
// 			if(e.target.value === 'ist' || e.target.value === ''){
// 				$('.istTimeZone').css('display','block')
// 				$('.pstTimeZone').css('display','none')
// 				$('.estTimeZone').css('display','none')

// 			} else if(e.target.value === 'pst'){
// 				$('.istTimeZone').css('display','none')
// 				$('.pstTimeZone').css('display','block')
// 				$('.estTimeZone').css('display','none')
// 			}
// 			else if(e.target.value === 'est'){
// 				$('.istTimeZone').css('display','none')
// 				$('.pstTimeZone').css('display','none')
// 				$('.estTimeZone').css('display','block')
// 			}
// 		}
//   	}
// 	getSchedule(schedule, k) {
// 		////console.log('sch',schedule)
// 		var startDateObj = new Date(parseInt(schedule.start_date) * 1000);
// 		////console.log(startDateObj,'start',startDateObj.toLocaleString("en-US", {timeZone: "Asia/Kolkata"}));
// 		////console.log('st',startDateObj.getDay())

// 		var startDateMonth = this.getMonthName(startDateObj.getMonth() + 1);
// 		var startDate = startDateObj.getDate();

// 		var startTimeHour = startDateObj.getUTCHours();
		
		
		
// 		var startTimeMin  = startDateObj.getUTCMinutes();
		
// 		if (startTimeMin < 10) {	
// 			startTimeMin = this.prefixZero(startTimeMin);
// 		}

// 		var startTimeM = "AM";
// 		if (startTimeHour > 12) {
// 			startTimeM = "PM";
// 			startTimeHour = this.get12HourFormat(startTimeHour);

// 		}

// 		if (startTimeHour < 10) {	
// 			startTimeHour = this.prefixZero(startTimeHour);
// 		}

// 		////console.log('mm',this.getMonthName(startDateObj.getMonth() + 1));

// 		//console.log('tz',startDateObj.toLocaleString("en-US", {timeZone: "America/Los_Angeles"}), 
// 		'in', startDateObj.toLocaleString("en-US", {timeZone: "Asia/Kolkata"}));



// 		var endDateObj = new Date(schedule.end_date * 1000); 
// 		var endDateMonth = this.getMonthName(endDateObj.getMonth() + 1);		
// 		var endDate = endDateObj.getDate();

// 		var endTimeHour = endDateObj.getUTCHours();
// 		var endTimeMin  = endDateObj.getUTCMinutes();

// 		if (endTimeMin < 10) {	
// 			endTimeMin = this.prefixZero(endTimeMin);
// 		}

// 		var endTimeM = "AM";
// 		if (endTimeHour > 12) {
// 			endTimeM = "PM";
// 			endTimeHour = this.get12HourFormat(endTimeHour);
// 		}
// 		if (endTimeHour < 10) {	
// 			endTimeHour = this.prefixZero(endTimeHour);
// 		}
		
// 		//est date start
// 		var date_est_start = new Date(schedule.start_EST.replace(/-/g,"/"));
// 		////console.log('dd',date_test.getMinutes());

// 		var startTimeHourEST = date_est_start.getUTCHours();			
		
// 		var startTimeMinEST  = date_est_start.getUTCMinutes();
		
// 		if (startTimeMinEST < 10) {	
// 			startTimeMinEST = this.prefixZero(startTimeMinEST);
// 		}

// 		var startTimeMEST = "AM";
// 		if (startTimeHourEST > 12) {
// 			startTimeMEST = "PM";
// 			startTimeHourEST = this.get12HourFormat(startTimeHourEST);

// 		}

// 		if (startTimeHourEST < 10) {	
// 			startTimeHourEST = this.prefixZero(startTimeHourEST);
// 		}

// 		//est end date
// 		var date_est_end = new Date(schedule.end_EST.replace(/-/g,"/"));

// 		var endTimeHourEST = date_est_end.getUTCHours();
// 		var endTimeMinEST  = date_est_end.getUTCMinutes();

// 		if (endTimeMinEST < 10) {	
// 			endTimeMinEST = this.prefixZero(endTimeMinEST);
// 		}

// 		var endTimeMEST = "AM";
// 		if (endTimeHourEST > 12) {
// 			endTimeMEST = "PM";
// 			endTimeHourEST = this.get12HourFormat(endTimeHourEST);
// 		}
// 		if (endTimeHourEST < 10) {	
// 			endTimeHourEST = this.prefixZero(endTimeHourEST);
// 		}

// 		//pst date start
// 		var date_pst_start = new Date(schedule.start_PST.replace(/-/g,"/"));
// 		////console.log('dd',date_test.getMinutes());

// 		var startTimeHourPST = date_pst_start.getHours();			
		
// 		var startTimeMinPST  = date_pst_start.getMinutes();
		
// 		if (startTimeMinPST < 10) {	
// 			startTimeMinPST = this.prefixZero(startTimeMinPST);
// 		}

// 		var startTimeMPST = "AM";
// 		if (startTimeHourPST > 12) {
// 			startTimeMPST = "PM";
// 			startTimeHourPST = this.get12HourFormat(startTimeHourPST);

// 		}

// 		if (startTimeHourPST < 10) {	
// 			startTimeHourPST = this.prefixZero(startTimeHourPST);
// 		}

// 		//pst end date
// 		var date_pst_end = new Date(schedule.end_PST.replace(/-/g,"/"));

// 		var endTimeHourPST = date_pst_end.getHours();
// 		var endTimeMinPST  = date_pst_end.getMinutes();

// 		if (endTimeMinPST < 10) {	
// 			endTimeMinPST = this.prefixZero(endTimeMinPST);
// 		}

// 		var endTimeMPST = "AM";
		
// 		if (endTimeHourPST > 12) {
// 			endTimeMPST = "PM";
// 			endTimeHourPST = this.get12HourFormat(endTimeHourPST);
// 		}

// 		if (endTimeHourPST < 10) {	
// 			endTimeHourPST = this.prefixZero(endTimeHourPST);
// 		}
        
//         //pst date

//         ////console.log('startPST', startTimeHourPST, startTimeMinPST, startTimeMPST)
//         ////console.log('endPST', endTimeHourPST, endTimeMinPST, endTimeMPST)


//         //var utcStartString = startDateObj.toUTCString();
//         //var utcEndString = endDateObj.toUTCString();
//         ////console.log('aa', utcStartString, utcEndString )
//         var Difference_In_Time = endDateObj.getTime() - startDateObj.getTime(); 
  
// 		// To calculate the no. of days between two dates 
// 		var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24); 

// 		//console.log('days', parseInt(Difference_In_Days));
//         var scheduleCalendar = this.scheduleCalendar(startDateObj, endDateObj);

//         	////console.log('sc', scheduleCalendar);


// 		return (
// 			<div className="schedule-course clearfix">
// 				<div className="schedule-course-summary clearfix border-color-1">
// 						<div className="schedule-course-list">
// 							<div className="clearfix">
// 								<div className="visible-xs xs-title">
// 								</div>
// 								<div className="xs-caption">
// 									<div className="dates" style={{ fontVariantNumeric: "oldstyle-nums" }}>
// 										{startDateMonth + " " + startDate + " - " + endDateMonth + " " + endDate}
// 									</div>
// 									<div className="batch">(Weekend Batch)</div>
// 									<a data-toggle="collapse" data-target={"#demo" + (k)} href="" aria-expanded="false" aria-controls={"schedule-" + (k)} class="more-details" rel="nofollow">
// 									More Details
// 									<i class="fa fa-caret-down"></i>
// 									</a>
// 								</div>
// 							</div>
// 						</div>
// 						<div className="schedule-course-list">
// 							<div className="clearfix">
// 								<div className="visible-xs xs-title">
// 								</div>
// 								<div className="xs-caption">
// 									<div className="schedule-delivery-type classroom" >
// 										Online
// 									</div>
// 									<div className="led">(Instructor Led)</div>
// 								</div>
// 							</div>
// 						</div>
// 						<div className="schedule-course-list-time">
// 							<div className="clearfix">
// 								<div className="visible-xs xs-title">
// 								</div>
// 								<div className="xs-caption">
// 									<div className="schedule-delivery-type ist" style={{ fontVariantNumeric: "oldstyle-nums" }}>
										
									
//         								<span className="pstTimeZone">{startTimeHourPST + ":" + startTimeMinPST + " " + startTimeMPST + " - " + endTimeHourPST + ":" + endTimeMinPST + " " + endTimeMPST}
// 										<span className="time-zone">(PST)</span></span>
// 										<span className="istTimeZone" style={{ display : 'none'}}>{startTimeHour + ":" + startTimeMin + " " + startTimeM + " - " + endTimeHour + ":" + endTimeMin + " " + endTimeM}
// 										<span className="time-zone">(IST)</span></span>
// 										<span className="estTimeZone" style={{ display : 'none'}}>{startTimeHourEST + ":" + startTimeMinEST + " " + startTimeMEST + " - " + endTimeHourEST + ":" + endTimeMinEST + " " + endTimeMEST}
// 										<span className="time-zone">(EST)</span></span>

// 										<br/>
// 									</div>
// 									<div></div>
// 								</div>
// 							</div>
// 						</div> 
// 						<div className="schedule-course-list-price mobileInrRupeebtn">
// 							<div className="clearfix">
// 								<div className="visible-xs xs-title">
// 								</div>
// 								<div className="xs-caption">
// 									{/*<strike style={{ fontVariantNumeric: "oldstyle-nums" }}>INR 24999</strike>*/}
// 									<span className="schedule-price" style={{ fontVariantNumeric: "oldstyle-nums" }}>INR {schedule.price}</span>
// 									{/*<span className="valid-till" style={{ fontVariantNumeric: "oldstyle-nums" }}>Valid till 09 Oct</span>*/}
// 								</div>
// 							</div>
// 						</div>

						
// 						{/* <div className="schedule-course-list mobileInrRupeebtn">
// 							<div className="clearfix">
// 								<div className="visible-xs xs-title">
// 								</div>
// 								<div className="xs-caption">
// 									<span>
										
// 										{ schedule.demo_link ? <Link to={{
// 						                      pathname: '/externalLink',
// 						                      externalUrl: schedule.demo_link
// 						                    }}><button className="demoBtn">
// 						                    Demo
// 						                    </button>
//                     					</Link> : <button  className="demoBtnDisabled" disabled>
// 						                    Demo
// 											</button> }


// 									</span>
// 								</div>
// 							</div>
// 						</div> */}


// 						<div className="schedule-course-list btnMobile">
// 							<div className="clearfix">
// 								<div className="visible-xs xs-title">
// 								</div>
// 								<div className="xs-caption">
// 									<div>

// 										<a href={schedule.enroll_link} >
// 											<button className="demoBtn">
// 												Enroll Now
// 											</button>
// 										</a>
// 									</div>
// 								</div>
// 							</div>
// 						</div>
// 				</div>                                        										
// 				<div className={"hidden-xs  schedule-border in collapse " + ("schedule-"+k)} id={"demo" + (k)} aria-expanded="false">
// 					<div className="schedule-training-dates position-relative">
// 						<div className="row">
// 						<div className="col-md-1"></div> 
// 							<div className="col-md-6 col-sm-6" align="center">
// 								<div className="training-dates">
// 									<div className="training-heading clearfix">
// 										<h3 className="text-center"	>
// 											<span>Training Dates</span>
// 										</h3>
// 									</div>

									
// 									<div className="bx-wrapper" style={{ maxWidth: "300px", margin: "0px auto" }}>
// 										<div className="bx-viewport" style={{ width: "100%", position: "relative", height: "58px" }}>
// 										{/*
// 											<div className="training-dates-overview bxslider" style={{ width: "auto" , position: "relative", transitionDuration: "0s", transform: "translate3d(0px, 0px, 0px)" }}>

// 												<div className="training-dates-list row" style={{ float: "none", listStyle: "none", position: "relative", left:"25px" }}>
// 													<div className="col-2">
// 														<div className="schedule-dates">
// 															<div className="training-week">Sat</div>
// 															<div className="training-date" style={{ fontVariantNumeric: "oldstyle-nums" }}>19</div>
// 															<div className="training-month">Oct</div>
// 														</div>
// 													</div>
// 													<div className="col-2">
// 														<div className="schedule-dates">
// 															<div className="training-week">Sun</div>
// 															<div className="training-date" style={{ fontVariantNumeric: "oldstyle-nums" }}>20</div>
// 															<div className="training-month">Oct</div>
// 														</div>
// 													</div>
// 													<div className="col-2">
// 														<div className="schedule-dates">
// 															<div className="training-week">Sun</div>
// 															<div className="training-date" style={{ fontVariantNumeric: "oldstyle-nums" }}>20</div>
// 															<div className="training-month">Oct</div>
// 														</div>
// 													</div>
// 													<div className="col-2">
// 														<div className="schedule-dates">
// 															<div className="training-week">Sun</div>
// 															<div className="training-date" style={{ fontVariantNumeric: "oldstyle-nums" }}>20</div>
// 															<div className="training-month">Oct</div>
// 														</div>
// 													</div>
// 												</div>
// 											</div>
// */}
// {scheduleCalendar}
// 										</div>
// 										{/* <div className="bx-controls bx-has-controls-direction">
// 											<div className="bx-controls-direction">
// 												<a className="bx-prev disabled" href=""><i className="fa fa-angle-up"></i></a>
// 												<a className="bx-next disabled" href=""><i className="fa fa-angle-down"></i></a>
// 											</div>
// 										</div> */}
// 									</div>
// 								</div>
// 							</div>

// 							{/* <div className="col-md-1"></div> */}
							
// 							<div className="col-md-5 col-sm-5" style={{ textAlign: "center" }}>
// 								<div className="classroom-details">
// 									<h4 style={{ marginTop: '5px', fontSize: '19px' }}>Instructor</h4>
// 									<img src={LogoSr} class="course-img" />
// 									<div className="classroom-details-info" style={{ display: "-webkit-inline-box",marginTop: "10px",fontWeight: "600"}}>
// 										<span className="name">Name</span> : <span className="classroom-trainer">{schedule.trainer} </span>
// 										<span className="classroom-trainer-info info it"></span>
// 									</div>
// 								</div>
// 							</div>
// 							{/*
// 							<div className="col-md-3 col-sm-3" style={{ textAlign: "center" }}>
// 								<div className="classroom-venue-details classroom-details">
// 									<address>
// 										<h4 className="venue">Venue</h4>
// 										<i className="fa fa-map-marker" aria-hidden="true"></i>
// 										<span className="classroom-venue text-center" style={{ fontVariantNumeric: "oldstyle-nums" }}>
// 											KnowledgeHut Solutions Pvt. Ltd. NO 10, 14th Main Road, Sector 5, HSR Layout, Bangalore - 560102 India
// 										</span>
// 									</address>
// 								</div>
// 							</div>
// 							*/}
// 						</div>
// 						<a data-toggle="collapse" href={".schedule-" + (k)} aria-expanded="true" aria-controls={"schedule-" + (k)} class="position-absolute right-top color-4 more-details" rel="nofollow">
// 							<i className="fa fa-times"></i>
// 						</a> 
// 					</div>
// 				</div>
// 			</div>
// 		)
// 	}

// 	getCurriculum (item, i) { //console.log('item', item.module_title)
		
// 		var descArray = ReactHtmlParser(item.module_desc);
// 		var moduleTitle = ReactHtmlParser(item.module_title);
// 		/*
// 		var moduleDesc = item.module_desc.split(",");
// 		var descArray = [];
        
//         if (moduleDesc.length) {
// 	        for (var j=0; j <  moduleDesc.length;j++) {
// 	          ////console.log('keydf', moduleDesc[j]);
// 				descArray.push(<p style={{ padding: "5px 20px" , margin: '1px'}}><span style={{ color: 'black', marginRight: '4px', fontSize: '6px' }}><i className="fa fa-circle" style={{ position
// 				:'relative', top: '-2px' }} aria-hidden="true"></i>
// 				</span> { moduleDesc[j]}</p>);
// 			}
// 		}
// 		*/

// 		return (
// 			 <React.Fragment>
// 		        <div className="card-header collapsed curiculumHeader" data-toggle="collapse" href={"#curiculum" + (i)}>
// 			        <a className="card-title ">
// 						<strong>Module{i} :</strong>	
						
// 						<span style={{ display: 'inline-block',marginBottom: '-15px' }} >{ReactHtmlParser(item.module_title)} </span>
// 							{/* <i className="fa fa-chevron-down" style={{ float: 'right' }} aria-hidden="true"></i> */}

// 					</a>
// 	    		</div>
// 			    <div id={"curiculum" + (i)} className={"collapse " + ( (i == 1) ? "show" : "")}  data-parent="#accordion" >
// 				<p style={{ padding: "5px 20px" , margin: '1px'}}>{descArray}</p>
// 				</div>
// 		     </React.Fragment>
// 		)
// 	}

// 	getFAQ(faq, i) {
// 		////console.log('faqs', faq);
// 		/*
// 		  var res = faq.answers.split("[");
// 		  var line1 = res[0];

// 		  var line2 = res[1];
// 		  var answerBulletArray = [];
// 		   if (undefined !== line2) {
// 			   var answerBullet = line2.split("|");
				
// 				if (answerBullet.length) {
// 					for (var j=0; j <  answerBullet.length;j++) {
// 						////console.log('keydf', keyWords[i]);
// 						answerBulletArray.push(
// 						<p style={{ padding: "5px 20px" , margin: '1px'}}><span  style={{ color: 'black', marginRight: '4px', fontSize: '6px' }}><i className="fa fa-circle" style={{ position
// 		:'relative', top: '-2px' }} aria-hidden="true"></i></span> {answerBullet[j]}</p>

// 										);
// 					}
// 				}
// 			}
// 			*/

// 			////console.log('answerArray', answerBulletArray)

// 		return (
// 			<div className="panel">
// 		        <div className="card-header collapsed faqHeader" data-toggle="collapse" href={"#faq" + (i)}>
// 		            <b><a className="card-title">
// 		                {ReactHtmlParser(faq.questions)}	  
// 						{/* <i className="fa fa-chevron-down" style={{ float: 'right' }} aria-hidden="true"></i> */}
// 		            </a></b>
// 		        </div>
// 		        <div id={"faq" + (i)} className={"collapse faqBody " + ( (i == 1) ? "show" : "")} data-parent="#faqaccordion" >
// 		            <p style={{ padding: "10px 10px 10px 22px" }}>{ReactHtmlParser(faq.answers)}</p>
// 		        </div>
// 			</div>
// 		)
// 	}

// 	render(){

// 		var e ="";
// 		const {description, FAQ, curriculum, keyWord, schedule, whatlearn, familiar} = this.state;
// 		//console.log('des',description, this.props);
// 		//console.log('lee', whatlearn)
// 		var faqData = [];
		
// 		var i = 1;

// 		//console.log('faq', FAQ)
// 		if (undefined !== FAQ && FAQ.length > 0) {
// 			var self = this;
// 			Object.keys(FAQ).map(function(keyName, keyIndex) {
// 	          ////console.log('adf', keyIndex);
	          
// 	          faqData.push(self.getFAQ(FAQ[keyName], i));
// 	          i++;
// 	        })
//         } else {
//         	if (undefined == this.props.courseId) {
// 		      return <Redirect to='/' />
// 		    }
//         }

//         var curriculumData = [];
//         var j =1;
//         if (curriculum.length > 0) {
// 			var self = this;
// 			Object.keys(curriculum).map(function(keyName, keyIndex) {
// 	          ////console.log('adf', keyIndex);
	          
// 	          curriculumData.push(self.getCurriculum(curriculum[keyName], j));
// 	          j++;
// 	        })
//         }

//         var keyWords = keyWord.split(",");
// 		var keyWordArray = [];
// 		if (keyWords.length) {
// 			for (var i=0; i <  keyWords.length;i++) {
// 				////console.log('keydf', keyWords[i]);
// 				keyWordArray.push(<div className="col-md-4 col-sm-6" id="sectionRowPadding">
// 									<div className="bulbIcon">
// 										<i className="fa fa-lightbulb-o" aria-hidden="true" />
// 									</div>
// 									<div className="pTagContentScroll">
// 										<p>{keyWords[i]}</p>                                          
// 									</div>
// 								</div>
// 								);
// 			}
// 		}

// 		var whatLearnArray = whatlearn;

// 		//var whatlearns = whatlearn.split(",");
// 		//var whatLearnArray = [];
// 		/*if (whatlearns.length) {
// 			for (var i=0; i <  whatlearns.length;i++) {
// 				////console.log('keydf', keyWords[i]);
// 				whatLearnArray.push(<p style={{ padding: "5px 20px" , margin: '1px'}}><span  style={{ color: 'black', marginRight: '4px', fontSize: '6px' }}><i className="fa fa-circle" style={{ position
// 		:'relative', top: '-2px' }} aria-hidden="true"></i></span> { whatlearns[i]}</p>
// 								);
// 			}
// 		}*/

// 		var familiarArray = familiar;
// 		/*var familiars = familiar.split(",");
// 		var familiarArray = [];
// 		if (familiars.length) {
// 			for (var i=0; i <  familiars.length;i++) {
// 				////console.log('keydf', keyWords[i]);
// 				familiarArray.push(<p style={{ padding: "5px 20px" , margin: '1px'}}><span  style={{ color: 'black', marginRight: '4px', fontSize: '6px' }}><i className="fa fa-circle" style={{ position
// 		:'relative', top: '-2px' }} aria-hidden="true"></i></span> { familiars[i]}</p>
// 								);
// 			}
// 		}*/

// 		//var schedulee = this.getSchedule();
// 		var scheduleData = [];
//         var k =1;
//         if (schedule.length > 0) {
// 			var self = this;
// 			Object.keys(schedule).map(function(keyName, keyIndex) {
// 	          ////console.log('adf', keyIndex);
// 	          scheduleData.push(self.getSchedule(schedule[keyName], k));
// 	          k++;
// 	        })
//         }

// 		return(
// 		<div>
// 		<style jsx>{ ` 
// 		.selectTimeZone{
// 			top: -45px;
// 			position: relative;
// 			background: #33A478;
// 			padding: 5px;
// 			border-radius: 5px;
// 			border: 1px solid #33A478;
// 		}
// 		.selectTimeZone:focus{
// 			outline: none;
// 		}
// 		select option[value=""] {
// 			background: white
// 		}	  
// 		select option[value="ist"] {
// 			background: white
// 		}
// 		select option[value="est"] {
// 			background: white
// 		}
// 		select option[value="pst"] {
// 			background: white
// 		}
		
// 		// @keyframes zoominoutsinglefeatured {
// 		// 	0% {
// 		// 		transform: scale(1,1);
// 		// 	}
// 		// 	50% {
// 		// 		transform: scale(1.2,1.2);
// 		// 	}
// 		// 	100% {
// 		// 		transform: scale(1,1);
// 		// 	}
// 		// }
// 		// .youImage{
// 		// 	position: absolute;
// 		// 	opacity: 0.5;
// 		// 	height: 320px;
// 		// 	width: 100%;
// 		// 	border-radius: 100%;
// 		// 	-webkit-animation: zoominoutsinglefeatured 3s infinite;
// 		// 	animation: zoominoutsinglefeatured 3s infinite;
// 		// 	max-width: 300px;
// 		// }
// 		// .bubbleCircle1{
// 		// 	position: fixed;
// 		// 	opacity: 0.5;
// 		// 	height: 160px;
// 		// 	width: 100%;
// 		// 	border-radius: 100%;
// 		// 	-webkit-animation: zoominoutsinglefeatured 3s infinite;
// 		// 	animation: zoominoutsinglefeatured 3s infinite;
// 		// 	top: 60%;
// 		// 	left: 75px;
// 		// 	max-width: 165px;
// 		// 	background: linear-gradient(125deg, #33A478, #33A478 30%, black);
// 		// 	// background-image: linear-gradient(to right, rgba(0,0,0), rgba(51,	164, 120) , rgba(0,0,0));
// 		// }
// 		// .bubbleCircle2{
// 		// 	position: fixed;
// 		// 	opacity: 0.5;
// 		// 	height: 160px;
// 		// 	width: 100%;
// 		// 	border-radius: 100%;
// 		// 	-webkit-animation: zoominoutsinglefeatured 3s infinite;
// 		// 	animation: zoominoutsinglefeatured 3s infinite;
// 		// 	top: 60%;
// 		// 	ma	n-left: 71%;
// 		// 	max-width: 165px;
// 		// 	background: linear-gradient(125deg, #33A478, #33A478 30%, black);
// 		// 	// background-image: linear-gradient(to right, rgba(0,0,0), rgba(51,	164, 120) , rgba(0,0,0));
// 		// }
// 		// .bubbleCircle3{
// 		// 	position: absolute;
// 		// 	opacity: 0.5;
// 		// 	height: 100px;
// 		// 	width: 100%;
// 		// 	border-radius: 100%;
// 		// 	-webkit-animation: zoominoutsinglefeatured 3s infinite;
// 		// 	animation: zoominoutsinglefeatured 3s infinite;
// 		// 	margin-top: -46px;
// 		// 	margin-left: 34%;
// 		// 	max-width: 96px;
// 		// 	background: linear-gradient(125deg, #33A478, #33A478 30%, black);
// 		// 	// background-image: linear-gradient(to right, rgba(0,0,0), rgba(51,	164, 120) , rgba(0,0,0));
// 		// }
// 		// .bubbleCircle4{
// 		// 	position: absolute;
// 		// 	opacity: 0.5;
// 		// 	height: 69px;
// 		// 	width: 100%;
// 		// 	border-radius: 100%;
// 		// 	-webkit-animation: zoominoutsinglefeatured 3s infinite;
// 		// 	animation: zoominoutsinglefeatured 3s infinite;
// 		// 	margin-top: 22px;
// 		// 	margin-left: 54%;
// 		// 	max-width: 70px;
// 		// 	background: linear-gradient(125deg, #33A478, #33A478 30%, black);
// 		// 	// background-image: linear-gradient(to right, rgba(0,0,0), rgba(51,	164, 120) , rgba(0,0,0));
// 		// }
		
// 		.icon-bar{
// 			width: 100%;
// 			max-width: 60px;
// 			color: #33A478;
// 			height: 350px;
// 			position: -webkit-sticky;
// 			position: sticky;
// 			top: 20%;
// 			float: left;
// 		}
// 		.icon-bar a {
// 			display: block;
// 			text-align: center;
// 			padding: 16px;
// 			transition: all 0.3s ease;
// 			color: #33A478;
// 			font-size: 20px;
// 		}
// 		.socialIcon {
// 			background: black;
// 			color: #33A478;
// 		}
// 		.socialIcon:hover {
// 			background: #33A478;
// 			color: black;
// 		}

// 		.card-header:after {
// 			font-family: 'FontAwesome';  
// 			content: "\\f077";
// 			position: absolute;
// 			right: 10px; 
// 		}
// 		.card-header.collapsed:after {
// 			/* symbol for "collapsed" panels */
// 			content: "\\f078";
// 			position: absolute;
// 			right: 10px;  
// 		}

// 		.errTextIndi{
// 			font-size: 12px;
// 			font-weight: normal;
// 			color: red;
// 			bottom: 0;
// 			position: absolute;
// 			z-index: 1;
// 		}
		
// 		.individualTab{
// 			padding: 3px 10px;
// 			outline: none;
// 			border-radius: 20px 0px 0px 20px;
// 			background: black;
// 			color: #33a478;
// 			border: 2px solid black;
// 			font-size: 14px;
// 			font-weight: 600;
// 		}
// 		.businessTab{
// 			padding: 3px 10px;
// 			outline: none !important;
// 			border-radius: 0px 20px 20px 0px;
// 			background: #33A478;
// 			border: 2px solid black;
// 			font-size: 14px;
// 			font-weight: 600;
// 			color: black;
// 		}

// 		.submitSideBtn{
// 			background: black;
// 			border: 1px solid black;
// 			font-size: 14px;
// 			padding: 1px 9px;
// 			color: #33A478;
// 			font-weight: 600;
// 			border-radius: 4px;
// 			margin-top: 13px;
// 		}
// 		.submitSideBtnBusiness{
// 			background: black;
// 			border: 1px solid black;
// 			font-size: 14px;
// 			padding: 1px 9px;
// 			color: #33A478;
// 			font-weight: 600;
// 			border-radius: 4px;
// 		}
// 		.inputFieldSide{
// 			font-size:15px;
// 			border: 0;
// 			outline: 0;
// 			position: relative;
// 			background: transparent;
// 			border-bottom: 1px solid black;
// 			-webkit-text-fill-color: #000000d1;
// 			margin-top:23px;
// 			display: flex;
// 			width: 100%;
// 		}
// 		.inputFieldSideMsz{
// 			font-size:15px;
// 			border: 0;
// 			outline: 0;
// 			position: relative;
// 			background: transparent;
// 			border-bottom: 1px solid black;
// 			-webkit-text-fill-color: #000000d1;
// 			margin-top:23px;
// 			display: flex;
// 			width: 100% ;
// 		}
// 		textarea{
// 			resize: none;
// 		}
// 		.cardForm{
// 			background: #33A478;
// 			padding: 10px;
// 			width: 100%;
// 			height: 385px;
// 			box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.16), 0 2px 3px 0 rgba(0,0,0,0.12);
// 			/* max-width: 311px; */
// 			min-width: 240px;
// 		}
// 		.sideForm{
// 			width: 100%;
// 			max-width: 240px;
// 			color: #fff;
// 			height: 350px;
// 			position: -webkit-sticky;
// 			position: sticky;
// 			top: 20%;
// 			float: right;
// 			margin-right: 10px;
// 		}
// 		.name{
// 			color: #33A478;
// 		}
// 		.listActive{
// 			background-color: black;
// 		}
// 		.listActive .navigation__link{
// 			color: #33A478;
// 			font-weight: 500;
// 		}
// 		section{
// 			min-height: 500px;
// 		}
		
// 		#Contact{
// 			min-height: 1000px;
// 		}
// 		.icon-bar{

// 		}
		
// 		.navigation {
// 			width: 80%;
// 			margin-left: 2%;
// 			background-color: #33A478;
// 			color: #fff;
// 			height: auto; 
// 			overflow: auto;
// 			position: -webkit-sticky;
// 			position: sticky;
// 			top: 20%;
// 		}
// 		.navigation__link {
// 			display: block;
// 			color: black;
// 			text-decoration: none;
// 			padding: 10px 20px;
// 			font-weight: 400;
// 			text-decoration: none;
// 		}
// 		.navigation__link:hover {
// 			background-color: black;
// 			text-decoration: none;
// 			color: #33A478;
// 			font-weight: 500;
// 		}
// 		ul.naivgationList{
// 			list-style-type: none;
// 			padding: 0;
// 			margin: 0; 
// 		}
// 		.sectionContent {
// 			height: auto;
// 			padding-bottom: 30px;
// 		}

// 		#sectionRowPadding{
// 			display: inline-flex;
// 			height: 80px;
// 		}

// 		.activeMenu {
// 			color: #33A478;
// 		    background-color: rgba(0, 0, 0, 0.83);
// 		    font-weight: 600;
// 		}
// 		#sectionHeading{
// 			font-weight: 700;
// 		    font-size: 18px;
// 		    color: #333742;
// 		    letter-spacing: 1.5px;
// 		    position: relative;
// 		    padding: 0px 0px 10px 0px;
// 		    margin-bottom: 20px;
// 		}
// 		#sectionHeading:before{
// 			content: ' ';
// 		    background: #33A478;
// 		    box-shadow: 0 4px 8px 0 rgba(76,215,200,0.3);
// 		    width: 44px;
// 		    height: 4px;
// 		    position: absolute;
// 		    bottom: 0;
// 		}
// 		.pTagContentScroll{
// 			padding-left: 20px;
// 			margin-top: 10px;
// 			font-size: 14px;
// 		}
// 		.bulbIcon{
// 			font-size: 25px;
// 			color: #33A478;
// 		}
// 		.descriptionPTag{
// 			text-align: justify;
// 		}
// 		.curiculumHeader{
// 			cursor: pointer;
// 			background-color: #33A478 !important;
// 			border-bottom: 1px solid rgba(0,0,0,.125) !important;
// 		}
// 		.curiculumCard{
// 			border: 1px solid rgba(0,0,0,.125);
// 		}
// 		.faqCard{
// 			border: none;
// 		}
// 		.faqHeader{
// 			cursor: pointer;
// 			background: none;
// 			border-width: 0;
// 			border-bottom: 5px solid #33a478;
// 			border-left: 5px solid;
// 		}
// 		.faqBody{
// 			border-bottom: 1px solid rgba(0,0,0,.125) !important;
// 		}
// 		.panel{
// 			border-width: 1px 0 1px 0;
// 			border-style: solid;
// 			border-color: #e7e7e7;
// 		}

// 		.previewBtn{
// 			font-size: 14px;
// 			background-color: white;
// 			padding: 5px 15px;
// 			border: 2px solid #33A478;
// 			border-radius: 30px;
// 			font-weight: 500;
// 			// top: 115px;
// 			top: 20px;
// 			left: 30px;
// 			position: relative;
// 		}

// 		.previewBtn:focus{
// 			outline: none;
// 		}

// 		.certificateImage{
// 			width: 100%;
// 			height: auto;
// 			min-width: 400px;
// 			max-width: 400px;		
// 		}

// 		.closeImage{
// 			background-color: #33A478 !important;
// 			padding: 0px 5px !important;
// 			opacity: 10 !important;
// 			position: absolute !important;
// 			border-radius: 12px !important;
// 			right: 8px !important;
// 			top: 2px !important;
// 			color: white !important;
// 			border: 2px solid #fff;
// 		}

// 		.closeImage:focus{
// 			outline: none;
// 		}

// 		#imageBody{
// 			top: 25%;
// 			position: absolute;
// 			left: -195px;
// 			transform: translateY(25%);
// 		}

// 		.popupImage{
// 			width: 100%;
// 			height: 510px;
// 			min-width: 850px;
// 			align-items: center;
// 			display: flex;
// 			justify-content: center;
// 		}

// 		.modal{
// 			background: #343a40de !important;
// 			position: fixed;
// 			top: 0;
// 			left: 0;
// 			z-index: 1069;
// 			display: none;
// 			width: 100%;
// 			height: 100%;
// 			overflow: hidden;
// 			outline: 0;
// 		}
// 		@media only screen and (max-width: 400px){
// 			.sideForm{
// 				display:none;
// 			}
// 			.icon-bar{
// 				display:none;
// 			}
// 			.certificateImage{
// 				width: 100%;
// 				height: auto;
// 				max-width: inherit;
// 				min-width: auto;
// 			}
// 			.previewBtn {
// 				font-size: 14px;
// 				background-color: white;
// 				padding: 5px 15px;
// 				border: 2px solid #33A478;
// 				border-radius: 30px;
// 				font-weight: 500;
// 				top: 27px;
// 				left: 0px;
// 				position: relative;
// 			}
			
// 			#imageBody{
// 				top: 40% !important;
// 				position: absolute !important;
// 				left: 0px;
// 				transform: translateY(40%) !important;
// 			}

// 			.popupImage{
// 				width: 100%;
// 				height: 350px;
// 				align-items: center;
// 				min-width: 280px;
// 				display: flex;
// 				justify-content: center;
// 			}
// 		}
// 		@media only screen and (min-width: 400px) and (max-width : 600px) {
// 			.sideForm{
// 				display:none;
// 			}
// 			.icon-bar{
// 				display:none;
// 			}
// 			.certificateImage{
// 				width: 100%;
// 				height: auto;
// 				max-width: inherit;
// 				min-width: auto;
// 			}
// 			.previewBtn {
// 				font-size: 14px;
// 				background-color: white;
// 				padding: 5px 15px;
// 				border: 2px solid #33A478;
// 				border-radius: 30px;
// 				font-weight: 500;
// 				top: 27px;
// 				left: 0px;
// 				position: relative;
// 			}
			
// 			#imageBody{
// 				top: 40% !important;
// 				position: absolute !important;
// 				left: 0px;
// 				transform: translateY(40%) !important;
// 			}

// 			.popupImage{
// 				width: 100%;
// 				height: 400px;
// 				align-items: center;
// 				min-width: 357px;
// 				display: flex;
// 				justify-content: center;
// 			}
// 		}
// 		@media only screen and (min-width : 768px) and (max-width : 1024px)  { 
// 			.selectTimeZone {
// 				top: -25px;
// 				position: relative;
// 				background: #33A478;
// 				padding: 5px;
// 				border-radius: 5px;
// 				border: 1px solid #33A478;
// 			}
// 			.sideForm{
// 				display:none;
// 			}
// 			.navigation {
// 				width: 100%;
// 				margin-left: 2%;
// 				background-color: #33A478;
// 				color: #fff;
// 				height: 25vh; 
// 				min-height: 225px;
// 				overflow: auto;
// 				position: -webkit-sticky;
// 				position: sticky;
// 				top: 20%;
// 			}
// 			#imageBody {
// 				top: 25% !important;
// 				position: absolute !important;
// 				left: -145px;
// 				transform: translateY(25%) !important;
// 			}
// 			.popupImage {
// 				width: 100%;
// 				height: 510px;
// 				min-width: 750px;
// 				align-items: center;
// 				display: flex;
// 				justify-content: center;
// 			}
// 		}

// 		// course schedule csss start

// 		.schedule-courses .container {
// 			padding-top: 20px;
// 			padding-bottom: 40px;
// 		}
		
// 		.headingTable{
// 			color: white;
// 			font-weight: 600;
// 		}
        
// 		.schedule-captions {
//             margin-bottom: 20px;
//             text-align: center;
// 			background-image: linear-gradient(to right, rgba(0,0,0), rgba(51,	164,	120) , rgba(0,0,0));			border-top-left-radius: 3px;
// 			border-top-right-radius: 3px;
// 			width: 100%;
// 			display: block;
// 			height: 50px;
// 			overflow: hidden;
// 			line-height: 50px;
// 			color: white;
// 		}
		
// 		.schedule-captions .schedule-captions-list {
//             float: left;
//             padding: 0px 0px;
//             width: 20%;
//             color: white;
//             font-size: 15px;
//             font-weight: 700;
//             border-right: 0 none;
// 		}
// 		.schedule-captions .schedule-captions-list-price {
//             float: left;
//             padding: 0px 0px;
//             width: 10%;
//             color: white;
//             font-size: 15px;
//             font-weight: 700;
//             border-right: 0 none;
// 		}
// 		.schedule-captions .schedule-captions-list-time {
//             float: left;
//             padding: 0px 0px;
//             width: 30%;
//             color: white;
//             font-size: 15px;
//             font-weight: 700;
//             border-right: 0 none;
// 		}
// 		.time-zone{
// 			margin: 0;
// 			padding: 0;
// 			border: 0;
// 			font-size: 100%;
// 			font: inherit;
// 		}
		
// 		.valid-till {
// 			position: relative;
// 			font-size: 12px;
// 			font-weight: 400;
// 			color: grey;
// 		}
		
// 		.schedule-course:hover {
// 			box-shadow: 0px 2px 4px lightgrey;
// 			background-color: #fff;
// 		}
		
// 		.btn:hover {

// 			box-shadow: 0px 4px 6px lightgrey;
// 			background-color: #33A478;
// 		}
		
// 		.schedule-course {
// 			background-color: #fff;
// 			margin-bottom: 20px;
// 		}
		
// 		.schedule-course {
// 			border-bottom-left-radius: 3px !important;
// 			overflow: hidden;
// 			border-bottom-right-radius: 3px !important;
//         }
        
// 		.border-color-1 {
// 			border: 1px solid black;
// 			border-radius: 3px;
// 		}

// 		 .schedule-course-list .xs-caption .dates {
// 			line-height: 24px;
// 			font-size: 13px;
// 		}
		
// 		.schedule-course-list .xs-caption .batch {
// 			font-size: 12px;
// 			font-weight:500;
// 		}
		
// 		.schedule-course-list .xs-caption .more-details {
// 			font-size: 12px;
// 			color: #58595B;
// 			display: block;
// 			margin-top: 5px;
// 			font-weight:500;
// 		}
		
// 		.schedule-course-list .xs-caption .more-details i {
// 			color: #33A478;
// 			margin-left: 10px;
// 		}
		
// 		.more-details i {
// 			pointer-events: none;
// 		}
		
// 		.schedule-training-dates {
//             padding-top:25px;
//             padding-right: 15px;
// 		}
		
// 		.training-heading h3 {
//             margin-top: 9px;
//             font-size: 18px !important;
//             color: #000 !important;
//             height: 2px;
//             position: relative;
//             margin-bottom: 5px;
//             font-weight: 400;
// 		}
		
// 		.training-heading h3>span {
// 			background: #fff none repeat scroll 0 0;
// 			display: inline-block;
// 			left: 50%;
// 			padding: 0 10px;
// 			position: absolute;
// 			top: 50%;
// 			transform: translate(-50%,-50%);
// 		}
		
// 		.training-dates .bx-wrapper {
// 			overflow-y: scroll;
// 			overflow-x: hidden;
// 			width: 100% !important;
// 			margin-top: 20px !important;
// 			margin-bottom: 20px !important;
// 			max-width: 100% !important;
// 			height: 300px;
// 		}
		
// 		.bx-wrapper {
// 			position: relative;
// 			padding: 0;
// 		}
		
// 		.training-dates .bx-wrapper .bx-viewport {
// 			background: transparent;
// 			border: 0 none;
// 			box-shadow: none;
// 			left: auto;
// 			transform: none;
// 			height: 210px !important;
// 		}
		
// 		.training-dates-overview {
// 			margin-left: -4px;
// 			margin-right: -5px;
// 		}
		
// 		.training-dates-list.row {
// 			margin-left: 0px;
// 			margin-right: 0px;
// 			margin-bottom: 16px;
// 			width: 100% !important;
// 		}
		
// 		.training-dates-list .col-2 {
// 			float: left;
// 			width: 20%;
// 			padding: 0 5px;
// 		}
		
// 		.col-2 .schedule-dates {
// 			border: 1px solid #4D5967;
// 			border-radius: 3px;
// 			text-align: center;
// 			padding: 2px 0 4px;
// 		}
		
// 		.training-week {
// 			font-size: 12px;
// 			color: #4D5967;
// 		}
		
// 		.training-date {
// 			font-size: 25px;
// 			color: #4D5967;
// 			line-height: 26px;
// 		}
		
// 		.training-month {
// 			font-size: 12px;
// 			color: #4D5967;
// 			text-transform: uppercase;
// 		}
		
// 		.training-dates-list .col-2 {
// 			float: left;
// 			width: 20%;
// 			padding: 0 5px;
// 		}
		
// 		.col-2 .schedule-dates {
// 			border: 1px solid #4D5967;
// 			border-radius: 3px;
// 			text-align: center;
// 			padding: 2px 0 4px;
// 		}
		
// 		.training-week {
// 			font-size: 12px;
// 			color: #4D5967;
// 		}
		
// 		.training-date {
// 			font-size: 25px;
// 			color: #4D5967;
// 			line-height: 26px;
// 		}
		
// 		.training-month {
// 			font-size: 12px;
// 			color: #4D5967;
// 			text-transform: uppercase;
// 		}
		
// 		.training-dates-list .col-2 {
// 			float: left;
// 			width: 20%;
// 			padding: 0 5px;
// 		}
		
// 		.col-2 .schedule-dates {
// 			border: 1px solid #4D5967;
// 			border-radius: 3px;
// 			text-align: center;
// 			padding: 2px 0 4px;
// 		}
		
// 		.training-week {
// 			font-size: 12px;
// 			color: #4D5967;
// 		}
		
// 		.training-date {
// 			font-size: 25px;
// 			color: #4D5967;
// 			line-height: 26px;
// 		}
		
// 		.training-month {
// 			font-size: 12px;
// 			color: #4D5967;
// 			text-transform: uppercase;
// 		}
		
// 		.training-dates-list .col-2 {
// 			float: left;
// 			width: 20%;
// 			padding: 0 5px;
// 		}
		
// 		.col-2 .schedule-dates {
// 			border: 1px solid #4D5967;
// 			border-radius: 3px;
// 			text-align: center;
// 			padding: 2px 0 4px;
// 		}
		
// 		.training-week {
// 			font-size: 12px;
// 			color: #4D5967;
// 		}
		
// 		.training-date {
// 			font-size: 25px;
// 			color: #4D5967;
// 			line-height: 26px;
// 		}
		
// 		.training-month {
// 			font-size: 12px;
// 			color: #4D5967;
// 			text-transform: uppercase;
// 		}
		
// 		.training-dates .bx-wrapper .bx-controls-direction a.disabled {
// 			display: block;
// 			opacity: 0.3;
// 		}
		
// 		.training-dates .bx-wrapper .bx-controls-direction a {
// 			bottom: -30px;
// 			height: auto;
// 			margin-top: 0;
// 			text-indent: initial;
// 			top: auto;
// 			width: auto;
// 			color: #333;
// 		}
		
// 		.training-dates .bx-wrapper .bx-prev {
// 			z-index: 0 !important;
// 		}
		
// 		.bx-wrapper .bx-controls-direction a {
// 			position: absolute;
// 			outline: 0;
// 		}
		
// 		.bx-wrapper .bx-prev {
// 			background: url(images/controls.png) 0 -32px no-repeat;
// 		}
		
// 		.training-dates .bx-wrapper .bx-controls-direction a i {
// 			font-size: 32px;
// 		}
		
// 		.training-dates .bx-wrapper .bx-controls-direction a.disabled {
// 			display: block;
// 			opacity: 0.3;
// 		}
		
// 		.training-dates .bx-wrapper .bx-controls-direction a {
// 			bottom: -30px;
// 			height: auto;
// 			margin-top: 0;
// 			text-indent: initial;
// 			top: auto;
// 			width: auto;
// 			color: #333;
// 		}
		
// 		.training-dates .bx-wrapper .bx-next {
// 			z-index: 0 !important;
// 		}
		
// 		.bx-wrapper .bx-controls-direction a {
// 			position: absolute;
// 			outline: 0;
// 		}
		
// 		.bx-wrapper .bx-next {
// 			background: url(images/controls.png) -43px -32px no-repeat;
// 		}
		
// 		.training-dates .bx-wrapper .bx-controls-direction .fa.fa-angle-up {
// 			font-size: 32px;
// 			position:relative;
// 			left:150px;
// 			bottom:5px;
// 		}
		
// 		.training-dates .bx-wrapper .bx-controls-direction .fa.fa-angle-down{
// 			font-size: 32px;
// 			position:relative;
// 			left:170px;
// 			bottom:5px;
// 		}

// 		.schedule-course-list .xs-caption .schedule-delivery-type {
// 			line-height: 24px;
// 			text-transform: capitalize;
// 			font-size: 14px;

// 		}
		
// 		.schedule-course-list .xs-caption>div {
// 			font-size: 12px;
// 			font-weight:500;
// 		}
		
// 		.schedule-course .schedule-course-summary .schedule-course-list {
//             float: left;
//             text-align: center;
// 			font-size: 14px;
// 			font-weight: 500;
// 			color: #3F4752;
// 			padding: 0px 0px !important;
// 			min-height: auto !important;
// 			width: 20%;
// 		}
// 		.schedule-course .schedule-course-summary .schedule-course-list-price {
//             float: left;
//             text-align: center;
// 			font-size: 14px;
// 			font-weight: 500;
// 			color: #3F4752;
// 			padding: 0px 0px !important;
// 			min-height: auto !important;
// 			width: 10%;
// 		}

// 		.schedule-course .schedule-course-summary .schedule-course-list-time {
//             float: left;
//             text-align: center;
// 			font-size: 14px;
// 			font-weight: 500;
// 			color: #3F4752;
// 			padding: 0px 0px !important;
// 			min-height: auto !important;
// 			width: 30%;
// 		}


// 		.schedule-course-list .xs-caption .schedule-price {
// 			display: block;
// 			line-height: 24px;
// 			letter-spacing: 0.3px;
// 			font-size:16px;
// 		}

// 		.text-center {
// 			text-align: center;
// 		}
		
// 		.schedule-course-list .btn-success {
// 			min-width: 100px;
// 			padding: 0px;
// 			font-size: 14px;
// 			margin-right: 0px;
// 			font-weight: 700;
// 			position: relative;
// 			margin-top: -55px;
// 			margin-left: 219px;
// 		}
		
// 		.waves-effect {
// 			position: relative;
// 			cursor: pointer;
// 			display: inline-block;
// 			overflow: hidden;
// 			user-select: none;
// 			-webkit-tap-highlight-color: transparent;
// 			vertical-align: middle;
// 			z-index: 1;
// 			transition: all 0.3s ease-out;
// 		}
		
// 		.btn-success {
// 			height: 33px;
// 			line-height: 33px !important;
// 			background: #33A478;
// 			border-radius: 3px;
// 			color: #fff !important;
// 			letter-spacing: 0px;
// 			border: 0 none;
// 		}
		
// 		.text-uppercase {
// 			text-transform: none!important;
// 		}
		
// 		.course-img {
// 			width: 115px;
// 			height: 115px;
// 			margin: 18px auto 0;
// 			display: block;
// 			border-radius: 100%;
// 			position: relative;
// 		}
		
// 		.classroom-trainer {
// 			position:relative;
// 			font-weight: 700;
// 			// font-size: 14px;
// 			// display: block;
// 			// margin-top: 15px;
// 			color: #24272f;
// 		}
		
// 		.classroom-trainer-info, .classroom-venue {
// 			color: #3f4752;
// 			font-size: 13px;
// 			line-height: 18px;
// 			font-weight: 400;
// 			padding-left: 0px;
// 			position: relative;
// 			margin-top: 15px;
// 			display: inline-block;
// 		}
		
// 		.linkBtn{
// 			background: #33A478;
// 			color: white;
// 			border: 1px solid #33A478;
// 			padding: 3px 10px 3px 10px;
// 			border-radius: 3px;
// 			font-weight: 600;
// 			font-size: 15px;
// 			margin-top: 4px;
// 		}

// 		.classroom-trainer-info{
// 			color: #3f4752;
// 			font-size: 13px;
// 			line-height: 18px;
// 			font-weight: 400;
// 			padding-left: 0px;
// 			position: relative;
// 			margin-top: 15px;
// 			display: inline-block;
// 		}
		
// 		.classroom-venue-details i {
// 			position:relative;
// 			font-size: 40px;
// 			color: #3f4752;
// 			height: 58px;
// 			line-height: 58px;
// 		}
		
// 		.classroom-details h4 {
// 			margin-bottom: 15px;
// 		}
		
// 		.schedule-training-dates h4 {
// 			font-size: 18px;
// 		}
		
// 		.right-top {
// 			top: 15px;
// 			right: 15px;
// 		}
		
// 		.color-4, .color-4:hover, .color-4:focus {
// 			color: #23563c !important;
// 		}
		
		
// 		.schedule-border {
// 			border: 1px solid black;
// 			border-top: 0;
// 		}
// 		.demoBtn{
// 			margin-top: 17px;
// 			color: white;
// 			background: #33A478;
// 			border: 1px solid #33a478;
// 			padding: 5px 4px;
// 			border-radius: 3px;
// 			font-size: 14px;
// 			font-weight: 600;
// 			margin-left: 0px;
//         }
//         .demoBtnDisabled{
// 			margin-top: 17px;
//             color: white;
//             background: #ccc;
//             border: 1px solid #ccc;
//             padding: 5px 10px;
//             border-radius: 3px;
//             font-size: 14px;
//             font-weight: 600;
// 		}
//         @media only screen and (max-width: 600px) {
// 			.schedule-course .schedule-course-summary .schedule-course-list-price {
// 				float: left;
// 				text-align: center;
// 				font-size: 14px;
// 				font-weight: 500;
// 				color: #3F4752;
// 				padding: 0px 0px !important;
// 				min-height: auto !important;
// 				width: 6%;			
// 			}
//             .schedule-captions {
//                 display: none;
//             }
//             .schedule-course .schedule-course-summary .schedule-course-list {
// 				float: left;
// 				text-align: center;
// 				font-size: 14px;
// 				font-weight: 500;
// 				color: #3F4752;
// 				padding: 0px 0px !important;
// 				min-height: auto !important;
// 				width: 33%;
// 				margin-bottom: 30px;
// 				margin-left: 0px;
//             }
//             .mobileInrRupeebtn {
// 				position: relative;
// 				right: 55%;
// 				top: 43px;
// 			}
//             .more-details{
// 				position: absolute;
// 				margin-top: 62px !important;
// 				margin-left: 20px;
// 				margin-bottom: -26px;
//             }
//             .btnMobile{
// 				// margin-top: -80px;
// 				margin-left: 95px;
//             }
//             .right-top {
//                 top: -105px;
//                 right: 15px;
//             }
//             .navigation {
// 				display: none !important;
// 			}
// 			.selectTimeZone{
// 				top: -20px;
// 				position: relative;
// 				background: #33A478;
// 				padding: 5px;
// 				border-radius: 5px;
// 				border: 1px solid #33A478;
// 			}
// 			.demoBtn{
// 				margin-top: 17px;
// 				color: white;
// 				background: #33A478;
// 				border: 1px solid #33a478;
// 				padding: 5px 4px;
// 				border-radius: 3px;
// 				font-size: 14px;
// 				font-weight: 600;
// 				// margin-left: 51px;
// 				width: 100%;
// 			}
//         }

// 		// course schedule csss ends

// 		// @media only screen and (min-width: 1024px) and (max-height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) {
// 		// 	.navigation {
// 		// 		width: 100%;
// 		// 		margin-left: 2%;
// 		// 		background-color: #33A478;
// 		// 		color: #fff;
// 		// 		height: 25vh; 
// 		// 		min-height: 225px;
// 		// 		overflow: auto;
// 		// 		position: -webkit-sticky;
// 		// 		position: sticky;
// 		// 		top: 5%;
// 		// 	}
// 		// }

// 		.card-title {
// 			display: inline-flex;
// 			margin-bottom: 0px;
// 		}
// 		// .design-fix-curriculum {
// 		// 	padding:10px
// 		// }
// 		` }
// 		</style>
// 		<div><Particles /></div>
// 		<div className="icon-bar">
// 			<a href="https://bit.ly/twitterSkillRary" className="socialIcon" target="_blank"><i className="fa fa-twitter"></i></a> 
// 			<a href="https://bit.ly/FSKILLRARY" className="socialIcon" target="_blank"><i className="fa fa-facebook"></i></a> 
// 			<a href="https://bit.ly/LISKILLRARY" className="socialIcon" target="_blank"><i className="fa fa-linkedin"></i></a>
// 			<a href="https://bit.ly/InstaSKILLRARY" className="socialIcon" target="_blank"><i className="fa fa-instagram"></i></a> 
// 			<a href="https://bit.ly/youtubeSkillRary" className="socialIcon" target="_blank"><i className="fa fa-youtube"></i></a>
// 		</div>

// 		<div className="sideForm">
// 				<div class="cardForm">
// 					<h6 style={{ color: "black", textAlign:'center', marginBottom: '15px'}}>Request more information</h6>
//                     <div align="center">
// 						<button onClick={this.onClickIndividuals} className="individualTab">Individuals</button>
// 						<button onClick={this.onClickBusiness} className="businessTab">Business</button>
// 					</div>
// 					<div>
// 					<form onSubmit={this.onClickIndiFormSubmit} className="individualForm">
						
// 					<div className="row">
// 							<div className="col-md-12"  style={{ marginBottom: '-28px'}}>
// 								<div className="form-group">
// 									<input type="text" name="first_name_indi" className="inputFieldSide" 
// 									placeholder="Firstname" 
// 									onChange={(v) => this.setState({first_name_indi: v.target.value, errors: {first_name_indi : false}})} value={this.state.first_name_indi} 
// 									autoComplete="off"
// 									/>
// 									<span className="errTextIndi">{this.state.errors.first_name_indi}</span>

// 								</div>
// 							</div>
// 					</div>
// 					<div className="row">
// 						<div className="col-md-12"  style={{ marginBottom: '-28px'}}>
// 							<div className="form-group">
// 								<input type="text" name="last_name_indi" className="inputFieldSide" 
// 								placeholder="Lastname"  autoComplete="off"
// 								onChange={(v) => this.setState({last_name_indi: v.target.value,  errors: {last_name_indi : false}})} value={this.state.last_name_indi} 
// 								/>
// 								<span className="errTextIndi">{this.state.errors.last_name_indi}</span>
// 							</div>
// 						</div>
// 					</div>
// 						<div className="row">
// 							<div className="col-md-12" style={{ marginBottom: '-28px'}}>
// 								<div className="form-group">
// 									<input type="email" name="user_email_indi" className="inputFieldSide" 
// 									placeholder="E-mail"  autoComplete="off"
// 									onChange={(v) => this.setState({user_email_indi: v.target.value,  errors: {user_email_indi : false}})} value={this.state.user_email_indi} 
// 									/>
// 									<span className="errTextIndi">{this.state.errors.user_email_indi}</span>

// 								</div>
// 							</div>
// 						</div>
// 						<div className="row">
// 							<div className="col-md-3"  style={{ marginBottom: '-28px'}}>
// 								<div className="form-group">
// 								<input type="text" name="country_code_indi" className="inputFieldSide" 
// 									autoComplete="off" 
// 									onChange={(v) => this.setState({country_code_indi: v.target.value})} value={this.state.country_code_indi}
// 									/>

// 								</div>
// 							</div>
						
// 							<div className="col-md-9"  style={{ marginBottom: '-28px'}}>
// 								<div className="form-group">
// 									<input type="number" name="contact_number_indi" className="inputFieldSide" 
// 									placeholder="Phone"  autoComplete="off"
// 									onChange={(v) => this.setState({contact_number_indi: v.target.value,  errors: { contact_number_indi : false}})} value={this.state.contact_number_indi}
// 									/>
// 									<span className="errTextIndi">{this.state.errors.contact_number_indi}</span>

// 								</div>
// 							</div>
// 						</div>
// 						<div className="row">
// 							<div className="col-md-12"  style={{ marginBottom: '-28px'}}>
// 								<div className="form-group">
// 									<textarea type="text" name="messages_indi" className="inputFieldSideMsz" 
// 									placeholder="Message"  autoComplete="off"
// 									onChange={(v) => this.setState({messages_indi: v.target.value, errors: {messages_indi : false} })} value={this.state.messages_indi}/>
// 									<span className="errTextIndi">{this.state.errors.messages_indi}</span>
// 								</div>
// 							</div>
// 						</div><br/>
					
// 						<div className="row" style={{ justifyContent: "center" }}>
// 							<button className="submitSideBtn">Submit</button>
// 						</div>

// 					</form>
// 					</div>
// 					<div>
// 						<form onSubmit={this.onClickBusiFormSubmit} className="businessForm" style={{ display: 'none' }}>
							
// 						<div className="row">
// 								<div className="col-md-12"  style={{ marginBottom: '-28px'}}>
// 									<div className="form-group">
// 										<input type="text" name="first_name_busi" className="inputFieldSide" 
// 										placeholder="Firstname" 
// 										onChange={(v) => this.setState({first_name_busi: v.target.value,  errors: {first_name_busi : false}})} value={this.state.first_name_busi} 
// 										autoComplete="off"
// 										/>
// 										<span className="errTextIndi">{this.state.errors.first_name_busi}</span>

// 									</div>
// 								</div>
// 						</div>
// 						<div className="row">
// 							<div className="col-md-12"  style={{ marginBottom: '-28px'}}>
// 								<div className="form-group">
// 									<input type="text" name="last_name_busi" className="inputFieldSide" 
// 									placeholder="Lastname"  autoComplete="off"
// 									onChange={(v) => this.setState({last_name_busi: v.target.value,  errors: {last_name_busi : false}})} value={this.state.last_name_busi} 
// 									/>
// 									<span className="errTextIndi">{this.state.errors.last_name_busi}</span>
// 								</div>
// 							</div>
// 						</div>
// 						<div className="row">
// 							<div className="col-md-12"  style={{ marginBottom: '-28px'}}>
// 								<div className="form-group">
// 									<input type="text" name="corporate_name_busi" className="inputFieldSide" 
// 									placeholder="Company"  autoComplete="off"
// 									onChange={(v) => this.setState({corporate_name_busi: v.target.value, errors: {corporate_name_busi : false}})} value={this.state.corporate_name_busi} 
// 									/>
// 									<span className="errTextIndi">{this.state.errors.corporate_name_busi}</span>
// 								</div>
// 							</div>
// 						</div>
// 							<div className="row">
// 								<div className="col-md-12" style={{ marginBottom: '-28px'}}>
// 									<div className="form-group">
// 										<input type="email" name="user_email_busi" className="inputFieldSide" 
// 										placeholder="E-mail"  autoComplete="off"
// 										onChange={(v) => this.setState({user_email_busi: v.target.value, errors: {user_email_busi : false}})} value={this.state.user_email_busi} 
// 										/>
// 										<span className="errTextIndi">{this.state.errors.user_email_busi}</span>

// 									</div>
// 								</div>
// 							</div>
// 							<div className="row">
// 								<div className="col-md-3"  style={{ marginBottom: '-28px'}}>
// 									<div className="form-group">
// 									<input type="text" name="country_code_indi" className="inputFieldSide" 
// 										autoComplete="off" 
// 										onChange={(v) => this.setState({country_code_indi: v.target.value})} value={this.state.country_code_indi}
// 										/>

// 									</div>
// 								</div>
							
// 								<div className="col-md-9"  style={{ marginBottom: '-28px'}}>
// 									<div className="form-group">
// 										<input type="number" name="contact_number_busi" className="inputFieldSide" 
// 										placeholder="Phone"  autoComplete="off"
// 										onChange={(v) => this.setState({contact_number_busi: v.target.value,errors: {contact_number_busi : false}})} value={this.state.contact_number_busi}
// 										/>
// 										<span className="errTextIndi">{this.state.errors.contact_number_busi}</span>

// 									</div>
// 								</div>
// 							</div>
// 							<div className="row">
// 								<div className="col-md-12"  style={{ marginBottom: '-28px'}}>
// 									<div className="form-group">
// 										<textarea type="text" name="message_busi" className="inputFieldSideMsz" 
// 										placeholder="Message"  autoComplete="off"
// 										onChange={(v) => this.setState({message_busi: v.target.value,errors: {message_busi : false}})} value={this.state.message_busi}/>
// 										<span className="errTextIndi">{this.state.errors.message_busi}</span>
// 									</div>
// 								</div>
// 							</div><br/>
						
// 							<div className="row" style={{ justifyContent: "center" }}>
// 								<button className="submitSideBtnBusiness">Submit</button>
// 							</div>
// 							</form>
// 					</div>
// 				</div>
// 			</div>
			
// 		{/* <div align="center">
// 			<div className="bubbleCircle1"></div>
// 			<div className="bubbleCircle2"></div>
// 			<div className="bubbleCircle3"></div>
// 			<div className="bubbleCircle4"></div>
// 		</div> */}



// 		<div className="container" style={{backgroundColor:"white"}}>
			
// 			<div className="row">
// 				{/* sidebar list starts */}
// 			<div className="col-md-3">
				
// 				<nav className="navigation" id="mainNav">
// 					<ul className="naivgationList">

// 						{/* <li><a className="navigation__link" href="#1">Key Features</a></li> */}
						
// 						{scheduleData.length > 0 && (<li><a className="navigation__link" href="#1">Course Schedule</a></li>)}

// 						{/* <li><a className="navigation__link" href="#3">Description</a></li> */}
// 						<li><a className="navigation__link" href="#2">Scope</a></li>
// 						<li><a className="navigation__link" href="#3">Course Overview</a></li>
// 						<li><a className="navigation__link" href="#4">What you will learn</a></li>
// 						<li><a className="navigation__link" href="#5">What you need to be familiar with?</a></li>
// 						<li><a className="navigation__link" href="#6">Curriculum</a></li>
// 						<li><a className="navigation__link" href="#7">Certification</a></li>
// 						{   this.state.whyCourseImg != null && 
// 						this.state.impOfCourse.length > 0 &&
// 						this.state.avgSalary.length > 0 &&
// 						this.state.jobOpen.length > 0 &&
// 						this.state.jobHiring.length > 0 &&
// 						this.state.studentNumber.length > 0 &&

// 						<li><a className="navigation__link" href="#8">Salary Trend</a></li>
// 						}
// 						{faqData.length > 0 && (<li><a className="navigation__link" href="#9">FAQs</a></li>)}
// 					</ul>

// 				</nav>

// 			</div>
// 				{/* sidebar list ends */}
// 			<div className="col-md-9 col-md-offset-3">

// 				{/* key features starts */}

// 				{/* <div className="page-section sectionContent" id="1"><br/><br/><br/>
// 					<h2 id="sectionHeading">Key Features</h2>
					
// 					{keyWordArray}
// 				</div>  */}

// 				{/* key features ends */}

// 				{/* course schedule starts  */}
// 				{scheduleData.length > 0 ? ( 
// 				<div className="page-section scetionContent" id="1"><br/><br/><br/>
// 					<h2 id="sectionHeading">Course Schedule</h2>
// 					<div align="center">
// 						<select className="selectTimeZone" onChange={this.onChangeTimeZone(this)}>
// 							{/*<option value="" selected>Select Time Zone</option>*/}
// 							<option value='pst' selected>PST - Time Zone</option>
// 							<option value='ist'>IST - Time Zone</option>
// 							<option value='est'>EST - Time Zone</option>
// 						</select>
// 					</div>
// 					<div className="schedule-courses">
// 						<div className="container" >
// 							{/* <div className="clearfix">
// 								<a href="javascript:void(0);" className="pull-right filter-btn visible-sm visible-xs filter-xs">
// 									<i className="filter-icon"></i>Filter
// 								</a>
// 							</div> */}
// 							<div className="row">
// 								<div className="col-md-12 schedule-calendar">
// 									<div className="schedule-list">
// 										<div className="schedule-captions">
// 											<div className="schedule-captions-list">
// 												<span className="headingTable">Date</span>
// 											</div>
// 											<div className="schedule-captions-list">
// 												<span className="headingTable">Delivery type</span>
// 											</div>
// 											<div className="schedule-captions-list-time">
// 												<span className="headingTable">Timing</span>
// 											</div>
// 											<div className="schedule-captions-list-price">
// 												<span className="headingTable">Price</span>
// 											</div>
											
// 											<div className="schedule-captions-list">
// 												<span className="headingTable">Link</span>
// 											</div>

// 										</div>
// 										{scheduleData}										
// 									</div>
// 								</div>
// 							</div>
// 						</div>
// 					</div>
// 				</div>) : ""}

// 				{/* course schedule end */}

				
// 				{/* scope start here  */}

// 				<div className="page-section scetionContent" id="2"><br/><br/><br/>
// 					<h2 id="sectionHeading">Scope</h2>
// 					{description}
// 				</div>

// 				{/* scope end here */}


// 				{/* Course Overview start here  */}

// 				<div className="page-section scetionContent" id="3"><br/><br/><br/>
// 					<h2 id="sectionHeading">Course Overview</h2>
// 					{this.state.overview}
// 				</div>

// 				{/* Course Overview end here */}

// 				{/* what you will learn start here  */}
				 
// 				<div className="page-section scetionContent" id="4"><br/><br/><br/>
// 					<h2 id="sectionHeading">What you will learn</h2>
// 					{whatLearnArray}
// 				</div>
				
// 				{/* what you will learn end here  */}

// 				{/* What you need to be familiar with? start here  */}
				
// 				<div className="page-section scetionContent" id="5"><br/><br/><br/>
// 					<h2 id="sectionHeading">What you need to be familiar with?</h2>
// 					{familiarArray}
// 				</div>

// 				{/* What you need to be familiar with? end here  */}

				

// 				{/* description starts  */}

// 				{/* <div className="page-section sectionContent" id="3"><br/><br/><br/>
// 					<h2 id="sectionHeading">Description</h2>
// 					<p className="descriptionPTag">
// 					{description}
// 					</p>
// 				</div> */}

// 				{/* description ends  */}

// 				{/* curiculum starts  */}

// 				<div className="page-section sectionContent" id="6"><br/><br/><br/>
// 					<h2 id="sectionHeading">Curriculum</h2>
// 					<div className="container">
// 					    <div id="accordion">
// 					        <div className="card curiculumCard mb-0">
// 					        {curriculumData}

// 					        </div>
// 					    </div>
// 					</div>
// 				</div>

// 				{/* curiculum ends  */}
							
// 				{/* ceritification starts  */}

// 				<div className="page-section sectionContent" id="7"><br/><br/><br/>

// 					<h2 id="sectionHeading">Certification</h2>
// 					<div>
// 						<img src={courseCertification} className="certificateImage" /><br/>
// 						{/* {this.state.certificate !== null ? <button type="button" className="previewBtn" data-toggle="modal" data-target="#myModal">Preview</button> : ''} */}
// 						<button type="button" className="previewBtn" data-toggle="modal" data-target="#myModal">Preview</button>
// 					</div>

// 					<div id="myModal" className="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
// 					<div className="modal-dialog">
// 						<div className="modal-content">
// 							<div className="modal-body" id="imageBody">
// 								<button data-dismiss="modal" className="closeImage">×</button>
// 								<img src={courseCertification}  className="popupImage"/>
// 							</div>
// 						</div>
// 					</div>
// 					</div>
			
// 				</div>

// 				{/* Certification ends  */}

// 				{/* salary trends starts  */}
				
				
// 					{/* <h2 id="sectionHeading">Why {this.state.courseTitle}?</h2> */}
// 					{/* <p>{this.state.whyCourseTag}</p>
// 						{(null !== this.state.whyCourseImg) ? (<img src={this.state.whyCourseImg.length > 0 ? this.state.whyCourseImg :  "https://www.skillrary.com/user/generalimages/1562847536_skillrary-13.png"} width="100%" className="landingImage"/>):""
// 						} */}
					

// 					{   this.state.whyCourseImg != null && 
// 						this.state.impOfCourse.length > 0 &&
// 						this.state.avgSalary.length > 0 &&
// 						this.state.jobOpen.length > 0 &&
// 						this.state.jobHiring.length > 0 &&
// 						this.state.studentNumber.length > 0 &&
// 						<div className="page-section sectionContent" id="8"><br/><br/><br/>
// 						<h2 id="sectionHeading">Why {this.state.courseTitle}?</h2>

// 						<ImageContainer whyCourseImg={this.state.whyCourseImg}
// 										impOfCourse={this.state.impOfCourse}
// 										avgSalary={this.state.avgSalary}
// 										jobOpen={this.state.jobOpen}
// 										jobHiring={this.state.jobHiring}
// 										studentNumber={this.state.studentNumber}
// 										enrollLink={this.state.enrollLink}/>

// 						</div>										
// 					}
										
					
				
// 				{/* salary trends ends  */}

// 				{/* faq starts  */}

// 				{faqData.length > 0 && (
// 				<div className="page-section sectionContent" id="9"><br/><br/><br/>
// 					<h2 id="sectionHeading">FAQs</h2>
// 					<div className="container">
// 					    <div id="faqaccordion">
// 					        <div className="card faqCard mb-0">
// 					        	{faqData}
// 					        </div>
// 					    </div>
// 					</div>
// 				</div>)}
				
// 				{/* faq ends  */}

// 			</div>
			
		
			
			
// 			</div>
	
// 		</div>
		
			

		
// 	</div>
// 		)
// 	}
// }

// export default ScrollSpy;




import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';
import courseCertification from '../liveTrainingDesign/assets/course-certificate-about.png';
import $ from 'jquery';
import LogoSr from '../liveTrainingDesign/assets/srlogo.png';
import ImageContainer from './imageContainer';
import Swal from 'sweetalert2';
import Particles from '../liveTrainingDesign/particle';
import { FacebookShareButton, TwitterShareButton, WhatsappShareButton, LinkedinShareButton } from 'react-share';
import Modal from 'react-responsive-modal';
// import 'react-responsive-modal/styles.css';
import { withRouter } from 'react-router-dom';

class ScrollSpy extends Component{
	constructor(props) {
		super(props);
		// //console.log(window.sessionStorage.getItem('courseId'),'window---------------------------')
		this.state = {
			courseId: props.courseId,
			courseTitle: "",
			courseTagLine: "",
			description:"",
			FAQ:[],
			curriculum: [],
			keyWord: "",
			schedule: [],
			whyCourseImg: "",
			impOfCourse: "",
			avgSalary: "",
			jobOpen: "",
			jobHiring: "",
			studentNumber: "",
			enrollLink: "",
			certificate : "",
			overview: "",
			whatlearn: "",
			familiar: "",
			whyCourseTag: "",
			errors: {},
			first_name_indi : '',
			last_name_indi : '',
			user_email_indi : '',
			country_code_indi: '+91',
			contact_number_indi : '',
			messages_indi : '',
			first_name_busi: '',
			last_name_busi: '',
			corporate_name_busi: '',
			user_email_busi: '',
			contact_number_busi: '',
			message_busi: '',
			whyCourseImg: '',
			recordedVideoLink: '',
			name: '',
			email: '',
			phone_number: '',
			modalPopupHeading: '',
			popupMessage: '',
			emailSession: '',
			slug: '',
			timezone: 'ist'
		}

		this.getCourse = this.getCourse.bind(this);
		this.getFAQ = this.getFAQ.bind(this);
		this.getCurriculum = this.getCurriculum.bind(this);
		this.getSchedule = this.getSchedule.bind(this);
		this.getMonthName = this.getMonthName.bind(this);
		this.prefixZero = this.prefixZero.bind(this);
		this.get12HourFormat = this.get12HourFormat.bind(this);
		this.scheduleCalendar = this.scheduleCalendar.bind(this);
		this.getScheduleCard = this.getScheduleCard.bind(this);
		this.setScheduleData = this.setScheduleData.bind(this);
		this.getDayName = this.getDayName.bind(this);
		// this.getSessionStorage = this.getSessionStorage.bind(this);
		this.onClickIndividuals = this.onClickIndividuals.bind(this);
		this.onClickBusiness = this.onClickBusiness.bind(this);
		this.onClickBusiFormSubmit = this.onClickBusiFormSubmit.bind(this);
		this.onClickIndiFormSubmit = this.onClickIndiFormSubmit.bind(this);
		this.onChangeTimeZone = this.onChangeTimeZone.bind(this);
		this.getEnrollData = this.getEnrollData.bind(this);
		this.submitEnrollData = this.submitEnrollData.bind(this);
		this.removeTags = this.removeTags.bind(this);
	}

	componentDidMount(){
		this.getCourse();

		$(window).scrollTop(0); 

		// $('.navigation ul li a').click(function(){
        // $(this).toggleClass('.activeMenu');
		// });

		$(window).bind('scroll', function() {
            var currentTop = $(window).scrollTop();
            var elems = $('.page-section');
            elems.each(function(index){
              var elemTop 	= $(this).offset().top;
              var elemBottom 	= elemTop + $(this).height();
              if(currentTop >= elemTop && currentTop <= elemBottom){
                var id 		= $(this).attr('id');
                var navElem = $('a[href="#' + id+ '"]');
            	navElem.parent().addClass('listActive').siblings().removeClass( 'listActive' );
              }
            })
		});
	}

	getCourse() {
		var url = document.location.pathname;
		var pathname = url.substring(url.lastIndexOf('/')+1)
		var completename = document.location.pathname.split('/');

		//console.log(pathname,'pathname')
		//console.log(completename,'completename')

		var courseType = 'all';
		if("" !== completename[2]){
			if(completename[2] == "ongoing-session"){
				courseType = 'running'
			}else if(completename[2] == "upcoming-session"){
				courseType = 'upcoming'
			}
		}

		fetch("https://live.skillrary.com/live-courses/index.php/api/v1/course-info", {
			method: 'POST',
			headers: {'Content-Type':'application/json'},
			body: JSON.stringify({
			slug: completename[1],
			course_id: 0,
			course_type: courseType,
			session_type: 1
			})
		})
		.then(res => res.json())
		.then(
		(result) => {
			//console.log('rest1', result.result);
			window.sessionStorage.setItem("courseId", result.result[0].id);
			if(result.result !==  "No Courses found"){
			this.setState({
			courseTitle: undefined != '' ? result.result[0].course_title : '',
			courseTagLine:   result.result[0].course_tagline,
			description:  ReactHtmlParser(result.result[0].description),
			FAQ: result.result[0].faq,
			curriculum:  result.result[0].curricullum,
			keyWord: result.result[0].key_features,
			schedule: result.result[0].sessions,
			whyCourseImg: result.result[0].why_course,
			whyCourseTag: result.result[0].whythis_tagline,
			certificate: result.result[0].certificate,
			overview:  ReactHtmlParser(result.result[0].overview),
			whatlearn: ReactHtmlParser(result.result[0].whatlearn),
			familiar:  ReactHtmlParser(result.result[0].familiar),
			impOfCourse:  ReactHtmlParser(result.result[0].impofcourse),
			avgSalary:  ReactHtmlParser(result.result[0].avgsalary),
			jobOpen:  ReactHtmlParser(result.result[0].jobopen),
			jobHiring:  ReactHtmlParser(result.result[0].jobhirings),
			studentNumber:  ReactHtmlParser(result.result[0].studnumber),
			enrollLink: result.result[0].enrolllink,
			recordedVideoLink: result.result[0].recorded_vedio_link,
			slug : result.result[0].slug,
			})
		} else {
			this.props.history.push('/filenotfound')
		}
		},	
		(error) => {
			this.setState({
			isLoaded: true,
			error
			});
		}
		)
	}

	onClickIndividuals(){
		$('.individualForm').css('display','block')
		$('.businessForm').css('display','none')
		$(".individualTab").css({'background':'black','color':'#33A478','outline':'none'});
		$(".businessTab").css({'background':'#33A478','color':'black','outline':'none'});
		this.setState({first_name_busi : '',last_name_busi: '',corporate_name_busi:'',user_email_busi:'',contact_number_busi:'',message_busi:''})
	}

	onClickBusiness(){
		$('.individualForm').css('display','none')
		$('.businessForm').css('display','block')
		$(".businessTab").css({'background':'black','color':'#33A478','outline':'none'});
		$(".individualTab").css({'background':'#33A478','color':'black','outline':'none'});
		this.setState({first_name_indi : '',last_name_indi: '',user_email_indi:'',contact_number_indi:'',messages_indi:'' })
	}

	onClickIndiFormSubmit(e){
		e.preventDefault();

		let errors = {};
		let formIsValid = false;
		
		if(!this.state.first_name_indi){
			formIsValid = true
			errors['first_name_indi'] = "This field is required"
		}
		else if(!this.state.last_name_indi){
			formIsValid = true
			errors['last_name_indi'] = "This field is required"
		}
		else if(!this.state.user_email_indi) {
			formIsValid = true
			errors['user_email_indi'] = "This field is required"
		}
		else if(!this.state.contact_number_indi){
			formIsValid = true
			errors['contact_number_indi'] = "This field is required"
		}
		else if(this.state.contact_number_indi.length < 10){
			formIsValid = true
			errors['contact_number_indi'] = "Minimum 10 characters"
		}
		else if(this.state.contact_number_indi.length > 10){
			formIsValid = true
			errors['contact_number_indi'] = "Maximum 10 characters"
		}
		else if(!this.state.messages_indi){
			formIsValid = true
			errors['messages_indi'] = "This field is required"
		}

		if(Object.keys(errors).length > 0){
			this.setState({errors:errors})
			return formIsValid;
		}

		fetch("https://live.skillrary.com/live-courses/index.php/api/v1/one-to-one", {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json'
			},
			body: JSON.stringify({
				course_id:  window.sessionStorage.getItem('courseId'),
				first_name: this.state.first_name_indi,
				last_name: this.state.last_name_indi,
				user_email: this.state.user_email_indi,
				contact_number: this.state.country_code_indi + ' '+ this.state.contact_number_indi,
				messages: this.state.messages_indi
			})
		})
      .then(res => res.json())
      .then((result) => {
		  ////console.log('rest', result.status);
		  if(result.status == 200){
            Swal.fire({
            type: 'success',
            title: 'Thank you, Your request is submitted successfully',
            showConfirmButton: false,
            timer: 2500
            })
			this.setState({first_name_indi : '',last_name_indi: '',user_email_indi:'',contact_number_indi:'',messages_indi:'' })
          }
          else if(result.status == 400){
            Swal.fire({
              type: 'error',
              title: 'Oops...',
              text: 'Something went wrong!',
              footer: '<a href>Why do I have this issue?</a>'
            })
          }
		})
		
	}

	onClickBusiFormSubmit(e){
		e.preventDefault();

		let errors = {};
		let formIsValid = false;

		if(!this.state.first_name_busi){
			formIsValid = true;
			errors['first_name_busi'] = "This field is required"
		}
		else if(!this.state.last_name_busi){
			formIsValid = true;
			errors['last_name_busi'] = "This field is required"
		}
		else if(!this.state.corporate_name_busi){
			formIsValid = true;
			errors['corporate_name_busi'] = "This field is required"
		}
		else if(!this.state.user_email_busi){
			formIsValid = true;
			errors['user_email_busi'] = "This field is required"
		}
		else if(!this.state.contact_number_busi){
			formIsValid = true
			errors['contact_number_busi'] = "This field is required"
		}
		else if(this.state.contact_number_busi.length < 10){
			formIsValid = true
			errors['contact_number_busi'] = "Minimum 10 characters"
		}
		else if(this.state.contact_number_busi.length > 10){
			formIsValid = true
			errors['contact_number_busi'] = "Maximum 10 characters"
		}
		else if(!this.state.message_busi){
			formIsValid = true;
			errors['message_busi'] = "This is field is required"
		}
		if(Object.keys(errors).length > 0 ){
			this.setState({errors:errors})
			return formIsValid;
		}

		fetch("https://live.skillrary.com/live-courses/index.php/api/v1/corporate-request", {
			method: 'POST',

			headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json'
			},
			body: JSON.stringify({
				course_id: window.sessionStorage.getItem('courseId'),
				first_name: this.state.first_name_busi,
				last_name: this.state.last_name_busi,
				corporate_name: this.state.corporate_name_busi,
				user_email: this.state.user_email_busi,
				contact_number: this.state.country_code_indi + ' ' + this.state.contact_number_busi,
				message: this.state.message_busi
			})
		})
      .then(res => res.json())
      .then((result) => {
		 // //console.log('rest', result.status);
		  if(result.status == 200){
            Swal.fire({
            type: 'success',
            title: 'Thank you, Your request is submitted successfully',
            showConfirmButton: false,
            timer: 2500
            })
			this.setState({first_name_busi : '',last_name_busi: '',corporate_name_busi:'',user_email_busi:'',contact_number_busi:'',message_busi:''})
          }
          else if(result.status == 400){
            Swal.fire({
              type: 'error',
              title: 'Oops...',
              text: 'Something went wrong!',
              footer: '<a href>Why do I have this issue?</a>'
            })
          }
		})
	}

	getCurriculum (item, i) { //console.log('item', item.module_title)
		
		var descArray = ReactHtmlParser(item.module_desc);
		var moduleTitle = ReactHtmlParser(item.module_title);
		/*
		var moduleDesc = item.module_desc.split(",");
		var descArray = [];
        
        if (moduleDesc.length) {
	        for (var j=0; j <  moduleDesc.length;j++) {
	          ////console.log('keydf', moduleDesc[j]);
				descArray.push(<p style={{ padding: "5px 20px" , margin: '1px'}}><span style={{ color: 'black', marginRight: '4px', fontSize: '6px' }}><i className="fa fa-circle" style={{ position
				:'relative', top: '-2px' }} aria-hidden="true"></i>
				</span> { moduleDesc[j]}</p>);
			}
		}
		*/

		return (
			 <React.Fragment>
		        <div className="card-header collapsed curiculumHeader" data-toggle="collapse" href={"#curiculum" + (i)}>
			        <a className="card-title ">
						<strong style={{ display: '-webkit-inline-box' }}>Module{i} :</strong>	
						
						<span style={{ 

						marginBottom: '-35px',
height: 'auto !important',
display: 'inline-flex',
flexDirection: 'column', 
 }} >{ReactHtmlParser(item.module_title)} </span>
							{/* <i className="fa fa-chevron-down" style={{ float: 'right' }} aria-hidden="true"></i> */}

					</a>
	    		</div>
			    <div id={"curiculum" + (i)} className={"collapse " + ( (i == 1) ? "show" : "")}  data-parent="#accordion" >
				<p style={{ padding: "5px 20px" , margin: '1px',textAlign:'justify' }}>{descArray}</p>
				</div>
		     </React.Fragment>
		)
	}

	prefixZero(num) {
		return ("0"+num);
	}

	get12HourFormat(hour) {
		var formatHour = 0;
		switch(parseInt(hour)) {
			case 13 : formatHour = 1;
					 break;
			case 14 : formatHour = 2;
					 break;
			case 15 : formatHour = 3;
					 break;
			case 16 : formatHour = 4;
					 break;
			case 17 : formatHour = 5;
					 break;
			case 18 : formatHour = 6;
					 break;
			case 19 : formatHour = 7;
					 break;
			case 20 : formatHour = 8;
					 break;
			case 21 : formatHour = 9;
					 break;
			case 22 : formatHour = 10;
					 break;
			case 23 : formatHour = 11;
					 break;
		}

		return formatHour;
	}

	getDayName(day) {
		var dayName = "";
		switch(parseInt(day) + 1) {
			case 1 : dayName = "Sun";
					 break;
			case 2 : dayName = "Mon";
					 break;
			case 3 : dayName = "Tue";
					 break;
			case 4 : dayName = "Wed";
					 break;
			case 5 : dayName = "Thu";
					 break;
			case 6 : dayName = "Fri";
					 break;
			case 7 : dayName = "Sat";
					 break;
		}

		return dayName;
	}

	getMonthName(month) {
		////console.log('a',month)
		var monthName = "";
		switch(parseInt(month)) {
			case 1 : monthName = "Jan";
					 break;
			case 2 : monthName = "Feb";
					 break;
			case 3 : monthName = "Mar";
					 break;
			case 4 : monthName = "Apr";
					 break;
			case 5 : monthName = "May";
					 break;
			case 6 : monthName = "Jun";
					 break;
			case 7 : monthName = "Jul";
					 break;
			case 8 : monthName = "Aug";
					 break;
			case 9 : monthName = "Sep";
					 break;
			case 10 : monthName = "Oct";
					 break;
			case 11 : monthName = "Nov";
					 break;
			case 12 : monthName = "Dec";
					 break;
		}

		return monthName;
	}

	getScheduleCard(schedule) {
		
		return (
			<div className="training-dates-list row" style={{ float: "none", listStyle: "none", position: "relative", left:"25px" }}>
				{schedule}
			</div>
		)

	}

	setScheduleData(scheduleData) {
	////console.log('sddd', this.getDayName(scheduleData.getDay()) )

		return (
			<div className="col-2">
				<div className="schedule-dates">
					<div className="training-week">{this.getDayName(scheduleData.getDay())}</div>
					<div className="training-date" style={{ fontVariantNumeric: "oldstyle-nums" }}>{scheduleData.getDate()}</div>
					<div className="training-month">{this.getMonthName(scheduleData.getMonth() + 1 )}</div>
				</div>
			</div>
		)

	}

	scheduleCalendar(start,end) {


		// var datesArray = [];
		// var startDate = new Date(start);
		// while (startDate <= end) {
		// 	datesArray.push(new Date(startDate));
		// 	startDate.setDate(startDate.getDate() + 1);
		// }
		// //console.log(datesArray);


	    var arr = new Array();
	    var dt = new Date(start);
	    // dt.setDate(dt.getDate() - 1);
	    while (dt <= end) {
			arr.push(new Date(dt));
			dt.setDate(dt.getDate() + 1);
		}
		// //console.log(arr);

		var i = 0;
		var scheduleValue = [];
		var scheduleCard = [];
	
		if (arr.length) {

	        var self = this;
	        
	        for (var j = 0 ; j< arr.length; j++) {
	     
	         
	           if (i > 4) {
	            scheduleCard.push(self.getScheduleCard(scheduleValue));
	            i = 0;
	            scheduleValue = [];
	           }

	           if (j == (arr.length - 1) ) {
	            scheduleValue.push(self.setScheduleData(arr[j]));
	            scheduleCard.push(self.getScheduleCard(scheduleValue));
	           } else {
	            scheduleValue.push(self.setScheduleData(arr[j]));
	           }

	           i++;
	        }
	    }
	    return scheduleCard;
	}

	// onChangeTimeZone(e,time){
	// 	//console.log('timezone========',time.value)
	// }

	onChangeTimeZone(){
		return e => {
			//console.log(e.target.value)
			
			if(e.target.value === 'ist' || e.target.value === ''){
				$('.istTimeZone').css('display','block')
				$('.pstTimeZone').css('display','none')
				$('.estTimeZone').css('display','none')

			} else if(e.target.value === 'pst'){
				$('.istTimeZone').css('display','none')
				$('.pstTimeZone').css('display','block')
				$('.estTimeZone').css('display','none')
			}
			else if(e.target.value === 'est'){
				$('.istTimeZone').css('display','none')
				$('.pstTimeZone').css('display','none')
				$('.estTimeZone').css('display','block')
			}
			this.setState({timezone:e.target.value})
		}

	}
	onOpenEDModal(a){
		if( a === 1){
			this.setState({openEnroll : true, modalPopupHeading: 'Download Syllabus'})
		}
		else if( a === 2){
			this.setState({openEnroll : true, modalPopupHeading: 'Request For a Enroll'})
		}
	}
	onCloseModalED = () => {
		this.setState({openEnroll : false})
		this.setState({ name: '',email: '',phone_number: '', errors: {} })
	}

	submitEnrollData(e){
		e.preventDefault();

		let errors = {};
		let formIsValid = false;
		
		// if(this.state.modalPopupHeading === 'Request For a Enroll'){
			if(!this.state.name){
				formIsValid = true
				errors['name'] = "This field is required"
			}
			else if(!this.state.name.match(/^[a-zA-Z]+(?:[\s.]+[a-zA-Z]+)*$/)){
				formIsValid = true;
				errors['name'] = "Please enter only characters";
			}    
		// }
		else if(!this.state.email) {
			formIsValid = true
			errors['email'] = "This field is required"
		}
		else if(!this.state.email.match(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)){
			formIsValid = true
			errors['email'] = "Please enter valid email"
		}
		else if(!this.state.phone_number){
			formIsValid = true
			errors['phone_number'] = "This field is required"
		}
		// else if(!this.state.phone_number.match("^[7-9][0-9]{9}$")){
		// 	formIsValid = true
		// 	errors['phone_number'] = "Number starts from 7,8,9"
		// }
		else if(this.state.phone_number.length < 10){
			formIsValid = true
			errors['phone_number'] = "Minimum should be 10 characters"
		}
		else if(this.state.phone_number.length > 10){
			formIsValid = true
			errors['phone_number'] = "Maximum should be 10 characters"
		}

		if(Object.keys(errors).length > 0){
			this.setState({errors:errors})
			return formIsValid;
		}

		fetch("https://live.skillrary.com/live-courses/index.php/api/v1/syllabus-request", {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json'
			},
			
			body: JSON.stringify({
				course_id: window.sessionStorage.getItem('courseId'),
				name: this.state.name,
				email: this.state.email,
				contact_number: this.state.phone_number,
				request_from: this.state.modalPopupHeading === 'Request For a Enroll' ? 'Enroll' : 'Syllabus'
			})
		})
      .then(res => res.json())
      .then((result) => {
		  //console.log('rest', result);
		  if(result.status == 200){
			if(this.state.modalPopupHeading === 'Request For a Enroll'){	
				Swal.fire({
					type: 'success',
					title: result.message,
					showConfirmButton: false,
					timer: 3000
					})
			} else {
				window.open(`https://live.skillrary.com/live-courses/index.php/syllabus/${this.state.slug}`,"_self");
			}
			this.setState({name : '',email: '',phone_number:'',openEnroll: false})
			//console.log(this.state.emailSession, "Email Session")
          }
          else if(result.status == 400){
            Swal.fire({
              type: 'error',
              title: 'Oops...',
              text: 'Something went wrong!',
              footer: '<a href>Why do I have this issue?</a>'
            })
          }
		})
		
	}
	getSchedule(schedule, k) {
		//console.log('sch=================url',typeof(schedule.isurl))

		// start date js 
		var startDateObj = new Date(parseInt(schedule.start_date) * 1000);
		var startDateMonth = this.getMonthName(startDateObj.getMonth() + 1);
		var startDate = startDateObj.getDate();
		var startTimeHour = startDateObj.getUTCHours();
		var startTimeMin  = startDateObj.getUTCMinutes();
		if (startTimeMin < 10) {	
			startTimeMin = this.prefixZero(startTimeMin);
		}
		var startTimeM = "AM";
		if (startTimeHour > 12) {
			startTimeM = "PM";
			startTimeHour = this.get12HourFormat(startTimeHour);

		}
		if (startTimeHour < 10) {	
			startTimeHour = this.prefixZero(startTimeHour);
		}

		////console.log('mm',this.getMonthName(startDateObj.getMonth() + 1));

		// //console.log('tz',startDateObj.toLocaleString("en-US", {timeZone: "America/Los_Angeles"}), 
		// 'in', startDateObj.toLocaleString("en-US", {timeZone: "Asia/Kolkata"}));

		// end date js 
		var endDateObj = new Date(schedule.end_date * 1000); 
		var endDateMonth = this.getMonthName(endDateObj.getMonth() + 1);		
		var endDate = endDateObj.getDate();
		var endTimeHour = endDateObj.getUTCHours();
		var endTimeMin  = endDateObj.getUTCMinutes();
		if (endTimeMin < 10) {	
			endTimeMin = this.prefixZero(endTimeMin);
		}
		var endTimeM = "AM";
		if (endTimeHour > 12) {
			endTimeM = "PM";
			endTimeHour = this.get12HourFormat(endTimeHour);
		}
		if (endTimeHour < 10) {	
			endTimeHour = this.prefixZero(endTimeHour);
		}

		// old code starts here 

			//est date start
			var date_est_start = new Date(schedule.start_EST.replace(/-/g,"/"));
			////console.log('dd',date_test.getMinutes());
	
			var startTimeHourEST = date_est_start.getUTCHours();			
			
			var startTimeMinEST  = date_est_start.getUTCMinutes();
			
			if (startTimeMinEST < 10) {	
				startTimeMinEST = this.prefixZero(startTimeMinEST);
			}
	
			var startTimeMEST = "AM";
			if (startTimeHourEST > 12) {
				startTimeMEST = "PM";
				startTimeHourEST = this.get12HourFormat(startTimeHourEST);
	
			}
	
			if (startTimeHourEST < 10) {	
				startTimeHourEST = this.prefixZero(startTimeHourEST);
			}
	
			//est end date
			var date_est_end = new Date(schedule.end_EST.replace(/-/g,"/"));
	
			var endTimeHourEST = date_est_end.getUTCHours();
			var endTimeMinEST  = date_est_end.getUTCMinutes();
	
			if (endTimeMinEST < 10) {	
				endTimeMinEST = this.prefixZero(endTimeMinEST);
			}
	
			var endTimeMEST = "AM";
			if (endTimeHourEST > 12) {
				endTimeMEST = "PM";
				endTimeHourEST = this.get12HourFormat(endTimeHourEST);
			}
			if (endTimeHourEST < 10) {	
				endTimeHourEST = this.prefixZero(endTimeHourEST);
			}
	
			//pst date start
			var date_pst_start = new Date(schedule.start_PST.replace(/-/g,"/"));
	
			var startTimeHourPST = date_pst_start.getHours();			
			
			var startTimeMinPST  = date_pst_start.getMinutes();
			
			if (startTimeMinPST < 10) {	
				startTimeMinPST = this.prefixZero(startTimeMinPST);
			}
	
			var startTimeMPST = "AM";
			if (startTimeHourPST > 12) {
				startTimeMPST = "PM";
				startTimeHourPST = this.get12HourFormat(startTimeHourPST);
	
			}
	
			if (startTimeHourPST < 10) {	
				startTimeHourPST = this.prefixZero(startTimeHourPST);
			}
	
			//pst end date
			var date_pst_end = new Date(schedule.end_PST.replace(/-/g,"/"));
	
			var endTimeHourPST = date_pst_end.getHours();
			var endTimeMinPST  = date_pst_end.getMinutes();
	
			if (endTimeMinPST < 10) {	
				endTimeMinPST = this.prefixZero(endTimeMinPST);
			}
	
			var endTimeMPST = "AM";
			
			if (endTimeHourPST > 12) {
				endTimeMPST = "PM";
				endTimeHourPST = this.get12HourFormat(endTimeHourPST);
			}
	
			if (endTimeHourPST < 10) {	
				endTimeHourPST = this.prefixZero(endTimeHourPST);
			}
	
			// ist start date
			var date_ist_start = new Date(schedule.start_IST);
			var istmonth = this.getMonthName(date_ist_start.getMonth()+1);
			var istday = date_ist_start.getDate();
			var iststartTimeHour = date_ist_start.getHours();
			var iststartTimeMin = date_ist_start.getMinutes();
			var iststartTimeM = "AM";
			if (iststartTimeHour > 12) {
				iststartTimeM = "PM";
				iststartTimeHour = this.get12HourFormat(iststartTimeHour);
			}
		
			if (iststartTimeMin < 10) {
				iststartTimeMin = "0"+iststartTimeMin;
			}
	  
			var iststartDateObj = new Date(parseInt(schedule.start_date) * 1000);
			var iststartstr = iststartDateObj.toLocaleString("en-US", {timeZone: "Asia/Kolkata"})
			var iststartres = iststartstr.split(","); 
			var iststarttimeA = iststartres[iststartres.length-1].substr(0,5);
			//console.log(istmonth,istday,iststarttimeA,iststartTimeM,'session start_IST')
	
			//ist end date
	
			var date_ist_end = new Date(schedule.end_IST);
			var istendmonth = this.getMonthName(date_ist_end.getMonth()+1);
			var istendday = date_ist_end.getDate();
			var istendTimeHour = date_ist_end.getHours();
			var istendTimeMin = date_ist_end.getMinutes();
	
			var istendTimeM = "AM";
			if (istendTimeHour > 12) {
				istendTimeM = "PM";
				istendTimeHour = this.get12HourFormat(istendTimeHour);
			}
		
			if (istendTimeMin < 10) {
				istendTimeMin = "0"+istendTimeMin;
			}
			var istendDateObj = new Date(parseInt(schedule.end_date) * 1000);
			var istendstr = istendDateObj.toLocaleString("en-US", {timeZone: "Asia/Kolkata"});
			
			var istendres = istendstr.split(","); 
			
			var istendtimeA = istendres[istendres.length-1].substr(0,5);
			//console.log(istendtimeA,'hour')
			//console.log(istendmonth,istendday,istendTimeM,'session end_IST')	
		
		// new code starts here

			// ist start date

			var date_ist_start = new Date(schedule.start_IST);
			var istmonth = this.getMonthName(date_ist_start.getMonth()+1);
			var istday = date_ist_start.getDate();

			var currentTime = new Date(parseInt(schedule.start_date) * 1000);
			var currentOffset = currentTime.getTimezoneOffset();
			var ISTOffset = 330;   
			var ISTTime = new Date(currentTime.getTime() + (ISTOffset + currentOffset)*60000);
			var hoursIST = ISTTime.getHours()
			var minutesIST = ISTTime.getMinutes()
			var iststartTimeM = "AM";
			if (hoursIST > 12) {
				iststartTimeM = "PM";
				hoursIST = this.get12HourFormat(hoursIST);
			}
			if (minutesIST < 10) {
				minutesIST = "0"+minutesIST;
			}
			//console.log(hoursIST + ":" + minutesIST,iststartTimeM,'Time----')


			//ist end date

			var date_ist_end = new Date(schedule.end_IST);
			var istendmonth = this.getMonthName(date_ist_end.getMonth()+1);
			var istendday = date_ist_end.getDate();

			var currentTime = new Date(parseInt(schedule.end_date) * 1000);
			var currentOffset = currentTime.getTimezoneOffset();
			var ISTOffset = 330; 
			var ISTTime = new Date(currentTime.getTime() + (ISTOffset + currentOffset)*60000);
			var hoursendIST = ISTTime.getHours()
			var minutesendIST = ISTTime.getMinutes()
			var istendTimeM = "AM";
			if (hoursendIST > 12) {
				istendTimeM = "PM";
				hoursendIST = this.get12HourFormat(hoursendIST);
			}
			if (minutesendIST < 10) {
				minutesendIST = "0"+minutesendIST;
			}
			//console.log(hoursendIST + ":" + minutesendIST,istendTimeM,'Time----')
			
			// pst start date

			// var offset = 480; 
			// var offsetMillis = offset * 60 * 1000;
			// var today = new Date();
			// var millis = today.getTime();

			// var pst = millis - offsetMillis; 
			// var currentDateToday = new Date(pst).toUTCString();

			// //console.log(currentDateToday,'psttodaydate======')


			var offset = 480; 
			var offsetMillis = offset * 60 * 1000;
			var today = new Date(parseInt(schedule.start_date) * 1000);
			var millis = today.getTime();

			var pst = millis - offsetMillis; 
			var currentDate = new Date(pst).toUTCString();
			var cdate = new Date(currentDate)
			var pststartmonth = this.getMonthName(cdate.getMonth()+1);
			var pststartday = cdate.getUTCDate();
			var pststartTimeHour = cdate.getUTCHours();
			var pststartTimeMin = cdate.getUTCMinutes();

			var TimeM = "AM";
			if (pststartTimeHour > 12) {
				TimeM = "PM";
				pststartTimeHour = this.get12HourFormat(pststartTimeHour);
			}
			if (pststartTimeMin < 10) {
				pststartTimeMin = "0"+pststartTimeMin;
			}
			//console.log(currentDate,pststartday,pststartTimeHour,pststartTimeMin,TimeM,pststartmonth,'pst======')

			// pst end date

			var todaypst = new Date(parseInt(schedule.end_date) * 1000);
			var millispst = todaypst.getTime();

			var pstend = millispst - offsetMillis; 
			var currentDatepst = new Date(pstend).toUTCString();
			var cdatepst = new Date(currentDatepst)
			var pstendmonth = this.getMonthName(cdatepst.getMonth()+1);
			var pstendday = cdatepst.getUTCDate();
			var pstendTimeHour = cdatepst.getUTCHours();
			var pstendTimeMin = cdatepst.getUTCMinutes();

			var TimeMPST = "AM";
			if (pstendTimeHour > 12) {
				TimeMPST = "PM";
				pstendTimeHour = this.get12HourFormat(pstendTimeHour);
			}
			if (pstendTimeMin < 10) {
				pstendTimeMin = "0"+pstendTimeMin;
			}
			//console.log(currentDatepst,pstendday,pstendTimeHour,pstendTimeMin,TimeMPST,pstendmonth,'endpst======')

			// est start date

			// var offset = -5.0

			// var clientDate = new Date();
			// var utc = clientDate.getTime() + (clientDate.getTimezoneOffset() * 60000);
		
			// var serverDate = new Date(utc + (3600000*offset));
		
			// var day = serverDate.getDate();
			// var Hour = serverDate.getHours();
			// var Min = serverDate.getMinutes();
			// var TimeM = "AM";
			// if (Hour > 12) {
			// 	TimeM = "PM";
			// 	Hour = this.get12HourFormat(Hour);
			// }

			// //console.log(serverDate,day,Hour,Min,TimeM,'esttoday==================');

			var offset = -5.0

			var clientDateest = new Date(parseInt(schedule.start_date) * 1000);
			var utc = clientDateest.getTime() + (clientDateest.getTimezoneOffset() * 60000);
		
			var serverstartDate = new Date(utc + (3600000*offset));
			var eststartmonth = this.getMonthName(serverstartDate.getMonth()+1);
			var eststartday = serverstartDate.getDate();
			var eststartTimeHour = serverstartDate.getHours();
			var eststartTimeMin = serverstartDate.getMinutes();
			//console.log('est', eststartTimeMin )


			var eststartTimeM = "AM";
			if (eststartTimeHour > 12) {
				eststartTimeM = "PM";
				eststartTimeHour = this.get12HourFormat(eststartTimeHour);
			}
			if (eststartTimeMin < 10) {
				eststartTimeMin = "0"+eststartTimeMin;
			}
			//console.log(eststartmonth,eststartday,eststartTimeHour,eststartTimeMin,eststartTimeM,'eststarttime')

			// est end date 

			var clientDate = new Date(parseInt(schedule.end_date) * 1000);
			var utc = clientDate.getTime() + (clientDate.getTimezoneOffset() * 60000);
		
			var serverDate = new Date(utc + (3600000*offset));
			var estendmonth = this.getMonthName(serverDate.getMonth()+1);
			var estendday = serverDate.getDate();
			var estendTimeHour = serverDate.getHours();
			var estendTimeMin = serverDate.getMinutes();

			var estendTimeM = "AM";
			if (estendTimeHour > 12) {
				estendTimeM = "PM";
				estendTimeHour = this.get12HourFormat(estendTimeHour);
			}
		
			if (estendTimeMin < 10) {
				estendTimeMin = "0"+estendTimeMin;
			}

			//console.log(estendmonth,estendday,estendTimeHour,estendTimeMin,estendTimeM,'est time')

        //var utcStartString = startDateObj.toUTCString();
        //var utcEndString = endDateObj.toUTCString();
        ////console.log('aa', utcStartString, utcEndString )
        var Difference_In_Time = todaypst.getTime() - today.getTime(); 
		// To calculate the no. of days between two dates 
		var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24); 

		//console.log('days', parseInt(Difference_In_Days));
        var scheduleCalendar = this.scheduleCalendar(today, todaypst);

		var ts = Math.floor(Date.now() / 1000);

		var endDateTimeStamp = schedule.last_enroll_date + ' ' + schedule.last_enroll_time
		var d = new Date(endDateTimeStamp);
		var endEnrollDate = Date.parse(d) / 1000;

		var datemonth;
		//console.log(this.state.timezone,'timezone')
		if(this.state.timezone === 'est'){
			datemonth = eststartmonth + " " + eststartday + " - " + estendmonth + " " + estendday
			var scheduleCalendar = this.scheduleCalendar(clientDateest, clientDate);
		} else if(this.state.timezone === 'pst'){
			datemonth = pststartmonth + " " + pststartday + " - " + pstendmonth + " " + pstendday
			var scheduleCalendar = this.scheduleCalendar(today, todaypst);
		} else {
			datemonth = istmonth + " " + istday + " - " + istendmonth + " " + istendday
			var scheduleCalendar = this.scheduleCalendar(startDateObj, endDateObj);
		}

		return (
			<div className="schedule-course clearfix">
				<div className="schedule-course-summary clearfix border-color-1">
						<div className="schedule-course-list">
							<div className="clearfix">
								<div className="visible-xs xs-title">
								</div>
								<div className="xs-caption">
									<div className="dates" style={{ fontVariantNumeric: "oldstyle-nums" }}>
										{/* {this.state.timezone !== 'pst' ? (this.state.timezone === 'ist' ? (this.state.timezone === 'est' ? eststartmonth+ " "+eststartday+" - " +estendmonth+" "+estendday : '') : istmonth + " " + istday + " - " + istendmonth + " " + istendday) :
										pststartmonth + " " + pststartday + " - " + pstendmonth + " " + pstendday} */}
										{datemonth}
									</div>
									<div className="batch">(Batch Details)</div>
									<a data-toggle="collapse" data-target={"#demo" + (k)} href="" aria-expanded="false" aria-controls={"schedule-" + (k)} class="more-details" rel="nofollow">
									More Details
									<i class="fa fa-caret-down"></i>
									</a>
								</div>
							</div>
						</div>
						<div className="schedule-course-list">
							<div className="clearfix">
								<div className="visible-xs xs-title">
								</div>
								<div className="xs-caption">
									<div className="schedule-delivery-type classroom" >
										Online
									</div>
									<div className="led">(Instructor Led)</div>
								</div>
							</div>
						</div>
						<div className="schedule-course-list-time">
							<div className="clearfix">
								<div className="visible-xs xs-title">
								</div>
								<div className="xs-caption">
									<div className="schedule-delivery-type ist" style={{ fontVariantNumeric: "oldstyle-nums" }}>
										
									
        								{/* <span className="pstTimeZone"  style={{ display : 'none'}}>{startTimeHourPST + ":" + startTimeMinPST + " " + startTimeMPST + " - " + endTimeHourPST + ":" + endTimeMinPST + " " + endTimeMPST} */}
										
										<span className="pstTimeZone"  style={{ display : 'none'}}>{eststartTimeHour+ ':' +eststartTimeMin+ ' ' +eststartTimeM + " - " + estendTimeHour+ ':' + estendTimeMin + ' ' +estendTimeM}
										<span className="time-zone">(PST)</span></span>

										{/* <span className="istTimeZone">{startTimeHour + ":" + startTimeMin + " " + startTimeM + " - " + endTimeHour + ":" + endTimeMin + " " + endTimeM} */}
										
										{/* <span className="istTimeZone">{iststarttimeA+ ' ' +iststartTimeM + " - " + istendtimeA+ ' ' +istendTimeM}
										<span className="time-zone">(IST)</span></span> */}

										
										{/* <span className="istTimeZone">{iststarttimeA+ ' ' +iststartTimeM + " - " + istendtimeCount+ ' ' +istendTimeM}
										<span className="time-zone">(IST)</span></span> */}

										<span className="istTimeZone">{hoursIST + ":" + minutesIST + " " +iststartTimeM + " - " + hoursendIST + ":" + minutesendIST + " " +istendTimeM}
										<span className="time-zone">(IST)</span></span>
										
										{/* <span className="estTimeZone" style={{ display : 'none'}}>{startTimeHourEST + ":" + startTimeMinEST + " " + startTimeMEST + " - " + endTimeHourEST + ":" + endTimeMinEST + " " + endTimeMEST} */}
										
										<span className="estTimeZone" style={{ display : 'none'}}>{pststartTimeHour+ ':' + pststartTimeMin+' ' +TimeM+ " - " + pstendTimeHour+ ':' +pstendTimeMin+ ' '+TimeMPST}
										<span className="time-zone">(EST)</span></span>

										<br/>
									</div>
									<div></div>
								</div>
							</div>
						</div> 
						{/* <div className="schedule-course-list-price mobileInrRupeebtn">
							<div className="clearfix">
								<div className="visible-xs xs-title">
								</div>
								<div className="xs-caption">
									<span className="schedule-price" style={{ fontVariantNumeric: "oldstyle-nums" }}>INR {schedule.price}</span>
									
								</div>
							</div>
						</div> */}

						
						{/* <div className="schedule-course-list mobileInrRupeebtn">
							<div className="clearfix">
								<div className="visible-xs xs-title">
								</div>
								<div className="xs-caption">
									<span>
										
										{ schedule.demo_link ? <Link to={{
						                      pathname: '/externalLink',
						                      externalUrl: schedule.demo_link
						                    }}><button className="demoBtn">
						                    Demo
						                    </button>
                    					</Link> : <button  className="demoBtnDisabled" disabled>
						                    Demo
											</button> }


									</span>
								</div>
							</div>
						</div> */}


						<div className="schedule-course-list btnMobile">
							<div className="clearfix">
								<div className="visible-xs xs-title">
								</div>
								<div className="xs-caption">
									<div>

											{schedule.isurl === '1' ? ( ts > endEnrollDate ? <a href={schedule.enroll_link} target="_blank">
											<button className="demoBtn"  onClick={this.onOpenEnrollBtn} disabled>
												Enroll closed
											</button>
											</a> : <a href={schedule.enroll_link} target="_blank">
											<button className="demoBtn"  onClick={this.onOpenEnrollBtn}>
												Enroll Now
											</button>
											</a> ) : ( ts > endEnrollDate ? <button className="demoBtn" onClick={() => this.onOpenEDModal(2)} disabled>
												Enroll closed
											</button> : <button className="demoBtn" onClick={() => this.onOpenEDModal(2)}>
												Enroll Now
											</button>
											)
											}
								
									{/* {
										ts > schedule.end_date ? <a href={schedule.enroll_link} >
										<button className="demoClosedBtn" disabled>
											Closed
										</button>
									</a> : <a href={schedule.enroll_link} target="_blank">
											<button className="demoBtn"  onClick={this.onOpenEnrollBtn}>
												Enroll Now
											</button>
											</a>
									}	 */}
									</div>
								</div>
							</div>
						</div>
				</div>                                        										
				<div className={"hidden-xs  schedule-border in collapse " + ("schedule-"+k)} id={"demo" + (k)} aria-expanded="false">
					<div className="schedule-training-dates position-relative">
						<div className="row">
						<div className="col-md-1"></div> 
							<div className="col-md-6 col-sm-6" align="center">
								<div className="training-dates">
									<div className="training-heading clearfix">
										<h3 className="text-center"	>
											<span>Training Dates</span>
										</h3>
									</div>

									
									<div className="bx-wrapper" style={{ maxWidth: "300px", margin: "0px auto" }}>
										<div className="bx-viewport" style={{ width: "100%", position: "relative", height: "58px" }}>
										{/*
											<div className="training-dates-overview bxslider" style={{ width: "auto" , position: "relative", transitionDuration: "0s", transform: "translate3d(0px, 0px, 0px)" }}>

												<div className="training-dates-list row" style={{ float: "none", listStyle: "none", position: "relative", left:"25px" }}>
													<div className="col-2">
														<div className="schedule-dates">
															<div className="training-week">Sat</div>
															<div className="training-date" style={{ fontVariantNumeric: "oldstyle-nums" }}>19</div>
															<div className="training-month">Oct</div>
														</div>
													</div>
													<div className="col-2">
														<div className="schedule-dates">
															<div className="training-week">Sun</div>
															<div className="training-date" style={{ fontVariantNumeric: "oldstyle-nums" }}>20</div>
															<div className="training-month">Oct</div>
														</div>
													</div>
													<div className="col-2">
														<div className="schedule-dates">
															<div className="training-week">Sun</div>
															<div className="training-date" style={{ fontVariantNumeric: "oldstyle-nums" }}>20</div>
															<div className="training-month">Oct</div>
														</div>
													</div>
													<div className="col-2">
														<div className="schedule-dates">
															<div className="training-week">Sun</div>
															<div className="training-date" style={{ fontVariantNumeric: "oldstyle-nums" }}>20</div>
															<div className="training-month">Oct</div>
														</div>
													</div>
												</div>
											</div>
*/}
{scheduleCalendar}
										</div>
										{/* <div className="bx-controls bx-has-controls-direction">
											<div className="bx-controls-direction">
												<a className="bx-prev disabled" href=""><i className="fa fa-angle-up"></i></a>
												<a className="bx-next disabled" href=""><i className="fa fa-angle-down"></i></a>
											</div>
										</div> */}
									</div>
								</div>
							</div>

							{/* <div className="col-md-1"></div> */}
							
							<div className="col-md-5 col-sm-5" style={{ textAlign: "center" }}>
								<div className="classroom-details">
									<h4 style={{ marginTop: '5px', fontSize: '19px' }}>Instructor</h4>
									<img src={LogoSr} class="course-img" />
									<div className="classroom-details-info" style={{ display: "-webkit-inline-box",marginTop: "10px",fontWeight: "600"}}>
										<span className="name">Name</span> : <span className="classroom-trainer">{schedule.trainer} </span>
										<span className="classroom-trainer-info info it"></span>
									</div>
								</div>
							</div>
							{/*
							<div className="col-md-3 col-sm-3" style={{ textAlign: "center" }}>
								<div className="classroom-venue-details classroom-details">
									<address>
										<h4 className="venue">Venue</h4>
										<i className="fa fa-map-marker" aria-hidden="true"></i>
										<span className="classroom-venue text-center" style={{ fontVariantNumeric: "oldstyle-nums" }}>
											KnowledgeHut Solutions Pvt. Ltd. NO 10, 14th Main Road, Sector 5, HSR Layout, Bangalore - 560102 India
										</span>
									</address>
								</div>
							</div>
							*/}
						</div>
						<a data-toggle="collapse" href={".schedule-" + (k)} aria-expanded="true" aria-controls={"schedule-" + (k)} class="position-absolute right-top color-4 more-details" rel="nofollow">
							<i className="fa fa-times"></i>
						</a> 
					</div>
				</div>
			</div>
		)
	}

	getFAQ(faq, i) {
		////console.log('faqs', faq);
		/*
		  var res = faq.answers.split("[");
		  var line1 = res[0];

		  var line2 = res[1];
		  var answerBulletArray = [];
		   if (undefined !== line2) {
			   var answerBullet = line2.split("|");
				
				if (answerBullet.length) {
					for (var j=0; j <  answerBullet.length;j++) {
						////console.log('keydf', keyWords[i]);
						answerBulletArray.push(
						<p style={{ padding: "5px 20px" , margin: '1px'}}><span  style={{ color: 'black', marginRight: '4px', fontSize: '6px' }}><i className="fa fa-circle" style={{ position
		:'relative', top: '-2px' }} aria-hidden="true"></i></span> {answerBullet[j]}</p>

										);
					}
				}
			}
			*/

			////console.log('answerArray', answerBulletArray)

		return (
			<div className="panel">
		        <div className="card-header collapsed faqHeader" data-toggle="collapse" href={"#faq" + (i)}>
		            <b><a className="card-title">
		                <span style={{ display: 'inline-block' }}>{ReactHtmlParser(faq.questions)}	</span>  
						{/* <i className="fa fa-chevron-down" style={{ float: 'right' }} aria-hidden="true"></i> */}
		            </a></b>
		        </div>
		        <div id={"faq" + (i)} className={"collapse faqBody " + ( (i == 1) ? "show" : "")} data-parent="#faqaccordion" >
		            <p style={{ padding: "10px 10px 10px 22px",textAlign:'justify' }}>{ReactHtmlParser(faq.answers)}</p>
		        </div>
			</div>
		)
	}

	getEnrollData(s) {

	
	var d = new Date(s.start_IST);
  	var month = this.getMonthName(d.getMonth()+1);
  	var day = d.getDate();
  	var startTimeHour = d.getHours();
  	var startTimeMin = d.getMinutes();
  	var startTimeM = "AM";

	if (startTimeHour > 12) {
		startTimeM = "PM";
		startTimeHour = this.get12HourFormat(startTimeHour);
	}

	if (startTimeMin < 10) {
		startTimeMin = "0"+startTimeMin;
	}

	if (null == s.enroll_link) {
		s.enroll_link = "https://skillrary.com";
	}
		//console.log('ss',s)
		var startDateObj = new Date(parseInt(s.start_date) * 1000);
		//console.log('aadate', startDateObj.toLocaleString("en-US", {timeZone: "Asia/Kolkata"}))
		var str = startDateObj.toLocaleString("en-US", {timeZone: "Asia/Kolkata"})
		var res = str.split(",");
		//console.log('rrr',res.length, res[res.length-1].substr(0,5)); 
		var timeA = res[res.length-1].substr(0,5);
		var startTimeM = res[res.length-1].split(" ")[2];
		////console.log('timeb',timeB)

		return (<div className="row">
							<div className="col-md-7">
								<p className="dateBox">{day}<br/>{month}</p>
								{/*<p className="timeText">{startTimeHour+":"+startTimeMin+" "+startTimeM} IST (GMT +5:30)</p>*/}
								<p className="timeText">{timeA+" "+startTimeM} IST (GMT +5:30)</p>
							</div>
							<div className="col-md-5">
								{/* <button className="enrollnow"><a href={s.enroll_link} target="_blank">Enroll now</a></button> */}
								{s.enroll_link != "" ? (<button className="enrollnow"><a href={s.enroll_link} target="_blank">Enroll now</a></button>):(<button className="demoBtn"  onClick={() => this.onOpenEDModal(2)} >
												Enroll Now
											</button>)}
								{/*<button className="demoBtn"  onClick={() => this.onOpenEDModal(2)} >
												Enroll Now
											</button>*/}
							</div>
						</div>);
	}

	// openModalDownload = () => {
	// 	this.setState({openDownload : true})
	//   }
	// onCloseModalDownload = () => {
	// 	this.setState({openDownload : false})
	// }

removeTags(str) {
	if ((str===null) || (str===''))
	return false;
	else
	str = str.toString();
	return str.replace( /(<([^>]+)>)/ig, '');
}

	render(){
		const {description, FAQ, curriculum, keyWord, schedule, whatlearn, familiar} = this.state;

		var faqData = [];
		var i = 1;

		//console.log('faq', FAQ)
		if (undefined !== FAQ && FAQ.length > 0) {
			var self = this;
			Object.keys(FAQ).map(function(keyName, keyIndex) {
	          ////console.log('adf', keyIndex);
	          faqData.push(self.getFAQ(FAQ[keyName], i));
	          i++;
	        })
        }

		var sch = schedule;
		//console.log(sch,'schedule')
		var schArray = [];
		if (sch.length) {
			for (var i=0; i <  sch.length;i++) {
				//console.log('sch', sch[i].start_date);

				var todayDate = new Date().getTime();

				var endDate = new Date(sch[i].end_IST).getTime();
								
				var tssidebar = Math.floor(Date.now() / 1000);
				//console.log(tssidebar,'schedule=====')

				var endDateTimeStamp = sch[i].last_enroll_date + ' ' + sch[i].last_enroll_time
				var d = new Date(endDateTimeStamp);
				var endEnrollDatesidebar = Date.parse(d) / 1000;
				//console.log(endEnrollDatesidebar,'endenroll')
				//console.log(tssidebar > endEnrollDatesidebar ? true : false,'tf')

				if (tssidebar > endEnrollDatesidebar) {
					continue;
				}
  
  				if ( endDate > todayDate) {
  					schArray.push(this.getEnrollData(sch[i]));
  				}
			}
		
		}
/*
		 var keyWords = keyWord.split(",");
		 //console.log('keyw', this.removeTags(keyWords))

 		var keyWordArray = [];
 		if (keyWords.length) {
 			for (var i=0; i <  keyWords.length;i++) {
 				////console.log('keydf', keyWords[i]);
 				keyWordArray.push(<div className="col-md-4 col-sm-6" id="sectionRowPadding">
 									<div className="bulbIcon">
 										<i className="fa fa-lightbulb-o" aria-hidden="true" />
 									</div>
 									<div className="pTagContentScroll">
 										<p>{this.removeTags(keyWords[i])}</p>                                          
 									</div>
 								</div>
 								);
 			}
 		}
*/

 var keyWordArray = ReactHtmlParser(keyWord);

		var whatLearnArray = whatlearn;

		//var whatlearns = whatlearn.split(",");
		//var whatLearnArray = [];
		/*if (whatlearns.length) {
			for (var i=0; i <  whatlearns.length;i++) {
				////console.log('keydf', keyWords[i]);
				whatLearnArray.push(<p style={{ padding: "5px 20px" , margin: '1px'}}><span  style={{ color: 'black', marginRight: '4px', fontSize: '6px' }}><i className="fa fa-circle" style={{ position
		:'relative', top: '-2px' }} aria-hidden="true"></i></span> { whatlearns[i]}</p>
								);
			}
		}*/

		var familiarArray = familiar;
		/*var familiars = familiar.split(",");
		var familiarArray = [];
		if (familiars.length) {
			for (var i=0; i <  familiars.length;i++) {
				////console.log('keydf', keyWords[i]);
				familiarArray.push(<p style={{ padding: "5px 20px" , margin: '1px'}}><span  style={{ color: 'black', marginRight: '4px', fontSize: '6px' }}><i className="fa fa-circle" style={{ position
		:'relative', top: '-2px' }} aria-hidden="true"></i></span> { familiars[i]}</p>
								);
			}
		}*/

		var curriculumData = [];
        var j =1;
        if (curriculum.length > 0) {
			var self = this;
			Object.keys(curriculum).map(function(keyName, keyIndex) {
	          ////console.log('adf', keyIndex);
	          curriculumData.push(self.getCurriculum(curriculum[keyName], j));
	          j++;
	        })
		}
		
		//var schedulee = this.getSchedule();
		var scheduleData = [];
        var k =1;
        if (schedule.length > 0) {
			var self = this;
			Object.keys(schedule).map(function(keyName, keyIndex) {
			  ////console.log('adf', schedule[keyName]);
			  var tssidebar = Math.floor(Date.now() / 1000);
			  ////console.log(tssidebar,'schedule=====')

			  var endDateTimeStamp = schedule[keyName].last_enroll_date + ' ' + schedule[keyName].last_enroll_time

			  var d = new Date(endDateTimeStamp);
			  var endEnrollDatesidebar = Date.parse(d) / 1000;

			 //var tssidebar = new Date().setDate(new Date(schedule[keyName].start_date).getDate()+15);



		 console.log(endEnrollDatesidebar,'endenroll')
			  var a = (schedule[keyName].start_IST);
			 
			  var b = new Date(a).getTime();

			  var c = new Date(a).setDate(new Date(a).getDate()+15)
			  var d = new Date().getTime();
			  //console.log('sd',b, c, tssidebar, d)
			  
			  

			//   console.log(typeof(tssidebar),'tf',new Date(Number(a)).setDate(new Date(Number(a)).getDate()+5))

			  //if ( d < c) {
				  
				scheduleData.push(self.getSchedule(schedule[keyName], k));
				k++;
			  //}
	        })
		}

		const { openEnroll } = this.state;
		// const { openDownload } = this.state;
		const shareUrl = window.location.href;
		const shareUrlF = 'http://github.com';
		const title = 'GitHub';

		////console.log('timezone',this.state.timezone)
	

	return(
	<div>
	<style jsx>{ ` 	
		.enrollErrText{
			font-size: 12px;
			font-weight: normal;
			color: red;
			width: 300px;
			bottom: 0;
			left: inherit;
			top: 49px;
			position: absolute;
			z-index: 1;
		}
		.downloadSyllabus{
			top: -140px;
			position: relative;
			background: #33A478;
			padding: 9px;
			border: 1px solid #33A478;
		}
		.enrollSubBtn{
			background: #33A478;
			border: 1px solid #33A478;
			font-size: 16px;
			padding: 5px 15px;
			color: white;
			font-weight: 600;
			border-radius: 4px;
		}
		.enrollHeading{
			font-weight: 700;
			font-size: 16px;
			color: #333742;
			letter-spacing: 1.5px;
			position: relative;
			padding: 0px 0px 10px 0px;
			margin-bottom: 20px;
			text-align: center;
		}
		.enrollHeading:before{
			content: ' ';
			background: #33A478;
			box-shadow: 0 4px 8px 0 rgba(76,215,200,0.3);
			width: 15%;
			height: 4px;
			position: absolute;
			bottom: 0;
			left: 50%;
			transform: translate(-50%);
		}
		.enrollmodalWidth{
			width: 600px !important;
			margin-top: 50px;
		}
		button:focus{
			outline: none;
		}
		// .icon-bar{
		// 	// width: 1%;
		// 	// max-width: 60px;
		// 	// float: left;
		// 	display: none;
		// 	color: #33A478;
		// 	height: 50px;
		// 	position: -webkit-sticky;
		// 	position: absolute;
		// 	top: -50px;
		// 	left: 50%;
		// 	transform: translateX(-50%);
		// }
		.socialIcon {
			background: black !important;
			width: 62px;
			padding: 16px 16px  !important;
			color: #33A478 !important;
			display: inline-flex;
			transition: all 0.2s ease-in;
		}
		.socialIcon:hover{
			width: 110px;
			color: black !important;
			background: #33A478 !important;
			border-radius: 0px 5px 5px 0px;
		}
		.socialIcon:hover .socialText{
			opacity: 1;
			padding-left: 9px;
		}
		.socialText{
			opacity: 0;
			transition: all 0.1s ease-in;
		}
		// .shareButton{
		// 	// width: 50px;
		// 	// word-break: break-all;
		// 	padding: 10px 30px 12px 20px;
		// 	background: black;
		// 	color: #33A478;
		// 	border: 1px solid black;
		// 	font-size: 16px;
		// 	line-height: 18px;
		// 	// border-radius: 0px 14px 14px 0px;
		// 	position: -webkit-sticky;
		// 	position: sticky;
		// 	// float: left;
		// 	font-weight: 600;
		// }
		// .buttonShare:hover .icon-bar{
		// 	display: inline-flex;
		// }
		// #thumbnail {
		// 	display: block;
		// 	width: 150px;
		// 	height:42px;
		// }
		
		// #thumbnail:hover + .icon-bar {
		// 	display: inline-flex;
		// }
		.iconSize{
			font-size: 24px !important;
		}
		// .buttonDiv{
		// 	position: fixed;
		// 	bottom: 0px;
		// 	left: 50%;
		// 	transform: translateX(-50%);
		// 	z-index: 99;
		// }

 		.selectTimeZone{
 			top: -45px;
 			position: relative;
 			background: #33A478;
 			padding: 5px;
 			border-radius: 5px;
 			border: 1px solid #33A478;
 		}
 		.selectTimeZone:focus{
 			outline: none;
 		}
 		select option[value=""] {
 			background: white
 		}	  
 		select option[value="ist"] {
 			background: white
 		}
 		select option[value="est"] {
 			background: white
 		}
 		select option[value="pst"] {
 			background: white
 		}
		
 		// @keyframes zoominoutsinglefeatured {
 		// 	0% {
 		// 		transform: scale(1,1);
 		// 	}
 		// 	50% {
 		// 		transform: scale(1.2,1.2);
 		// 	}
 		// 	100% {
 		// 		transform: scale(1,1);
 		// 	}
 		// }
 		// .youImage{
 		// 	position: absolute;
 		// 	opacity: 0.5;
 		// 	height: 320px;
 		// 	width: 100%;
 		// 	border-radius: 100%;
 		// 	-webkit-animation: zoominoutsinglefeatured 3s infinite;
 		// 	animation: zoominoutsinglefeatured 3s infinite;
 		// 	max-width: 300px;
 		// }
 		// .bubbleCircle1{
 		// 	position: fixed;
 		// 	opacity: 0.5;
 		// 	height: 160px;
 		// 	width: 100%;
 		// 	border-radius: 100%;
 		// 	-webkit-animation: zoominoutsinglefeatured 3s infinite;
 		// 	animation: zoominoutsinglefeatured 3s infinite;
 		// 	top: 60%;
 		// 	left: 75px;
 		// 	max-width: 165px;
 		// 	background: linear-gradient(125deg, #33A478, #33A478 30%, black);
 		// 	// background-image: linear-gradient(to right, rgba(0,0,0), rgba(51,	164, 120) , rgba(0,0,0));
 		// }
 		// .bubbleCircle2{
 		// 	position: fixed;
 		// 	opacity: 0.5;
 		// 	height: 160px;
 		// 	width: 100%;
 		// 	border-radius: 100%;
 		// 	-webkit-animation: zoominoutsinglefeatured 3s infinite;
 		// 	animation: zoominoutsinglefeatured 3s infinite;
 		// 	top: 60%;
 		// 	ma	n-left: 71%;
 		// 	max-width: 165px;
 		// 	background: linear-gradient(125deg, #33A478, #33A478 30%, black);
 		// 	// background-image: linear-gradient(to right, rgba(0,0,0), rgba(51,	164, 120) , rgba(0,0,0));
 		// }
 		// .bubbleCircle3{
 		// 	position: absolute;
 		// 	opacity: 0.5;
 		// 	height: 100px;
 		// 	width: 100%;
 		// 	border-radius: 100%;
 		// 	-webkit-animation: zoominoutsinglefeatured 3s infinite;
 		// 	animation: zoominoutsinglefeatured 3s infinite;
 		// 	margin-top: -46px;
 		// 	margin-left: 34%;
 		// 	max-width: 96px;
 		// 	background: linear-gradient(125deg, #33A478, #33A478 30%, black);
 		// 	// background-image: linear-gradient(to right, rgba(0,0,0), rgba(51,	164, 120) , rgba(0,0,0));
 		// }
 		// .bubbleCircle4{
 		// 	position: absolute;
 		// 	opacity: 0.5;
 		// 	height: 69px;
 		// 	width: 100%;
 		// 	border-radius: 100%;
 		// 	-webkit-animation: zoominoutsinglefeatured 3s infinite;
 		// 	animation: zoominoutsinglefeatured 3s infinite;
 		// 	margin-top: 22px;
 		// 	margin-left: 54%;
 		// 	max-width: 70px;
 		// 	background: linear-gradient(125deg, #33A478, #33A478 30%, black);
 		// 	// background-image: linear-gradient(to right, rgba(0,0,0), rgba(51,	164, 120) , rgba(0,0,0));
		// }
		#mainCenter{
			margin-left: -40px;
		}
		.divStyle{
			height: 330px;
			overflow-y: auto;
			overflow-x: hidden;
		}
		.enrollnow{
			background: #33A478;
			border: 1px solid #33A478;
			padding: 4px 7px;
		}
		.enrollnow a{
			color: black;
			font-size: 15px;
		}
		.enrollnow a:hover{
			color: black;
			text-decoration: none;
		}
		.self-paced{
			color: #3F4752;
			font-size: 18px;
		}
		.rupees{
			color: #3F4752;
			font-size: 16px;
			font-weight: 600;
		}
		.life-time{
			color: #3F4752;
			font-size: 14px;
		}
		.timeText{
			color: #3F4752;
			font-size: 12px;
			font-weight: 600;
			padding-left: 50px;
		}
		.strikeMoney{
			color: black;
			font-size: 14px;
			text-decoration: line-through;
			margin-bottom: -2px;		
		}
		.dateBox{
			background: black;
			padding: 7px 8px;
			text-align: center;
			line-height: 16px;
			float: left;
			height: 45px;
		}
 		.icon-bar{
 			width: 100%;
 			max-width: 60px;
 			color: #33A478;
 			height: 350px;
 			position: -webkit-sticky;
 			position: sticky;
 			top: 20%;
 			float: left;
 		}
 		.icon-bar a {
 			display: block;
 			text-align: center;
 			padding: 16px;
 			transition: all 0.3s ease;
 			color: #33A478;
 			font-size: 20px;
 		}
 		.socialIcon {
 			background: black;
 			color: #33A478;
 		}
 		.socialIcon:hover {
 			background: #33A478;
 			color: black;
 		}
 		.card-header:after {
 			font-family: 'FontAwesome';  
 			content: "\\f077";
 			position: absolute;
 			right: 10px; 
 		}
 		.card-header.collapsed:after {
 			/* symbol for "collapsed" panels */
 			content: "\\f078";
 			position: absolute;
 			right: 10px;  
 		}
 		.errTextIndi{
 			font-size: 12px;
 			font-weight: normal;
 			color: red;
 			bottom: 0;
 			position: absolute;
 			z-index: 1;
 		}

 		.individualTab{
 			padding: 3px 10px;
 			outline: none;
 			border-radius: 20px 0px 0px 20px;
 			background: black;
 			color: #33a478;
 			border: 2px solid black;
 			font-size: 14px;
 			font-weight: 600;
 		}
 		.businessTab{
 			padding: 3px 10px;
 			outline: none !important;
 			border-radius: 0px 20px 20px 0px;
 			background: #33A478;
 			border: 2px solid black;
 			font-size: 14px;
 			font-weight: 600;
 			color: black;
 		}
 		.submitSideBtn{
 			background: black;
 			border: 1px solid black;
 			font-size: 14px;
 			padding: 1px 9px;
 			color: #33A478;
 			font-weight: 600;
 			border-radius: 4px;
 			margin-top: 13px;
 		}
 		.submitSideBtnBusiness{
 			background: black;
 			border: 1px solid black;
 			font-size: 14px;
 			padding: 1px 9px;
 			color: #33A478;
 			font-weight: 600;
 			border-radius: 4px;
 		}
 		.inputFieldSide{
 			font-size:15px;
 			border: 0;
 			outline: 0;
 			position: relative;
 			background: transparent;
 			border-bottom: 1px solid black;
 			-webkit-text-fill-color: #000000d1;
 			margin-top:23px;
 			display: flex;
 			width: 100%;
 		}
 		.inputFieldSideMsz{
 			font-size:15px;
 			border: 0;
 			outline: 0;
 			position: relative;
 			background: transparent;
 			border-bottom: 1px solid black;
 			-webkit-text-fill-color: #000000d1;
 			margin-top:23px;
 			display: flex;
 			width: 100% ;
 		}
 		textarea{
 			resize: none;
 		}
 		.cardForm{
 			background: white;
 			padding: 10px;
 			width: 100%;
 			height: 540px;
 			box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.16), 0 2px 3px 0 rgba(0,0,0,0.12);
 			/* max-width: 311px; */
 			min-width: 240px;
 		}
 		.sideForm{
 			width: 100%;
 			max-width: 350px;
 			color: #fff;
 			height: 487px;
 			position: -webkit-sticky;
 			position: sticky;
 			top: 20%;
 			float: right;
 			margin-right: 15px;
 		}
 		.name{
 			color: #33A478;
 		}
 		.listActive{
 			background-color: black;
 		}
 		.listActive .navigation__link{
 			color: #33A478;
 			font-weight: 500;
 		}
 		section{
 			min-height: 500px;
 		}

 		#Contact{
 			min-height: 1000px;
 		}
 		.icon-bar{
 		}

 		.navigation {
 			width: 80%;
 			margin-left: 2%;
 			background-color: #33A478;
 			color: #fff;
 			height: auto; 
 			overflow: auto;
 			position: -webkit-sticky;
 			position: sticky;
 			top: 20%;
 		}
 		.navigation__link {
 			display: block;
 			color: black;
 			text-decoration: none;
 			padding: 10px 20px;
 			font-weight: 400;
 			text-decoration: none;
 		}
 		.navigation__link:hover {
 			background-color: black;
 			text-decoration: none;
 			color: #33A478;
 			font-weight: 500;
 		}
 		ul.naivgationList{
 			list-style-type: none;
 			padding: 0;
 			margin: 0; 
 		}
 		.sectionContent {
			height: auto;
			text-align: justify;
 			padding-bottom: 30px;
 		}
 		#sectionRowPadding{
 			display: inline-flex;
 			height: 80px;
 		}
		.activeMenu {
 			color: #33A478;
 		    background-color: rgba(0, 0, 0, 0.83);
 		    font-weight: 600;
 		}
 		#sectionHeading{
 			font-weight: 700;
 		    font-size: 18px;
 		    color: #333742;
 		    letter-spacing: 1.5px;
 		    position: relative;
 		    padding: 0px 0px 10px 0px;
 		    margin-bottom: 20px;
 		}
 		#sectionHeading:before{
 			content: ' ';
 		    background: #33A478;
 		    box-shadow: 0 4px 8px 0 rgba(76,215,200,0.3);
 		    width: 44px;
 		    height: 4px;
 		    position: absolute;
 		    bottom: 0;
 		}
 		.pTagContentScroll{
 			padding-left: 20px;
 			margin-top: 10px;
 			font-size: 14px;
 		}
 		.bulbIcon{
 			font-size: 25px;
 			color: #33A478;
 		}
 		.descriptionPTag{
 			text-align: justify;
 		}
 		.curiculumHeader{
 			cursor: pointer;
 			background-color: #33A478 !important;
 			border-bottom: 1px solid rgba(0,0,0,.125) !important;
 		}
 		.curiculumCard{
 			border: 1px solid rgba(0,0,0,.125);
 		}
 		.faqCard{
 			border: none;
 		}
 		.faqHeader{
 			cursor: pointer;
 			background: none;
 			border-width: 0;
 			border-bottom: 5px solid #33a478;
 			border-left: 5px solid;
 		}
 		.faqBody{
 			border-bottom: 1px solid rgba(0,0,0,.125) !important;
 		}
 		.panel{
 			border-width: 1px 0 1px 0;
 			border-style: solid;
 			border-color: #e7e7e7;
 		}
 		.previewBtn{
 			font-size: 14px;
 			background-color: white;
 			padding: 5px 15px;
 			border: 2px solid #33A478;
 			border-radius: 30px;
 			font-weight: 500;
 			// top: 115px;
 			top: 20px;
 			left: 30px;
 			position: relative;
 		}
 		.previewBtn:focus{
 			outline: none;
 		}
 		.certificateImage{
 			width: 100%;
 			height: auto;
 			min-width: 400px;
 			max-width: 400px;		
 		}
 		.closeImage{
			background-color: #33A478 !important;
			padding: 0px 8px !important;
			opacity: 10 !important;
			position: absolute !important;
			border-radius: 100px !important;
			right: 8px !important;
			top: 2px !important;
			font-size: 20px;
			color: white !important;
			border: 2px solid #fff;
 		}
 		.closeImage:focus{
 			outline: none;
 		}
 		#imageBody{
 			top: 25%;
 			position: absolute;
 			left: -195px;
 			transform: translateY(25%);
 		}
 		.popupImage{
 			width: 100%;
 			height: 510px;
 			min-width: 850px;
 			align-items: center;
 			display: flex;
 			justify-content: center;
 		}
 		.modal{
 			background: #343a40de !important;
 			position: fixed;
 			top: 0;
 			left: 0;
 			z-index: 1069;
 			display: none;
 			width: 100%;
 			height: 100%;
 			overflow: hidden;
 			outline: 0;
 		}
 		@media only screen and (max-width: 400px){
			.enrollmodalWidth{
				width: 600px !important;
				margin-top: 50px;
			}
			#mainCenter{
				margin-left: initial;
			}
 			.sideForm{
 				display:none;
 			}
 			.icon-bar{
 				display:none;
 			}
 			.certificateImage{
 				width: 100%;
 				height: auto;
 				max-width: inherit;
 				min-width: auto;
 			}
 			.previewBtn {
 				font-size: 14px;
 				background-color: white;
 				padding: 5px 15px;
 				border: 2px solid #33A478;
 				border-radius: 30px;
 				font-weight: 500;
 				top: 27px;
 				left: 0px;
 				position: relative;
 			}
 			#imageBody{
 				top: 40% !important;
 				position: absolute !important;
 				left: 0px;
 				transform: translateY(40%) !important;
 			}
 			.popupImage{
 				width: 100%;
 				height: 350px;
 				align-items: center;
 				min-width: 280px;
 				display: flex;
 				justify-content: center;
 			}
 		}
 		@media only screen and (min-width: 400px) and (max-width : 600px) {
			.enrollmodalWidth{
				width: 600px !important;
				margin-top: 50px;
			}
			 #mainCenter{
				 margin-left: initial;
			 }
 			.sideForm{
 				display:none;
 			}
 			.icon-bar{
 				display:none;
 			}
 			.certificateImage{
 				width: 100%;
 				height: auto;
 				max-width: inherit;
 				min-width: auto;
 			}
 			.previewBtn {
 				font-size: 14px;
 				background-color: white;
 				padding: 5px 15px;
 				border: 2px solid #33A478;
 				border-radius: 30px;
 				font-weight: 500;
 				top: 27px;
 				left: 0px;
 				position: relative;
 			}
	
 			#imageBody{
 				top: 40% !important;
 				position: absolute !important;
 				left: 0px;
 				transform: translateY(40%) !important;
 			}
 			.popupImage{
 				width: 100%;
 				height: 400px;
 				align-items: center;
 				min-width: 357px;
 				display: flex;
 				justify-content: center;
 			}
 		}
 		@media only screen and (min-width : 768px) and (max-width : 1024px)  {
			.enrollmodalWidth{
				width: 600px !important;
				margin-top: 50px;
			} 
			#mainCenter{
				margin-left: initial;
			}
 			.selectTimeZone {
 				top: -25px;
 				position: relative;
 				background: #33A478;
 				padding: 5px;
 				border-radius: 5px;
 				border: 1px solid #33A478;
 			}
 			.sideForm{
 				display:none;
 			}
 			.navigation {
 				width: 100%;
 				margin-left: 2%;
 				background-color: #33A478;
 				color: #fff;
 				height: 25vh; 
 				min-height: 225px;
 				overflow: auto;
 				position: -webkit-sticky;
 				position: sticky;
 				top: 20%;
 			}
 			#imageBody {
 				top: 25% !important;
 				position: absolute !important;
 				left: -145px;
 				transform: translateY(25%) !important;
 			}
 			.popupImage {
 				width: 100%;
 				height: 510px;
 				min-width: 750px;
 				align-items: center;
 				display: flex;
 				justify-content: center;
 			}
 		}
 		// course schedule csss start
 		.schedule-courses .container {
 			padding-top: 20px;
 			padding-bottom: 40px;
 		}

 		.headingTable{
 			color: white;
 			font-weight: 600;
 		}
      
 		.schedule-captions {
             margin-bottom: 20px;
             text-align: center;
 			background-image: linear-gradient(to right, rgba(0,0,0), rgba(51,	164,	120) , rgba(0,0,0));			border-top-left-radius: 3px;
 			border-top-right-radius: 3px;
 			width: 100%;
 			display: block;
 			height: 50px;
 			overflow: hidden;
 			line-height: 50px;
 			color: white;
 		}

 		.schedule-captions .schedule-captions-list {
             float: left;
             padding: 0px 0px;
             width: 20%;
             color: white;
             font-size: 15px;
             font-weight: 700;
             border-right: 0 none;
 		}
 		.schedule-captions .schedule-captions-list-price {
             float: left;
             padding: 0px 0px;
             width: 10%;
             color: white;
             font-size: 15px;
             font-weight: 700;
             border-right: 0 none;
 		}
 		.schedule-captions .schedule-captions-list-time {
             float: left;
             padding: 0px 0px;
             width: 30%;
             color: white;
             font-size: 15px;
             font-weight: 700;
             border-right: 0 none;
 		}
 		.time-zone{
 			margin: 0;
 			padding: 0;
 			border: 0;
 			font-size: 100%;
 			font: inherit;
 		}

 		.valid-till {
 			position: relative;
 			font-size: 12px;
 			font-weight: 400;
 			color: grey;
 		}

 		.schedule-course:hover {
 			box-shadow: 0px 2px 4px lightgrey;
 			background-color: #fff;
 		}

 		.btn:hover {
 			box-shadow: 0px 4px 6px lightgrey;
 			background-color: #33A478;
 		}

 		.schedule-course {
 			background-color: #fff;
 			margin-bottom: 20px;
 		}

 		.schedule-course {
 			border-bottom-left-radius: 3px !important;
 			overflow: hidden;
 			border-bottom-right-radius: 3px !important;
         }
      
 		.border-color-1 {
 			border: 1px solid black;
 			border-radius: 3px;
 		}
 		 .schedule-course-list .xs-caption .dates {
 			line-height: 24px;
 			font-size: 13px;
 		}

 		.schedule-course-list .xs-caption .batch {
 			font-size: 12px;
 			font-weight:500;
 		}
		
 		.schedule-course-list .xs-caption .more-details {
 			font-size: 12px;
 			color: #58595B;
 			display: block;
 			margin-top: 5px;
 			font-weight:500;
 		}

 		.schedule-course-list .xs-caption .more-details i {
 			color: #33A478;
 			margin-left: 10px;
 		}

 		.more-details i {
 			pointer-events: none;
 		}

 		.schedule-training-dates {
             padding-top:25px;
             padding-right: 15px;
 		}

 		.training-heading h3 {
             margin-top: 9px;
             font-size: 18px !important;
             color: #000 !important;
             height: 2px;
             position: relative;
             margin-bottom: 5px;
             font-weight: 400;
 		}

 		.training-heading h3>span {
 			background: #fff none repeat scroll 0 0;
 			display: inline-block;
 			left: 50%;
 			padding: 0 10px;
 			position: absolute;
 			top: 50%;
 			transform: translate(-50%,-50%);
 		}

 		.training-dates .bx-wrapper {
 			overflow-y: scroll;
 			overflow-x: hidden;
 			width: 100% !important;
 			margin-top: 20px !important;
 			margin-bottom: 20px !important;
 			max-width: 100% !important;
 			height: 300px;
 		}

 		.bx-wrapper {
 			position: relative;
 			padding: 0;
 		}

 		.training-dates .bx-wrapper .bx-viewport {
 			background: transparent;
 			border: 0 none;
 			box-shadow: none;
 			left: auto;
 			transform: none;
 			height: 210px !important;
 		}

 		.training-dates-overview {
 			margin-left: -4px;
 			margin-right: -5px;
 		}

 		.training-dates-list.row {
 			margin-left: 0px;
 			margin-right: 0px;
 			margin-bottom: 16px;
 			width: 100% !important;
 		}

 		.training-dates-list .col-2 {
 			float: left;
 			width: 20%;
 			padding: 0 5px;
 		}

 		.col-2 .schedule-dates {
 			border: 1px solid #4D5967;
 			border-radius: 3px;
 			text-align: center;
 			padding: 2px 0 4px;
 		}

 		.training-week {
 			font-size: 12px;
 			color: #4D5967;
 		}

 		.training-date {
 			font-size: 25px;
 			color: #4D5967;
 			line-height: 26px;
 		}

 		.training-month {
 			font-size: 12px;
 			color: #4D5967;
 			text-transform: uppercase;
 		}

 		.training-dates-list .col-2 {
 			float: left;
 			width: 20%;
 			padding: 0 5px;
 		}

 		.col-2 .schedule-dates {
 			border: 1px solid #4D5967;
 			border-radius: 3px;
 			text-align: center;
 			padding: 2px 0 4px;
 		}

 		.training-week {
 			font-size: 12px;
 			color: #4D5967;
 		}

 		.training-date {
 			font-size: 25px;
 			color: #4D5967;
 			line-height: 26px;
 		}

 		.training-month {
 			font-size: 12px;
 			color: #4D5967;
 			text-transform: uppercase;
 		}

 		.training-dates-list .col-2 {
 			float: left;
 			width: 20%;
 			padding: 0 5px;
 		}

 		.col-2 .schedule-dates {
 			border: 1px solid #4D5967;
 			border-radius: 3px;
 			text-align: center;
 			padding: 2px 0 4px;
 		}

 		.training-week {
 			font-size: 12px;
 			color: #4D5967;
 		}

 		.training-date {
 			font-size: 25px;
 			color: #4D5967;
 			line-height: 26px;
 		}

 		.training-month {
 			font-size: 12px;
 			color: #4D5967;
 			text-transform: uppercase;
 		}

 		.training-dates-list .col-2 {
 			float: left;
 			width: 20%;
 			padding: 0 5px;
 		}

 		.col-2 .schedule-dates {
 			border: 1px solid #4D5967;
 			border-radius: 3px;
 			text-align: center;
 			padding: 2px 0 4px;
 		}

 		.training-week {
 			font-size: 12px;
 			color: #4D5967;
 		}

 		.training-date {
 			font-size: 25px;
 			color: #4D5967;
 			line-height: 26px;
 		}

 		.training-month {
 			font-size: 12px;
 			color: #4D5967;
 			text-transform: uppercase;
 		}

 		.training-dates .bx-wrapper .bx-controls-direction a.disabled {
 			display: block;
 			opacity: 0.3;
 		}

 		.training-dates .bx-wrapper .bx-controls-direction a {
 			bottom: -30px;
 			height: auto;
 			margin-top: 0;
 			text-indent: initial;
 			top: auto;
 			width: auto;
 			color: #333;
 		}

 		.training-dates .bx-wrapper .bx-prev {
 			z-index: 0 !important;
 		}

 		.bx-wrapper .bx-controls-direction a {
 			position: absolute;
 			outline: 0;
 		}

 		.bx-wrapper .bx-prev {
 			background: url(images/controls.png) 0 -32px no-repeat;
 		}

 		.training-dates .bx-wrapper .bx-controls-direction a i {
 			font-size: 32px;
 		}

 		.training-dates .bx-wrapper .bx-controls-direction a.disabled {
 			display: block;
 			opacity: 0.3;
 		}
		
 		.training-dates .bx-wrapper .bx-controls-direction a {
 			bottom: -30px;
 			height: auto;
 			margin-top: 0;
 			text-indent: initial;
 			top: auto;
 			width: auto;
 			color: #333;
 		}

 		.training-dates .bx-wrapper .bx-next {
 			z-index: 0 !important;
 		}

 		.bx-wrapper .bx-controls-direction a {
 			position: absolute;
 			outline: 0;
 		}

 		.bx-wrapper .bx-next {
 			background: url(images/controls.png) -43px -32px no-repeat;
 		}

 		.training-dates .bx-wrapper .bx-controls-direction .fa.fa-angle-up {
 			font-size: 32px;
 			position:relative;
 			left:150px;
 			bottom:5px;
 		}

 		.training-dates .bx-wrapper .bx-controls-direction .fa.fa-angle-down{
 			font-size: 32px;
 			position:relative;
 			left:170px;
 			bottom:5px;
 		}
 		.schedule-course-list .xs-caption .schedule-delivery-type {
 			line-height: 24px;
 			text-transform: capitalize;
 			font-size: 14px;
 		}

 		.schedule-course-list .xs-caption>div {
 			font-size: 12px;
 			font-weight:500;
 		}

 		.schedule-course .schedule-course-summary .schedule-course-list {
             float: left;
             text-align: center;
 			font-size: 14px;
 			font-weight: 500;
 			color: #3F4752;
 			padding: 0px 0px !important;
 			min-height: auto !important;
 			width: 20%;
 		}
 		.schedule-course .schedule-course-summary .schedule-course-list-price {
             float: left;
             text-align: center;
 			font-size: 14px;
 			font-weight: 500;
 			color: #3F4752;
 			padding: 0px 0px !important;
 			min-height: auto !important;
 			width: 10%;
 		}
 		.schedule-course .schedule-course-summary .schedule-course-list-time {
             float: left;
             text-align: center;
 			font-size: 14px;
 			font-weight: 500;
 			color: #3F4752;
 			padding: 0px 0px !important;
 			min-height: auto !important;
 			width: 30%;
 		}
 		.schedule-course-list .xs-caption .schedule-price {
 			display: block;
 			line-height: 24px;
 			letter-spacing: 0.3px;
 			font-size:16px;
 		}
 		.text-center {
 			text-align: center;
 		}

 		.schedule-course-list .btn-success {
 			min-width: 100px;
 			padding: 0px;
 			font-size: 14px;
 			margin-right: 0px;
 			font-weight: 700;
 			position: relative;
 			margin-top: -55px;
 			margin-left: 219px;
 		}

 		.waves-effect {
 			position: relative;
 			cursor: pointer;
 			display: inline-block;
 			overflow: hidden;
 			user-select: none;
 			-webkit-tap-highlight-color: transparent;
 			vertical-align: middle;
 			z-index: 1;
 			transition: all 0.3s ease-out;
 		}

 		.btn-success {
 			height: 33px;
 			line-height: 33px !important;
 			background: #33A478;
 			border-radius: 3px;
 			color: #fff !important;
 			letter-spacing: 0px;
 			border: 0 none;
 		}

 		.text-uppercase {
 			text-transform: none!important;
 		}

 		.course-img {
 			width: 115px;
 			height: 115px;
 			margin: 18px auto 0;
 			display: block;
 			border-radius: 100%;
 			position: relative;
 		}

 		.classroom-trainer {
 			position:relative;
 			font-weight: 700;
 			// font-size: 14px;
 			// display: block;
 			// margin-top: 15px;
 			color: #24272f;
 		}

 		.classroom-trainer-info, .classroom-venue {
 			color: #3f4752;
 			font-size: 13px;
 			line-height: 18px;
 			font-weight: 400;
 			padding-left: 0px;
 			position: relative;
 			margin-top: 15px;
 			display: inline-block;
 		}

 		.linkBtn{
 			background: #33A478;
 			color: white;
 			border: 1px solid #33A478;
 			padding: 3px 10px 3px 10px;
 			border-radius: 3px;
 			font-weight: 600;
 			font-size: 15px;
 			margin-top: 4px;
 		}
 		.classroom-trainer-info{
 			color: #3f4752;
 			font-size: 13px;
 			line-height: 18px;
 			font-weight: 400;
 			padding-left: 0px;
 			position: relative;
 			margin-top: 15px;
 			display: inline-block;
 		}

 		.classroom-venue-details i {
 			position:relative;
 			font-size: 40px;
 			color: #3f4752;
 			height: 58px;
 			line-height: 58px;
 		}

 		.classroom-details h4 {
 			margin-bottom: 15px;
 		}

 		.schedule-training-dates h4 {
 			font-size: 18px;
 		}

 		.right-top {
 			top: 15px;
 			right: 15px;
 		}

 		.color-4, .color-4:hover, .color-4:focus {
 			color: #23563c !important;
 		}


 		.schedule-border {
 			border: 1px solid black;
 			border-top: 0;
 		}
 		.demoBtn{
 			margin-top: 17px;
 			color: white;
 			background: #33A478;
 			border: 1px solid #33a478;
 			padding: 5px 4px;
 			border-radius: 3px;
 			font-size: 14px;
 			font-weight: 600;
 			margin-left: 0px;
		 }
		 .demoClosedBtn{
			margin-top: 17px;
			color: white;
			background: #33A478;
			border: 1px solid #33a478;
			padding: 5px 17px;
			border-radius: 3px;
			font-size: 14px;
			font-weight: 600;
			margin-left: 0px;
		 }
         .demoBtnDisabled{
 			margin-top: 17px;
             color: white;
             background: #ccc;
             border: 1px solid #ccc;
             padding: 5px 10px;
             border-radius: 3px;
             font-size: 14px;
             font-weight: 600;
		 }
		 #courseDetailDiv{
			 margin-left: 55px;
		 }
         @media only screen and (max-width: 600px) {
			.enrollmodalWidth{
				width: 240px !important;
				margin-top: 50px;
			}
			#courseDetailDiv{
				margin-left: inherit;
			}
 			.schedule-course .schedule-course-summary .schedule-course-list-price {
 				float: left;
 				text-align: center;
 				font-size: 14px;
 				font-weight: 500;
 				color: #3F4752;
 				padding: 0px 0px !important;
 				min-height: auto !important;
 				width: 22%;			
 			}
             .schedule-captions {
                 display: none;
             }
             .schedule-course .schedule-course-summary .schedule-course-list {
 				float: left;
 				text-align: center;
 				font-size: 14px;
 				font-weight: 500;
 				color: #3F4752;
 				padding: 0px 0px !important;
 				min-height: auto !important;
 				width: 29%;
 				margin-bottom: 30px;
 				margin-left: 0px;
             }
             .mobileInrRupeebtn {
 				position: relative;
 				right: 55%;
 				top: 43px;
 			}
             .more-details{
 				position: absolute;
 				margin-top: 62px !important;
 				margin-left: 20px;
 				margin-bottom: -26px;
             }
             .btnMobile{
 				// margin-top: -80px;
 				margin-left: 95px;
             }
             .right-top {
                 top: -47px;
                 right: 15px;
             }
             .navigation {
 				display: none !important;
 			}
 			.selectTimeZone{
 				top: -20px;
 				position: relative;
 				background: #33A478;
 				padding: 5px;
 				border-radius: 5px;
 				border: 1px solid #33A478;
 			}
 			.demoBtn{
 				margin-top: 17px;
 				color: white;
 				background: #33A478;
 				border: 1px solid #33a478;
 				padding: 5px 4px;
 				border-radius: 3px;
 				font-size: 14px;
 				font-weight: 600;
 				// margin-left: 51px;
 				width: 100%;
 			}
		}
			@media only screen and (min-device-width : 768px) and (max-device-width : 1024px)  { 
				#courseDetailDiv{
					margin-left: inherit;
				}
			 }
			// course schedule csss end
			@media only screen and (min-width: 1024px) and (max-height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) {
				#courseDetailDiv{
					margin-left: inherit;
				}
			}
 		` }
 		</style>
		<div><Particles /></div>
		<div className="icon-bar">
 			{/* <a href="https://bit.ly/twitterSkillRary" className="socialIcon" target="_blank"><i className="fa fa-twitter"></i></a> 
 			<a href="https://bit.ly/FSKILLRARY" className="socialIcon" target="_blank"><i className="fa fa-facebook"></i></a> 
 			<a href="https://bit.ly/LISKILLRARY" className="socialIcon" target="_blank"><i className="fa fa-linkedin"></i></a>
 			<a href="https://bit.ly/InstaSKILLRARY" className="socialIcon" target="_blank"><i className="fa fa-instagram"></i></a> 
 			<a href="https://bit.ly/youtubeSkillRary" className="socialIcon" target="_blank"><i className="fa fa-youtube"></i></a> */}
			 
				<TwitterShareButton
						url={shareUrl}
						// title={title}
						className="socialIcon"
					>
						<i className="fa fa-twitter iconSize"></i><span class="socialText">Tweet</span>
				</TwitterShareButton>

				<FacebookShareButton
						url={shareUrl}
						// quote={title}
						className="socialIcon"
						target="_blank"
						>
						<i className="fa fa-facebook iconSize"></i><span class="socialText">Share</span>
				</FacebookShareButton>

				<LinkedinShareButton url={shareUrl} className="socialIcon">
					<i className="fa fa-linkedin iconSize"></i><span class="socialText">Share</span>
				</LinkedinShareButton>

				<WhatsappShareButton
						url={shareUrl}
					
						separator=":: "
						className="socialIcon"
					>
					<i className="fa fa-whatsapp iconSize" aria-hidden="true"></i><span class="socialText">Share</span>
				</WhatsappShareButton>

 		</div>

		{/* <div className="buttonShare buttonDiv">
			<button className="shareButton">Share Course</button>
				<div className="icon-bar">

				<TwitterShareButton
						url={shareUrl}
						// title={title}
						className="socialIcon"
					>
						<i className="fa fa-twitter iconSize"></i>
				</TwitterShareButton>

				<FacebookShareButton
						url={shareUrl}
						// quote={title}
						className="socialIcon"
						target="_blank"
						>
						<i className="fa fa-facebook iconSize"></i>
				</FacebookShareButton>

				<LinkedinShareButton url={shareUrl} className="socialIcon">
					<i className="fa fa-linkedin iconSize"></i>
				</LinkedinShareButton>

				<WhatsappShareButton
						url={shareUrl}
					
						separator=":: "
						className="socialIcon"
					>
					<i className="fa fa-whatsapp iconSize" aria-hidden="true"></i>
				</WhatsappShareButton>

				</div>
        </div> */}

			{scheduleData.length > 0 && ( tssidebar > endEnrollDatesidebar === false) ? <div className="sideForm">
			<div class="cardForm">
		 		<div className="container-fluid">
					 
				 {this.state.recordedVideoLink !== null ?  
				
				 	<div className="row">
						<div className="col-md-6">
							<p className="self-paced">Self-paced</p>
						</div>
						<div className="col-md-4 offset-md-2">
							{/*<p className="rupees"><i class="fa fa-inr" aria-hidden="true"></i> 8151</p>*/}
						</div>
					{/* </div>
					<div className="row"> */}
						<div className="col-md-6">
							<p className="life-time">Life time access and 24/7 support</p>
						</div>
						<div className="col-md-5 offset-md-1">
							<button className="enrollnow"><a href={this.state.recordedVideoLink} target="_blank">Enroll now</a></button> 
						</div>
					</div>
				: ""}

					<div className="row">
						<div className="col-md-8">
							<p className="self-paced">Online Classroom</p>
						</div>
						<div className="col-md-4">
							{/*<p className="strikeMoney"><i class="fa fa-inr" aria-hidden="true"></i> 720</p>
							<p className="rupees"><i class="fa fa-inr" aria-hidden="true"></i> 8151</p>*/}
						</div>
					</div>
					<div className="divStyle">
						{/* <div className="row">
							<div className="col-md-7">
								<p className="dateBox">5<br/>Jan</p>
								<p className="timeText">8 PM IST (GMT +5:30)</p>
							</div>
							<div className="col-md-5">
								<button className="enrollnow"><a href="https://www.skillrary.com/" target="_blank">Enroll now</a></button>
							</div>
						</div>

						<div className="row">
							<div className="col-md-7">
								<p className="dateBox">5<br/>Jan</p>
								<p className="timeText">8 PM IST (GMT +5:30)</p>
							</div>
							<div className="col-md-5">
								<button className="enrollnow"><a href="https://www.skillrary.com/" target="_blank">Enroll now</a></button>
							</div>
						</div> */}
				
						{schArray}
					</div>
				 </div>
			</div>
		</div> : '' }

		<div className="container-fluid">
			{/* <div className="row" id="courseDetailDiv"> */}
				<div class="row">
				{/* sidebar list starts */}
			
				<div className="col-md-3 offset-md-1">
					<nav className="navigation" id="mainNav">
						<ul className="naivgationList">
							<li><a className="navigation__link" href="#1">Key Features</a></li> 
							<li><a className="navigation__link" href="#2">Curriculum</a></li>
							{scheduleData.length > 0 && (<li><a className="navigation__link" href="#3">Course Schedule</a></li>)}
							{/* <li><a className="navigation__link" href="#3">Description</a></li> */}
							<li><a className="navigation__link" href="#4">Scope</a></li>
							<li><a className="navigation__link" href="#5">Course Overview</a></li>
							<li><a className="navigation__link" href="#6">What you will learn</a></li>
							<li><a className="navigation__link" href="#7">What you need to be familiar with?</a></li>
							
							<li><a className="navigation__link" href="#8">Certification</a></li>
							{   this.state.whyCourseImg != null && 
							this.state.impOfCourse.length > 0 &&
							this.state.avgSalary.length > 0 &&
							this.state.jobOpen.length > 0 &&
							this.state.jobHiring.length > 0 &&
							this.state.studentNumber.length > 0 &&
							<li><a className="navigation__link" href="#9">Salary Trend</a></li>
							}
							{faqData.length > 0 && (<li><a className="navigation__link" href="#10">FAQs</a></li>)}
						</ul>
					</nav>
				</div>

				{/* sidebar list ends */}

				<div className="col-md-8" id="mainCenter"  style={{backgroundColor:"white"}}>

				{/* key features starts */}

				 <div className="page-section sectionContent" id="1"><br/><br/><br/>
 					<h2 id="sectionHeading">Key Features</h2>
					
 					{keyWordArray}
 				</div>  

 				{/* key features ends */}

 				{/* curiculum starts  */}

 				<div className="page-section sectionContent" id="2"><br/><br/><br/>
 					<h2 id="sectionHeading">Curriculum</h2>
					<div align="center">
						<button className="downloadSyllabus" onClick={() => this.onOpenEDModal(1)}>Download Syllabus</button>
					</div>
 					<div className="container">
 					    <div id="accordion">
 					        <div className="card curiculumCard mb-0">
 					        {curriculumData}
 					        </div>
 					    </div>
 					</div>
 				</div>

 				{/* curiculum ends  */}

				{/* course schedule starts  */}

				{scheduleData.length > 0 ? ( 
 				<div className="page-section sectionContent" id="3"><br/><br/><br/>
 					<h2 id="sectionHeading">Course Schedule</h2>
					{/* <div align="center">
						<button className="downloadSyllabus" onClick={() => this.onOpenEDModal(1)}>Download Syllabus</button>
						<Modal open={openDownload} onClose={this.onCloseModalDownload} closeOnOverlayClick={false} closeIconSize={22} styles={{ modal: { marginTop: '10%' }}}>
							<div className="enrollmodalWidth" >
								<h2 className="enrollHeading" style={{marginTop: '-50px'}}>Download Syllabus</h2>
								<div className="container">
									<div className="row">
										<div className="col-md-6 offset-md-3"  style={{ marginBottom: '-15px'}}>
											<div className="form-group">
												<input type="email" name="email" 
												className="inputField" placeholder="Email" autoComplete="off"
												/>
												<i className="fa fa-user" id="userLogo"></i>
												<span className="errText">{this.state.errors.first_name_quote}</span>
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-md-6 offset-md-3"  style={{ marginBottom: '-15px'}}>
											<div className="form-group">
												<input type="number" name="phone" 
												className="inputField" placeholder="Phone number" autoComplete="off"
												/>
												<i className="fa fa-phone" id="userLogo"></i>
												<span className="errText">{this.state.errors.last_name_quote}</span>
											</div>
										</div>
									</div><br/>
									<div align="center" style={{ justifyContent: "center" }}>
										<button className="enrollSubBtn">Submit</button>
									</div>
								</div>
							</div>
						</Modal>
					</div> */}
 					<div align="center">
 						<select className="selectTimeZone" onChange={this.onChangeTimeZone(this)}>
 							{/*<option value="" selected>Select Time Zone</option>*/}
							<option value='ist' selected>IST - Time Zone</option>
 							<option value='pst'>PST - Time Zone</option>
 							<option value='est'>EST - Time Zone</option>
 						</select>
 					</div>
 					<div className="schedule-courses">
 						<div className="container" >
 							{/* <div className="clearfix">
 								<a href="javascript:void(0);" className="pull-right filter-btn visible-sm visible-xs filter-xs">
 									<i className="filter-icon"></i>Filter
 								</a>
 							</div> */}
 							<div className="row">
 								<div className="col-md-12 schedule-calendar">
 									<div className="schedule-list">
 										<div className="schedule-captions">
 											<div className="schedule-captions-list">
 												<span className="headingTable">Date</span>
 											</div>
 											<div className="schedule-captions-list">
 												<span className="headingTable">Delivery type</span>
 											</div>
 											<div className="schedule-captions-list-time">
 												<span className="headingTable">Timing</span>
 											</div>
 											{/* <div className="schedule-captions-list-price">
 												<span className="headingTable">Price</span>
 											</div>
											 */}
 											<div className="schedule-captions-list">
 												<span className="headingTable">Link</span>
 											</div>

 										</div>
 										{scheduleData}										
 									</div>
 								</div>
 							</div>
 						</div>
 					</div>
 				</div>) : ""}

 				{/* course schedule end */}

				{/* scope start here  */}

 				<div className="page-section sectionContent" id="4"><br/><br/><br/>
 					<h2 id="sectionHeading">Scope</h2>
 					{description}
 				</div>

 				{/* scope end here */}

				{/* Course Overview start here  */}

 				<div className="page-section sectionContent" id="5"><br/><br/><br/>
 					<h2 id="sectionHeading">Course Overview</h2>
 					{this.state.overview}
 				</div>

 				{/* Course Overview end here */}

				{/* what you will learn start here  */}
				 
 				<div className="page-section sectionContent" id="6"><br/><br/><br/>
 					<h2 id="sectionHeading">What you will learn</h2>
 					{whatLearnArray}
 				</div>
		
 				{/* what you will learn end here  */}

 				{/* What you need to be familiar with? start here  */}
				
 				<div className="page-section sectionContent" id="7"><br/><br/><br/>
 					<h2 id="sectionHeading">What you need to be familiar with?</h2>
 					{familiarArray}
 				</div>

 				{/* What you need to be familiar with? end here  */}

				

				{/* ceritification starts  */}

 				<div className="page-section sectionContent" id="8"><br/><br/><br/>

 					<h2 id="sectionHeading">Certification</h2>
 					<div>
 						<img src={courseCertification} className="certificateImage" /><br/>
 						{/* {this.state.certificate !== null ? <button type="button" className="previewBtn" data-toggle="modal" data-target="#myModal">Preview</button> : ''} */}
 						<button type="button" className="previewBtn" data-toggle="modal" data-target="#myModal">Preview</button>
 					</div>

 					<div id="myModal" className="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
 					<div className="modal-dialog">
 						<div className="modal-content">
 							<div className="modal-body" id="imageBody">
 								<button data-dismiss="modal" className="closeImage">×</button>
 								<img src={courseCertification}  className="popupImage"/>
 							</div>
 						</div>
 					</div>
 					</div>
	
 				</div>

 				{/* Certification ends  */}
				 
 				{/* salary trends starts  */}
				
					{/* <h2 id="sectionHeading">Why {this.state.courseTitle}?</h2> */}
					{/* <p>{this.state.whyCourseTag}</p>
						{(null !== this.state.whyCourseImg) ? (<img src={this.state.whyCourseImg.length > 0 ? this.state.whyCourseImg :  "https://www.skillrary.com/user/generalimages/1562847536_skillrary-13.png"} width="100%" className="landingImage"/>):""
						} */}
				
					{   this.state.whyCourseImg != null && 
						this.state.impOfCourse.length > 0 &&
						this.state.avgSalary.length > 0 &&
						this.state.jobOpen.length > 0 &&
						this.state.jobHiring.length > 0 &&
						this.state.studentNumber.length > 0 &&
						<div className="page-section sectionContent" id="9"><br/><br/><br/>
						<h2 id="sectionHeading">Why {this.state.courseTitle}?</h2>

						<ImageContainer whyCourseImg={this.state.whyCourseImg}
										impOfCourse={this.state.impOfCourse}
										avgSalary={this.state.avgSalary}
										jobOpen={this.state.jobOpen}
										jobHiring={this.state.jobHiring}
										studentNumber={this.state.studentNumber}
										enrollLink={this.state.enrollLink}/>

						</div>										
					}
										
				{/* salary trends ends  */}

				{/* faq starts  */}

 				{faqData.length > 0 && (
 				<div className="page-section sectionContent" id="10"><br/><br/><br/>
 					<h2 id="sectionHeading">FAQs</h2>
 					<div className="container">
 					    <div id="faqaccordion">
 					        <div className="card faqCard mb-0">
 					        	{faqData}
 					        </div>
 					    </div>
 					</div>
 				</div>)}
				
 				{/* faq ends  */}

				</div>
			</div>
		</div>

		<Modal open={openEnroll} onClose={this.onCloseModalED} closeOnOverlayClick={false} closeIconSize={22} styles={{ modal: { marginTop: '10%' }}}>
		<div className="enrollmodalWidth" >
			<h2 className="enrollHeading" style={{marginTop: '-50px'}}>{this.state.modalPopupHeading}</h2>
			<form onSubmit={this.submitEnrollData}>
				<div className="container">
					<div className="row">
						<div className="col-md-6 offset-md-3"  style={{ marginBottom: '-15px'}}>
							<div className="form-group">
								<input type="text" name="name" 
								className="inputField" placeholder="Name" autoComplete="off" onChange={(v) => this.setState({name: v.target.value, errors: { name: false }})} value={this.state.name}
								/>
								<i className="fa fa-user" id="userLogo"></i>
								<span className="enrollErrText">{this.state.errors.name}</span>
							</div>
						</div>
				 	</div> 
					<div className="row">
						<div className="col-md-6 offset-md-3"  style={{ marginBottom: '-15px'}}>
							<div className="form-group">
								<input type="text" name="email" 
								className="inputField" placeholder="Email" autoComplete="off" onChange={(v) => this.setState({email: v.target.value, errors: { email: false }})} value={this.state.email}
								/>
								<i className="fa fa-envelope" id="userLogo"></i>
								<span className="enrollErrText">{this.state.errors.email}</span>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-md-6 offset-md-3"  style={{ marginBottom: '-15px'}}>
							<div className="form-group">
								<input type="number" name="phone_number" 
								className="inputField" placeholder="Phone number" autoComplete="off" onChange={(v) => this.setState({phone_number: v.target.value, errors: { phone_number: false } })} value={this.state.phone_number}
								/>
								<i className="fa fa-phone" aria-hidden="true" id="userLogo"></i>
								<span className="enrollErrText">{this.state.errors.phone_number}</span>
							</div>
						</div>
					</div><br/>
					<div align="center" style={{ justifyContent: "center" }}>
						<button className="enrollSubBtn">Submit</button>
					</div>
				</div>
			</form>
		</div>
		</Modal>

	</div>
		)
	}
}

export default withRouter(ScrollSpy);






































{/* <div>

// <div className="sideForm">
// <div class="cardForm">
// 	<h6 style={{ color: "black", textAlign:'center', marginBottom: '15px'}}>Request more information</h6>
// 		<div align="center">
// 		<button onClick={this.onClickIndividuals} className="individualTab">Individuals</button>
// 		<button onClick={this.onClickBusiness} className="businessTab">Business</button>
// 	</div>
// 	<div>
// 	<form onSubmit={this.onClickIndiFormSubmit} className="individualForm"		>				
// 	<div className="row">
// 			<div className="col-md-12"  style={{ marginBottom: '-28px'}}>
// 				<div className="form-group">
// 					<input type="text" name="first_name_indi" className="inputFieldSide" 
// 					placeholder="Firstname" 
// 					onChange={(v) => this.setState({first_name_indi: v.target.value, errors: {first_name_indi : false}})} value={this.state.first_name_indi} 
// 					autoComplete="off"
// 					/>
// 					<span className="errTextIndi">{this.state.errors.first_name_indi}</span>

// 				</div>
// 			</div>
// 	</div>
// 	<div className="row">
// 		<div className="col-md-12"  style={{ marginBottom: '-28px'}}>
// 			<div className="form-group">
// 				<input type="text" name="last_name_indi" className="inputFieldSide" 
// 				placeholder="Lastname"  autoComplete="off"
// 				onChange={(v) => this.setState({last_name_indi: v.target.value,  errors: {last_name_indi : false}})} value={this.state.last_name_indi} 
// 				/>
// 				<span className="errTextIndi">{this.state.errors.last_name_indi}</span>
// 			</div>
// 		</div>
// 	</div>
// 		<div className="row">
// 			<div className="col-md-12" style={{ marginBottom: '-28px'}}>
// 				<div className="form-group">
// 					<input type="email" name="user_email_indi" className="inputFieldSide" 
// 					placeholder="E-mail"  autoComplete="off"
// 					onChange={(v) => this.setState({user_email_indi: v.target.value,  errors: {user_email_indi : false}})} value={this.state.user_email_indi} 
// 					/>
// 					<span className="errTextIndi">{this.state.errors.user_email_indi}</span>

// 				</div>
// 			</div>
// 		</div>
// 		<div className="row">
// 			<div className="col-md-3"  style={{ marginBottom: '-28px'}}>
// 				<div className="form-group">
// 				<input type="text" name="country_code_indi" className="inputFieldSide" 
// 					autoComplete="off" 
// 					onChange={(v) => this.setState({country_code_indi: v.target.value})} value={this.state.country_code_indi}
// 					/>

// 				</div>
// 			</div>
		
// 			<div className="col-md-9"  style={{ marginBottom: '-28px'}}>
// 				<div className="form-group">
// 					<input type="number" name="contact_number_indi" className="inputFieldSide" 
// 					placeholder="Phone"  autoComplete="off"
// 					onChange={(v) => this.setState({contact_number_indi: v.target.value,  errors: { contact_number_indi : false}})} value={this.state.contact_number_indi}
// 					/>
// 					<span className="errTextIndi">{this.state.errors.contact_number_indi}</span>

// 				</div>
// 			</div>
// 		</div>
// 		<div className="row">
// 			<div className="col-md-12"  style={{ marginBottom: '-28px'}}>
// 				<div className="form-group">
// 					<textarea type="text" name="messages_indi" className="inputFieldSideMsz" 
// 					placeholder="Message"  autoComplete="off"
// 					onChange={(v) => this.setState({messages_indi: v.target.value, errors: {messages_indi : false} })} value={this.state.messages_indi}/>
// 					<span className="errTextIndi">{this.state.errors.messages_indi}</span>
// 				</div>
// 			</div>
// 		</div><br/>
	
// 		<div className="row" style={{ justifyContent: "center" }}>
// 			<button className="submitSideBtn">Submit</button>
// 		</div>

// 	</form>
// 	</div>
// 	<div>
// 		<form onSubmit={this.onClickBusiFormSubmit} className="businessForm" style={{ display: 'none' }}>
			
// 		<div className="row">
// 				<div className="col-md-12"  style={{ marginBottom: '-28px'}}>
// 					<div className="form-group">
// 						<input type="text" name="first_name_busi" className="inputFieldSide" 
// 						placeholder="Firstname" 
// 						onChange={(v) => this.setState({first_name_busi: v.target.value,  errors: {first_name_busi : false}})} value={this.state.first_name_busi} 
// 						autoComplete="off"
// 						/>
// 						<span className="errTextIndi">{this.state.errors.first_name_busi}</span>

// 					</div>
// 				</div>
// 		</div>
// 		<div className="row">
// 			<div className="col-md-12"  style={{ marginBottom: '-28px'}}>
// 				<div className="form-group">
// 					<input type="text" name="last_name_busi" className="inputFieldSide" 
// 					placeholder="Lastname"  autoComplete="off"
// 					onChange={(v) => this.setState({last_name_busi: v.target.value,  errors: {last_name_busi : false}})} value={this.state.last_name_busi} 
// 					/>
// 					<span className="errTextIndi">{this.state.errors.last_name_busi}</span>
// 				</div>
// 			</div>
// 		</div>
// 		<div className="row">
// 			<div className="col-md-12"  style={{ marginBottom: '-28px'}}>
// 				<div className="form-group">
// 					<input type="text" name="corporate_name_busi" className="inputFieldSide" 
// 					placeholder="Company"  autoComplete="off"
// 					onChange={(v) => this.setState({corporate_name_busi: v.target.value, errors: {corporate_name_busi : false}})} value={this.state.corporate_name_busi} 
// 					/>
// 					<span className="errTextIndi">{this.state.errors.corporate_name_busi}</span>
// 				</div>
// 			</div>
// 		</div>
// 			<div className="row">
// 				<div className="col-md-12" style={{ marginBottom: '-28px'}}>
// 					<div className="form-group">
// 						<input type="email" name="user_email_busi" className="inputFieldSide" 
// 						placeholder="E-mail"  autoComplete="off"
// 						onChange={(v) => this.setState({user_email_busi: v.target.value, errors: {user_email_busi : false}})} value={this.state.user_email_busi} 
// 						/>
// 						<span className="errTextIndi">{this.state.errors.user_email_busi}</span>

// 					</div>
// 				</div>
// 			</div>
// 			<div className="row">
// 				<div className="col-md-3"  style={{ marginBottom: '-28px'}}>
// 					<div className="form-group">
// 					<input type="text" name="country_code_indi" className="inputFieldSide" 
// 						autoComplete="off" 
// 						onChange={(v) => this.setState({country_code_indi: v.target.value})} value={this.state.country_code_indi}
// 						/>

// 					</div>
// 				</div>
			
// 				<div className="col-md-9"  style={{ marginBottom: '-28px'}}>
// 					<div className="form-group">
// 						<input type="number" name="contact_number_busi" className="inputFieldSide" 
// 						placeholder="Phone"  autoComplete="off"
// 						onChange={(v) => this.setState({contact_number_busi: v.target.value,errors: {contact_number_busi : false}})} value={this.state.contact_number_busi}
// 						/>
// 						<span className="errTextIndi">{this.state.errors.contact_number_busi}</span>

// 					</div>
// 				</div>
// 			</div>
// 			<div className="row">
// 				<div className="col-md-12"  style={{ marginBottom: '-28px'}}>
// 					<div className="form-group">
// 						<textarea type="text" name="message_busi" className="inputFieldSideMsz" 
// 						placeholder="Message"  autoComplete="off"
// 						onChange={(v) => this.setState({message_busi: v.target.value,errors: {message_busi : false}})} value={this.state.message_busi}/>
// 						<span className="errTextIndi">{this.state.errors.message_busi}</span>
// 					</div>
// 				</div>
// 			</div><br/>
		
// 			<div className="row" style={{ justifyContent: "center" }}>
// 				<button className="submitSideBtnBusiness">Submit</button>
// 			</div>
// 			</form>
// 	</div>
// </div>
// </div>
</div> */}