import React, { Component } from 'react';
import LiveTrainingBanner from './components/liveTrainingDesign/liveTrainingBanner';
import CategoryRelatedCourses from './components/liveTrainingDesign/categoryRelatedCourses';
import Header from '../src/components/liveTrainingDesign/liveTrainingHeader';
import FooterTraining from '../src/components/liveTrainingDesign/footerTraining';
import ContactLiveTraining from '../src/components/liveTrainingDesign/liveTrainingContact';

class CategoryPage extends Component{
    render(){
        return(
            <div>
                <Header /><br/><br/><br/>
                {/* <LiveTrainingBanner /><br/><br/> */}
                <CategoryRelatedCourses /><br/>
                <ContactLiveTraining />
                <FooterTraining />
            </div>
        )
    }
}

export default CategoryPage;