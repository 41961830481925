import React, { Component } from 'react';
import git from '../liveTrainingDesign/assets/git.png';
import jenkins from '../liveTrainingDesign/assets/jenkins.png';
import docker from '../liveTrainingDesign/assets/docker.png';
import kubernetes from '../liveTrainingDesign/assets/kubernetes.png';
import jfrog from '../liveTrainingDesign/assets/jfrog.png';
import ansible from '../liveTrainingDesign/assets/ansible.png';
import tc from '../liveTrainingDesign/assets/tc.png';
import jacoco from '../liveTrainingDesign/assets/jacoco.png';
import amazonweb from '../liveTrainingDesign/assets/amazonweb.png';
import linux from '../liveTrainingDesign/assets/linux.png';

class FacebookTools extends Component{
    render(){
        console.log('aa', this.props.facebook_toolsexplain )
        return(
            <div>
                <style jsx>
                    {`
                    .toolsContainer{
                        // background: #ffc339;
                        padding: 40px 0px 0px 0px;
                        margin-top: 20px;
                        margin: 0px;
                    }
                    .imageSize{
                        width: 100%;
                        height: 130px;
                        max-width: 200px;
                        min-width: 200px;
                        margin-bottom: 30px;
                    }
                    .courseToolsButton{
                        padding: 15px 35px;
                        font-size: 35px;
                        background: white;
                        color: black;
                        font-weight: 600;
                        border: 1px solid white;
                        letter-spacing: 1px;
                        box-shadow: 5px 5px black;
                    }
                    `}
                </style>
                <div className="container-fluid toolsContainer">
                    {/* <div className="row" style={{ margin: '0',padding: '0' }}>
                        <div className="col-lg-6 col-md-6 offset-md-1">
                            <p className="courseToolsButton">Tools explained during Devops Training</p>
                        </div>
                    </div><br/><br/><br/>
                    <div className="row" style={{ margin: '0',padding: '0',textAlign: 'center' }}>
                        <div className="col-md-2 offset-md-1" align="center">
                            <img src={git} alt="git" className="imageSize"/>
                        </div>
                        <div className="col-md-2">
                            <img src={jenkins} alt="git" className="imageSize"/>
                        </div>
                        <div className="col-md-2">
                            <img src={docker} alt="git" className="imageSize"/>
                        </div>
                        <div className="col-md-2">
                            <img src={kubernetes} alt="git" className="imageSize"/>
                        </div>
                        <div className="col-md-2">
                            <img src={jfrog} alt="git" className="imageSize"/>
                        </div>
                    </div><br/><br/><br/><br/>
                    <div className="row" style={{ margin: '0',padding: '0',textAlign: 'center' }}>
                        <div className="col-md-2 offset-md-1" align="center">
                            <img src={ansible} alt="git" className="imageSize"/>
                        </div>
                        <div className="col-md-2">
                            <img src={tc} alt="git" className="imageSize"/>
                        </div>
                        <div className="col-md-2">
                            <img src={jacoco} alt="git" className="imageSize"/>
                        </div>
                        <div className="col-md-2">
                            <img src={amazonweb} alt="git" className="imageSize"/>
                        </div>
                        <div className="col-md-2">
                            <img src={linux} alt="git" className="imageSize"/>
                        </div>
                    </div> */}
                    {(null !== this.props.facebook_toolsexplain && undefined !== this.props.facebook_toolsexplain) ? (<img src={this.props.facebook_toolsexplain} alt="tools_image" width="100%" />) : ''}
                </div>
            </div>
        )
    }
}

export default FacebookTools;
