import React, { Component } from 'react';
import skillrary_logo from '../liveTrainingDesign/assets/skillrary_logo.jpg';
import devops from '../liveTrainingDesign/assets/devopsss.png';
import Swal from 'sweetalert2';
import Modal from 'react-responsive-modal';
import $ from 'jquery';

var value;

class FacebookBanner extends Component{

    constructor(props){
        super(props);
        console.log(this.props,'faceboook')
        this.state = {
            name : '',
            email : '',
            contact_number : '',
            city: '',
            course_id : '',
            errors: {},
            checkboxFacebook: 'true',
            openDownloadF: false,
            nameD: '',
            emailD: '',
            phone_number: ''
        }
        this.onfacebookSubmit = this.onfacebookSubmit.bind(this);
        this.downloadSyllabus = this.downloadSyllabus.bind(this)
    }
    onhandleCheckboxFacebook = () => {
        this.setState({checkboxFacebook: !this.state.checkboxFacebook});
    }
    onfacebookSubmit(e){
        e.preventDefault();
	
		let errors = {};
		let formIsValid = false;
		
        if(!this.state.name){
            formIsValid = true
            errors['name'] = "This field is required"
        }
        else if(!this.state.name.match(/^[a-zA-Z]+(?:[\s.]+[a-zA-Z]+)*$/)){
            formIsValid = true;
            errors['name'] = "Please enter only characters";
        }    

        else if(!this.state.email) {
            formIsValid = true
            errors['email'] = "This field is required"
        }
        else if(!this.state.email.match(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)){
            formIsValid = true
            errors['email'] = "Please enter valid email"
        }
        else if(!this.state.contact_number){
            formIsValid = true
            errors['contact_number'] = "This field is required"
        }
        else if(this.state.contact_number.length < 10){
            formIsValid = true
            errors['contact_number'] = "Minimum should be 10 characters"
        }
        else if(this.state.contact_number.length > 10){
            formIsValid = true
            errors['contact_number'] = "Maximum should be 10 characters"
        }
		else if(!this.state.city){
			formIsValid = true
			errors['city'] = "This field is required"
        }
    
		if(Object.keys(errors).length > 0){
			this.setState({errors:errors})
			return formIsValid;
        }
    
        fetch("https://live.skillrary.com/live-courses/index.php/api/v1/ads-page-demo", {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json'
			},
			body: JSON.stringify({
                name: this.state.name,
                email_id: this.state.email,
                mobile: this.state.contact_number,
                city: this.state.city,
                course_id : this.props.courseId,
                contact_me:  this.state.checkboxFacebook,
			})
			})
			.then(res => res.json())
			.then(
				(result) => {
				console.log('rest', result);
				if(result.status == 200){
					Swal.fire({
					type: 'success',
					title: 'Thank you, Your request is submitted successfully',
					showConfirmButton: false,
					timer: 2500
					})
                this.setState({name : '',email: '',contact_number: '',city: '',request_from: '',checkboxFacebook: '',course_id: ''})
                $(".checkboxFacbook").prop( "checked", false );
				}
				else if(result.status == 400){
					Swal.fire({
					type: 'error',
					title: 'Oops...',
					text: 'Something went wrong!',
					footer: '<a href>Why do I have this issue?</a>'
					})
				}
				},
			)
        
    }
    downloadSyllabus(){
        this.setState({ openDownloadF : true })
        // window.open(`https://live.skillrary.com/live-courses/index.php/syllabus/devops`,"_self");
    }
    onCloseModalDownloadF = () => {
        this.setState({ openDownloadF : false })
        this.setState({nameD : '',emailD: '',phone_number:'',errors: {}})
    }
    downloadSyllabusSubmit = (e) => {
        e.preventDefault();
        
		let errors = {};
		let formIsValid = false;
		
        if(!this.state.nameD){
            formIsValid = true
            errors['nameD'] = "This field is required"
        }
        else if(!this.state.nameD.match(/^[a-zA-Z]+(?:[\s.]+[a-zA-Z]+)*$/)){
            formIsValid = true;
            errors['nameD'] = "Please enter only characters";
        }    
		else if(!this.state.emailD) {
			formIsValid = true
			errors['emailD'] = "This field is required"
		}
		else if(!this.state.emailD.match(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)){
			formIsValid = true
			errors['emailD'] = "Please enter valid email"
		}
		else if(!this.state.phone_number){
			formIsValid = true
			errors['phone_number'] = "This field is required"
		}
		else if(this.state.phone_number.length < 10){
			formIsValid = true
			errors['phone_number'] = "Minimum should be 10 characters"
		}
		else if(this.state.phone_number.length > 10){
			formIsValid = true
			errors['phone_number'] = "Maximum should be 10 characters"
		}

		if(Object.keys(errors).length > 0){
			this.setState({errors:errors})
			return formIsValid;
        }
        
        fetch("https://live.skillrary.com/live-courses/index.php/api/v1/syllabus-request", {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json'
			},
			
			body: JSON.stringify({
				course_id : this.props.courseId,
				name: this.state.nameD,
				email: this.state.emailD,
				contact_number: this.state.phone_number,
				request_from: 'ad'
			})
		})
      .then(res => res.json())
      .then((result) => {
		  console.log('rest', result);
		  if(result.status == 200){
			window.open(`https://live.skillrary.com/live-courses/index.php/syllabus/${this.props.slugname}`,"_self");
			this.setState({nameD : '',emailD: '',phone_number:'',openDownloadF: false})
          }
          else if(result.status == 400){
            Swal.fire({
              type: 'error',
              title: 'Oops...',
              text: 'Something went wrong!',
              footer: '<a href>Why do I have this issue?</a>'
            })
          }
        })
    }
    render(){
        const { openDownloadF } = this.state;
        if (this.state.checkboxFacebook) {
            value = 'false'
        } 
        else {
            value = 'true'
        }
        return(
            <div>
                <style jsx>
                    {`
                    .downloadSubmitBtn{
                        background: #ffc339;
                        border: 1px solid #ffc339;
                        font-size: 16px;
                        padding: 5px 15px;
                        color: white;
                        font-weight: 600;
                        border-radius: 4px;
                    }
                    .downloadFHeading{
                        font-weight: 700;
                        font-size: 16px;
                        color: #333742;
                        letter-spacing: 1.5px;
                        position: relative;
                        padding: 0px 0px 10px 0px;
                        margin-bottom: 20px;
                        text-align: center;
                    }
                    .downloadFHeading:before{
                        content: ' ';
                        background: #ffc339;
                        box-shadow: 0 4px 8px 0 rgba(76,215,200,0.3);
                        width: 15%;
                        height: 4px;
                        position: absolute;
                        bottom: 0;
                        left: 50%;
                        transform: translate(-50%);
                    }
                    .modalWidthDownloadF{
                        width: 600px !important;
					    margin-top: 50px;
                    }
                    .errTextDownloadF{
                        font-size: 12px;
                        font-weight: normal;
                        color: red;
                        width: 300px;
                        bottom: 0;
                        // left: -75px;
                        top: 50px;
                        position: absolute;
                        z-index: 1;
                    }
                    .errTextFacebook{
                        font-size: 12px;
                        font-weight: normal;
                        color: red;
                        width: 300px;
                        bottom: 0;
                        // left: -75px;
                        top: 38px;
                        position: absolute;
                        z-index: 1;
                    }
                    #logoF{
                        position: absolute;
                        left: 20px;
                        top: 25px;
                        float: left;
                        color: #696969;
                    }
                    .facebookContainer{
                        background: black;
                        height: auto;
                        padding: 20px 0px 50px 0px;
                        position: relative;
                    }
                    .cerCount{
                        color: #74ece2;
                        font-size: 18px;
                        text-align: center;
                        margin-top: 40px;
                        letter-spacing: 0.5px;
                        // position: absolute;
                    }
                    .becomeExpert{
                        color: #FFC339;
                        font-size: 50px;
                        font-weight: 600;
                    }
                    .courseImageDiv{
                        margin-left: 50%;
                        transform: translateX(-50%);
                    }
                    .courseImage{
                        min-width: 200px;
                        max-width: 200px;
                    }
                    .accelerateText{
                        color: white;
                        font-size: 20px;
                        //letter-spacing: 1px;
                        //text-align: justify;
                        //word-break: break-all;
                    }
                    .downloadButton{
                        padding: 15px 35px;
                        font-size: 22px;
                        background: #ffc339;
                        font-weight: 600;
                        border: 1px solid #ffc339;
                        letter-spacing: 1px;
                        box-shadow: 5px 5px #b9b9b8;
                    }
                    .fieldContainer{
                        background: #FFFDF8;
                        padding: 0px 0px 30px 0px;
                        // margin-top: -15%;
                    }
                    .freeDemo{
                        background:#e6e3dd;
                        text-align: center;
                        padding: 15px;
                        font-size: 30px;
                    }
                    .inputField{
                        margin-bottom: 20px;
                    }
                    .inputFieldDownload{
                        font-size:15px;
                        border: 0;
                        outline: 0;
                        position: relative;
                        background: transparent;
                        border-bottom: 1px solid #ffc339;
                        // -webkit-text-fill-color: black;
                        padding-left:25px;
                        padding-top:23px;
                        display: flex;
                        width: 100%;
                    }
                    @media only screen and (max-width: 600px){
                        .becomeExpert {
                            color: #FFC339;
                            font-size: 45px;
                            font-weight: 600;
                        }
                        .fieldContainer{
                            background: #FFFDF8;
                            padding: 0px 0px 20px 0px;
                            margin-top: 10%;
                        }
                        .form-check-input {
                            position: absolute;
                            margin-top: .3rem;
                            margin-left: 25px !important;
                        }
                        .form-check-label {
                            margin-bottom: 0;
                            margin-left: 47px;
                        }               
                        .modalWidthDownloadF{
                            width: 280px !important;
                            margin-top: 50px;
                        }    
                        .input_field_free{
                            display: inline-flex;
                            align-items: center;
                            justify-content: center;
                            margin-bottom: 20px;
                        }     
                        .form-control{
                            width: initial;
                        }
                        .cerCount{
                            color: #74ece2;
                            font-size: 18px;
                            text-align: center;
                            margin-top: 40px;
                            letter-spacing: 0.5px;
                            position: inherit !important;
                        }
                    }
                    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px)  { 
                        .courseImageDiv{
                            margin-left: inherit;
                            transform: inherit;
                        }
                    }
                    `}
                </style>
                <div className="container-fluid facebookContainer">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-2">
                                <a href="https://live.skillrary.com/"><img src={ skillrary_logo } alt="logo" style={{ width: '100%' }}/></a>
                            </div>
                            <div className="col-md-4">
                                {null !== this.props.FPopup_message ? ( <p className="cerCount">* {this.props.FPopup_message}</p>):""}

                            </div>
                        </div><br/><br/>
                        <div className="row">
                            <div className="col-md-5 offset-md-1">
                                <div className="row">
                                    <div className="col-md-12">
                                        <h2 className="becomeExpert">Become an expert in {this.props.facebook_title}</h2>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="courseImageDiv">
                                        {undefined !== this.props.facebook_courseimage ? (<img src={ this.props.facebook_courseimage } alt="course_img" className="courseImage"/>):""}
                                    </div>
                                </div><br/>
                                <div className="row">
                                    <div className="col-md-12">
                                        <p className="accelerateText">Accelerate your Career in {this.props.facebook_title} with SkillRary Flexible Training & Certification Program designed by Industry experts which includes Updated Curriculum Access to Cloud Labs Practical Training with Live Projects| Industry Cases Assessments Placement Assistance Grooming Session Certificate and 24/ support, to help you become a {this.props.facebook_title} Expert.</p>
                                    </div>
                                </div><br/>
                                <div className="row">
                                    <div className="courseImageDiv">
                                        <button className="downloadButton" onClick={this.downloadSyllabus}>Download Syllabus</button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 offset-md-1">
                                <div className="fieldContainer">
                                    <div>
                                    <h3 className="freeDemo">Free Demo</h3>
                                    </div><br/>
                                   
                                    <div className="row">
                                        <form onSubmit={this.onfacebookSubmit}>
                                            <div className="col-md-8 offset-md-2 input_field_free">
                                                <input type="text" placeholder="Name" name="name" className="form-control" onChange={v => this.setState({name: v.target.value , errors: false})} value={this.state.name} autoComplete="off"/>
                                                <span className="errTextFacebook">{this.state.errors.name}</span>
                                            </div><br/>
                                            <div className="col-md-8 offset-md-2 input_field_free">
                                                <input type="text" placeholder="Email" className="form-control"  onChange={v => this.setState({email: v.target.value, errors: false})} value={this.state.email} autoComplete="off"/>
                                                <span className="errTextFacebook">{this.state.errors.email}</span>
                                            </div><br/>
                                            <div className="col-md-8 offset-md-2 input_field_free">
                                                <input type="number" placeholder="Phone number" className="form-control"  onChange={v => this.setState({contact_number: v.target.value, errors: false})} value={this.state.contact_number} autoComplete="off"/>
                                                <span className="errTextFacebook">{this.state.errors.contact_number}</span>
                                            </div><br/>
                                            <div className="col-md-8 offset-md-2 input_field_free">
                                                <input type="text" placeholder="City" className="form-control"  onChange={v => this.setState({city: v.target.value , errors: false})} value={this.state.city} autoComplete="off"/>
                                                <span className="errTextFacebook">{this.state.errors.city}</span>
                                            </div><br/>
                                            <div className="col-md-7 offset-md-3 " style={{ color: '#656262'}}>
                                                <input class="form-check-input checkboxFacbook" type="checkbox" checked onChange={this.onhandleCheckboxFacebook} value={this.state.checkboxFacebook} autoComplete="off"/>
                                                <label class="form-check-label" for="defaultCheck1">
                                                I authorize SkillRary representative to contact me via phone, SMS and/or email
                                                </label>
                                            </div>
                                            <div align="center" style={{ marginTop: '4%' }}>
                                                <button className="downloadButton">Apply Now</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Modal open={openDownloadF} onClose={this.onCloseModalDownloadF} closeOnOverlayClick={false} closeIconSize={22} styles={{ modal: { marginTop: '10%' }}}>
							<div className="modalWidthDownloadF">
                                <h3 className="downloadFHeading" style={{ marginTop: '-50px' }}>Please Fill Below Details to Download</h3>
                                <form onSubmit ={this.downloadSyllabusSubmit}>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-md-6 offset-md-3">
                                                <input type="text" name="nameD" placeholder="Name" className="inputFieldDownload" onChange={(v) => this.setState({ nameD: v.target.value, errors: false})} value={this.state.nameD} autoComplete="off"/>
                                                <i className="fa fa-user" id="logoF"></i>
                                                 <span className="errTextDownloadF">{this.state.errors.nameD}</span>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6 offset-md-3">
                                                <input type="text" placeholder="Email"name="emailD" className="inputFieldDownload" onChange={(v) => this.setState({ emailD: v.target.value, errors: false})} value={this.state.emailD} autoComplete="off"/>
                                                <i className="fa fa-envelope" id="logoF"></i>
                                                <span className="errTextDownloadF">{this.state.errors.emailD}</span>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6 offset-md-3">
                                                <input type="text" placeholder="Phone Number" name="phone_number" className="inputFieldDownload" onChange={(v) => this.setState({ phone_number: v.target.value, errors: false})} value={this.state.phone_number} autoComplete="off"/>
                                                <i className="fa fa-phone" id="logoF"></i>
                                                <span className="errTextDownloadF">{this.state.errors.phone_number}</span>
                                            </div>
                                        </div><br/>
                                        <div className="row" style={{ justifyContent: "center" }}>
                                            <button className="downloadSubmitBtn">Submit</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </Modal>
                    </div>
                </div>
            </div>
        )
    }
}

export default FacebookBanner;
