import React, { Component } from "react";
import $ from 'jquery';


class dropdownpage extends Component {
 constructor(props) {
    super(props);
    this.state = {
      course: [],
    }
    this.getCoursePage = this.getCoursePage.bind(this);
  }
componentDidMount(){
  $(window).scrollTop(0); 
  this.getCoursePage();
}
getCoursePage() {
    fetch("https://live.skillrary.com/live-courses/index.php/api/v1/all-catogories-courses/virtusa", {
     method: 'GET',
    })
      .then(res => res.json())
      .then(
        (result) => {
          console.log('res carousel', result);
          this.setState({
            course: result.result,
          });   
          console.log('courseList-=======================',this.state.course)
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }
  render() {
  return (
    <div>
      <style jsx>
      {`
      ul.menu {
        margin-top: 16px !important;
      }
      .box h4 {
          text-align: center;
          color: #fefafa;
          background: #33A478 !important;
          position: relative;
          margin-bottom: 0;
          top: -7px;
          -moz-border-radius: 8px;
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
          font-size: 1.1em;
          font-weight: 400;
          letter-spacing: -0.7px;
          margin-top: 5px;
          text-transform: uppercase;
      }
      .menu li{
        list-style: initial;
      }
      .menu li a:hover{
        list-style: initial;
        text-decoration: none;
      }
      .menu li a {
          text-align: left;
          display: block;
          padding: 5px 0;
          border-bottom: 1px dotted #bbb;
          color: #434343!important;
      }
      .box{
       background:#dcdddd2e;
      }
      .coursesAll{
        font-weight: 700;
        font-size: 22px;
        color: #333742;
        letter-spacing: 1.5px;
        position: relative;
        padding: 0px 0px 10px 0px;
        margin-bottom: 20px;
        text-align: center;
      }
      .coursesAll:before{
        content: ' ';
        background: #33A478;
        box-shadow: 0 4px 8px 0 rgba(76,215,200,0.3);
        width: 5%;
        height: 4px;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%);
      }

      `}
      </style>
      <div className="container-fluid" style={{padding : '0'}}>
        <img class="d-block img-fluid" src="https://skillrary.com/./uploads/banner/1591251986_798e7743-eb5a-41d6-9a58-c9e6710befc9.png" alt="" width="100%"/>
      </div><br/>
      <h4 className="coursesAll">All Courses</h4><br/>
      <div className="container-fluid" style={{columnCount:'4'}}>
       <div className="row" style={{display:'table'}}>
        {this.state.course.map(value => 
            <div className="col-md-12">
            <div className="box">
              <h4>{value.category_name}</h4>
              {value.courses.map(valueCourse =>
              <ul class="menu" id="academic_tutorials" data-href="academic_tutorials.htm">
                <li><a href={"/"+valueCourse.slug} title="CBSE Syllabus">{valueCourse.course_title}</a></li>
              </ul>
              )}
            </div>
          </div>
        )}
         </div>
    </div><br/>
    </div>
  );

  }
}
 

export default dropdownpage;