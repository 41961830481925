
import React, { Component } from "react";
import { NavLink , Link} from 'react-router-dom';
import skillrary_logo from '../liveTrainingDesign/assets/skillrary_logo.jpg';
import sr_Logo from '../liveTrainingDesign/assets/srlogo.png';
import $ from 'jquery';
import Categories from '../liveTrainingDesign/categoriesTraining';
import VLogo from '../liveTrainingDesign/assets/virtusalogo.png';

import SearchBarComponent from "./searchBarComponent";

class Header extends Component {

	constructor(props) {
		super(props);
		
		console.log('prrr',props)
		if (!window.location.pathname.includes("searchCourses/")) {
			window.sessionStorage.setItem('courseSearch', "");
		}
		this.state = {
		searchText: (undefined !== window.sessionStorage.getItem('courseSearch')) ? window.sessionStorage.getItem('courseSearch') : "" 
		}
		this.handleSearch = this.handleSearch.bind(this);
		this.onFocus = this.onFocus.bind(this);
  	}


  onFocus = () => {
  		console.log('test')
  		if (window.location.pathname.includes("searchCourses/")) {
  			window.history.pushState(null, "Search Results", "searchCourses/")
  		}
  }
 
  handleSearch = (e) => {
   	console.log('eee', e.target.value)
   	var id = e.target.value;
   	this.setState ({searchText: id})
   	//window.location.href = window.location.origin + "search?course = "+e.target.value;
   console.log('ww', window.location.pathname);
   
   if (window.location.pathname.includes("searchCourses/")) {
   window.history.pushState(null, "Search Results", "" + e.target.value)
   } else {
   	window.history.pushState(null, "Search Results", "searchCourses/" + e.target.value)
   }
   //window.history.pushState(null, "Search Results", "" + e.target.value)
   window.sessionStorage.setItem("courseSearch", e.target.value);

   //return window.location.reload();
   }

	modalClose = () => {
		$('.modalDialogContainer').css('display','none');
	}	

	componentDidMount(){
		var im = document.getElementById("Dialogimg");
		var images = [sr_Logo,sr_Logo,sr_Logo];
		var index=0;

		function changeImage()
		{
		im.setAttribute("src", images[index]);
		index++;
		if(index >= images.length)
		{
			index=0;
		}
		}

		setInterval(changeImage, 2000);

		var text = [];
		
		text[0] = Math.floor((Math.random() * 100) + 10) + "+ users enrolled for Selenium Course in last 1 hour";
		text[1] = Math.floor((Math.random() * 100) + 10) + "+ users enrolled for Manual Testing Course in last 1 hour";
		text[2] = Math.floor((Math.random() * 100) + 10) + "+ users enrolled for Devops Course in last 1 hour";
		

		// var text = ["80+ users enrolled for Selenium Course",
		// 			"50+ users enrolled for Manual Testing Course",
		// 			"70+ users enrolled for Devops Course"];
		var counter = 0;
		var elem = document.getElementById("changeText");
		elem.innerHTML=`${Math.floor((Math.random() * 100) + 10)}+ users enrolled for AI Course in last 1 hour`;
		// var inst = setInterval(change, 2000);
		setInterval(change, 2000);

		function change() {
		  elem.innerHTML = text[counter];
		  counter++;
		  if (counter >= text.length) {
			counter = 0;
			// clearInterval(inst); // uncomment this if you want to stop refreshing after one cycle
		  }
		}

	}

	// dropdownContent = () => {
	// 	$('.dropdown-content').toggle();
	// }
 	render(){
    return (
      <div>
     
		<style jsx>{`

			.navbar-light .navbar-toggler{ 
				border-color: white; 
			} 
			.navbar-light .navbar-toggler:focus{
				outline: none;
			}  
			.navbar-light .navbar-toggler-icon { 
				background-image: url( "data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(250,250,250)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E"); 
			} 
			#Dialogimg{
				width: 60px;
				height: 60px;
				border: 2px solid #33A478;
			}
			.modalDialogContainer{
				position: fixed;
				bottom: 10px;
				left: 0;
				z-index: 1;
			}
			#crossIcon{
				cursor: pointer;
				position: absolute;
				right: -10px;
				top: -11px;
				background: #33A478;
				color: black;
				padding: 2px 1px;
				border-radius: 15px;
			}
			#dialog{
				text-align: center;
				background: black;
				height: 120px;
				width: 300px;
				padding: 20px;
				font-size: 15px;
				margin-left: 20px;
				border-radius: 5px;
				color: #33A478;	
			}
			.activeHeading {
				// border-bottom:2px solid black;
				background: black;
				border-radius: 5px;
			}
			.skillrary_logo{
				height: 40px;
			}
	      	.bg-light{
				padding: 3px;
				position: fixed;
				background-color: #33A478 !important;
				background-image: linear-gradient(to right, rgba(0,0,0), rgba(51,	164,	120) , rgba(0,0,0));
				width: 100%;
				z-index: 9;
	      	}
	      	#containerFluid{
	      		padding-right: 0px;
	      		padding-left: 0px;
	      	}
	      	.nav-bar{
	      		padding: 25px 20px !important;
	      	}
	      	.nav-item{
				text-decoration: none;
				font-size: 15px;
				padding: 0px 25px;
				color: black;
			  }
			.nav-link-header:hover{
				background: black;
				border-radius: 5px;
			}
			.categoryLink:hover{
				background: transparent !important;
				// border-radius: 5px;
			}
	      	.dropbtn a{
	      		font-weight: 600;
	      		font-size: 20px;
	      		color: black;
	      	}
	      	.nav-item-categories{
	      		font-weight: 600;
	      		font-size: 20px;
	      		padding: 25px 20px;
	      	}
	      	.nav-item .nav-link{
	      		font-weight: 600;
			}
			.nav-link-header{
				color: white !important;
			}
	      	.nav-item-categories .nav-link{
	      		color: black !important;
	      	}
	      	.navbar-nav{
	      		text-align: center;
	      	}
			.example {
				padding: 1px 10px;
				font-size: 15px;
				border: 1px solid black;
				border-radius: 4px 0px 0px 4px;
				float: left;
				width: 80%;
			    background: white;
			}
			.example:focus  {
			  	outline: none;
			}
			.searchButton {
				float: right;
				/* width: 15%; */
				padding: 0px 6px;
				background: transparent;
				color: black;
				font-size: 17px;
				border: 1px solid transparent;
				border-radius: 0px 4px 4px 0px;
				border-left: none;
				cursor: pointer;
				margin-top: 3px;
				height: 28px;
				position: absolute;
				// margin-left: 205px;
				display: block;
			}
			.searchButton:focus{
			    outline: none;
			}
			.example::after {
			  content: "";
			  clear: both;
			  display: table;
			}
			.dropdown .dropbtn {
			  font-size: 16px;  
			  border: none;
			  outline: none;
			  color: white;
			  padding: 0px 9px;
			  background-color: inherit;
			  font-family: inherit;
			  margin: 0;
			}

			.dropdown-content {
			  display: none;
			  position: absolute;
			  background-color: #f9f9f9;
			  min-width: 160px;
			  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
			  z-index: 1;
			}

			.dropdown-content a.achorText {
				//float: none;
				color: black;
				padding: 12px 16px;
				text-decoration: none;
				display: block;
				text-align: right;
			}
			.dropdown-content a {
				//float: none;
				color: black;
				padding: 12px 16px;
				text-decoration: none;
				display: block;
				text-align: left;
			}
			// .dropdown:hover .dropdown-content {
			//   display: block;
			// }
			#searchFaIcon{
				margin-top: -9px;
    			position: absolute;
			}
			.serviceButton{
				background: transparent;
				border: 1px solid transparent;
			}
			.servicesMenu{
				margin-left: -95px;
			}
			.serviceButton:focus{
				outline: none !important;
			}
			.navbar-light .navbar-nav .nav-link {
				color: white;
			}
			.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
				color: white;
			}
			.navbar-light .navbar-nav .active>.nav-link, .navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .show>.nav-link{
				color: white;
			}
			@media only screen and (max-width: 600px){
				.serviceButton{
					left: 50%;
					position: relative;
					transform: translateX(-50%);
				}
				.servicesMenu{
					margin-left: 0px;
				}
				#dialog{
					display: none;
				}
				.nav-item{
					text-decoration: none;
					font-size: 15px;
					padding: 0px 30px;
					color: black;
					margin-bottom: 10px;
				}
				.nav-search-bar{
					margin-left: 31px;
				}
				.modalDialogContainer{
					display: none;
				}
			}
			@media only screen and (min-device-width : 768px) and (max-device-width : 1024px)  {
				.modalDialogContainer{
					display: none;
				}
				.nav-search-bar{
					left: 30%;
					transform: translateX(30%)
				}
			}
			@media only screen and (min-width: 1024px) and (max-height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) {
				.nav-item{
		      		font-weight: 600;
		      		font-size: 20px;
		      		padding: 3px 0px;
					color: black;
					margin-bottom: 10px;
				  }
				.modalDialogContainer{
					display: none;
				}
			}
      	`}
      </style>
	  	<div className="modalDialogContainer">
			<div id="crossIcon">
				<i className="fa fa-times" style={{ width: '25px',textAlign: 'center'}}onClick={this.modalClose}></i>
			</div>
			<a href="https://www.skillrary.com/user/login" target = "_blank" style={{textDecoration: "none"}}>
			<div id="dialog">
				<div className="container-fluid">
					<div className="row">
						<div className="col-md-3">
							<img id="Dialogimg" src={sr_Logo}/>
						</div>
						<div className="col-md-9">
							<p id="changeText">200 users enrolled for AI courses</p>
						</div>
					</div>
				</div>
			</div>
			</a>
		</div>
      <div className="container-fluid" id="containerFluid">
        <nav className="navbar navbar-expand-lg bg-light navbar-light">
        	<a className="navbar-brand" href="https://virtusa.skillrary.com"><img src={skillrary_logo} className="skillrary_logo"/>&nbsp;&nbsp;<img src={VLogo} className="skillrary_logo"/></a>
        	<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="collapsibleNavbar">
				{/* <ul className="navbar-nav">
					
				</ul> */}
			  <ul className="navbar-nav ml-auto">
			  {/* <ul className="navbar-nav"> */}
                
                {/* <li className="nav-item">
					<div className="dropdown">
					    <button className="dropbtn">
					     	<a className="nav-link nav-link-header categoryLink" href="#" style={{ fontSize: '15px' }}><i className="fa fa-bars" aria-hidden="true"></i> Courses </a>
					    </button>
					    <div className="dropdown-content">
							<Categories/>
						  
					    </div>
					</div> 
	            </li>  */}
				{/*
                <li className="nav-item" style={{ lineHeight: "30px"}}>
					<input type="text" id="search" placeholder="Search.." name="search" className="example" 
					 onChange={this.handleSearch} value={this.state.searchText} />
  					<Link to={{
                      pathname: window.location.pathname
                    }}>
  					<button type="submit" className="searchButton"><i className="fa fa-search"></i></button>
  					</Link>                
				*/}
                 {/* <li className="nav-item nav-search-bar" style={{ lineHeight: "36px"}}>
					<div style={{ display: 'flex' }}>
						<SearchBarComponent />
						<button type="submit" className="searchButton">
							<i className="fa fa-search" id="searchFaIcon"></i>
						</button>
					</div>
                </li>   */}
				<li className="nav-item dropdown megamenu"><a id="megamneu" href="" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="nav-link dropdown-toggle font-weight-bold ">Courses</a>
					<div aria-labelledby="megamneu" className="dropdown-menu border-0 p-0 m-0">
					<Categories />
					</div>
				</li> 
				<li className="nav-item nav-search-bar" style={{ lineHeight: "36px"}}>
					<div style={{ display: 'flex' }}>
						<SearchBarComponent />
						<button type="submit" className="searchButton">
							<i className="fa fa-search" id="searchFaIcon"></i>
						</button>
					</div>
				</li>  
				<li className="nav-item">
                  	<NavLink to="/" exact activeClassName="activeHeading"  className="nav-link nav-link-header">LiveSkillRary</NavLink>
                </li>
                <li className="nav-item">
					{/* <a className="nav-link" href={window.location.origin + '/#ongoingClasses'}>Ongoing Classes</a> */}
					{/* <Link to="#ongoingClasses"}><button>ongoingClasses</button></Link> */}

					{/*<NavLink to="" activeClassName="activeHeading" exact onClick={this.ongoingClasses} className="nav-link">Ongoing Classes</NavLink> */}
					<NavLink to="/ongoingclasses"  activeClassName="activeHeading"  className="nav-link nav-link-header">Ongoing Classes</NavLink>
			 	</li>    
                <li className="nav-item">
					<NavLink to="/upcomingclasses"  activeClassName="activeHeading"  className="nav-link nav-link-header">Upcoming Classes</NavLink>
                </li>
                <li className="nav-item">
					<NavLink to="/howItWorks"  activeClassName="activeHeading"  className="nav-link nav-link-header">How It Works?</NavLink>
                </li>
				<li className="nav-item">
					<div className="dropdown">
					<button type="button" className="nav-link nav-link-header dropdown-toggle serviceButton" data-toggle="dropdown">
						Services
					</button>
					<div className="dropdown-menu servicesMenu">
						<a className="dropdown-item" href="https://compiler.skillrary.com/" target="_blank">Compiler</a>
						<a className="dropdown-item" href="https://assess.skillrary.com/" target="_blank">Skillrary Assessments</a>
						<a className="dropdown-item" href="https://business.skillrary.com/" target="_blank">Skillrary Business</a>
						<a className="dropdown-item" href="https://universities.skillrary.com/" target="_blank">Skillrary Universities</a>
						<a className="dropdown-item" href="https://code.skillrary.com/" target="_blank">Skillrary Code test</a>
						<a className="dropdown-item" href="https://assess.skillrary.com/essay" target="_blank">Skillrary Essay</a>
					</div>
					</div>
                </li>
              </ul>
            </div>  
          </nav>

      </div>
      </div>
    );
  }
}

export default Header;
