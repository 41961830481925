import React, { Component } from 'react';
import flexible from '../liveTrainingDesign/assets/flexible.png';
import cloud from '../liveTrainingDesign/assets/server.png';
import grooming from '../liveTrainingDesign/assets/grooming.png';
import projects from '../liveTrainingDesign/assets/projects.png';
import time from '../liveTrainingDesign/assets/24hrs.png';
import certificate from '../liveTrainingDesign/assets/certificate.png';
import assess from '../liveTrainingDesign/assets/assess.png';
import analytics from '../liveTrainingDesign/assets/analytics.png';

class FacebookOverview extends Component{
    constructor(props){
        super(props);
        console.log(props,'facebook overview')
    }
    render(){

        return(
            <div>
                <style jsx>
                    {`
                    .videoTagContainer{
                        margin: 85px 40px 0px;
                        width: 100%;
                        /* max-width: 749px; */
                        height: 260px;
                        border: 4px solid black;
                        min-width: 450px;
                        padding: 10px;
                    }
                    .stand{
                        position: relative;
                        margin-top: 0px;
                        left:60%;
                        transform: translate(-60%);
                        width: 0;
                        height: 0;
                        border-left: 50px solid transparent;
                        border-right: 50px solid transparent;
                        border-bottom: 45px solid black;
                    }
                    .divider{
                        border-right: 5px solid #ffc339;
                        // height: 400px;
                    }
                    .courseOverviewButton{
                        padding: 15px 35px;
                        font-size: 30px;
                        background: black;
                        color: #ffc339;
                        font-weight: 600;
                        border: 1px solid black;
                        letter-spacing: 1px;
                        box-shadow: 5px 5px #b9b9b8;
                    }
                    .courseoverviewTest{
                        font-weight: 600;
                        font-size: 22px;
                    }
                    .serviceContent{
                        font-size: 16px;
                        text-align: center;
                    }
                    .serviceIconInside{
                        float: left;
                        font-size: 30px;
                        width: 45px;
                        height: 47px;
                    }
                    .serviceIcon{
                        width: 75px;
                        height: 80px;
                        vertical-align: text-top;
                    }
                    .artStudio{
                        font-size: 22px;
                        font-weight: 600;
                    }
                    .serviceText{
                        padding-left: 90px;
                    }
                    .serviceText p{
                        font-size: 15px;
                    }
                    @media only screen and (max-width: 600px){
                        .divider{
                            border-right: inherit;
                            height: inherit;
                        }
                        .videoTagContainer{
                            margin: initial;
                            width: 100%;
                            /* max-width: 749px; */
                            height: 260px;
                            border: 4px solid black;
                            min-width: initial;
                            padding: 10px;
                        }
                        .stand {
                            position: relative;
                            margin-top: 0px;
                            left: 50%;
                            transform: translate(-50%);
                            width: 0;
                            height: 0;
                            border-left: 50px solid transparent;
                            border-right: 50px solid transparent;
                            border-bottom: 45px solid black;
                        }
                    }
                    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px)  { 
                        .stand {
                            display: none;
                            position: relative;
                            margin-top: 0px;
                            left: 50%;
                            transform: translate(-50%);
                            width: 0;
                            height: 0;
                            border-left: 50px solid transparent;
                            border-right: 50px solid transparent;
                            border-bottom: 45px solid black;
                        }
                        .divider{
                            border-right: inherit;
                            height: inherit;
                        }
                    }
                    `}
                </style>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-1"></div>
                        <div className='col-xl-5 col-md-10'>
                            <p className="courseOverviewButton">{this.props.facebook_title} Course Overview</p>
                        </div>
                    </div><br/>
                    <div className="row">
                        <div className="col-xl-1"></div>
                        <div className='col-xl-10 col-md-12'>
                            <p className="courseoverviewTest">{this.props.facebook_course_overview}</p>
                        </div>
                    </div><br/>
                    <div className="row">
                        <div className="col-xl-1"></div>
                        <div className='col-xl-3 col-md-6'>
                            <p className="courseOverviewButton">Key Features</p>
                        </div>
                    </div><br/><br/>
                    <div className="row">
                        <div className='col-xl-8 col-md-12 divider'>
                            <div className="row">
                                <div className="col-xl-1"></div>
                                <div className="col-lg-5 col-sm-6" style={{ marginBottom: '15px' }}>
                                    <div className="serviceIconInside">
                                        <img src={flexible} className="serviceIcon"/>
                                    </div>
                                    <div className="serviceText">
                                        <h4 className="artStudio">Cloud Lab Services</h4>
                                        <p>Access to multiple cloud labs such as Programming |Bucket - Technology, Skills, Platform | Microsoft Labs etc.</p>
                                    </div>
                                </div>
                                <div className="col-lg-5 col-sm-6"  style={{ marginBottom: '15px' }}>
                                    <div className="serviceIconInside">
                                        <img src={cloud} className="serviceIcon"/>
                                    </div>
                                    <div className="serviceText">
                                        <h4 className="artStudio">Compilers</h4>
                                        <p>Compatible Compilers for C | C++ | Java | Python | NodeJS | Pascal | Groovy | .Net | Cobol etc.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-xl-1"></div>
                                <div className="col-lg-5 col-sm-6" style={{ marginBottom: '15px' }}>
                                    <div className="serviceIconInside">
                                        <img src={grooming} className="serviceIcon"/>
                                    </div>
                                    <div className="serviceText">
                                        <h4 className="artStudio">Course Customization</h4>
                                        <p>Content customisation as per project needs and demands</p>
                                    </div>
                                </div>
                                <div className="col-lg-5 col-sm-6"  style={{ marginBottom: '15px' }}>
                                    <div className="serviceIconInside">
                                        <img src={projects} className="serviceIcon"/>
                                    </div>
                                    <div className="serviceText">
                                        <h4 className="artStudio">Projects</h4>
                                        <p>Learners can choose projects as per needs.Live project support available.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-xl-1"></div>
                                <div className="col-lg-5 col-sm-6" style={{ marginBottom: '15px' }}>
                                    <div className="serviceIconInside">
                                        <img src={time} className="serviceIcon"/>
                                    </div>
                                    <div className="serviceText">
                                        <h4 className="artStudio">24 hrs Support</h4>
                                        <p>Our professional team to assist you day & night, to respond you within 24 hrs to all your concerns</p>
                                    </div>
                                </div>
                                <div className="col-lg-5 col-sm-6"  style={{ marginBottom: '15px' }}>
                                    <div className="serviceIconInside">
                                        <img src={certificate} className="serviceIcon"/>
                                    </div>
                                    <div className="serviceText">
                                        <h4 className="artStudio">Certificate</h4>
                                        <p>Recognised certification accross industries to add value to your workforce</p>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-xl-1"></div>
                                <div className="col-lg-5 col-sm-6" style={{ marginBottom: '15px' }}>
                                    <div className="serviceIconInside">
                                        <img src={analytics} className="serviceIcon"/>
                                    </div>
                                    <div className="serviceText">
                                        <h4 className="artStudio">Learning Analytics</h4>
                                        <p>Keep tracks of your learning progress; insights into learning effectiveness</p>
                                    </div>
                                </div>
                                <div className="col-lg-5 col-sm-6"  style={{ marginBottom: '15px' }}>
                                    <div className="serviceIconInside">
                                        <img src={assess} className="serviceIcon"/>
                                    </div>
                                    <div className="serviceText">
                                        <h4 className="artStudio">Assessments</h4>
                                        <p>Customised assessments to examine your performance metrics</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-md-3">
                            <div className="row">
                               
                                {/* <video width="100%" muted preload="none" playsinline autoplay="autoplay" loop="false" controls className="videoTagContainer">
                                    <source src={this.props.facebook_youtube_url === null ? this.props.facebook_youtube_url : video } />
                                </video> */}
                                {/* <iframe  src="https://www.youtube.com/watch?v=XUdfKzsYm5g&t=138s" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
                                {this.props.facebook_youtube_url ? this.props.facebook_youtube_url :<iframe className="videoTagContainer" src="https://www.youtube.com/embed/XUdfKzsYm5g" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>}
                                <p className="stand"></p>
                                {/* <p>{this.props.facebook_youtube_url}</p> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default FacebookOverview;