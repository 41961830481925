import React,{ Component } from 'react';
import Carousel from '../liveTrainingDesign/carousel';
import ListCarousel from '../liveTrainingDesign/listCarouselView';
import $ from 'jquery';

class AllCourses extends Component{
    constructor(props) {
        super(props);

        console.log('alllcourses===',props)
            this.state = {
                heading: "Enroll Our Upcoming Classes",
                courseType: "all"
            }
            this.onClickGridView = this.onClickGridView.bind(this);
            this.onClickListView = this.onClickListView.bind(this);
        }
        onClickGridView(){
            $('.fstCarousel').css({ 'display' : 'block'})
            $('.secondCarousel').css({ 'display' : 'none'})

        }
        onClickListView(){
            $('.fstCarousel').css({ 'display' : 'none'})
            $('.secondCarousel').css({ 'display' : 'block'})
        }
        componentDidMount(){
            $('.btn-group-view button').on('click', function(){
                $(this).siblings().removeClass('btnviewactive')
                $(this).addClass('btnviewactive');
            })
        }

    render(){
        return(
            <div style={{ marginTop: '-5%' }}>
                <style jsx>
                    {`
                    .viewbtn{
                            position: absolute;
                            width: 100%;
                            padding: 5px 5px;
                            //border: 1px solid black;
                            color: white;
                            font-weight: 600;
                            background: #33A478;
                            border-radius: 8px;
                            max-width: 180px;
                            left: 0%;
                            top: 85px;
                            z-index: 1;
                    }
                    .viewbtn:hover{
                        width: 100%;
                        padding: 5px 5px;
                        // border: 1px solid black;
                        color: white;
                        background: #33A478;
                        background-image: linear-gradient(196deg, black, transparent);
                        border-radius: 8px;
                        max-width: 180px;
                        min-width: 180px;
                    }
                    .searchBtn{
                            position: absolute;
                            width: 100%;
                            padding: 5px 5px;
                            //border: 1px solid black;
                            color: white;
                            font-weight: 600;
                            background: #33A478;
                            border-radius: 8px;
                            max-width: 180px;
                            left: 17%;
                            top: 85px;
                            z-index: 1;
                    }
                    .searchBtn:hover{
                        width: 100%;
                        padding: 5px 5px;
                        // border: 1px solid black;
                        color: white;
                        background: #33A478;
                        background-image: linear-gradient(196deg, black, transparent);
                        border-radius: 8px;
                        max-width: 180px;
                        min-width: 180px;
                    }
                    .courselistBtn{
                        width: 100%;
                        padding: 5px 5px;
                        // border: 1px solid black;
                        // background: #33A478;
                        border-radius: 8px;
                        max-width: 180px;
                        min-width: 180px;
                        border: 1px solid transparent;
                    }
                    .courselistBtn a{
                        color: black;
                    }
                    .btn.btnviewactive{
                        background: #33A478;
                        background-image: linear-gradient(196deg, black, transparent);
                        color: white;
                        border-radius: 8px;
                        border: 1px solid #33A478;
                    } 
                    // .btn.btnviewactive:hover{
                    //     background: #33A478;
                    //     color: black;
                    //     border-radius: 8px;
                    // }
                    .btn.btnviewactive:focus{
                        box-shadow: none;
                    } 
                    .allCourseTabHeading{
                        color: #333742 !important;
                    }
                    .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active{
                        color: #495057 !important;
                        border-bottom: 4px solid #33A478 !important;
                    }

                    .nav-item-allcourse{
                        padding: 0px;
                    }
                   
                    .nav-tabs .nav-item-allcourse{
                        margin-bottom: -3px !important;
                        font-size: 22px;
                        font-weight: 600;
                        color: #333742 !important;
                    }
                    #buttonSection{
                        position: relative;
                        margin-top: 20px;
                        //left: -2%;
                        //transform: translateX(14%);
                        display: flex;
                        width: 100%;
                       // max-width: 250px;
                    }
                    .hrDesg{
                        border: 0;
                        height: 1px;
                        background-image: linear-gradient(to right,  rgba(0, 0, 0, 0),#33A478, rgba(0, 0, 0, 0));
                    }
                    @media only screen and (max-width: 400px){
                        .nav-tabs .nav-link{
                            width: 260px;
                        }
                        .nav-tabs .nav-link{
                            width: 265px;
                        }
                        .fstCarousel{
                            margin-top: 30px;
                        }
                        .secondCarousel{
                            margin-top: 30px;
                            display: none;
                        }
                        #buttonSection{
                            display: none;
                        }
                    }
                    @media only screen and (min-width: 400px) and (max-width: 600px){
                        #buttonSection{
                            position: relative;
                            margin-top: 20px;
                            left: 11%;
                            transform: translateX(14%);
                            display: flex;
                            width: 100%;
                            max-width: 250px;
                        }
                        .viewbtn{
                            width: 100%;
                            padding: 5px 5px;
                            border: 1px solid black;
                            color: black;
                            background: white;
                            border-radius: 8px;
                        }
                        .viewbtn:hover{
                            color: black;
                        }
                        .viewbtn:focus{
                            outline: none;
                        }
                        .nav-tabs .nav-link{
                            width: 313px;
                        }
                        .fstCarousel{
                            margin-top: 30px;
                        }
                        .secondCarousel{
                            margin-top: 30px;
                            display: none;
                        }
                        #buttonSection{
                            display: none;
                        }
                    }
                    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px)  { 
                        .nav-tabs .nav-item-allcourse{
                            margin-bottom: -3px !important;
                            font-size: 20px;
                            font-weight: 600;
                            color: #333742 !important;
                        }
                        #buttonSection{
                            display: none;
                        }
                    }
                   
                    `}
                </style>
                <div className="container-fluid">
              
                    <div className="row">
                    
                        <div className="col-md-12">
                        <div className="container">
                            {/* <ul className="nav nav-tabs nav-justified" id="myTab" role="tablist">
                                <li className="nav-item nav-item-allcourse">
                                    <a className="nav-link allCourseTabHeading active" data-toggle="tab" href="#allcourses" role="tab" aria-selected="true">All Courses</a>
                                </li>
                                <li className="nav-item nav-item-allcourse">
                                    <a className="nav-link allCourseTabHeading" data-toggle="tab" href="#ongoingCourses" role="tab" aria-selected="false">Ongoing Courses</a>
                                </li>
                                <li className="nav-item nav-item-allcourse">
                                    <a className="nav-link allCourseTabHeading" data-toggle="tab" href="#upcomingCourses" role="tab" aria-selected="false">Upcoming Courses</a>
                                </li>
                            </ul>  */}
                        </div>
                        <div className="container">
                            <div className="btn-group-view row" id="buttonSection" style={{ marginTop:'28px' }} >
                                {/* <button className="viewbtn"><a href="/schedule_courses">Search Schedule</a></button>&nbsp;&nbsp; */}
                                {/* <button onClick={this.onClickGridView} className="btn btn-default viewbtn btnviewactive"><i className="fa fa-sliders" aria-hidden="true"></i> Slider View</button>&nbsp;&nbsp;  */}
                                {/* <button onClick={this.onClickListView} className="btn btn-default viewbtn"><i className="fa fa-list" aria-hidden="true"></i> List View</button> */}
                                {/* <div className="col-md-6" align="center">
                                <a href="/course_list_view"><button className="btn btn-default viewbtn"><i className="fa fa-list" aria-hidden="true"></i> List View</button></a>&nbsp;
                                </div>
                                <div className="col-md-6" align="center">
                                <a href="/schedule_courses"><button className="btn btn-default viewbtn">Search Schedule</button></a>&nbsp;
                                </div> */}



                                {/* <a href="/course_list_view"><button className="btn btn-default viewbtn"><i className="fa fa-list" aria-hidden="true"></i> List View</button></a>&nbsp; */}
                                                              
                                {/* <a href="/schedule_courses"><button className="btn btn-default  searchBtn">Search Schedule</button></a>&nbsp; */}



                            </div>
                        </div><br/>
                        {/*<hr className="hrDesg"/>*/}
                        <div className="tab-content" id="myTabContent">
                            <div className="tab-pane fade show active" id="allcourses" role="tabpanel">
                                <div  className="fstCarousel">
                                    <Carousel heading="Our latest Courses" cid="1" id='1'/>
                                </div>
                                {/* <div className="secondCarousel" style={{ display: 'none' }}>
                                    <ListCarousel cid="1" heading="Our latest Courses" />
                                </div> */}
                            </div>

                            <div className="tab-pane fade show" id="upcomingCourses" role="tabpanel">
                                <div  className="fstCarousel">
                                    <Carousel heading="Enroll Our Upcoming Courses" cid="3" id='3'/>
                                </div>
                                {/* <div className="secondCarousel" style={{ display: 'none' }}>
                                    <ListCarousel cid="3" heading="Enroll Our Upcoming Courses" />
                                </div> */}
                            </div>

                            <div className="tab-pane fade show " id="ongoingCourses" role="tabpanel">
                                <div  className="fstCarousel">
                                    <Carousel heading="Enroll our Ongoing courses" cid="2" id="2"/>
                                </div>
                                {/* <div className="secondCarousel" style={{ display: 'none' }}>
                                    <ListCarousel cid="2" heading="Enroll our Ongoing courses" />
                                </div> */}
                            </div>
                        </div>

                        </div>
                    </div>
                </div>
        </div>
        )
    }
}

export default AllCourses;