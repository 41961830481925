import React, { Component } from 'react';
import Header from '../src/components/liveTrainingDesign/liveTrainingHeader';
import FooterTraining from "../src/components/liveTrainingDesign/footerTraining.jsx";
import Dropdownpage from '../src/components/liveTrainingDesign/categoryDropdownPage.jsx';
import WhatsappComponent from './components/liveTrainingDesign/whatsappcomponent';

class ViewCategoryCourses extends Component{
    render(){
        return(
            <div>
                <Header /><br/><br/>
                <Dropdownpage />
                <WhatsappComponent />
                <FooterTraining />
            </div>
        )
    }
}

export default ViewCategoryCourses;