import React, { Component } from 'react';
import Modal from 'react-responsive-modal';
import $ from 'jquery';
import Swal from 'sweetalert2';

var value;
class FaceBookBatch extends Component{
    constructor(props){
    super(props);
    console.log(props,'chooseBatch')
        this.state = {
            openSession: false,
            dateType : 'text',
            dateTo : 'text',
            errors: {},
            name: '',
            email: '',
            contact_number: '',
            city: '',
            session_id: '',
            schedule_name: '',
            schedule_email: '',
            schedule_phonenumber: '',
            schedule_fromDate: '',
            schedule_toDate: '',
            checkboxSchedule: 'true'
        }  
    }
    openSessionModal = (a) => {
        // console.log('oncick particular id', a)
        this.setState({ openSession : true,session_id: a})
    }
    onCloseSessionModal = () => {
        $(".radioBtn").prop( "checked", false );
        this.setState({ openSession : false,errors: {},name : '',email: '',contact_number: '',city: '' })
    }
    onFocusShowFromDate = ()  => {
        this.setState({ dateType: 'date'})
    }
    onFocusShowToDate = ()  => {
        this.setState({ dateTo: 'date'})
    }
    onSessionSubmit = (e) => {
        e.preventDefault();
  
		let errors = {};
		let formIsValid = false;
		
        if(!this.state.name){
            formIsValid = true
            errors['name'] = "This field is required"
        }
        else if(!this.state.name.match(/^[a-zA-Z]+(?:[\s.]+[a-zA-Z]+)*$/)){
            formIsValid = true;
            errors['name'] = "Please enter only characters";
        }    
        else if(!this.state.email) {
            formIsValid = true
            errors['email'] = "This field is required"
        }
        else if(!this.state.email.match(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)){
            formIsValid = true
            errors['email'] = "Please enter valid email"
        }
        else if(!this.state.contact_number){
            formIsValid = true
            errors['contact_number'] = "This field is required"
        }
        else if(this.state.contact_number.length < 10){
            formIsValid = true
            errors['contact_number'] = "Minimum should be 10 characters"
        }
        else if(this.state.contact_number.length > 10){
            formIsValid = true
            errors['contact_number'] = "Maximum should be 10 characters"
        }
		else if(!this.state.city){
			formIsValid = true
			errors['city'] = "This field is required"
        }
        
		if(Object.keys(errors).length > 0){
			this.setState({errors:errors})
			return formIsValid;
        }

        fetch("https://live.skillrary.com/live-courses/index.php/api/v1/ads-page-demo", {
	
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json'
			},
			body: JSON.stringify({
                name: this.state.name,
                email_id: this.state.email,
                mobile: this.state.contact_number,
                city: this.state.city,
                course_id : this.props.courseId,
                session_id: this.state.session_id,
			})
			})
			.then(res => res.json())
			.then(
				(result) => {
	
				console.log('rest', result);
	
				if(result.status == 200){
					Swal.fire({
					type: 'success',
					title: 'Thank you, Your request is submitted successfully',
					showConfirmButton: false,
					timer: 2500
					})
                this.setState({name : '',email: '',contact_number: '',city: '',openSession: false})
                $(".radioBtn").prop( "checked", false );
				}
				else if(result.status == 400){
					Swal.fire({
					type: 'error',
					title: 'Oops...',
					text: 'Something went wrong!',
					footer: '<a href>Why do I have this issue?</a>'
					})
				}
				},
			)
    }
    getMonthName = (month) => {
		//console.log('a',month)
		var monthName = "";
		switch(parseInt(month)) {
			case 1 : monthName = "Jan";
					 break;
			case 2 : monthName = "Feb";
					 break;
			case 3 : monthName = "Mar";
					 break;
			case 4 : monthName = "Apr";
					 break;
			case 5 : monthName = "May";
					 break;
			case 6 : monthName = "Jun";
					 break;
			case 7 : monthName = "Jul";
					 break;
			case 8 : monthName = "Aug";
					 break;
			case 9 : monthName = "Sep";
					 break;
			case 10 : monthName = "Oct";
					 break;
			case 11 : monthName = "Nov";
					 break;
			case 12 : monthName = "Dec";
					 break;
		}

		return monthName;
    }
    get12HourFormat = (hour) => {
		var formatHour = 0;
		switch(parseInt(hour)) {
			case 13 : formatHour = 1;
					 break;
			case 14 : formatHour = 2;
					 break;
			case 15 : formatHour = 3;
					 break;
			case 16 : formatHour = 4;
					 break;
			case 17 : formatHour = 5;
					 break;
			case 18 : formatHour = 6;
					 break;
			case 19 : formatHour = 7;
					 break;
			case 20 : formatHour = 8;
					 break;
			case 21 : formatHour = 9;
					 break;
			case 22 : formatHour = 10;
					 break;
			case 23 : formatHour = 11;
					 break;
		}

		return formatHour;
    }	

    componentDidMount(){
        var dtToday = new Date();
        var month = dtToday.getMonth() + 1;
        var day = dtToday.getDate();
        var year = dtToday.getFullYear();
        if(month < 10)
            month = '0' + month.toString();
        if(day < 10)
            day = '0' + day.toString();
        var maxDate = year + '-' + month + '-' + day;
        $('.date').attr('min', maxDate);
    }
    onScheduleSubmit = (e) => {
        e.preventDefault();

		let errors = {};
		let formIsValid = false;
		
        if(!this.state.schedule_name){
            formIsValid = true
            errors['schedule_name'] = "This field is required"
        }
        else if(!this.state.schedule_name.match(/^[a-zA-Z]+(?:[\s.]+[a-zA-Z]+)*$/)){
            formIsValid = true;
            errors['schedule_name'] = "Please enter only characters";
        }    
        else if(!this.state.schedule_email) {
            formIsValid = true
            errors['schedule_email'] = "This field is required"
        }
        else if(!this.state.schedule_email.match(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)){
            formIsValid = true
            errors['schedule_email'] = "Please enter valid email"
        }
        else if(!this.state.schedule_phonenumber){
            formIsValid = true
            errors['schedule_phonenumber'] = "This field is required"
        }
        else if(this.state.schedule_phonenumber.length < 10){
            formIsValid = true
            errors['schedule_phonenumber'] = "Minimum should be 10 characters"
        }
        else if(this.state.schedule_phonenumber.length > 10){
            formIsValid = true
            errors['schedule_phonenumber'] = "Maximum should be 10 characters"
        }
		else if(!this.state.schedule_fromDate){
			formIsValid = true
			errors['schedule_fromDate'] = "This field is required"
        }
        else if(!this.state.schedule_toDate){
			formIsValid = true
			errors['schedule_toDate'] = "This field is required"
        }
        
		if(Object.keys(errors).length > 0){
			this.setState({errors:errors})
			return formIsValid;
        }

        fetch("https://live.skillrary.com/live-courses/index.php/api/v1/ads-page-request", {
	
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json'
			},
		
			body: JSON.stringify({
                name: this.state.schedule_name,
                email_id: this.state.schedule_email,
                mobile: this.state.schedule_phonenumber,
                start_from: this.state.schedule_fromDate,
                ends_to: this.state.schedule_toDate,
                course_id : this.props.courseId,
                contact_me:  this.state.checkboxSchedule,
			})
			})
			.then(res => res.json())
			.then(
				(result) => {
	
				console.log('rest', result);
	
				if(result.status == 200){
					Swal.fire({
					type: 'success',
					title: 'Thank you, Your request is submitted successfully',
					showConfirmButton: false,
					timer: 2500
					})
                this.setState({schedule_name : '',schedule_email: '',schedule_phonenumber: '',schedule_fromDate: '',schedule_toDate:''})
                $(".schedule").prop( "checked", false );
				}
				else if(result.status == 400){
					Swal.fire({
					type: 'error',
					title: 'Oops...',
					text: 'Something went wrong!',
					footer: '<a href>Why do I have this issue?</a>'
					})
				}
				},
			)

    }
    onhandleCheckboxSchedule = () => {
        this.setState({checkboxSchedule: !this.state.checkboxSchedule});
    }
    render(){
        const { openSession } = this.state;
        // if (this.state.checkboxSchedule) {
        //     value = 'false'
        // } 
        // else {
        //     value = 'true'
        // }
        return(
            <div>
                <style jsx>
                    {`
                
                    .titleFor{
                        color:  #940808;
                        font-weight: 600;
                    }
                    .session_heading_radio{
                        width: 10%;
                    }
                    .session_heading{
                        width: 25%;
                    }
                    .sessionSubmitBtn{
                        background: #ffc339;
                        border: 1px solid #ffc339;
                        font-size: 16px;
                        padding: 5px 15px;
                        color: white;
                        font-weight: 600;
                        border-radius: 4px;
                    }
                    #logo{
                        position: absolute;
                        left: 20px;
                        top: 25px;
                        float: left;
                        color: #696969;
                    }
                    .sessionHeading{
                        font-weight: 700;
                        font-size: 16px;
                        color: #333742;
                        letter-spacing: 1.5px;
                        position: relative;
                        padding: 0px 0px 10px 0px;
                        margin-bottom: 20px;
                        text-align: center;
                    }
                    .sessionHeading:before{
                        content: ' ';
                        background: #ffc339;
                        box-shadow: 0 4px 8px 0 rgba(76,215,200,0.3);
                        width: 15%;
                        height: 4px;
                        position: absolute;
                        bottom: 0;
                        left: 50%;
                        transform: translate(-50%);
                    }
                    .errTextSession{
                        font-size: 12px;
                        font-weight: normal;
                        color: red;
                        // width: 300px;
                        bottom: 0;
                        left: 20px;
                        top: 50px;
                        position: absolute;
                        z-index: 1;
                    }
                    .sessionWidth{
                        width: 600px !important;
                        margin-top: 50px;
                    }
                    .inputField{
                        font-size:15px;
                        border: 0;
                        outline: 0;
                        position: relative;
                        background: transparent;
                        border-bottom: 1px solid #ffc339;
                        // -webkit-text-fill-color: black;
                        padding-left:25px;
                        padding-top:23px;
                        display: flex;
                        width: 100%;
                    }
                    .ChooseBatch{
                        background: black;
                        padding: 20px;
                    }
                    .scheduleButton{
                        padding: 15px 35px;
                        font-size: 30px;
                        background: black;
                        color: #ffc339;
                        font-weight: 600;
                        border: 1px solid black;
                        letter-spacing: 1px;
                        // width: 45%;
                        box-shadow: 5px 5px #b9b9b8;
                    }
                    .chooseBatchText{
                        color: white;
                        font-size: 30px;
                    }
                    .scheduleDetails{
                        background: #f1f1ef;
                        padding: 20px;
                        margin-bottom: 40px;
                    }
                    .tableView{
                        width: 100%;
                        text-align: center;
                    }
                    .moreDetails{
                        padding: 15px;
                        font-size: 20px;
                        background: #ffc339;
                        border: 1px solid #ffc339;
                        color: black;
                        box-shadow: 5px 5px #b9b9b8;
                    }
                    .link{
                        color: black;
                        text-decoration: none;
                    }
                    .link:hover{
                        color:  #940808;
                        text-decoration: inherit;
                    }
                    .ChooseBatchContainer{
                        background: #FFFDF8;
                        padding: 30px 0px 30px 0px;
                        margin-top: 10%;
                    }
                    .flexible{
                        margin-top: 100px;
                        margin-bottom: -40px;
                        font-size: 32px;
                        color: white;
                    }
                    @media only screen and (max-width: 600px){
                        .ChooseBatchContainer {
                            background: #FFFDF8;
                            padding: 30px 0px 30px 0px;
                            margin-top: 15%;
                        }
                        .sessionWidth{
                            width: 290px !important;
                            margin-top: 50px;
                        }
                        .form-check-input {
                            position: absolute;
                            margin-top: .3rem;
                            margin-left: 25px !important;
                        }
                        .form-check-label {
                            margin-bottom: 0;
                            margin-left: 47px;
                        }      
                        .input_field_free{
                            display: inline-flex;
                            align-items: center;
                            justify-content: center;
                            margin-bottom: 20px;
                        }     
                        .form-control{
                            width: initial;
                        } 
                        .scheduleDates{
                            margin-left: 50%;
                            transform: translate(-50%);
                        }
                    }
                    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px)  { 
                        .flexible{
                            margin-top: 100px;
                            margin-bottom: initial;
                            font-size: 32px;
                            color: white;
                        }
                    }
                    `}
                </style>
                <div className="container-fluid ChooseBatch">
                    <div className="row">
                       <div className="col-md-7">
                            <div className="row">
                                <div className="col-xl-4 col-md-6 offset-md-1">
                                    <p className="scheduleButton">Schedule</p>
                                </div>
                            </div><br/><br/>
                            <div className="row">
                                <div className="col-md-6 offset-md-1">
                                    {undefined !== this.props.facebook_sessions ? (this.props.facebook_sessions.length === 0 ? '' : <h4 className="chooseBatchText">Choose a Batch</h4>):""}
                                </div>
                            </div><br/><br/>
                            <div className="row">
                                <div className="col-md-8 offset-md-1">
                                    {undefined !== this.props.facebook_sessions && this.props.facebook_sessions.map((session) => {
                                        var thestartDate = new Date(session.start_date * 1000);
                                        var startdateString = thestartDate.toGMTString();
                                        var resstart = startdateString.split(" ");
                                        // console.log(resstart[1],resstart[2],'starts dates')
                        
                                        var theDate = new Date(session.end_date * 1000);
                                        var enddateString = theDate.toGMTString();
                                        var resend = enddateString.split(" ");
                                        // console.log(resend[1],resend[2],'ends dates')

                                        var d = new Date(session.start_IST);
                                        var month = this.getMonthName(d.getMonth()+1);
                                        var day = d.getDate();
                                        var startTimeHour = d.getHours();
                                        var startTimeMin = d.getMinutes();
                                        var startTimeM = "AM";
                                        if (startTimeHour > 12) {
                                            startTimeM = "PM";
                                            startTimeHour = this.get12HourFormat(startTimeHour);
                                        }
                                    
                                        if (startTimeMin < 10) {
                                            startTimeMin = "0"+startTimeMin;
                                        }
                                  
                                        var startDateObj = new Date(parseInt(session.start_date) * 1000);
                                        var startstr = startDateObj.toLocaleString("en-US", {timeZone: "Asia/Kolkata"})
                                        var startres = startstr.split(","); 
                                        var starttimeA = startres[startres.length-1].substr(0,5);
                                          
                                        // console.log(month,day,starttimeA,startTimeM,'session start_IST')

                                        var d = new Date(session.end_IST);
                                        var month = this.getMonthName(d.getMonth()+1);
                                        var day = d.getDate();
                                        var endTimeHour = d.getHours();
                                        var endTimeMin = d.getMinutes();
                                        var endTimeM = "AM";
                                        if (endTimeHour > 12) {
                                            endTimeM = "PM";
                                            endTimeHour = this.get12HourFormat(endTimeHour);
                                        }
                                    
                                        if (endTimeMin < 10) {
                                            endTimeMin = "0"+endTimeMin;
                                        }
                                  
                                        var endDateObj = new Date(parseInt(session.end_date) * 1000);
                                        var endstr = endDateObj.toLocaleString("en-US", {timeZone: "Asia/Kolkata"})
                                        var endres = endstr.split(","); 
                                        var endtimeA = endres[endres.length-1].substr(0,5);
                                        // console.log(month,day,endtimeA,endTimeM,'session end_IST') 

                                        return <div className="scheduleDetails">
                                            <table className="tableView">
                                                <tbody>
                                                    <tr>
                                                        <th className="session_heading"><i className="fa fa-calendar-o" aria-hidden="true"></i> Date</th>
                                                        <th className="session_heading"><i className="fa fa-clock-o" aria-hidden="true"></i> Time</th>
                                                        <th className="session_heading">Class Type</th>
                                                        <th className="session_heading_radio"></th>
                                                    </tr>
                                                
                                                        <tr>
                                                        <td>{resstart[1]} {resstart[2]}-{resend[1]} {resend[2]}</td>
                                                        <td>{starttimeA + ' ' + startTimeM + '-' + endtimeA + ' ' + endTimeM}</td>
                                                        <td>{session.session_types === 'week' ? 'Weekdays' : 'Weekend(Sat-Sun)'}</td>
                                                        <td><input type="radio" name="session" className="radioBtn" onClick={() => this.openSessionModal(session.id)}></input></td>
                                                    </tr> 
                                                </tbody>
                                            </table>
                                        </div> 
                                    })}
                                </div>
                            </div>
                           
                            <div className="row" style={{ textAlign: 'center' }}>
                                <div className="col-md-7 offset-md-1">
                                    <a href={"https://live.skillrary.com/" + this.props.slugname} target="_blank" className="link"><p className="moreDetails">For more details on <span className="titleFor">{this.props.facebook_title}</span> <br/>Visit: https://live.skillrary.com/{this.props.slugname}</p></a>
                                </div>
                            </div>
                        </div> 
                        <div className="col-md-4">
                            <h3 className="flexible">For Flexible Schedule Contact Us</h3>
                            <form onSubmit={this.onScheduleSubmit}>
                                <div className="ChooseBatchContainer">
                                    <div className="row">
                                        <div className="col-md-8 offset-md-2" style={{ marginBottom: '20px'}}>
                                            <input type="text" placeholder="Name" name="schedule_name" className="form-control" onChange={(v) => this.setState({schedule_name:v.target.value,errors:false})}
                                            value={this.state.schedule_name} autoComplete="off"/>
                                            <span className="errTextFacebook">{this.state.errors.schedule_name}</span>
                                        </div>
                                        <div className="col-md-8 offset-md-2" style={{ marginBottom: '20px'}} >
                                            <input type="text" placeholder="Email" name="schedule_email" className="form-control" onChange={(v) => this.setState({schedule_email:v.target.value,errors:false})}
                                            value={this.state.schedule_email} autoComplete="off"/>
                                            <span className="errTextFacebook">{this.state.errors.schedule_email}</span>
                                        </div>
                                        <div className="col-md-8 offset-md-2" style={{ marginBottom: '20px'}}>
                                            <input type="text" placeholder="Phone number" name="schedule_phonenumber" className="form-control" onChange={(v) => this.setState({schedule_phonenumber:v.target.value,errors:false})}
                                            value={this.state.schedule_phonenumber} autoComplete="off"/>
                                            <span className="errTextFacebook">{this.state.errors.schedule_phonenumber}</span>
                                        </div>
                                        <div className="col-md-8 offset-md-2 scheduleDates" style={{ marginBottom: '20px'}}>
                                            <label>Schedule:</label>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <input type={this.state.dateType} onFocus={this.onFocusShowFromDate} onChange={(v) => this.setState({schedule_fromDate:v.target.value,errors:false})}
                                                    value={this.state.schedule_fromDate} placeholder="From" className="form-control date" autoComplete="off"/>
                                                    <span className="errTextFacebook">{this.state.errors.schedule_fromDate}</span>
                                                </div><br/><br/><br/>
                                                <div className="col-md-6">
                                                    <input type={this.state.dateTo} onFocus={this.onFocusShowToDate} placeholder="To" className="form-control date" onChange={(v) => this.setState({schedule_toDate:v.target.value,errors:false})}
                                                    value={this.state.schedule_toDate} autoComplete="off"/>
                                                    <span className="errTextFacebook">{this.state.errors.schedule_toDate}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-7 offset-md-3" style={{ color: '#656262'}} style={{ marginBottom: '20px'}}>
                                            <input class="form-check-input schedule" type="checkbox" checked onChange={this.onhandleCheckboxSchedule} value={this.state.checkboxSchedule} />
                                            <label class="form-check-label" for="defaultCheck1">
                                            I authorize SkillRary representative to contact me via phone, SMS and/or email
                                            </label>
                                        </div>
                                    </div>
                                    <div align="center" style={{ marginTop: '2%' }}>
                                        <button className="downloadButton">Submit</button>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <Modal open={openSession} onClose={this.onCloseSessionModal} closeOnOverlayClick={false} closeIconSize={22} styles={{ modal: { marginTop: '10%' }}}>
                            <div className="sessionWidth" >
                                <h2 className="sessionHeading" style={{ marginTop: '-50px' }}>Request for a Session</h2>
                                {/* <p style={{ fontSize: '13px', textAlign: 'center' }}>Please fill in the details and Skillrary Course Advisor will get back within 24 hrs to help you up skill your team</p> */}
                                <div className="container" align="center">
                                    <form onSubmit={this.onSessionSubmit}>
                                        <div className="row">
                                            <div className="col-md-6 offset-md-3"  style={{ marginBottom: '-15px'}}>
                                                <div className="form-group">
                                                    <input type="text" name="name" className="inputField" 
                                                    placeholder="Name"  autoComplete="off" onChange={(v) => this.setState({name: v.target.value, errors: false})} value={this.state.name}
                                                   />
                                                    <i className="fa fa-user" id="logo"></i>
                                                    <span className="errTextSession">{this.state.errors.name}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6 offset-md-3" style={{ marginBottom: '-15px'}}>
                                                <div className="form-group">
                                                    <input type="text" name="email" className="inputField" 
                                                    placeholder="E-mail"  autoComplete="off"
                                                    onChange={(v) => this.setState({email: v.target.value,errors: false})} value={this.state.email} />
                                                    <i className="fa fa-envelope" id="logo"></i>
                                                    <span className="errTextSession">{this.state.errors.email}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6 offset-md-3"  style={{ marginBottom: '-15px'}}>
                                                <div className="form-group">
                                                    <input type="number" name="contact_number" className="inputField" 
                                                    placeholder="Phone"  autoComplete="off"
                                                    onChange={(v) => this.setState({contact_number: v.target.value,errors: false})} value={this.state.contact_number}/>
                                                    <i className="fa fa-phone" aria-hidden="true" id="logo"></i>
                                                    <span className="errTextSession">{this.state.errors.contact_number}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6 offset-md-3"  style={{ marginBottom: '-15px'}}>
                                                <div className="form-group">
                                                    <input type="text" name="city" className="inputField" 
                                                    placeholder="City"  autoComplete="off"
                                                    onChange={(v) => this.setState({city: v.target.value,errors: false})} value={this.state.city}/>
                                                   <i className="fa fa-home" aria-hidden="true" id="logo"></i>
                                                    <span className="errTextSession">{this.state.errors.city}</span>
                                                </div>
                                            </div>
                                        </div><br/>
                                        <div className="row" style={{ justifyContent: "center" }}>
                                            <button className="sessionSubmitBtn">Submit</button>
                                        </div> 
                                    
                                        {/* <div className="row">
                                            <div className="col-md-6 offset-md-3"  style={{ marginBottom: '-15px'}}>
                                                <div className="form-group">
                                                    <input type="text" name="name" className="inputField" 
                                                    placeholder="Name"  autoComplete="off"
                                                    />
                                                    <i className="fa fa-user" aria-hidden="true" id="logo"></i>
                                                    <span className="errTextSession">{this.state.errors.name}</span>
                                                </div>
                                            </div>
                                            <div className="col-md-6 offset-md-3"  style={{ marginBottom: '-15px'}}>
                                                <div className="form-group">
                                                    <input type="text" name="email" className="inputField" 
                                                    placeholder="Email"  autoComplete="off"
                                                    />
                                                    <i className="fa fa-envelope" aria-hidden="true" id="logo"></i>
                                                    <span className="errTextSession">{this.state.errors.email}</span>
                                                </div>
                                            </div>
                                            <div className="col-md-6 offset-md-3"  style={{ marginBottom: '-15px'}}>
                                                <div className="form-group">
                                                    <input type="number" name="contact_number" className="inputField" 
                                                    placeholder="Phone"  autoComplete="off"
                                                    />
                                                    <i className="fa fa-phone" aria-hidden="true" id="logo"></i>
                                                    <span className="errTextSession">{this.state.errors.contact_number}</span>
                                                </div>
                                            </div>
                                            <div className="col-md-6 offset-md-3"  style={{ marginBottom: '-15px'}}>
                                                <div className="form-group">
                                                    <input type="text" name="city" className="inputField" 
                                                    placeholder="City"  autoComplete="off"
                                                    />
                                                    <i className="fa fa-home" aria-hidden="true" id="logo"></i>
                                                    <span className="errTextSession">{this.state.errors.city}</span>
                                                </div>
                                            </div>
                                            <div className="row" style={{ justifyContent: "center" }}>
                                                <button className="sessionSubmitBtn">Submit</button>
                                            </div> 
                                        </div> */}
                                    </form>
                                </div> 
                            </div>
                        </Modal> 
                    </div>
                </div>
            </div>
        )
    }
}

export default FaceBookBatch;
