
import React, { Component } from 'react';
import srlogo from '../liveTrainingDesign/assets/shir.png';
import srlogo1 from '../liveTrainingDesign/assets/mithun.png';

class WebinarTrainer extends Component{
    render(){
        return(
            <div>
                <style jsx>
                    {`
                    .buttonText{
                        color: black;
                    }
                    .buttonText:hover{
                        text-decoration: none;
                        color: black;
                    }
                    .contentImage{
                        margin-bottom: 20px;
                    }
                    .webinarTContainer{
                        background: #EDEDED;
                        padding:70px 25px;
                    }
                    .trainer_image{
                        width: 100%;
                        max-width: 170px;
                        min-width: 170px;
                        border-radius: 50%;
                        height: 170px;
                    }
                    .buttonContainer{
                        background: #0548CE;
                        padding: 20px 20px 20px 30px;
                        text-align: center;
                        margin: 25px;
                        border-radius: 20px;
                    }
                    .buttonKnowMore{
                        background: transparent;
                        border: 1px solid transparent;
                        font-size: 20px;
                        color: black;
                    }
                    .buttonKnowMore:hover{
                        color: black;
                        text-decoration: none;
                    }
                    .buttonDesign{
                        background: #F9CA33;
                        border: 1px solid #F9CA33;
                        padding: 15px;
                        width: 100%;
                        max-width: 210px;
                        min-width: 210px;
                        font-size: 20px;
                        margin-bottom: 15px;
                        box-shadow: 4px 4px #e6e6e6;
                    }
                    .buttonDesign:last-child{
                        margin:20px;
                    }
                    .trainer_details{
                        margin-left: 45px;
                    }
                    @media only screen and (max-width: 600px){
                        .buttonDesign:last-child{
                            margin-left: initial;
                        }
                        .trainer_image {
                            width: 100%;
                            max-width: 150px;
                            min-width: 150px;
                            border-radius: 50%;
                            height: 150px;
                            text-align: center;
                            left: 50%;
                            position: relative;
                            transform: translateX(-50%);
                        }
                        .buttonContainer {
                            background: #0548CE;
                            padding: 20px 20px 20px 20px;
                            text-align: center;
                            margin: 25px;
                            border-radius: 20px;
                        }
                    }
                    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px)  { 
                        .buttonDesign:last-child{
                            margin-left: 10px;
                        }
                    }
                    `}
                </style>
                <div className="container-fluid webinarTContainer">
                    <div className="row" style={{ margin: '0', padding: '0' }}>
                        <div className="col-lg-5 col-md-6 offset-lg-1">
                            <div className="row">
                                <div className="col-lg-3 col-md-6">
                                    <div className="contentImage">
                                        <img src={srlogo} alt="trainer_image" className="trainer_image"/>
                                    </div>
                                </div>
                                <div className="col-lg-9 col-md-6">
                                    <div className="trainer_details">
                                        <h4>Java Webinar by Dr. Shishira Bhat</h4>
                                        <p>He leads the online training unit QSpiders/QSpiders and responsible for Training, global marketing & communication activites including brand management , market development, corporate  communications</p>
                                        <p><a href="/webinar-shishirabhat" className="buttonKnowMore">Know More</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 offset-lg-1">
                            <div className="buttonContainer">
                                <button className="buttonDesign"><a href="https://skillrary.com/user/register" className="buttonText" target="_blank">Attend</a></button><button className="buttonDesign">Download Syllabus</button>
                            </div>
                        </div>
                    </div><br/><br/>

                    <div className="row" style={{ margin: '0', padding: '0' }}>
                        <div className="col-lg-5 col-md-6 offset-lg-1">
                            <div className="row">
                                <div className="col-lg-3 col-md-6">
                                    <div className="contentImage">
                                        <img src={srlogo1} alt="trainer_image" className="trainer_image"/>
                                    </div>
                                </div>
                                <div className="col-lg-9 col-md-6">
                                    <div className="trainer_details">
                                        <h4>DevOps Webinar by Mr. Mithun Ashok</h4>
                                        <p>DevOps Expert, Currently Heads a Startup SkillRary.
                                        Experienced Vice President Technology with a demonstrated
                                        history of working in the telecommunications industry.</p>
                                        {/* <p><a href="/webinar_knowmore" className="buttonKnowMore">Know More</a></p> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 offset-lg-1">
                            <div className="buttonContainer">
                                <button className="buttonDesign"><a href="https://skillrary.com/user/register" className="buttonText" target="_blank">Attend</a></button><button className="buttonDesign">Download Syllabus</button>
                            </div>
                        </div>
                    </div>

                    {/* <div className="row" style={{ margin: '0', padding: '0' }}>
                        <div className="col-md-5 offset-md-1">
                            <div className="row">
                                <div className="col-md-3">
                                    <img src={srlogo} alt="trainer_image" className="trainer_image"/>
                                </div>
                                <div className="col-md-9">
                                    <h4>Java Webinar by Dr. Shishira Bhat</h4>
                                    <p>He leads the online training unit QSpiders/QSpiders and responsible for Training, global marketing & communication activites including brand management , market development, corporate  communications</p>
                                    <p><a href="#" className="buttonKnowMore">Know More</a></p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 offset-md-1">
                            <div className="buttonContainer">
                                <button className="buttonDesign">Attend</button><button className="buttonDesign">Download Syllabus</button>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        )
    }
}

export default WebinarTrainer;