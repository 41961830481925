import React, { Component } from "react";
import Particles from "react-particles-js";

class ParticleComponent extends Component{
    render(){
        return(
            <div>
                <style jsx>
                {`
                canvas{
                    position : fixed; 
                    top:0 ; 
                    left: 0; 
                    width:100%;
                    height:100%; 
                    z-index:-1;
                }
                `}
                </style>
                <Particles
                params={{
                    particles: {
                    number: {
                        value: 100,
                        density: {
                        enable: true,
                        value_area: 700
                        }
                    },
                    color: {
                        value: "#33A478"
                    },
                    shape: {
                        type: "circle",
                        stroke: {
                        width: 0,
                        color: "#33A478"
                        },
                        polygon: {
                        nb_sides: 3
                        },
                        image: {
                        src: "img/github.svg",
                        width: 400,
                        height: 400
                        }
                    },
                    opacity: {
                        value: 0.5,
                        random: false,
                        anim: {
                        enable: false,
                        speed: 1,
                        opacity_min: 0.1,
                        sync: false
                        }
                    },
                    size: {
                        value: 10,
                        random: true,
                        anim: {
                        enable: false,
                        speed: 40,
                        size_min: 0.1,
                        sync: false
                        }
                    },
                    line_linked: {
                        enable: true,
                        distance: 10,
                        color: "#33A478",
                        opacity: 0.4,
                        width: 1
                    },
                    move: {
                        enable: true,
                        speed: 1,
                        direction: "none",
                        random: false,
                        straight: false,
                        out_mode: "out",
                        attract: {
                        enable: false,
                        rotateX: 600,
                        rotateY: 1200
                        }
                    }
                    },
                    interactivity: {
                    detect_on: "canvas",
                    events: {
                        onhover: {
                        enable: true,
                        mode: "repulse"
                        },
                        onclick: {
                        enable: true,
                        mode: "push"
                        },
                        resize: true
                    },
                    modes: {
                        grab: {
                        distance: 400,
                        line_linked: {
                            opacity: 1
                        }
                        },
                        bubble: {
                        distance: 400,
                        size: 20,
                        duration: 2,
                        opacity: 8,
                        speed: 3
                        },
                        repulse: {
                        distance: 200
                        },
                        push: {
                        particles_nb: 4
                        },
                        remove: {
                        particles_nb: 2
                        }
                    }
                    },
                    retina_detect: true,
                    config_demo: {
                    hide_card: false,
                    background_color: "#b61924",
                    background_image: "",
                    background_position: "50% 50%",
                    background_repeat: "repeat",
                    background_size: "cover"
                    }
                }}
                />
            </div>
            );
}
}

export default ParticleComponent;
  