import React, { Component } from 'react';
import srlogo from '../liveTrainingDesign/assets/shir.png';
import $ from 'jquery';
import Modal from 'react-responsive-modal';
import Swal from 'sweetalert2';

class KnowMoreShi extends Component{

    constructor(props){
        super(props);
        this.state = {
            name: '',
            email: '',
            phone_number: '',
            errors: {},
            course_id:''
        }
    }

    componentDidMount(){
        $('.detail_KnowMore').click(function(){
            var link = $(this);
            $('.moreDetails').slideToggle('slow', function() {
                if ($(this).is(":visible")) {
                     link.text('Show Less');                
                } else {
                     link.text('Know More');                
                }        
            });
                
        });
    }

    onOpenDownloadModal = () => {
		this.setState({openDownload : true})
    }
    
	onCloseDownloadModal = () => {
        this.setState({openDownload : false})
        this.setState({name : '',email: '',phone_number:'',errors:{}})
	}
    
    submitDownloadData = (e) => {
        e.preventDefault();

        let errors = {};
		let formIsValid = false;
		

        if(!this.state.name){
            formIsValid = true
            errors['name'] = "This field is required"
        }
        else if(!this.state.name.match(/^[a-zA-Z]+(?:[\s.]+[a-zA-Z]+)*$/)){
            formIsValid = true;
            errors['name'] = "Please enter only characters";
        }   
		else if(!this.state.email) {
			formIsValid = true
			errors['email'] = "This field is required"
		}
		else if(!this.state.email.match(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)){
			formIsValid = true
			errors['email'] = "Please enter valid email"
		}
		else if(!this.state.phone_number){
			formIsValid = true
			errors['phone_number'] = "This field is required"
		}
		else if(this.state.phone_number.length < 10){
			formIsValid = true
			errors['phone_number'] = "Minimum should be 10 characters"
		}
		else if(this.state.phone_number.length > 10){
			formIsValid = true
			errors['phone_number'] = "Maximum should be 10 characters"
		}

		if(Object.keys(errors).length > 0){
			this.setState({errors:errors})
			return formIsValid;
        }
        
        fetch("https://live.skillrary.com/live-courses/index.php/api/v1/syllabus-request", {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json'
			},
			
			body: JSON.stringify({
				course_id: 0,
				name: this.state.name,
				email: this.state.email,
				contact_number: this.state.phone_number,
				request_from: 'webinar'
			})
		})
      .then(res => res.json())
      .then((result) => {
		  console.log('rest', result);
		  if(result.status == 200){
			
			window.open(`https://live.skillrary.com/live-courses/index.php/syllabus/core-java`,"_self");
			
			this.setState({name : '',email: '',phone_number:'',openDownload: false})
          }
          else if(result.status == 400){
            Swal.fire({
              type: 'error',
              title: 'Oops...',
              text: 'Something went wrong!',
              footer: '<a href>Why do I have this issue?</a>'
            })
          }
        })
    }
    
    render(){
        const { openDownload } = this.state
        return(
            <div>
                <style jsx>
                    {`
                    
                    .listingShi{
                        font-size: 20px;
                        list-style-type: none;
                    }
                   .downloadHeading{
                        font-weight: 700;
                        font-size: 16px;
                        color: #333742;
                        letter-spacing: 1.5px;
                        position: relative;
                        padding: 0px 0px 10px 0px;
                        margin-bottom: 20px;
                        text-align: center;
                    }
                    .downloadHeading:before{
                        content: ' ';
                        background: #F9CA33;
                        box-shadow: 0 4px 8px 0 rgba(76,215,200,0.3);
                        width: 15%;
                        height: 4px;
                        position: absolute;
                        bottom: 0;
                        left: 50%;
                        transform: translate(-50%);
                    }
                    .downloadenrollErrText{
                        font-size: 12px;
                        font-weight: normal;
                        color: red;
                        width: 300px;
                        bottom: 0;
                        // left: -75px;
                        top: 49px;
                        position: absolute;
                        z-index: 1;
                    }
                    .downloadSubmit{
                        background: #F9CA33;
                        border: 1px solid #F9CA33;
                        font-size: 16px;
                        padding: 5px 15px;
                        color: white;
                        font-weight: 600;
                        border-radius: 4px;
                    }
                    #downloaduserLogo{
                        position: absolute;
                        left: 20px;
                        top: 25px;
                        float: left;
                        color: #696969;
                    }
                    .downloadWidth{
                        width: 600px !important;
                        margin-top: 50px;
                    }
                    .downloadinputField{
                        font-size:15px;
                        border: 0;
                        outline: 0;
                        position: relative;
                        background: transparent;
                        border-bottom: 1px solid #F9CA33;
                        // -webkit-text-fill-color: black;
                        padding-left:25px;
                        padding-top:23px;
                        display: flex;
                        width: 100%;
                    }
                    .detailsContainer{
                        background: #EDEDED;
                        padding: 75px 25px 0px 25px;
                    }
                    .trainer_image{
                        border-radius: 200px;
                        // width: 100%;
                        margin-top: -30px;
                        margin-left: -21px;
                        // margin-bottom: 20px;
                    }
                    .trainer_details{
                        
                    }
                    .detail_heading{
                        font-size: 32px;
                        color:#0548CE;
                    }
                    .detail_content{
                        font-size: 20px;
                        text-align: justify;
                    }
                    .detail_KnowMore{
                        color: black;
                        font-size: 21px;
                        cursor: pointer;
                        font-weight: 600;
                    }
                    .detail_KnowMore:hover{
                        color: black;
                        text-decoration: none;
                    }
                    .knowbuttonContainer{
                        background: #0548CE;
                        padding: 20px 20px 20px 30px;
                        text-align: center;
                        margin: 25px;
                        border-radius: 20px;
                    }
                    .knowbuttonDesign{
                        background: #F9CA33;
                        border: 1px solid #F9CA33;
                        padding: 15px;
                        width: 100%;
                        max-width: 210px;
                        min-width: 210px;
                        font-size: 20px;
                        margin-bottom: 15px;
                        box-shadow: 4px 4px #e6e6e6;
                        color: black;
                    }
                    .knowbuttonDesign:last-child{
                        margin:20px;
                    }
                    .knowbuttonText{
                        color: black;
                    }
                    .knowbuttonText:hover{
                        text-decoration: none;
                        color: black;
                    }
                    @media only screen and (max-width: 600px){
                        .knowbuttonDesign:last-child{
                            margin:initial;
                        }
                        .downloadWidth{
                            width: 290px !important;
                            margin-top: 50px;
                        }
                        .detail_content {
                            font-size: 22px;
                            word-break: break-all;
                        }
                    }
                    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px)  { {
                        .trainer_image{
                            border-radius: 200px;
                            width: 100%;
                            margin-top: 0px;
                        }
                    }
                    `}
                </style>
                <div className="container-fluid detailsContainer">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="row">
                                <div className="col-md-3 imageContainer">
                                    <div align="center">
                                        <img src={srlogo} alt='img' className="trainer_image"/>
                                    </div>
                                </div>
                                <div className="col-md-9">
                                    <div className="trainer_details">
                                        <h4 className="detail_heading">Java Webinar by Dr. Shishira Bhat</h4>
                                        <p  className="detail_content">He leads the online training unit QSpiders/JSpiders and responsible for training Global Marketing & Communication activites including Brand Management, Market Development, Corporate  Communications</p>
                                        <div className="moreDetails" style={{ display : 'none'}}>
                                            <h4 style={{ color: '#0548CE' }}>Co-Founder of JSpiders & Vice President</h4>
                                                <p className="detail_content">Diverse exposure to Client Relationship Management, Global Delivery, Application Development & Testing
                                                Recognized for expertise in building large Global Training Delivery Centers.
                                                Worked with OOAD Scientists, SME's, architects & Product creators.
                                                Has working experience in India and overseas in IT Service Industry & responsible for global delivery.
                                                Designed & developed highly scalable enterprise applications for Retail, Telecom & Banking domains.
                                                Responsible for the leadership, strategic direction and operational management.</p>
                                            <h4 style={{ color: '#0548CE' }}>Certifications & Work Experience</h4>
                                                <p>
                                                    <li className="listingShi">Sun Certified Java programmer (SCJP).</li>
                                                    <li className="listingShi">Sun Certified Web Component Developer (SCWCD).</li>
                                                    <li className="listingShi">Cloudera Certified Associate (CCA) - Big Data & Hadoop</li>
                                                    <li className="listingShi">Internal ATG Certified & TSI Certified.</li>
                                                    <li className="listingShi">Technical Director and Mentor for 6 companies.</li>
                                                    <li className="listingShi">Has 12+ years of Experience & worked in top MNC's & Fortune companies.</li>
                                                </p>
                                            <h4 style={{ color: '#0548CE' }}>Teaching Profile</h4>
                                                <p>
                                                    <li className="listingShi">Invented new Training Models & Patterns.</li>
                                                    <li className="listingShi">Trained countless number of students on Java, JEE & Frameworks.</li>
                                                    <li className="listingShi">Trained top industry trainers & constantly mentoring them.</li>
                                                    <li className="listingShi">Trained student not only technically but also on Yoga & Life Science.</li>
                                                    <li className="listingShi">Handled many corporate trainings & Tech talks in India & ABROAD.</li>
                                                </p>
                                        </div>
                                        <p  className="detail_KnowMore">Know More</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="knowbuttonContainer">
                                <button className="knowbuttonDesign"><a href="https://skillrary.com/user/register" className="knowbuttonText" target="_blank">Attend</a></button><button className="knowbuttonDesign" onClick={this.onOpenDownloadModal} disabled>Download Syllabus</button>
                            </div>
                        </div>
                    </div>
                    <Modal open={openDownload} onClose={this.onCloseDownloadModal} closeOnOverlayClick={false} closeIconSize={22} styles={{ modal: { marginTop: '10%' }}}>
                        <div className="downloadWidth" >
                            <h2 className="downloadHeading" style={{marginTop: '-50px'}}>Request for Download</h2>
                            <form onSubmit={this.submitDownloadData}>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-6 offset-md-3"  style={{ marginBottom: '-15px'}}>
                                            <div className="form-group">
                                                <input type="text" name="name" 
                                                className="downloadinputField" placeholder="Name" autoComplete="off" onChange={(v) => this.setState({name: v.target.value, errors: { name: false }})} value={this.state.name}
                                                />
                                                <i className="fa fa-user" id="downloaduserLogo"></i>
                                                <p className="downloadenrollErrText">{this.state.errors.name}</p>
                                            </div>
                                        </div>
                                    </div> 
                                    <div className="row">
                                        <div className="col-md-6 offset-md-3"  style={{ marginBottom: '-15px'}}>
                                            <div className="form-group">
                                                <input type="text" name="email" 
                                                className="downloadinputField" placeholder="Email" autoComplete="off" onChange={(v) => this.setState({email: v.target.value, errors: { email: false }})} value={this.state.email}
                                                />
                                                <i className="fa fa-envelope" id="downloaduserLogo"></i>
                                                <span className="downloadenrollErrText">{this.state.errors.email}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 offset-md-3"  style={{ marginBottom: '-15px'}}>
                                            <div className="form-group">
                                                <input type="number" name="phone_number" 
                                                className="downloadinputField" placeholder="Phone number" autoComplete="off" onChange={(v) => this.setState({phone_number: v.target.value, errors: { phone_number: false } })} value={this.state.phone_number}
                                                />
                                                <i className="fa fa-phone" aria-hidden="true" id="downloaduserLogo"></i>
                                                <span className="downloadenrollErrText">{this.state.errors.phone_number}</span>
                                            </div>
                                        </div>
                                    </div><br/>
                                    <div align="center" style={{ justifyContent: "center" }}>
                                        <button className="downloadSubmit">Submit</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </Modal>
                </div>
            </div>
        )
    }
}

export default KnowMoreShi;