import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class HowToJoinWebinar extends Component{
    render(){
        return (
            <div>
                <style jsx>
                    {`
                    .content{
                        margin-bottom: 20px;
                    }
                    .videoContainer{
                        width: 100%;
                    }
                    .webinarAttendContainer{
                        background: #0548CE;
                        padding: 50px 25px;
                    }
                    .howAttend{
                        color: white;
                        font-size: 60px;
                        width: 80%;
                    }
                    .stepsWebinar{
                        color: white;
                        font-size: 35px;
                    }
                    .pTagContent{
                        color: white;
                        font-size: 18px;
                        text-align: justify;
                    }
                    .readBtn{
                        background: #F9CA33;
                        border: 1px solid #F9CA33;
                        padding: 5px;
                        width: 100%;
                        max-width: 210px;
                        min-width: 210px;
                        font-size: 22px;
                        color: #0548CE;
                        box-shadow: 4px 4px #e6e6e6;
                    }
                    .arricon{
                        background: #F9CA33;
                        padding: 25px;
                        font-size: 25px;
                        border-radius: 50px;
                        position: relative;
                        margin-left: 20px;
                        top: 19px;
                        color: white;
                    }
                    @media only screen and (max-width: 600px){
                        .frameWidth{
                            width: 100%;
                        }
                    }
                    `}
                </style>
                <div  className="container-fluid webinarAttendContainer">
                    <div className="row">
                        <div className="col-lg-5 col-md-6 offset-lg-1">
                            <div className="content">
                            <h3 className="howAttend">How to attend Webinar</h3><br/><br/>
                            <h4 className="stepsWebinar">Steps to join the Webinar</h4><br/><br/>
                            <p className="pTagContent">Firstly, open
                            the web browser and search for Skillrary. On the home-page of Skillrary, click
                            on the sign-in option. Here enter your email id, password and enter the
                            Recaptcha to ensure the user is human and click on submit.</p>
                            <p className="pTagContent">Once you have clicked, you will find all the courses that you have assigned.</p>
                            <p className="pTagContent">For you to join the sessions, your account has to be approved in the back-end from SkillRary
                            admin to begin the course. This is done only for the first session for the
                            course. For later sessions, you need not do this as the courses have already
                            been approved.</p>
                            <p className="pTagContent">Choose the course you have to join. Bear in mind, you can join the meeting only 10 mins
                            prior to the assigned time of your course.</p><br/>
                            <a href="/webinar_instructions"><button className="readBtn">Read Complete Instructions</button></a> <a href="/webinar_instructions"><span className="arricon"><i className="fa fa-long-arrow-right" aria-hidden="true"></i></span></a>
                            </div>
                        </div>
                        <div className="col-md-4 offset-md-1 align-self-center">
                        {/* <video width="500" height="300" controls="controls"  className="videoContainer">
                            <source src="https://www.youtube.com/watch?v=XUdfKzsYm5g&t=138s" type="video/mp4" />
                        </video> */}
                        <iframe className="frameWidth" width="500" height="300" src="https://www.youtube.com/embed/XUdfKzsYm5g" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default HowToJoinWebinar;