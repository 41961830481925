import React, { Component } from 'react';

class whySkillrary extends Component{
    render(){
        return (
            <div>
                <style jsx>
                    {`
                    .whyContainer{
                        background: #EDEDED;
                        padding: 75px 15px;
                    }
                    .whySkillrary{
                        color: #0548CE;
                        font-size: 70px;
                        font-weight: 600;                                                                   
                    }
                    .saveTravel{
                        font-size: 32px;
                    }
                    .pTag{
                        color: black;
                        font-size: 18px;
                        text-align: justify;
                    }
                    .whiteContainer{
                        background: white;
                        padding: 20px;
                        border-radius: 15px;
                        width: 85%;
                        height: auto;   
                        // position: absolute;   
                    }
                    .blueContainer{
                        background: #0548CE;
                        position: relative;
                        border-radius: 20px;
                        margin-left: 45px;
                        padding: 10px 60px;
                    }
                    .userFir{
                        color: #95b4f1;
                        font-size: 30px;
                    }
                    .pTag1{
                        font-size: 18px;
                        color: white;
                    }
                    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px)  { 
                        .whiteContainer {
                            background: white;
                            padding: 20px;
                            border-radius: 15px;
                            width: 100%;
                            height: auto;
                        }
                        .blueContainer {
                            background: #0548CE;
                            position: relative;
                            border-radius: 20px;
                            margin-left: 45px;
                            padding: 10px 30px;
                        }
                    }
                    `}
                </style>
                <div  className="container-fluid whyContainer">
                    <div className="row">
                        <div className="col-md-3 offset-md-1">
                            <h3 className="whySkillrary">Why Skillrary</h3><br/><br/>
                            <p className="saveTravel">Save your travel time and
                            learn at your own comfort!!</p>
                            <p className="pTag">Moving over traditional methods, SkillRary aim in
                            making learning simpler, easier and much more
                            effective to students advantage.</p>
                            <p className="pTag">At SkillRary, we begin classes quickly to wrap up in a
                            single learning session, while covering all the
                            important topics.</p>
                            <p className="pTag">We provide consistency to ensure that all the learners
                            receive the same type of training with fluid learning
                            mode</p>
                        </div>

                        <div className="col-md-5 offset-md-2">
                        <div className="container whiteContainer">
                                <div className='row'>
                                
                                    <div className='col-md-12'>
                                        <div className="container blueContainer">
                                            <h3 className="userFir">User Friendly Platform</h3>
                                            <p className="pTag1">Multiple feather light features such as Audio, Video and Screen
                                            Sharing etc.</p>
                                        </div>
                                    </div>
                                </div><br/>
                                <div className='row'>
                                 
                                    <div className='col-md-12'>
                                        <div className="container blueContainer">
                                            <h3 className="userFir">Top Industry Experts</h3>
                                            <p className="pTag1">Learn from Industry Experts who have taught over 1,00,000 +
                                            students across the globe.</p>
                                        </div>
                                    </div>
                                </div><br/>
                                <div className='row'>
                               
                                    <div className='col-md-12'>
                                        <div className="container blueContainer">
                                            <h3 className="userFir">Individual Attention</h3>
                                            <p className="pTag1">Each of your queries will be addressed individually to attend
                                            deeper learning experience.</p>
                                        </div>
                                    </div>
                                </div><br/>
                                <div className='row'>
                                    
                                    <div className='col-md-12'>
                                        <div className="container blueContainer">
                                            <h3 className="userFir">Dynamic Teaching</h3>
                                            <p className="pTag1">We use various teaching approaches such as Quiz, Asignments,
                                            PPT's and Videos etc. to provide holistic learning.</p>
                                        </div>
                                    </div>
                                </div>
                                
                        </div>
                    </div>
                </div>
            </div>
            </div>
            
        )
    }
}

export default whySkillrary;