import React, { Component } from 'react';
import Header from '../src/components/liveTrainingDesign/liveTrainingHeader';
import FooterTraining from "../src/components/liveTrainingDesign/footerTraining.jsx";
import WorksBanner from "../src/components/liveTrainingDesign/howItWorksBanner.jsx";
import WorksForm from "../src/components/liveTrainingDesign/howItWorksForm.jsx";
import GearsContainer from '../src/components/liveTrainingDesign/gearsContainer';
import WhatsappComponent from '../src/components/liveTrainingDesign/whatsappcomponent';

class howItWorks extends Component{

    render(){
        return (
            <div>
                <Header /><br/><br/>
                <GearsContainer />
                <WorksBanner />
                <WorksForm /><br/>
                <WhatsappComponent />
                <FooterTraining />
            </div>
        )
    }
}

export default howItWorks;