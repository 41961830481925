import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import bannerImg from '../liveTrainingDesign/assets/Main-Page-Banner.jpg';
import bannerImage from '../liveTrainingDesign/assets/image001.png';
import bannerImage1 from '../liveTrainingDesign/assets/image002.png';
import $ from 'jquery';

class LiveVideo extends Component{

  componentDidMount() {
  	$(window).scrollTop(0); 
  	
  }

  render(){
    return(
      <div>
	  <style jsx>{`
	  .carousel-indicators{
		  z-index: 1 !important;
	  }
			.carousel-indicators li {
			    box-sizing: content-box;
			    -ms-flex: 0 1 auto;
			    flex: 0 1 auto;
			    width: 10px;
			    height: 10px;
			    border-radius: 50%;
			    /* border: 2px solid #33a477; */
			    margin-right: 5px;
			    margin-left: 5px;
			    text-indent: -999px;
			    cursor: pointer;
			    padding: 3px;
			    background-color: #33A478;
			    background-clip: padding-box;
			    border-top: 10px solid transparent;
			    border-bottom: 10px solid transparent;
			    opacity: .5;
			    transition: opacity .6s ease;
			}
			.carousel-indicators li:after {
			    
			    border: 2px solid #33a477;
			    
			}
			.imageContainer{
				padding: 0;
			}

			.knowMoreBtn{
				background: #33A478;
			    border: 1px solid #33A478;
			    font-size: 16px;
			    font-weight: 600;
				position: absolute;
				z-index: 1;
			    margin-top: 0%;
			    right: 0%;
			    float: right;
			    /* transform: translate(0%, -50%); */
			    -ms-transform: translate(-50%, -50%);
			    color: white;
			    font-size: 16px;
			    padding: 8px 15px;
			    cursor: pointer;
			    border-radius: 5px;
			    text-align: center;
			}
			.knowMoreBtn:focus{
				outline: none;
			}
			#videoContainer{
				padding-right: 0px;
				padding-left: 0px;
				position: relative;
			}
            .textContainer{
				position: absolute;
				top: 50%;
				right: 50px;
				width: 40%;
				transform: translateY( -50% );
				text-align: center;
				color: white;
				font-weight: bold;
				float: right;
        	}
            .pTextOnImage{
              	font-size: 20px;
            }
            .h1TextOnImage{
              	font-weight: 600;
            }
            .startFree{
				background-color: #00F;
				font-size: 1.5em;
				padding: 15px 45px;
				color: white;
				border: 1px solid #00F
            }
            .landingImage{
				height: 600px;
			}
			.butnContainer{
				position: absolute;
				top: 70%;
				left: 8%;
				transform: translate( -50%, -50% );
				text-align: center;
				color: white;
				font-weight: bold;
			}
			.img-fluid{
				height: 400px !important;
			}
			@media only screen and (max-width: 600px) {
				.textContainer {
					position: absolute;
					top: 30%;
					left: 50%;
					transform: translate( -50%, -50% );
					text-align: center;
					color: white;
					font-weight: bold;
					display: none;
				}
				.pTextOnImage{
					font-size:12px;
					min-height: 50px;
					max-height: 600px;
					height: 100%;
					object-fit: cover;
					position: relative; 12px;
					}
				.h1TextOnImage{
					font-weight: 600;
					font-size: 14px;
				}
				.landingImage{
					height: 260px;
				}
				
			}

          	@media only screen and (device-width : 375px) and (device-height : 812px) and (-webkit-device-pixel-ratio : 3) { 
				.textContainer {
					position: absolute;
					top: 22%;
					left: 50%;
					transform: translate( -50%, -50% );
					text-align: center;
					color: white;
					font-weight: bold;
				}
				.landingImage{
					height: 300px;
				}
				
				#videoContainer{
					padding-right: 0px;
					padding-left: 0px;
				}
			}
			 
			@media only screen and (min-device-width : 768px) and (max-device-width : 1024px)  { 
				.textContainer {
					position: absolute;
					top: 30%;
					left: 50%;
					transform: translate( -50%, -50% );
					text-align: center;
					color: white;
					font-weight: bold;
					display: none;
				}
				.landingImage{
					height: auto;
				}
				
			}
				
      `}
      </style>

	 <div class="container-fluid imageContainer">
		<div class="carousel slide" id="main-carousel" data-ride="carousel">
			<ol class="carousel-indicators">
				<li data-target="#main-carousel" data-slide-to="0" class="active"></li>
				<li data-target="#main-carousel" data-slide-to="1"></li>
				<li data-target="#main-carousel" data-slide-to="2"></li>
				{/* <li data-target="#main-carousel" data-slide-to="3"></li> */}
			</ol>
			
			<div class="carousel-inner">
				<div class="carousel-item active imgHeight" >
					<img class="d-block img-fluid" src={bannerImage} alt="" width="100%"/>
					
				</div>
				<div class="carousel-item imgHeight">
					<img class="d-block img-fluid" src={bannerImage1} alt="" width="100%"/>
					
				</div>
				<div class="carousel-item imgHeight">
					<img class="d-block img-fluid" src="https://skillrary.com/./uploads/banner/1591252006_b73a94ae-b0f9-47af-a61e-c96f709b9c7a.png" alt="" width="100%"/>
					
				</div>
				{/* <div class="carousel-item imgHeight">
					<img src="https://skillrary.com/./uploads/banner/1591252023_13fdd3dd-19b8-4d04-bb50-48ad33bff6e7.png" alt="" class="d-block img-fluid" width="100%" />
				
				</div> */}
			</div>
			
		</div>
		<Link to="/howItWorks"> 
			<button className="knowMoreBtn">Know More</button> 
		</Link> 
	</div>


      </div>
    )
  }
}

export default LiveVideo; 