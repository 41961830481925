import React, { Component } from "react";
import { Link } from 'react-router-dom';
import DefaultImage from './assets/no-image.jpeg';
import $ from 'jquery';
import ReactHtmlParser from 'react-html-parser';
import CarouselLoader from "./carouselLoader.jsx";
import paginate from 'paginate-array';

class Carousel extends Component {
  constructor(props) {
    super(props);
    //console.log('list carousel',props)
      this.state = {
        todos: [],
        size: 6,
        page: 1,
        currPage: null
    }
    this.getCourse = this.getCourse.bind(this);
    // this.getCourseCard = this.getCourseCard.bind(this);
    // this.setCourseData = this.setCourseData.bind(this);
    this.previousPage = this.previousPage.bind(this);
    this.nextPage = this.nextPage.bind(this);
    // this.setCourseData = this.setCourseData.bind(this);
  }

  componentDidMount() {
    $(window).scrollTop(0); 
    this.getCourse();

  }
  getCourse() {
    fetch(`https://live.skillrary.com/live-courses/index.php/api/v1/all-live-courses/virtusa`)
      .then(response => response.json())
      .then(todos => {

        const { page, size } = this.state;

        const currPage = paginate(todos.result, page, size);

        this.setState({
          ...this.state,
          todos,
          currPage
        });
      });
  }

  previousPage() {
      console.log('sdfsfsdf')
    const { currPage, page, size, todos } = this.state;

    if (page > 1) {
      const newPage = page - 1;
      const newCurrPage = paginate(todos.result, newPage, size);

      this.setState({
        ...this.state,
        page: newPage,
        currPage: newCurrPage
      });
    }
  }

  nextPage() {
    const { currPage, page, size, todos } = this.state;
    if (page < currPage.totalPages) {
      const newPage = page + 1;
      const newCurrPage = paginate(todos.result, newPage, size);
      this.setState({ ...this.state, page: newPage, currPage: newCurrPage });
    }
  }

  render() {
    
    const { page, size, currPage } = this.state;
    console.log(currPage)


  return (
    <div>
    <style jsx>{`
        .cardList:hover{
          box-shadow: 5px 5px 0px #33A478;
          transform: translate(-2px, -2px);
        }
        .cardList{
          height: 200px;
          margin-bottom: 10px;
          boxShadow: 7px 5px 13px #ccc; 
          border: 2px solid #33A478;
          transition: all 0.2s ease-in-out;
        }
        .previousButton{
          padding: 5px 10px;
          background: #33A478;
          border: 1px solid #33A478;
          width: 100%;
          min-width: 120px;
          max-width: 120px;
          border-radius: 50px;
        }
        .nextButton{
          padding: 5px 10px;
          background: #33A478;
          border: 1px solid #33A478;
          width: 100%;
          min-width: 120px;
          max-width: 120px;
          border-radius: 50px;
        }
        .previousButton:focus{
          outline: none;
        }
        .nextButton:focus{
          outline: none;
        }
        .previousButton:hover{
          border: 1px solid black;
          background: black;
          color: white;
        }
        .nextButton:hover{
          border: 1px solid black;
          background: black;
          color: white;
        }
        .backDescription ul li{
          list-style: disc;
        }
        .cardColMd3{
          display: inline-block;
        }
        .completeCardList{
          height: 300px;
          border-radius: 3px;
          transition: all 0.8s;
          overflow: hidden;
          border: 0 none;
          margin-bottom: 15px;
          padding: 0;
        }
        .completeCardList span{
          display: -webkit-box;
        }
        .frontCardList{
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
          transition: all 0.5s;
        }
        .frontLogoList{
          display: inline-block;
          padding: 10px;
          width: 100% !important;
          height: 180px !important;
          background-position: 50% 50%;
          background-repeat: no-repeat;
          background-size: cover;
        }
        .frontNameList{
            font-weight: 700;
            font-size: 18px;
            color: #000;
            line-height: 1.3;
            padding: 15px 0 0 0;
            margin-left: -20px;
            display: block;
            display: -webkit-box;
            height: 66px;
            -webkit-line-clamp: 2;
            text-overflow: ellipsis;
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
        }
        .frontDescription{
            opacity: 0.69;
            font-weight: 700;
            font-size: 12px;
            color: #fff;
            line-height: 22px;
            text-align: center;
            height: 22px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            padding: 0 15px;
          }
          .frontCardList:before{
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            opacity: .09;
            background: linear-gradient(to top left,#33A478 50%,transparent 0);
          }
        
      .backCard .backDescription{
        opacity: 0;
      }
      .backCard .backDescription{
        font-size: 14px;
          color: #a6a6a6;
          letter-spacing: 0;
          text-align: left;
          margin-bottom: 23px;
          line-height: 18px;
          height: 175px;
          overflow: hidden;
      }
      .secondPart{
        background-color: white;
        transition: all 0.5s;
          padding: 30px 15px 0;
        }
      .points{
        padding-left: 15px;
          position: relative;
          line-height: 18px;
          margin-bottom: 10px;
          font-size: 14px;
          color: #333;
      }
      .cardBackTopName{
          opacity: 0;
          border-radius: 3px 3px 0 0;
          height: 66px;
          vertical-align: middle;
          font-weight: 700;
          font-size: 16px;
          color: #fff;
          text-align: center;
          position: relative;
          transition: all 0.8s;

      }
      .cardBackTopName span{
          color: white;
          position: absolute;
          top: -100%;
          left: 0;
          right: 0;
          transition: all 0.6s;
          height: auto;
          padding: 0 25px;
      }
      .cardMainContent{
        transition: all 0.5s;
          padding: 30px 15px 0;
          background-color: #fff;
          opacity: 0;
      }
      .cardBackContent{
        font-size: 14px;
          color: #a6a6a6;
          letter-spacing: 0;
          text-align: left;
          margin-bottom: 23px;
          line-height: 18px;
          height: 140px;
          overflow: hidden;
      }
      #viewDetailsBtn{
        color: white;
        margin-bottom: 0 !important;
        margin: 0 auto !important;
        display: block;
        width: auto !important;
        font-size: 12px;
        background-color: #33A478;
        border: 1px solid #33A478;
        box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.16), 0 2px 3px 0 rgba(0,0,0,0.12);
      }
      a:hover{
        text-decoration: none;
      }
      .backCardBottom{
        border-top: 1px solid rgba(140,142,150,0.18);
          margin-top: 23px;
          height: 50px;
          line-height: 50px;
          position: relative;
      }
   
      .completeCardList:hover .cardBackTopName span{
        top: 50%;
          -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
      }
      #iconArrow{
        
    position: relative;
    right: 10px;
    float: right;
    top: -40px;

      }
      .eyeIcon i{
        font-size: 15px;
      }
      .bottomLeft span{
        float: left;
        display: inline-block !important;
        font-size: 12px;
        color: #33A478;
      }
      .commentIcon{
        padding-left: 5px;
      }
      .bottomRight{
        float: left;
        color: black;
        line-height: 46px;
        margin-left: 2px;
      }
      .col-md-3{
        display: inline-block;
        }
      .col-md-3 img{
        width:100%;
        height:auto;
      }

      body .no-padding{
        padding-left: 0;
        padding-right: 0;
      }
   
      .enrollCourse{
        position: relative;
        bottom: 40px;
        left: 30px;
      }
      .textCenter{
        position: absolute;
        top: 30%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      .carousel-control-prev{
          opacity: 5 !important;
      }
      .carousel-control-prev-icon {
          background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
      }

      .carousel-control-next-icon {
          opacity: 3;
          background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
      }

      .kFBgBorder{
        border-top: 45px solid #33A478;
        margin-top: 10px;
      }
      .keyFeaturesHeading{
        color: white;
          margin-top: -38px;
          font-size: 20px;
          text-align: center;
      }
      .allCourseText{
          font-weight: 700;
          font-size: 22px;
          color: #333742;
          letter-spacing: 1.5px;
          position: relative;
          padding: 0px 0px 10px 0px;
          margin-bottom: 20px;
      }
      .allCourseText:before{
        content: ' ';
        background: #33A478;
        box-shadow: 0 4px 8px 0 rgba(76,215,200,0.3);
        width: 5%;
        height: 4px;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%);
      }
      .keyFea{
           margin: 0;
        padding: 0;
        color: black;
        list-style: none;
        margin-left: -22px;
      }
      .keyFea div ul{
        margin: 0;
        padding: 0;
      }
      .keyFea div{
        height: 115px;
        overflow: hidden;
         list-style-position: inside;
      }
       .keyFea div ul li{
        list-style-type: disc !important;
      }
      ul li {
    list-style: inherit;
}
.buttonName{
  float:right;
  margin-right: 50px;
  margin-top: -55px;
}
      @media only screen and (max-width: 600px){
        .allCourseText{
          font-weight: 700;
          font-size: 22px;
          color: #333742;
          letter-spacing: 1.5px;
          position: relative;
          padding: 0px 0px 10px 0px;
          margin-bottom: 20px;
      }
      .frontCardList {
        position: initial;
      }
      .completeCardList span {
        display: flex;
    }
      .buttonName{
        float:right;
        margin-right: 50px;
        margin-top: 0px;
        margin-bottom: 20px;
      }
      .allCourseText:before{
        content: ' ';
        background: #33A478;
        box-shadow: 0 4px 8px 0 rgba(76,215,200,0.3);
        width: 70%;
        height: 4px;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%);
      }
      }
      @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
        .enrollCourse{
          position: relative;
          bottom: 71px;
          left: 22px;
          width: 38%;
        }
      }

      `}</style>
        <div><br/><br/>
        <div id="ongoing"><h4 className="allCourseText" style= {{ textAlign: 'center' }}>All Courses</h4><br/></div>
         
          <div id="demo">
            <div className="container">
            <div className="buttonName">

              <button onClick={this.previousPage} className="previousButton"> <i class="fa fa-angle-left" aria-hidden="true" style={{fontSize: "24px",
                fontWeight: '600'}}></i>  &nbsp;<span style={{position:'relative',top:'-3px'}}> Previous </span></button>&nbsp;&nbsp;
              <button onClick={this.nextPage} className="nextButton">  <span style={{position:'relative',top:'-3px'}}>Next </span> &nbsp;<i class="fa fa-angle-right" aria-hidden="true" style={{fontSize: "24px",
                fontWeight: '600'}}></i></button>

              </div>
                {currPage &&
                  <div>
                {currPage.data.map(todo => 
                  <div className="col-md-6 col-sm-6 cardColMd3"> 
                  <div className="card cardList">
                    <Link to={`${todo.slug}`}>
                      <a className="completeCardList">
                        <span className="frontCardList">
                          <div className="col-md-6">
                            <span style={{ textAlign: "center"}}>
                              <img src={(null !== todo.course_img) ? todo.course_img : ""} className="frontLogoList"/>
                            </span>

                          </div>
                          <div className="col-md-6">
                            <span className="frontNameList">{todo.course_title}</span>
                              <span>
                              <ul className="keyFea">
                                <li>
                                  <div dangerouslySetInnerHTML={{__html: todo.key_features}}></div>
                                </li>
                              </ul>
                              </span>
                            
                          </div>
                          </span>
                      </a>       
                    </Link>
                  </div>
                   <span className="arrow">
                      <span className="ratingFrontList">
                            {/* {ratingArray} */}
                      </span>
                      <i className="fa fa-arrow-right" id="iconArrow" ></i>
                    </span>
                </div>

                  )}
              </div>
            }
            </div>
          </div>
      </div>
    </div>
);

  }
}
 

export default Carousel;