import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class SearchListComponent extends Component{
    constructor(props) {
        super(props);

        console.log('aaa', props);
        this.state = {
            course: ""
        }
        this.getCourse = this.getCourse.bind(this);
        this.createCourse = this.createCourse.bind(this);
    }

    componentDidMount() {
        this.getCourse();
    }
    getCourse() {
        fetch("https://live.skillrary.com/live-courses/index.php/api/v1/course-search", {
        method: 'POST',
        headers: {'Content-Type':'application/json'},
        body: JSON.stringify({
            query: this.props.course,
            domain: 'virtusa'
        })
        })
          .then(res => res.json())
          .then(
            (result) => {
              //console.log('search', result);
              this.setState({
                course: result.result,
              });   
              //console.log('courseList-=======================',this.state.course)
            },
            (error) => {
              this.setState({
                isLoaded: true,
                error
              });
            }
          )
    }

    createCourse(courseInfo) {
        console.log('cc', courseInfo)

            var ratingArray = [];
    
    if (null !== courseInfo.ratings) {

      var rating = courseInfo.ratings * 10;
      console.log('rating', rating)
      var fullStar =  Math.floor(rating/10);

      var halfStar = rating%10;

      let emptyStar = 0;
        console.log('fff', fullStar)
      for (var i = 0; i < fullStar; i++) {
        ratingArray.push(<span className="SearchRating"><i className="fa fa-enable fa-star"></i></span>);      
      }

      if (halfStar > 0) {
        ratingArray.push(<span className="SearchRating"><i className="fa fa-enable fa-star-half-o"></i></span>);
        emptyStar = 5 - (fullStar + 1);
      } else {
        emptyStar = 5 - (fullStar); 
      }
    console.log('empty', emptyStar)  
      for (var i = 0; i < emptyStar; i++) {
        ratingArray.push(<span className="SearchRating"><i className="fa fa-star-o"></i></span>);      
      }
    } else {
      for (var i=0; i <  5;i++) {
        ratingArray.push(<span className="SearchRating"><i className="fa fa-star-o"></i></span>);
      }
    }
    return (
                        <div className="row" style={{ margin: "0px", padding: "0px"}}>
                    <div className="contentContainer" style={{ width: '100%'}}>
                        <div className="content-sections">
                            <div className="imgSearch">
                            <img src={courseInfo.course_img} alt="courseImg" className="courseSearchImg"/>
                            </div>
                        </div>
                        <div className="secondSectionSearch">
                            <h6 className="searchHeading">{courseInfo.course_title}</h6>
                            {/*<div className="courseDetailsSearch">
                                <span className="course-details">100 lectures</span>
                                <span className="course-details">6.5 hours</span>
                                <span>Beginner</span>
                            </div><br/>*/}
                            <div className="courseSearchContent">
                            {courseInfo.description.substring(0, 200) + "..."}
                                {/*A course on <strong>Java</strong> for complete beginners to computer programming, for those who want concepts explained in plain English. | By Nick H <br/><br/>*/}
                                
                            </div>
                             <Link style={{ textDecoration: 'none' }} to={`/${courseInfo.slug}`}>
                                    <button className="btn btn-warning viewBtn" id="viewDetailsBtnSearch">
                                        View Details
                                    </button>
                                </Link>
                        </div>
                        <div className="thirdSectionContent">
                            {/*<p className="priceSearch">₹ 455 <br/>  <span style={{ textDecoration: "line-through",color: "#a1a7b3" }}>₹ 1920</span></p>
                            <span className="SearchRating">
                                <i className="fa fa-enable fa-star"></i>
                                <i className="fa fa-enable fa-star"></i>
                                <i className="fa fa-enable fa-star"></i>
                                <i className="fa fa-enable fa-star"></i>
                                <i className="fa fa-enable fa-star-half-o"></i>
                            </span> <span className="SearchRatingNumber">4.5</span> */}
                            {ratingArray} <span className="SearchRatingNumber">{courseInfo.ratings}</span>

                        </div>                        
                    </div>
                </div> 
    )
    }

    render(){

        const searchResult = this.state.course;

        var courseData = [];
        console.log('ss', typeof(searchResult))
        if (typeof(searchResult) == "object") {
            if (searchResult.length > 0) {

                var self = this;
                Object.keys(searchResult).map(function(keyName, keyIndex) {
                  //console.log('adf',keyName, searchResult[keyName]);
                 
                    courseData.push(self.createCourse(searchResult[keyName]));
                  
                })
            }
        } else {
             courseData.push(<div align="center" style={{ background: 'white'}}>
                     <div class="col-md-6 col-sm-6">
                        <div class="macSearch">
                            <div className="bgColorMacSearch">
                                <p className="number404Search">No Courses Found</p>
                            </div>
                        </div>
                    </div><br/><br/>
                </div>);
        }
     
        return(
            <div style={{ margin: '60px' }}>
                <style jsx>
                    {`
                    .searchContainer{
                        display: flex;
                        flex-direction: column;
                        min-height: 100%;
                    }
                    .macSearch {
                        background: url("http://edurary.com/assets/skillrary/images/lap.png") no-repeat rgba(0, 0, 0, 0);
                        height: 306px;
                        padding: 0;
                        width: 105%;
                        padding: 28px 74px;
                    }
                    .bgColorMacSearch{
                        background: linear-gradient(180deg, #33a478 3%, black);
                        height: 240px;                    
                    }
                    .number404Search{
                        position: relative;
                        top: 25%;
                        color: black;
                        background: white;
                        height: 100px;
                        width: 26%;
                        padding: 13px;
                        border-radius: 60px;
                        font-size: 1em;
                    }
                    #viewDetailsBtnSearch{
                        color: white;
                        display: block;
                        font-size: 12px;
                        background-color: #33A478;
                        border: 1px solid #33A478;
                        box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.16), 0 2px 3px 0 rgba(0,0,0,0.12);
                        margin-top: 10px;
                    }

                    .content-sections{
                        float:left; 
                        width:20%;
                        text-align: center;
                    }
                    .contentContainer{
                        border-top: 1px solid black;
                        padding-top: 10px;
                        padding-bottom: 10px;
                    }
                    .courseSearchImg{
                        width: 100%;
                    }
                    .secondSectionSearch{
                        text-align: left;
                        float:left; 
                        width:46%;
                    }
                    .searchHeading{
                        font-size: 19px;
                        color: #333742;
                        font-weight: 600;
                    }
                    .imgSearch{
                        width: 210px;
                        height: auto;
                        margin-bottom: 16px;
                    }
                    .courseDetailsSearch{
                        display: flex;
                        flex-wrap: wrap;
                        color: #a1a7b3;
                        font-size: 13px;
                    }
                    .course-details:after{
                        content: '\\25CF';
                        margin: 0 8px;
                        font-size: 10px;
                        color: #a1a7b3;
                    }
                    .courseSearchContent{
                        color: #a1a7b3;
                        font-size: 14px;
                    }
                    .thirdSectionContent{
                        text-align: right;
                        float:left; 
                        width: 33%;
                    }
                    .SearchRating{
                        color: gold;
                        line-height: 46px;
                        margin-left: 2px;
                    }
                    .SearchRatingNumber{
                        font-size: 15px;
                        color: #a1a7b3;
                    }
                    .priceSearch{
                        margin-bottom: 0px;
                    }
                    @media only screen and (max-width: 600px) {
                        .bgColorMacSearch{
                            background: white;
                            height: 240px;                    
                        }
                        .macSearch {
                            background: initial;
                            height: 306px;
                            padding: 0;
                            width: 105%;
                            padding: 28px 74px;
                        }
                        .number404Search{
                            position: relative;
                            top: 25%;
                            color: black;
                            background: white;
                            height: 100px;
                            width: 100%;
                            padding: 13px;
                            border-radius: 60px;
                            font-size: 1em;
                        }
                        .courseDetailsSearch{
                            display: none;
                        }
                        .courseSearchContent{
                            display: none;
                        }
                        .content-sections{
                            float: left;
                            width: 40%;
                            text-align: center;
                        }
                        .imgSearch{
                            width: 110px;
                            height: auto;
                            margin-left: 10px;
                            margin-bottom: 16px;
                        }
                        .secondSectionSearch{
                            text-align: left;
                            float: left;
                            width: 60%;
                            margin-bottom: -25px;
                        }
                        .thirdSectionContent{
                            text-align: inherit;
                            float:left; 
                            width: 33%;
                            margin-top: 40px;
                        }
                        .priceSearch{
                            margin-bottom: -5px;
                        }
                        .searchHeading{
                            font-size: 17px;
                            color: #333742;
                            font-weight: 600;
                        }
                        #viewDetailsBtnSearch{
                            color: white;
                            display: block;
                            font-size: 12px;
                            background-color: #33A478;
                            border: 1px solid #33A478;
                            box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.16), 0 2px 3px 0 rgba(0,0,0,0.12);
                            margin-top: -17px;
                        }
                    }
                    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px)  {
                        .bgColorMacSearch{
                            background: white;
                            height: 240px;                    
                        }
                        .macSearch {
                            background: initial;
                            height: 306px;
                            padding: 0;
                            width: 105%;
                            padding: 28px 74px;
                        }
                        .number404Search{
                            position: relative;
                            top: 25%;
                            color: black;
                            background: white;
                            height: 100px;
                            width: 100%;
                            padding: 13px;
                            border-radius: 60px;
                            font-size: 1em;
                        }
                        .content-sections {
                            float: left;
                            width: 32%;
                            text-align: center;
                        }
                        .thirdSectionContent {
                            text-align: right;
                            float: left;
                            width: 18%;
                        } 
                    }

                    `}
                </style>
            <div className="container searchContainer">
                {/* <div  style={{ height: '300px', background: 'white' }}>
                    <h5 style={{textAlign:'center',lineHeight:'170px'}}>No Matches Found</h5>
                </div> */}
                {courseData}
                
                {/*
                <div className="row">
                    <div className="contentContainer">
                        <div className="content-sections">
                            <div className="imgSearch">
                            <img src="https://i.udemycdn.com/course/240x135/1228490_9cb9.jpg" alt="courseImg" className="courseSearchImg"/>
                            </div>
                        </div>
                        <div className="secondSectionSearch">
                            <h6 className="searchHeading">Java for Absolute Beginners</h6>
                            <div className="courseDetailsSearch">
                                <span className="course-details">100 lectures</span>
                                <span className="course-details">6.5 hours</span>
                                <span>Beginner</span>
                            </div><br/>
                            <div className="courseSearchContent">
                            A course on <strong>Java</strong> for complete beginners to computer programming, for those who want concepts explained in plain English. | By Nick H
                            </div>
                        </div>
                        <div className="thirdSectionContent">
                            <p className="priceSearch">₹ 455 <br/>  <span style={{ textDecoration: "line-through",color: "#a1a7b3" }}>₹ 1920</span></p>
                            <span className="SearchRating">
                                <i className="fa fa-enable fa-star"></i>
                                <i className="fa fa-enable fa-star"></i>
                                <i className="fa fa-enable fa-star"></i>
                                <i className="fa fa-enable fa-star"></i>
                                <i className="fa fa-enable fa-star-half-o"></i>
                            </span> <span className="SearchRatingNumber">4.5</span>
                        </div>
                    </div>
                </div>
                
                <div className="row">
                    <div className="contentContainer">
                        <div className="content-sections">
                            <div className="imgSearch">
                            <img src="https://i.udemycdn.com/course/240x135/1228490_9cb9.jpg" alt="courseImg" className="courseSearchImg"/>
                            </div>
                        </div>
                        <div className="secondSectionSearch">
                            <h6 className="searchHeading">Java for Absolute Beginners</h6>
                            <div className="courseDetailsSearch">
                                <span className="course-details">100 lectures</span>
                                <span className="course-details">6.5 hours</span>
                                <span>Beginner</span>
                            </div><br/>
                            <div className="courseSearchContent">
                            A course on <strong>Java</strong> for complete beginners to computer programming, for those who want concepts explained in plain English. | By Nick H
                            </div>
                        </div>
                        <div className="thirdSectionContent">
                            <p className="priceSearch">₹ 455 <br/>  <span style={{ textDecoration: "line-through",color: "#a1a7b3" }}>₹ 1920</span></p>
                            <span className="SearchRating">
                                <i className="fa fa-enable fa-star"></i>
                                <i className="fa fa-enable fa-star"></i>
                                <i className="fa fa-enable fa-star"></i>
                                <i className="fa fa-enable fa-star"></i>
                                <i className="fa fa-enable fa-star-half-o"></i>
                            </span> <span className="SearchRatingNumber">4.5</span>
                        </div>
                    </div>
                </div> 


                <div className="row">
                    <div className="contentContainer">
                        <div className="content-sections">
                            <div className="imgSearch">
                            <img src="https://i.udemycdn.com/course/240x135/1228490_9cb9.jpg" alt="courseImg" className="courseSearchImg"/>
                            </div>
                        </div>
                        <div className="secondSectionSearch">
                            <h6 className="searchHeading">Java for Absolute Beginners</h6>
                            <div className="courseDetailsSearch">
                                <span className="course-details">100 lectures</span>
                                <span className="course-details">6.5 hours</span>
                                <span>Beginner</span>
                            </div><br/>
                            <div className="courseSearchContent">
                            A course on <strong>Java</strong> for complete beginners to computer programming, for those who want concepts explained in plain English. | By Nick H
                            </div>
                        </div>
                        <div className="thirdSectionContent">
                            <p className="priceSearch">₹ 455 <br/>  <span style={{ textDecoration: "line-through",color: "#a1a7b3" }}>₹ 1920</span></p>
                            <span className="SearchRating">
                                <i className="fa fa-enable fa-star"></i>
                                <i className="fa fa-enable fa-star"></i>
                                <i className="fa fa-enable fa-star"></i>
                                <i className="fa fa-enable fa-star"></i>
                                <i className="fa fa-enable fa-star-half-o"></i>
                            </span> <span className="SearchRatingNumber">4.5</span>
                        </div>
                    </div>
                </div> 


                <div className="row">
                    <div className="contentContainer">
                        <div className="content-sections">
                            <div className="imgSearch">
                            <img src="https://i.udemycdn.com/course/240x135/1228490_9cb9.jpg" alt="courseImg" className="courseSearchImg"/>
                            </div>
                        </div>
                        <div className="secondSectionSearch">
                            <h6 className="searchHeading">Java for Absolute Beginners</h6>
                            <div className="courseDetailsSearch">
                                <span className="course-details">100 lectures</span>
                                <span className="course-details">6.5 hours</span>
                                <span>Beginner</span>
                            </div><br/>
                            <div className="courseSearchContent">
                            A course on <strong>Java</strong> for complete beginners to computer programming, for those who want concepts explained in plain English. | By Nick H
                            </div>
                        </div>
                        <div className="thirdSectionContent">
                            <p className="priceSearch">₹ 455 <br/>  <span style={{ textDecoration: "line-through",color: "#a1a7b3" }}>₹ 1920</span></p>
                            <span className="SearchRating">
                                <i className="fa fa-enable fa-star"></i>
                                <i className="fa fa-enable fa-star"></i>
                                <i className="fa fa-enable fa-star"></i>
                                <i className="fa fa-enable fa-star"></i>
                                <i className="fa fa-enable fa-star-half-o"></i>
                            </span> <span className="SearchRatingNumber">4.5</span>
                        </div>
                    </div>
                </div> <br/><br/>
                */}

            </div>
            </div>
        )
    }
}

export default SearchListComponent;