import React, { Component } from 'react';
import loaderBanner from '../liveTrainingDesign/assets/loading-banner.gif';
import { withRouter } from 'react-router-dom';

class LiveTrainingBanner extends Component{
	constructor(props) {
		super(props);

		console.log('props',props);
		this.state = {
			courseBanner: "",
		}
		this.getCourse = this.getCourse.bind(this);
	}

	componentDidMount(){
		this.getCourse();
	}

	getCourse() {
	var url = document.location.pathname;
	var pathname = url.substring(url.lastIndexOf('/')+1)
	var completename = document.location.pathname.split('/');

	console.log(pathname,'pathname')
	console.log(completename,'completename')

	
	var courseType = 'all';

		
	if("" !== completename[2]){

		if(completename[2] == "ongoing-session"){
			courseType = 'running'
		}else if(completename[2] == "upcoming-session"){
			courseType = 'upcoming'
		}
	}

	fetch("https://live.skillrary.com/live-courses/index.php/api/v1/course-info", {
		method: 'POST',
		headers: {'Content-Type':'application/json'},
		body: JSON.stringify({
		slug: completename[1],
		course_id: 0,
		course_type: courseType,
		session_type: 1
		})
	})
	.then(res => res.json())
	.then(
	(result) => {
		console.log('rest-------', result.result);
		if(result.result !== "No Courses found"){
			this.setState({
				courseBanner:  result.result[0].course_banner,
			});
		} else {
			this.props.history.push('/filenotfound')
		}
	},
	(error) => {
		this.setState({
		isLoaded: true,
		error
		});
	}
	)
  }

	render(){
		return(
			<div>
				<style jsx>{`
						#bannerContainer{
							padding: 0px;
							position: relative;
							height: 400px;
						}
						.bannerImage{
							opacity: 0.94;
							width:100%;
							height:400px;
						}
						.bannerContentContainer{
	      					position: absolute;
	      					width: 100%;
		      				top: 50%;
		      				left: 0px;
		      				padding: 1rem;
		      				transform: translateY(-50%);
		      				color: white
						}
						.bannerContent h1{
							width: 35%;
							font-weight: 600;
							font-size: 50px;
							line-height: 60px;
						}
						@media only screen and (max-width: 600px) {
							.bannerContent h1{
								width: initial;
								font-weight: 600;
								font-size: 40px;
								line-height: 40px;
							}	
							#bannerContainer{
								padding: 0px;
								position: relative;
								height:300px;
							}
							.bannerImage{
								opacity: 0.94;
								width:100%;
								height:300px;
							}					
						}
					`}
				</style>
			<div className="container-fluid" id="bannerContainer">

				{this.state.courseBanner.length > 0 && 
				<img style={{ marginTop: '56px' }} src={null !== this.state.courseBanner && this.state.courseBanner.length > 0 ? this.state.courseBanner : loaderBanner } alt="banner" className="bannerImage"/> 
				}	

				{/* <img style={{ marginTop: '56px' }} src={null !== this.state.bannerImage && this.state.bannerImage.length > 0 ? this.state.bannerImage : loaderBanner} alt="banner" width="100%" height="515px" className="bannerImage"/>
				*/}

				
				{/*<img style={{ marginTop: '56px' }} src={null !== this.state.courseBanner && this.state.courseBanner.length > 0 ? this.state.courseBanner : loaderBanner } alt="banner" width="100%" height="515px" className="bannerImage"/> 

				 <img style={{ marginTop: '56px',background: 'white' }} src={null !== this.state.bannerImage && this.state.bannerImage.length > 0 ? this.state.bannerImage : loaderBanner} alt="" width="100%" height="515px" className="bannerImage"/>
				 */}

				 <div className="bannerContentContainer" align="center">
					<div className="bannerContent">
						<h1>{this.state.courseTitle}</h1><br />
						<p style={{ width: "53%" }}>{this.state.bannerTagline}</p>
					</div>
				</div>
			</div>			
			</div>
		)
	}
}

export default withRouter(LiveTrainingBanner);