import React, { Component, Fragment }from 'react';
import './App.css';
import Header from '../src/components/liveTrainingDesign/liveTrainingHeader';
import LiveVideo from '../src/components/liveTrainingDesign/liveVideo';
// import KeyFeatures from '../src/components/liveTrainingDesign/liveTrainingKeyFeatures';
import About from '../src/components/liveTrainingDesign/liveTrainingAboutus';
import Carousel from '../src/components/liveTrainingDesign/carousel';
import FooterTraining from "../src/components/liveTrainingDesign/footerTraining.jsx";
import ContactLiveTraining from "../src/components/liveTrainingDesign/liveTrainingContact.jsx";
// import UpcomingCarousel from "../src/components/liveTrainingDesign/upcomingCarousel.jsx";

import ScrollSpy from '../src/components/liveTrainingDesign/liveTrainingScrollSpy';
import LiveTrainingBanner from "../src/components/liveTrainingDesign/liveTrainingBanner.jsx";
// import $ from 'jquery';
import IdleTimer from 'react-idle-timer'
import Swal from 'sweetalert2';
import GearsContainer from '../src/components/liveTrainingDesign/gearsContainer';
import WhatsappComponent from '../src/components/liveTrainingDesign/whatsappcomponent';

class OngoingClasses extends Component {
    constructor(props) {
        super(props);
            console.log('proo', props)
            this.state = {
                heading: "Enroll Our Ongoing Classes",
                test: 0,
                courseType: 'running'
            }
            this.idleTimer = null
            this.onAction = this._onAction.bind(this)
            this.onActive = this._onActive.bind(this)
            this.onIdle = this._onIdle.bind(this)
          }
        componentDidMount() {
          window.scrollTo(500, 0);
        }
    render() {
        window.scrollTo(500, 0);
        return (
            <div className="App">
            <IdleTimer
          ref={ref => { this.idleTimer = ref }}
          element={document}
          onActive={this.onActive}
          onIdle={this.onIdle}
          onAction={this.onAction}
          debounce={250}
          timeout={3000} />
                <Header /><br/><br/>
                {this.props.location.courseId !== undefined ?
                    (<Fragment>
                        <LiveTrainingBanner courseId={this.props.location.courseId}/><br/>
                        <ScrollSpy  courseId={this.props.location.courseId} />
                    </Fragment>) :
                    (<Fragment>
                        {/*<LiveVideo />*/}
                        {/*<KeyFeatures />*/}
                        {/*<About /><br/>*/}
                        <br/>
                        <Carousel heading={this.state.heading} courseType={this.state.courseType}/><br/>
                    </Fragment>)
                }
                <GearsContainer />
                {/* <UpcomingCarousel /> */}
                <ContactLiveTraining />
                <WhatsappComponent />
                <FooterTraining />
                {/* {this.state.test > 1 ? "a":"b"} */}
            </div>
        );
    }

    _onAction(e) {
    console.log('user did something', e)
  }
 
  _onActive(e) {
    console.log('user is active', e)
    console.log('time remaining', this.idleTimer.getRemainingTime())
  }
 
  _onIdle(e) {
    console.log('user is idle', e)
    this.setState({test:2});
    if (this.state.test < 2) {
        Swal.fire({
                  title: '<strong style="color:#33A478;border-bottom:2px solid black">SkillRary <span >Login</span></strong>',
                  icon: 'info',
                  html:
                    '<span style="color:black">To enroll our latest <b>courses login to</b>, ' +
                    '<a href="https://www.skillrary.com/user/login" style="color:#33A478;outline:none;border-bottom:2px solid black">skillrary.com</a></span> ',
                  showCloseButton: false,
                  showCancelButton: true,
                  focusConfirm: false,
                  confirmButtonColor: '#33A478',
                  cancelButtonColor: '#33A478',
                  cancelButtonText: '<a href="" style="color:black">May be later</a>',
                  confirmButtonText:
                    '<a href="https://www.skillrary.com/user/login" target="_blank" style="color:black">Login</a>',
                  confirmButtonAriaLabel: 'Thumbs up, great!',
                 
                })
        console.log('last active', this.idleTimer.getLastActiveTime())
    }
  }
}
export default OngoingClasses;
