import React, { Component } from 'react';

class schedule extends Component{
    constructor(props){
        super(props);
        console.log(props)
        this.state = {
            course: [],
            session: '',
            uri: '',
            name: ''
        }
        this.refs = React.createRef();
    }
    onChangeSession = (event) => {
        console.log(event.target.value,'event')
        this.setState({session: event.target.value});
        this.onSearchCourseName(event.target.value)
        if(event.target.value === 'running'){
            this.state.uri="/ongoing-session"
        }
        else if(event.target.value === 'upcoming'){
            this.state.uri="/upcoming-session"
        }
    }
    componentDidMount(){
        this.onSearchCourseName()
    }
    onChangeCourseName = (event) => {
        console.log('ref', this.refs.anything.value)
        this.setState({ name : event.target.value })
        this.onSearchCourseName()
    }
    onSearchCourseName = (session) => {
        console.log('Course_Name', this.refs.anything.value,session)
        fetch("https://live.skillrary.com/live-courses/index.php/api/v1/advanced-search", {
            method: 'POST',
            headers: {'Content-Type':'application/json'},
			body: JSON.stringify({
                course_name: this.refs.anything.value,
                delivery_type: "online",
                session_type : undefined !== session ? session : this.state.session
			})
        })
        .then(res => res.json())
        .then((result) => {
            console.log('schedule results',result.result)
            if (result.result !== "No records found") {
                this.setState({
                    course: result.result,
                });
            } else {
                this.setState({
                    course: [],
                });
            }
        },
        (error) => {
            this.setState({
            isLoaded: true,
            error
            });
        }
        )
    }
    onSearchSubmit = (e) => {
        e.preventDefault();
        this.onSearchCourseName()
        this.setState({ session: '',name:'',uri: '' })
    }
    render(){
        return(
            <div>
                <style jsx>
                    {`
                        .searchButn{
                            background: #33A478;
                            border: 1px solid #33A478;
                            padding: 5px 15px;
                            margin-top: 32px;                        
                        }
                        .searchButn:focus{
                            outline: none;
                        }
                        #searchContainer{
                            margin-top: 2%;
                        }
                        #labelText{
                            font-size: 16px;
                            color: #33A478;
                        }
                        .ongoingField:focus{
                            border-color: inherit;
                            box-shadow: inherit;
                        }
                    	// course schedule csss start
                        .ongoing-schedule-courses .container {
                            padding-top: 20px;
                            padding-bottom: 40px;
                        }
                        .viewBtn{
                            background: #33A478;
                            border: 1px solid #33A478;
                            padding: 5px 15px;
                            margin-top: 4px;
                        }
                        .viewBtn:focus{
                            outline: none;
                        }
                        .ongoingheadingTable{
                            color: white;
                            font-weight: 600;
                        }
                     
                        .ongoing-schedule-captions {
                            margin-bottom: 20px;
                            text-align: center;
                            background-image: linear-gradient(to right, rgba(0,0,0), rgba(51,	164,	120) , rgba(0,0,0));			border-top-left-radius: 3px;
                            border-top-right-radius: 3px;
                            width: 100%;
                            display: block;
                            height: 50px;
                            overflow: hidden;
                            line-height: 50px;
                            color: white;
                        }
                        .ongoing-schedule-calendar{
                            margin: 35px 0px;
                        }
                        .ongoing-schedule-captions .ongoing-schedule-captions-list {
                            float: left;
                            padding: 0px 0px;
                            width: 33%;
                            color: white;
                            font-size: 15px;
                            font-weight: 700;
                            border-right: 0 none;
                        }
                        .ongoing-schedule-captions .ongoing-schedule-captions-list-time {
                            float: left;
                            padding: 0px 0px;
                            width: 33%;
                            color: white;
                            font-size: 15px;
                            font-weight: 700;
                            border-right: 0 none;
                        }
                        .ongoing-time-zone{
                            margin: 0;
                            padding: 0;
                            border: 0;
                            font-size: 100%;
                            font: inherit;
                        }
                        .ongoing-schedule-course:hover {
                            box-shadow: 0px 2px 4px lightgrey;
                            background-color: #fff;
                        }

                        .ongoing-schedule-course {
                            background-color: #fff;
                            margin-bottom: 20px;
                            border-bottom-left-radius: 3px !important;
                            overflow: hidden;
                            border-bottom-right-radius: 3px !important;
                        }
                     
                        .ongoing-border-color-1 {
                            border: 1px solid black;
                            border-radius: 3px;
                            padding: 5px 0px;
                        }
                        .ongoing-schedule-course .ongoing-schedule-course-summary .ongoing-schedule-course-list {
                            float: left;
                            text-align: center;
                            font-size: 14px;
                            font-weight: 500;
                            color: #3F4752;
                            padding: 0px 0px !important;
                            min-height: auto !important;
                            width: 33%;
                        }
            
                        .ongoing-schedule-course .ongoing-schedule-course-summary .ongoing-schedule-course-list-time {
                            float: left;
                            text-align: center;
                            font-size: 16px;
                            font-weight: 500;
                            color: #3F4752;
                            padding: 0px 0px !important;
                            min-height: auto !important;
                            width: 33%;
                        }
                        .ongoing-schedule-course-list .xs-caption .ongoingdates {
                            line-height: 24px;
                            font-size: 16px;
                        }
               
                        .ongoing-schedule-course-list .xs-caption .ongoingbatch {
                            font-size: 12px;
                            font-weight:500;
                        }
                        .text-center {
                            text-align: center;
                        }
                        .ongoing-schedule-course-list .xs-caption .ongoing-schedule-delivery-type {
                            line-height: 24px;
                            text-transform: capitalize;
                            font-size: 16px;
                        }
               
                        .ongoing-schedule-course-list .xs-caption>div {
                            font-size: 12px;
                            font-weight:500;
                        }
                        @media only screen and (max-width: 600px) {
                     
                            .ongoing-schedule-course .ongoing-schedule-course-summary .ongoing-schedule-course-list {
                                float: left;
                                text-align: center;
                                font-size: 14px;
                                font-weight: 500;
                                color: #3F4752;
                                padding: 0px 0px !important;
                                min-height: auto !important;
                                width: 29%;
                                margin-bottom: 30px;
                                margin-left: 0px;
                            }
                           
                       }
                        
                           // course schedule csss end
                    `}
                </style>
                
            <div className="container" id="searchContainer">
                <form onSubmit={(e) => this.onSearchSubmit(e)}>
                <div className="row">
                    <div className="col-md-1"></div>
                    <div className="col-md-3">
                        <div className="form-group">
                            <label for="course_name" id="labelText">Course name:</label>
                            <input type="text" name="name" className="form-control ongoingField" ref="anything" onChange={this.onChangeCourseName} value={this.state.name}/>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div class="form-group">
                            <label for="course_name" id="labelText">Session Type:</label>
                            <select className="form-control ongoingField">
                                {/* <option selected disabled>Session Type</option> */}
                                <option value="online" selected disabled>Online Classroom</option>
                                {/* <option value="classroom">Classroom</option>
                                <option value="one-to-one">One-to-One</option>
                                <option value="team/corporate">Team/Corporate Training</option> */}
                            </select>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="form-group">
                            <label for="course_name" id="labelText">Sessions</label>
                            <select className="form-control ongoingField" onChange={this.onChangeSession} value={this.state.session}>
                                {/* <option selected disabled>Sessions</option> */}
                                <option value="">All</option>
                                <option value="running">Ongoing</option>
                                <option value="upcoming">Upcoming</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-md-1">
                        <button className="searchButn">Clear</button>
                    </div>
                </div>
                </form>
            </div>

            <div className="ongoing-schedule-courses">
                <div className="container" >
                    <div className="row">
                        <div className="col-md-12 ongoing-schedule-calendar">
                            <div className="ongoing-schedule-list">
                                <div className="ongoing-schedule-captions">
                                    <div className="ongoing-schedule-captions-list">
                                        <span className="ongoingheadingTable">Course Name</span>
                                    </div>
                                    <div className="ongoing-schedule-captions-list">
                                        <span className="ongoingheadingTable">Delivery type</span>
                                    </div>
                                    <div className="ongoing-schedule-captions-list-time">
                                        <span className="ongoingheadingTable">Details</span>
                                    </div>
                                </div>
                                {this.state.course.length !== 0 ? this.state.course.map((course) =>  {
                                        return (<div className="ongoing-schedule-course clearfix">
                                        <div className="ongoing-schedule-course-summary clearfix ongoing-border-color-1">
                                            <div className="ongoing-schedule-course-list">
                                                <div className="clearfix">
                                                    <div className="visible-xs xs-title">
                                                    </div>
                                                    <div className="xs-caption">
                                                        <div className="ongoingdates" style={{ fontVariantNumeric: "oldstyle-nums" }}>
                                                           {course.course_title}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="ongoing-schedule-course-list">
                                                <div className="clearfix">
                                                    <div className="visible-xs xs-title">
                                                    </div>
                                                    <div className="xs-caption">
                                                        <div className="ongoing-schedule-delivery-type" >
                                                            Online
                                                        </div>
                                                        <div className="led">(Instructor Led)</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="ongoing-schedule-course-list-time">
                                                <div className="clearfix">
                                                    <div className="visible-xs xs-title">
                                                    </div>
                                                    <div className="xs-caption">
                                                        <div className="ongoing-schedule-delivery-type" style={{ fontVariantNumeric: "oldstyle-nums" }}>
                                                        <a href={`${course.slug}${this.state.uri}`}><button className="viewBtn">View</button></a>
                                                        </div>
                                                        <div></div>
                                                    </div>
                                                </div>
                                            </div> 
                                        </div>                                        										
                                    </div>

                                )}) : <h3 style={{ textAlign: 'center'}}>No Courses Found</h3>}					
                            </div>
                        </div>
                    </div>
                </div>
 			</div>
            </div>
        )
    }
}

export default schedule;