import React, { Component } from 'react';
import Header from '../src/components/liveTrainingDesign/liveTrainingHeader';
import Schedule from '../src/components/liveTrainingDesign/scheduleOngoing';
import ContactLiveTraining from "../src/components/liveTrainingDesign/liveTrainingContact.jsx";
import FooterTraining from "../src/components/liveTrainingDesign/footerTraining.jsx";

class OngoingSchedule extends Component{
    render(){
        return(
            <div>
                <Header /><br/><br/><br/>
                <Schedule /><br/><br/>
                <ContactLiveTraining />
                <FooterTraining />
            </div>
        )
    }
}

export default OngoingSchedule;