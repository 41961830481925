import React, { Component } from 'react';
import learner from '../liveTrainingDesign/assets/srlogo.png';

class FacebookLearners extends Component{
    render(){
        return(
            <div>
                <style jsx>
                    {`
                    .leanerReviewContainer{
                        background: #ffc339;
                        padding: 40px 20px;
                    }
                    .imageLearner{
                        width: 100%;
                        border-radius: 70px;
                    }
                    .learnerName{
                        text-align: center;
                        font-size: 28px;
                        font-weight: 600;
                    }
                    .learnerDate{
                        text-align: center;
                        font-size: 22px;
                        font-weight: 600;
                    }
                    .learnerP{
                        text-align: center;
                        font-size: 16px;
                    }
                    .learnerViewTxt{
                        font-size: 32px;
                        font-weight: 600;
                    }
                    `}
                </style>
                <div className="container-fluid leanerReviewContainer">
                    <div className="row" style={{ margin: '0',padding: '0'}}>
                        <div className="col-md-6 offset-md-1">
                            <h4 className="learnerViewTxt">Learners Review</h4>
                        </div>
                    </div><br/><br/><br/>
                    <div className="row" style={{ margin: '0',padding: '0'}}>
                        <div className="col-md-6">
                            <div className="row" style={{ margin: '0',padding: '0'}}>
                                <div className="col-md-2 offset-md-2">
                                    <img src={learner} alt="img" className="imageLearner"/>
                                </div>
                                <div className="col-md-8">
                                    <h3 className="learnerName">Chris Miguel</h3>
                                    <h5 className="learnerDate">August 20, 2020</h5>
                                    <p className="learnerP">for participating in Kraft-in association's House of Carpentry courses held at the Kraft-in office and showing exemplary performance in the courses taken.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="row" style={{ margin: '0',padding: '0'}}>
                                <div className="col-md-2 pull-right">
                                    <img src={learner} alt="img"  className="imageLearner"/>
                                </div>
                                <div className="col-md-8">
                                    <h3 className="learnerName">Chris Miguel</h3>
                                    <h5 className="learnerDate">August 20, 2020</h5>
                                    <p className="learnerP">for participating in Kraft-in association's House of Carpentry courses held at the Kraft-in office and showing exemplary performance in the courses taken.</p>
                                </div>
                            </div>
                        </div>
                    </div><br/><br/>

                    <div className="row" style={{ margin: '0',padding: '0'}}>
                        <div className="col-md-6">
                            <div className="row" style={{ margin: '0',padding: '0'}}>
                                <div className="col-md-2 offset-md-2">
                                    <img src={learner} alt="img" className="imageLearner"/>
                                </div>
                                <div className="col-md-8">
                                    <h3 className="learnerName">Chris Miguel</h3>
                                    <h5 className="learnerDate">August 20, 2020</h5>
                                    <p className="learnerP">for participating in Kraft-in association's House of Carpentry courses held at the Kraft-in office and showing exemplary performance in the courses taken.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="row" style={{ margin: '0',padding: '0'}}>
                                <div className="col-md-2 pull-right">
                                    <img src={learner} alt="img"  className="imageLearner"/>
                                </div>
                                <div className="col-md-8">
                                    <h3 className="learnerName">Chris Miguel</h3>
                                    <h5 className="learnerDate">August 20, 2020</h5>
                                    <p className="learnerP">for participating in Kraft-in association's House of Carpentry courses held at the Kraft-in office and showing exemplary performance in the courses taken.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}

export default FacebookLearners;