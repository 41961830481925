import React, { Component, Fragment }from 'react';
import Header from '../src/components/liveTrainingDesign/liveTrainingHeader';
import FooterTraining from "../src/components/liveTrainingDesign/footerTraining.jsx";
import ContactLiveTraining from "../src/components/liveTrainingDesign/liveTrainingContact.jsx";
// import UpcomingCarousel from "../src/components/liveTrainingDesign/upcomingCarousel.jsx";
import Carousel from '../src/components/liveTrainingDesign/carousel';
import GearsContainer from '../src/components/liveTrainingDesign/gearsContainer';
import WhatsappComponent from '../src/components/liveTrainingDesign/whatsappcomponent';

class UpcomingClasses extends Component {
    constructor(props) {
        super(props);
            console.log('proo', props)
            this.state = {
                heading: "Enroll Our Upcoming Classes",
                courseType: 'upcoming'
            }
        }
    render() {

        return (
            <div className="App">
                <Header /><br/><br/><br/>
                <GearsContainer />
                {/* <UpcomingCarousel /> */}
                <Carousel heading={this.state.heading} courseType={this.state.courseType}/><br/>
                <ContactLiveTraining />
                <WhatsappComponent />
                <FooterTraining />
            </div>
        );
    }
}
export default UpcomingClasses;

