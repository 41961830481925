import React, { Component } from 'react';
import FacebookBanner from './components/liveTrainingDesign/facebookBanner';
import FacebookOverview from './components/liveTrainingDesign/facebookCourseOverview';
import FacebookTools from './components/liveTrainingDesign/facebookToolsExplained';
import FacebookTrainingOptions from './components/liveTrainingDesign/facebookTrainingOptions';
import FacebookLearners from './components/liveTrainingDesign/facebookLearners';
import FaceBookBatch from './components/liveTrainingDesign/facebookChooseBatch';
import $ from 'jquery';
import jQuery from 'jquery';
import  { Redirect } from 'react-router-dom';

class Facebook extends Component{
    constructor(props){
        super(props);
        this.state = {
            facebook_courseimage: "",
            facebook_course_intro: "",
            facebook_course_overview: "",
            facebook_title: "",
            facebook_course_id: "",
            facebook_popup_message: "",
            facebook_toolsexplain: "",
            facebook_youtube_url: "",
            facebook_sessions: [],
            slugname: '',
        }
        this.getAdDetails = this.getAdDetails.bind(this);
     
    }
    componentDidMount(){
        console.log('test')
        this.getAdDetails();

        // $.getJSON("https://api.ipify.org?format=json", function (data) {
        //     console.log("IP: " + data.ip)
        // })

        // $.getJSON('https://ipapi.co/json/', function(data) {
        //     console.log(JSON.stringify(data, null, 2));
        // });

        // console.log("Hostname is :" + window.location.hostname)

        // var OSName="Unknown OS"; 
        // if (navigator.appVersion.indexOf("Win")!=-1) OSName="Windows"; 
        // if (navigator.appVersion.indexOf("Mac")!=-1) OSName="MacOS"; 
        // if (navigator.appVersion.indexOf("X11")!=-1) OSName="UNIX"; 
        // if (navigator.appVersion.indexOf("Linux")!=-1) OSName="Linux"; 
          
        // // Display the OS name 
        // alert(OSName) 
        // alert($.browser)
    }

    getAdDetails(){
        var completename = document.location.pathname.split('/');
        this.setState({slugname: completename[2]})
        console.log(completename[2],'pathname')
        fetch('https://live.skillrary.com/live-courses/index.php/api/v1/ads-page',{
            method: 'POST',
            headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				course_slug: completename[2]
			})
		})
      .then(res => res.json())
      .then((result) => {
            
            if (undefined !== result.result[0].id) {
                this.setState({ 
                    facebook_courseimage: result.result[0].course_img,
                    facebook_course_intro: result.result[0].course_intro,
                    facebook_course_overview: result.result[0].course_overview,
                    facebook_title: result.result[0].course_title,
                    facebook_course_id: result.result[0].id,
                    facebook_popup_message: result.result[0].popup_message,
                    facebook_toolsexplain: result.result[0].tools_explained,
                    facebook_youtube_url: result.result[0].youtube_url,
                    facebook_sessions: result.result[0].sessions
                })
            } else {
               this.props.history.push('/'); 
            }
		})
    }
    render(){
        console.log(this.state.facebook_sessions,'------sessions------')
        return(
            <div>
                {/* <p id="info"></p> */}
                <FacebookBanner slugname={this.state.slugname} courseId={this.state.facebook_course_id} facebook_title={this.state.facebook_title} FCourse_intro={this.state.facebook_course_intro} FPopup_message={this.state.facebook_popup_message} facebook_courseimage={this.state.facebook_courseimage}/><br/>
                <FacebookOverview facebook_title={this.state.facebook_title} facebook_course_overview={this.state.facebook_course_overview} facebook_youtube_url={this.state.facebook_youtube_url} />
                {/* <FacebookBanner /><br/>
                <FacebookOverview /><br/> */}
                <FacebookTools facebook_toolsexplain={this.state.facebook_toolsexplain} />
                {/* <FacebookTrainingOptions /><br/><br/> */}
                {/* <FacebookLearners /><br/><br/> */}
                {/* <FaceBookBatch /> */}
                <FaceBookBatch courseId={this.state.facebook_course_id} facebook_title={this.state.facebook_title} slugname={this.state.slugname} facebook_course_overview={this.state.facebook_course_overview} facebook_sessions={this.state.facebook_sessions} />
             </div>
        )
    }
}

export default Facebook;