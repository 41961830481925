import React, { Component } from 'react';
import srlogo from '../liveTrainingDesign/assets/user.jpg';
import $ from 'jquery';

class KnowMoreRating extends Component{
    constructor(props){
        super(props);
        this.state = {
            rating: ''
        }
    }
    componentDidMount(){
        $('#comment').on('click',function(){
            $('#commentForm').slideToggle('slow');
            // $(this).toggle('2000');
        })
    }
    render(){
        console.log(this.state.rating,'rating-------')
        return(
            <div>
                <style jsx>
                    {`
                    .commentContainer{
                        background: #dae3f5;
                        padding: 20px;
                    }
                    .formLabel{
                        color:#0548CE;
                    }
                    .user-rating {
                        direction: rtl;
                        font-size: 20px;
                        unicode-bidi: bidi-override;
                        padding: 10px 30px;
                        display: inline-block;
                    }
                    .user-rating input {
                        opacity: 0;
                        position: relative;
                        left: -15px;
                        z-index: 2;
                        cursor: pointer;
                    }
                    .user-rating span.star {
                        display: inline-block;
                        font-family: FontAwesome;
                        font-style: normal;
                        font-weight: normal;
                        position: relative;
                        z-index: 1;
                    }
                    .user-rating span {
                        margin-left: -10px;
                    }
                    .user-rating span.star:before {
                        color: #777777;
                        content:"\\f006";
                        /*padding-right: 5px;*/
                    }
                    .user-rating input:hover + span.star:before, .user-rating input:hover + span.star ~ span.star:before, .user-rating input:checked + span.star:before, .user-rating input:checked + span.star ~ span.star:before {
                        color: #ffd100;
                        content:"\\f005";
                    }                    
                    .submitComment{
                        background: #0548CE;
                        padding: 9px 15px;
                        border: 1px solid #0548ce;
                        font-size: 18px;
                        color: white;
                        margin-bottom: 20px;
                    }
                    .leaveComment{
                        background: #0548CE;
                        padding: 9px 15px;
                        border: 1px solid #0548ce;
                        font-size: 18px;
                        color: white;
                    }
                    .starRate{
                        margin: 3px;
                        color: gold;
                    }
                    .listRate{
                        list-style-type: none;
                        display: inline-flex;
                    }
                    .circle{
                        background: #ececec;
                        padding: 15px 20px;
                        border: 2px solid gold;
                        border-radius: 40px;
                    }
                    .coursePosition{
                        margin-left: 10px;
                    }
                    .user_image{
                        border-radius: 80px;
                        width: 100%;
                        max-width: 100px;
                        min-width: 100px;
                        margin-bottom: 20px;
                    }
                    .ratingContainer{
                        background: #EDEDED;
                        padding: 70px 55px;
                    }
                    @media only screen and (max-width : 600px){
                        .listRate {
                            list-style-type: none;
                            display: inline-flex;
                            margin-top: 20px;
                            padding: 0px;
                        }
                    }
                    `}
                </style>
                <div className="container-fluid ratingContainer">
                    <div className="row">
                        <div className="col-lg-8 col-md-8">
                            <div className="row">
                                <div className="col-lg-2 col-md-3">
                                    <span>5 <i className="fa fa-star starRate" aria-hidden="true"></i><i className="fa fa-star starRate" aria-hidden="true"></i><i className="fa fa-star starRate" aria-hidden="true"></i><i className="fa fa-star starRate" aria-hidden="true"></i><i className="fa fa-star starRate" aria-hidden="true"></i><br/>
                                    <span>2.5K Votes</span></span>
                                </div>
                                <div className="col-md-6">
                                    <ul className="listRate">
                                        <li className="coursePosition"><span className="circle">5</span> <br/><br/> <span>Course</span></li>
                                        <li className="coursePosition"><span className="circle">5</span> <br/><br/> <span>Trainer</span></li>
                                        <li className="coursePosition"><span className="circle">5</span> <br/><br/> <span>Videos</span></li>
                                        <li className="coursePosition"><span className="circle">5</span> <br/><br/> <span>Quality</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div><br/>
                    <div className="row">
                        <div className="col-lg-8 col-md-8">
                            <div className="row">
                                <div className="col-lg-2 col-md-3">
                                    <img src={srlogo} alt="img" className="user_image"/>
                                </div>
                                <div className="col-lg-9 col-md-9">
                                    <h4 style={{ fontSize: '18px', fontWeight: '600' }}>Excellent training and support <i className="fa fa-star starRate" aria-hidden="true"></i><i className="fa fa-star starRate" aria-hidden="true"></i><i className="fa fa-star starRate" aria-hidden="true"></i><i className="fa fa-star starRate" aria-hidden="true"></i><i className="fa fa-star starRate" aria-hidden="true"></i></h4>
                                    <span style={{ fontSize: '16px' }}>Varun Tyagi</span>    
                                    <p style={{marginTop: '10px' , fontSize: '16px'}}>He is very knowledgeable and caters his workouts to specific needs. I’ve had 5 sessions with him and I’m going to continue with him. Highly recommend him.</p>
                                </div>
                            </div>
                        </div>
                    </div><br/>
                    
                    <div className="row">
                        <div className="col-lg-8 col-md-8 ">
                            <div className="row">
                                <div className="col-lg-2 col-md-3">
                                    <img src={srlogo} alt="img" className="user_image"/>
                                </div>
                                <div className="col-lg-9 col-md-9">
                                    <h4 style={{ fontSize: '18px', fontWeight: '600' }}>Training was awesome <i className="fa fa-star starRate" aria-hidden="true"></i><i className="fa fa-star starRate" aria-hidden="true"></i><i className="fa fa-star starRate" aria-hidden="true"></i><i className="fa fa-star starRate" aria-hidden="true"></i><i className="fa fa-star starRate" aria-hidden="true"></i></h4>
                                    <span style={{ fontSize: '16px' }}>Jenna T.</span>    
                                    <p style={{marginTop: '10px' , fontSize: '16px'}}>Just started but my trainer was on time and seemed knowledgeable and nice. I feel it was a good match.</p>
                                </div>
                            </div>
                        </div>
                    </div><br/>
                    <div id="commentForm"  className="col-md-6 commentContainer" style={{ display: 'none' }}>
                        <div className="form-group">
                            <div className="row">
                                <div className="col-md-4 offset-md-3" style={{ marginBottom: '-4px' }}>
                                    <label className="formLabel">Upload your profile:</label>
                                    <input type="file" /><br/>
                                </div>
                            </div>
                        </div>
                        <div className="form-group"> 
                            <div className="row">
                                <div className="col-md-4 offset-md-3" style={{ marginBottom: '-10px' }}>
                                    <label className="formLabel">Name:</label>
                                    <input type="text" className="form-control" />
                                </div> 
                            </div>
                        </div>
                        <div className="form-group"> 
                            <div className="row">
                                <div className="col-md-4 offset-md-3" style={{ marginBottom: '-10px' }}>
                                    <label className="formLabel">Title:</label>
                                    <select name="title" className="form-control">
                                        <option selected disabled>Select your rating</option>
                                        <option value='ok'>Ok</option>
                                        <option value='average'>Average</option>
                                        <option value='good'>Good</option>
                                        <option value='excellent'>Excellent</option>
                                        <option value='awesome'>Awesome</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="form-group"> 
                            <div className="row">
                                <div className="col-md-4 offset-md-3" style={{ marginBottom: '-15px' }}>
                                    <label className="formLabel">Comments:</label>
                                    <input type="text" className="form-control" />
                                </div>
                            </div>
                        </div>
                        <div className="form-group"> 
                            <div className="row">
                                <div className="col-md-5 offset-md-3" style={{ marginBottom: '-10px' }}>
                                    <label className="formLabel">Rating:</label>
                                    <span class="user-rating">
                                        <input type="radio" name="rating" value="5" onChange={(v) => this.setState({rating: v.target.value})} /><span class="star"></span>
                                        <input type="radio" name="rating" value="4" onChange={(v) => this.setState({rating: v.target.value})} /><span class="star"></span>
                                        <input type="radio" name="rating" value="3" onChange={(v) => this.setState({rating: v.target.value})} /><span class="star"></span>
                                        <input type="radio" name="rating" value="2" onChange={(v) => this.setState({rating: v.target.value})} /><span class="star"></span>
                                        <input type="radio" name="rating" value="1" onChange={(v) => this.setState({rating: v.target.value})} /><span class="star"></span>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 offset-md-4">
                            <button  className="submitComment">Submit</button>
                        </div>
                    </div><br/>
                    {/* <p><button className="leaveComment" id="comment">Leave Tour Comment</button></p> */}
                </div>
            </div>
        )
    }
}

export default KnowMoreRating;