import React, { Component } from "react";
import { Link } from 'react-router-dom';
import DefaultImage from './assets/no-image.jpeg';
import CarouselLoader from "./carouselLoader.jsx";
import $ from 'jquery';
import ReactHtmlParser from 'react-html-parser';

class Carousel extends Component {
  constructor(props) {
    super(props);
    //console.log('prrr',props)
    this.state = {
      course: [],
      uri : '/ongoing-session',
      checkFlag: undefined
    }
    this.getCourse = this.getCourse.bind(this);
    this.getCourseCard = this.getCourseCard.bind(this);
    this.setCourseData = this.setCourseData.bind(this);
    this.detectmob = this.detectmob.bind(this);
  }
  componentDidMount() {
    $(window).scrollTop(0); 
    this.getCourse();
  }
  getCourse() {
    var apiUrl = "https://live.skillrary.com/live-courses/index.php/api/v1/live-running-sessions/virtusa";
    if (this.props.cid == "1" || this.props.courseType == "all") {
      apiUrl = "https://live.skillrary.com/live-courses/index.php/api/v1/all-live-courses/virtusa";
      this.state.uri =""
    }
    if (this.props.cid == "3" || this.props.courseType == "upcoming") {
      apiUrl = "https://live.skillrary.com/live-courses/index.php/api/v1/live-upcoming-sessions/virtusa";
      this.state.uri="/upcoming-session"
    }
    fetch(apiUrl, {
     method: 'GET',
    })
      .then(res => res.json())
      .then(
        (result) => {
     
         if (result.result !== "No records found") {
            
            this.setState({
              course: result.result,
            });
            
          } else {
            this.setState({
              checkFlag: "a",
            });
          }
        },
        (error) => {
          this.setState({
           isLoaded: true,
            error
          });
        }
      )
  }

  getCourseCard(course, i, l) {
  //console.log(l,'length===')
    return (
      // <div className={"carousel-item " +  ((window.innerWidth <= 800 ? (i == 1) : (i < 4))  ? "active" : "")} align="center">
      //   {course}
      // </div>
      // <div className={"carousel-item " + ((l <= 0) || (i == 4) ? "active" : "")} align="center">
      //   {course}
      // </div>
      <div className={"carousel-item " + ((window.innerWidth <= 800 ? (i == 1) : (l <= 0) || (i == 4)) ? "active" : "")} align="center">
        {course}
      </div>
    )
  }

  setCourseData(course) {

    /*
    if (undefined !== course.key_features) {
      var keyWord = course.key_features.split(",");
      var keyWordArray = [];
      if (keyWord.length) {
        for (var i=0; i <  keyWord.length;i++) {
          console.log('keydf', keyWord[i]);
          keyWordArray.push(<span className="points">
                          {keyWord[i]}
                        </span>);
        }
      }
    }
    */

    var keyWordArray = ReactHtmlParser(course.key_features);

    var ratingArray = [];
    
    if (null !== course.ratings) {

      var rating = course.ratings * 10;
      //console.log('rating', rating)
      var fullStar =  Math.floor(rating/10);

      var halfStar = rating%10;

      let emptyStar = 0;
      //console.log('fff', fullStar)
      for (var i = 0; i < fullStar; i++) {
        ratingArray.push(<span className="bottomRight"><i className="fa fa-enable fa-star"></i></span>);      
      }

      if (halfStar > 0) {
        ratingArray.push(<span className="bottomRight"><i className="fa fa-enable fa-star-half-o"></i></span>);
        emptyStar = 5 - (fullStar + 1);
      } else {
        emptyStar = 5 - (fullStar); 
      }
      //console.log('empty', emptyStar)  
      for (var i = 0; i < emptyStar; i++) {
        ratingArray.push(<span className="bottomRight"><i className="fa fa-star-o"></i></span>);      
      }
    } else {
      for (var i=0; i <  5;i++) {
        ratingArray.push(<span className="bottomRight"><i className="fa fa-star-o"></i></span>);
      }
    }

    return (
      
        <div className="col-md-3 col-sm-6"> 
          <div className="card" style={{ height: "360px", marginBottom: "10px"}} >
            <a className="completeCard">
              <span className="frontCard">
              <span style={{ textAlign: "center"}}>
                <img src={(null !== course.course_img) ? course.course_img : DefaultImage} className="frontLogo"/>
              </span>
              <span className="frontName">{course.course_title}</span>
              {/*<span className="frontDescription">{course.description} </span>*/}
              <span className="arrow">
                <span className="ratingFront">
                      {ratingArray}
                </span>
                <i className="fa fa-arrow-right pull-right" id="arrowIcon" ></i></span>
              </span>
              <span className="backCard">
                <span className="cardBackTopName" style={{  backgroundColor: "#33A478", borderBottom: "1px solid white" }}>
                  <span>{course.course_title}</span> 
                </span>
                <span className="secondPart">
                <span className="backDescription">
                  {keyWordArray}
                </span>
                  {/* <Link to={{ pathname:`/app1/${course.id}`,  state: { fromNotifications: true }}}>
                  */}
                    <Link to={`${course.slug}${this.state.uri}`}><button className="btn btn-warning viewBtn" id="viewDetailsBtn">
                    View Details
                    </button>
                    </Link>

                  {/* </Link> */}
                  {/* <span className="backCardBottom">
                    <span className="bottomLeft">
                      <span className="eyeIcon"><i className="fa fa-eye"></i>1325</span>
                      <span className="commentIcon"><i className="fa fa-comment-o"></i>1325</span>
                    </span>
                    <span className="bottomRight">
                      <i className="fa fa-enable fa-star"></i>
                      <i className="fa fa-enable fa-star"></i>
                      <i className="fa fa-enable fa-star"></i>
                      <i className="fa fa-enable fa-star"></i>
                      <i className="fa fa-enable fa-star-half-o"></i>
                    </span>
                  </span>
                  */}
                </span>
              </span>
            </a>              
          </div>
        </div>
    )

  }

  detectmob() {
    if(window.innerWidth <= 800) {
      return false;
    } else {
      return true;
    }
  }

  render() {
    
      //console.log('a', this.detectmob())
      const course = this.state;
      var i = 0;
      var courseValue = [];
      var courseCard = [];

      var winWidth = this.detectmob();
      //console.log('cou', course);

      if (course.course.length) {

        var self = this;
        Object.keys(course.course).map(function(keyName, keyIndex) {
          //console.log('adf', course.course[keyName].id);
          //console.log('i',i)
          if (winWidth) { 
            if (i > 3) {
              courseCard.push(self.getCourseCard(courseValue, keyName, (course.course.length - 1)));
              i = 0;
              courseValue = [];
            }

           // console.log('key',keyName,course.course.length)
            
            if (keyName == (course.course.length - 1) ) {
              courseValue.push(self.setCourseData(course.course[keyName]));
              courseCard.push(self.getCourseCard(courseValue, i, (course.course.length - 1)));
            } else {
              courseValue.push(self.setCourseData(course.course[keyName]));
            }

            i++;
          } else {
            courseValue.push(self.setCourseData(course.course[keyName]));
            courseCard.push(self.getCourseCard(courseValue, keyName, (course.course.length - 1)));
            courseValue = [];
          }

        })
      }
      // console.log('heading',this.props.Heading)
  return (
    <div>
    <style jsx>{`
     .backDescription ul li{
      list-style: disc;
    }
        .viewallButton{
          position: relative;
          left: 70%;
          transform: translate(-70%);
          top: 40px;
          background: #33A478;
          padding: 7px 15px;
          border: 1px solid #33A478;
          color: white;
          font-weight: 600;
          border-radius: 5px;
          cursor: pointer !important;
          z-index: 1;
        }
        .viewallButton:focus{
          outline: none;
        }
        .completeCard{
          height: 362px;
          border-radius: 3px;
          transition: all 0.8s;
          overflow: hidden;
          border: 0 none;
          margin-bottom: 15px;
          padding: 0;
        }
        .completeCard span{
          display: block;
        }
      .frontCard{
          // background: #33A478;
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
          transition: all 0.5s;
          background: linear-gradient(180deg, #33a478 3%, black);
          // background: linear-gradient(black 20%, #33a478 3%, black);
      }
      .frontLogo{
        display: inline-block;
        /* margin: 23px 0 54px 0; */
        padding: 10px;
        width: 100% !important;
        height: 200px !important;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-size: cover;
      }
      .frontName{
        font-weight: 700;
        font-size: 18px;
        color: #fff;
        line-height: 25px !important;
        text-align: center;
        height: 75px;
        /* overflow: hidden; */
        padding: 40px 15px;
      }
      .frontDescription{
          opacity: 0.69;
          font-weight: 700;
          font-size: 12px;
          color: #fff;
          line-height: 22px;
          text-align: center;
          height: 22px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          padding: 0 15px;
        }
        .arrow{
          bottom: 0;
          border-top: 1px solid white;
          margin-top: 29px;
          height: 50px;
          transition: all 0.5s; 
        }
        .arrow #arrowIcon{
          color: white;
          line-height: 49px;
          margin-right: 22px;
        }
        .ratingFront{
          margin-left:10px;
        }
      .backCard .backDescription{
        opacity: 0;
      }
      .backCard .backDescription{
        font-size: 14px;
          color: #a6a6a6;
          letter-spacing: 0;
          text-align: left;
          margin-bottom: 23px;
          line-height: 18px;
          height: 175px;
          overflow: hidden;
      }
      .secondPart{
          background-color: white;
          transition: all 0.5s;
          padding: 30px 15px 0;
        }
      .points{
          padding-left: 15px;
          position: relative;
          line-height: 18px;
          margin-bottom: 10px;
          font-size: 14px;
          color: #333;
      }
      .cardBackTopName{
          opacity: 0;
          border-radius: 3px 3px 0 0;
          height: 66px;
          vertical-align: middle;
          font-weight: 700;
          font-size: 16px;
          color: #fff;
          text-align: center;
          position: relative;
          transition: all 0.8s;

      }
      .cardBackTopName span{
          color: white;
          position: absolute;
          top: -100%;
          left: 0;
          right: 0;
          transition: all 0.6s;
          height: auto;
          padding: 0 25px;
      }
      .cardMainContent{
        transition: all 0.5s;
          padding: 30px 15px 0;
          background-color: #fff;
          opacity: 0;
      }
      .cardBackContent{
        font-size: 14px;
          color: #a6a6a6;
          letter-spacing: 0;
          text-align: left;
          margin-bottom: 23px;
          line-height: 18px;
          height: 140px;
          overflow: hidden;
      }
      #viewDetailsBtn{
        color: white;
        margin-bottom: 0 !important;
        margin: 0 auto !important;
        display: block;
        width: auto !important;
        font-size: 12px;
        background-color: #33A478;
        border: 1px solid #33A478;
        box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.16), 0 2px 3px 0 rgba(0,0,0,0.12);
      }
      a:hover{
        text-decoration: none;
      }
      .backCardBottom{
        border-top: 1px solid rgba(140,142,150,0.18);
          margin-top: 23px;
          height: 50px;
          line-height: 50px;
          position: relative;
      }
      .completeCard:hover .frontCard {
          opacity: 0;
          z-index: -1;
      }
      .completeCard:hover .cardBackTopName span{
        top: 50%;
          -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
      }
      .completeCard:hover .cardBackTopName {
          opacity: 1;
      }
      .completeCard:hover .backCard .backDescription{
        opacity: 1;
      }
      .completeCard:hover .backCardBottom{
        opacity: 1;
      }
      .backCardBottom {
        opacity: 0;
        
      }
      .eyeIcon i{
        font-size: 15px;
      }
      .bottomLeft span{
        float: left;
        display: inline-block !important;
        font-size: 12px;
        color: #33A478;
      }
      .commentIcon{
        padding-left: 5px;
      }
      .bottomRight{
        float: left;
        color: white;
        line-height: 46px;
        margin-left: 2px;
      }
      .col-md-3{
        display: inline-block;
        }
      .col-md-3 img{
        width:100%;
        height:auto;
      }

      body .no-padding{
        padding-left: 0;
        padding-right: 0;
      }
      // .demoBtn{
      //   position: relative;
      //   bottom: 40px;
      //   left: 16px;
      // }
      .enrollCourse{
        position: relative;
        bottom: 40px;
        left: 30px;
      }
      .textCenter{
        position: absolute;
        top: 30%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      .carousel-control-prev{
          opacity: 5 !important;
      }
      .carousel-control-prev-icon {
          background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
      }

      .carousel-control-next-icon {
          opacity: 3;
          background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
      }

      .kFBgBorder{
        border-top: 45px solid #33A478;
        margin-top: 10px;
      }
      .keyFeaturesHeading{
        color: white;
          margin-top: -38px;
          font-size: 20px;
          text-align: center;
      }
      .enrollOngoingClasses{
          font-weight: 700;
          font-size: 22px;
          color: #333742;
          letter-spacing: 1.5px;
          position: relative;
          padding: 0px 0px 10px 0px;
          margin-bottom: 20px;
      }
      .enrollOngoingClasses:before{
        content: ' ';
        background: #33A478;
        box-shadow: 0 4px 8px 0 rgba(76,215,200,0.3);
        width: 20%;
        height: 4px;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%);
      }
      @media only screen and (max-width: 600px){
        .enrollOngoingClasses{
          font-weight: 700;
          font-size: 22px;
          color: #333742;
          letter-spacing: 1.5px;
          position: relative;
          padding: 0px 0px 10px 0px;
          margin-bottom: 20px;
      }
      .enrollOngoingClasses:before{
        content: ' ';
        background: #33A478;
        box-shadow: 0 4px 8px 0 rgba(76,215,200,0.3);
        width: 70%;
        height: 4px;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%);
      }
      .viewallButton {
        position: relative;
        left: 50%;
        transform: translate(-50%);
        top: -20px;
        background: #33A478;
        padding: 7px 15px;
        border: 1px solid #33A478;
        color: white;
        font-weight: 600;
        border-radius: 5px;
        cursor: pointer !important;
        z-index: 9;
    }
      }
      @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
        .enrollCourse{
          position: relative;
          bottom: 71px;
          left: 22px;
          width: 38%;
        }
      }

      `}</style>

<Link to="/category"><button className="viewallButton">View all Courses</button></Link> 
<div id="ongoing"><h4 className="enrollOngoingClasses" style= {{ textAlign: 'center' }}>{this.props.heading}</h4><br/></div>

<div id={"demo" + this.props.id} className="carousel slide" data-ride="carousel" data-interval="10000">
  <div className="container carousel-inner no-padding">

{courseCard.length > 0 ? courseCard : undefined !== this.state.checkFlag ? (<h3 style={{ textAlign:'center' }}> No Course Found </h3>) : <CarouselLoader />}
</div>
{course.course.length > 4 ? 
(<span><a className="carousel-control-prev" href={"#demo" + this.props.id} data-slide="prev">
    <span className="carousel-control-prev-icon"></span>
</a>
<a className="carousel-control-next" href={"#demo" + this.props.id} data-slide="next">
    <span className="carousel-control-next-icon"></span>
</a></span>) : ""}

</div>
</div>
);

  }
}
 

export default Carousel;